import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import ReplyIcon from '@material-ui/icons/Reply';
import TabelaPaginada from '../partials/DataTablePaginadapadrao';
import {FaUsersCog} from 'react-icons/fa';
import Service from '../../services/dpo.service';
import userPerfilDefault from '../../assets/images/user-perfil-default.png';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SelectPadrao from '../partials/SelectPadrao';
import GerarSolicitacao
  from '../partials/solicitacao-exclusao-user/GerarSolicitacao';
import Visualizar from '../partials/solicitacao-exclusao-user/Visualizar';
import SolicitacaoResposta
  from '../partials/solicitacao-exclusao-user/SolicitacaoResposta';
import InputPesquisarPadrao from '../partials/InputPesquisarPadrao';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  buttonCadastro: theme.pagina.buttonCadastro,
  formCadastroTitulo: {
    margin: '15px 0 15px 0',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '85%',
  },
  button: {
    color: '#000',
    backgroundColor: '#FFF',
    border: 'solid 1px #005b96',
    //maxWidth: 100,
    height: '42px',
    marginTop: '15px',
    '&:hover': {
      color: '#005b96',
      backgroundColor: '#FFF',
      border: 'solid 1px #005b96',
    },
  },
  iconButton: {
    padding: 10,
    right: '-2%'
  },
});

class SolicitacaoExclusaoUser extends Component {
  constructor(props) {
    super(props);
    this.carregaDados = this.carregaDados.bind(this);
    this.onClickVisualizar = this.onClickVisualizar.bind(this);
    this.onClickResposta = this.onClickResposta.bind(this);

    this.state = {
      cod_solic_anonimizacao: null,
      str_seach: null,
      cod_tipo_solicita_seach: null,
      redirect: false,
      arrList: [],
      arrListOriginais: false,
      isLoadingVisualizarUsuario: true,
      isLoading: true,
      message: '',
      loading: false,
      successful: '',
      showForm: 'listar',
      arrTipoSolicitacaoSeach: [{
        'valor': 'A',
        'texto': 'Anônimo',
      }, {'valor': 'I', 'texto': 'Inatividade'}, {
        'valor': 'U',
        'texto': 'Usuário',
      }],
    };
  }

  componentDidMount() {
    this.carregaDados();
  }

  carregaDados = () => {
    const data = Promise.resolve(Service.getList({flg_situacao: ['E']}));
    data.then((v) => {
      this.populate(v);
    });
  };

  populate = (data) => {
    this.setState({
      arrList: data, isLoading: false,
    });
  };

  onClickResposta = (codSolicAnonimizacao) => {
    this.setState({
      showForm: 'resposta',
      cod_solic_anonimizacao: codSolicAnonimizacao,
    });
  };

  onClickVisualizar = (codSolicAnonimizacao) => {
    this.setState({
      showForm: 'visualzar',
      cod_solic_anonimizacao: codSolicAnonimizacao,
    });
  };

  onClickCadastrar = (codSolicAnonimizacao = null) => {
    this.setState({
      showForm: 'cadastrar',
      cod_solic_anonimizacao: codSolicAnonimizacao,
    });
  };

  onVoltar = () => {
    this.setState({
      showForm: 'listar',
      cod_solic_anonimizacao: null,
      isLoading: true,
    }, this.carregaDados);
  };

  strImagemPerfilFormatter = (imagem) => {
    return (
      <img src={imagem ? imagem : userPerfilDefault} className="img-perfil"
           style={{objectFit: 'cover'}}/>
    );
  };

  filtrarDados = () => {
    this.setState({isLoading: true});

    if (this.state.str_seach == null && this.state.cod_tipo_solicita_seach == null) {
      return this.carregaDados();
    }

    let result = [];
    if (this.state.arrListOriginais === false) {
      this.setState({arrListOriginais: this.state.arrList});
    }

    //diferencia os filtros
    if (this.state.str_seach != null && this.state.cod_tipo_solicita_seach != null) {
      if (this.state.arrListOriginais.length > 0) {
        result = this.state.arrListOriginais.filter((data) => {
          return (
            (data && data.str_nome_solicitante && data.str_nome_solicitante.toLowerCase().search(this.state.str_seach.toLowerCase()) != -1)
            && (data && data.tp_solicitacao && data.tp_solicitacao.toLowerCase().search(this.state.cod_tipo_solicita_seach.toLowerCase()) != -1)
          );
        });
      } else {
        result = this.state.arrList.filter((data) => {
          return (
            (data && data.str_nome_solicitante && data.str_nome_solicitante.toLowerCase().search(this.state.str_seach.toLowerCase()) != -1)
            && (data && data.tp_solicitacao && data.tp_solicitacao.toLowerCase().search(this.state.cod_tipo_solicita_seach.toLowerCase()) != -1)
          );
        });
      }
    } else if (this.state.str_seach == null && this.state.cod_tipo_solicita_seach != null) {
      if (this.state.arrListOriginais.length > 0) {
        result = this.state.arrListOriginais.filter((data) => {
          return (
            (data && data.tp_solicitacao && data.tp_solicitacao.toLowerCase().search(this.state.cod_tipo_solicita_seach.toLowerCase()) != -1)
          );
        });
      } else {
        result = this.state.arrList.filter((data) => {
          return (
            (data && data.tp_solicitacao && data.tp_solicitacao.toLowerCase().search(this.state.cod_tipo_solicita_seach.toLowerCase()) != -1)
          );
        });
      }
    } else if (this.state.str_seach != null && this.state.cod_tipo_solicita_seach == null) {
      if (this.state.arrListOriginais.length > 0) {
        result = this.state.arrListOriginais.filter((data) => {
          return (
            (data && data.str_nome_solicitante && data.str_nome_solicitante.toLowerCase().search(this.state.str_seach.toLowerCase()) != -1)
          );
        });
      } else {
        result = this.state.arrList.filter((data) => {
          return (
            (data && data.str_nome_solicitante && data.str_nome_solicitante.toLowerCase().search(this.state.str_seach.toLowerCase()) != -1)
          );
        });
      }
    }

    this.setState({arrList: result, isLoading: false});
  };

  render() {
    const {message, classes} = this.props;

    return (
      <>
        {
          this.state.showForm === 'cadastrar' &&
          <GerarSolicitacao
            codSolicAnonimizacao={this.state.cod_solic_anonimizacao}
            onVoltar={this.onVoltar}/>
        }
        {this.state.showForm === 'visualzar' &&
          <Visualizar
            codSolicAnonimizacao={this.state.cod_solic_anonimizacao}
            onVoltar={this.onVoltar}/>
        }
        {this.state.showForm === 'resposta' &&
          <SolicitacaoResposta
            codSolicAnonimizacao={this.state.cod_solic_anonimizacao}
            onVoltar={this.onVoltar}/>
        }
        {
          this.state.showForm === 'listar' &&
          <div className={classes.root}>
            <Grid container className={classes.conteudoFundoBranco}
                  style={{minWidth: '558px'}}>
              <Grid item xs={12} className={classes.formCadastroTitulo}>
                <FaUsersCog className={classes.iconeTitulo}/> <span
                className={classes.tituloPadrao}>Solicitações de Exclusão de Usuário</span>
              </Grid>
              {this.state.codInstrucaoBotaoPanico == null &&
                <>
                  <Grid container spacing={3} style={{marginTop: 5}}>
                    <Grid item xs={3} style={{minWidth: 355}}>
                      <InputPesquisarPadrao
                        stylePaper={{height: 51}}
                        iconButton={classes.iconButton}
                        placeholder="Pesquisar Solicitação"
                        exibirBotao={false}
                        onChange={(e) => {
                          this.setState({str_seach: e});
                        }}
                        value={this.state.str_seach || ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} style={{marginTop: '-7px'}}>
                      <SelectPadrao
                        label="Selecione o Tipo"
                        id="id-tipo"
                        value={this.state.cod_tipo_solicita_seach}
                        onChange={(e) => {
                          this.setState({cod_tipo_solicita_seach: e.target.value});
                        }}
                        arrDados={this.state.arrTipoSolicitacaoSeach}
                        isValueNull={true}
                      />
                    </Grid>

                    <Grid item xs={12} sm={1} style={{marginTop: '-8px'}}>
                      <Button variant="contained"
                              onClick={() => {
                                this.filtrarDados();
                              }} className={classes.button} color="primary">
                        Pesquisar
                      </Button>
                    </Grid>

                    <Grid item xs={12} sm={5} style={{marginTop: '-10px'}}>
                      <Button
                        onClick={() => {
                          this.onClickCadastrar(null);
                        }}
                        variant="outlined"
                        style={{minWidth: '170px'}}
                        className={classes.buttonCadastro}> Gerar Solicitação
                        Anônima</Button>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid container spacing={3}>
                      <Grid item xs={12} id="table" name="tabela-sem-borda">
                        <TabelaPaginada
                          arrCabecalho={['Foto', 'Nome', 'Data do requerimento da Exclusão', 'Tipo de solicitação', '', 'Ações']}
                          arrColunas={['str_imagem_perfil', 'str_nome_solicitante', 'dat_requerimento_fmt', 'tp_solicitacao_fmt', 'contador', 'cod_solic_anonimizacao']}
                          isLoading={this.state.isLoading}
                          style={{boxShadow: 'none!important'}}
                          mensagemSemRegistros="Nenhum usuário encontrado"
                          arrDados={
                            this.state.arrList
                          }
                          arrFuncoes={
                            {
                              str_imagem_perfil: {
                                chave: 'str_imagem_perfil',
                                funcao: this.strImagemPerfilFormatter,
                              },
                            }
                          }
                          arrAcoes={
                            {
                              cod_solic_anonimizacao: [
                                {
                                  chave: 'cod_solic_anonimizacao',
                                  funcao: this.onClickVisualizar,
                                  icone: SearchIcon,
                                },
                                {
                                  chave: 'cod_solic_anonimizacao',
                                  funcao: this.onClickResposta,
                                  icone: ReplyIcon,
                                },
                              ],
                            }
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            </Grid>
          </div>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(SolicitacaoExclusaoUser));
