import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Titulo, SubTitulo } from '../../../views/style/styles';
import ItinerarioService from "../../../services/itinerario.service";
import TabelaPaginada from '../DataTablePaginadapadrao';
import GoogleMapReact from 'google-map-react';

const REACT_APP_KEY_GOOGLE_MAPS_API = process.env.REACT_APP_KEY_GOOGLE_MAPS_API;

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
    )

const VisualizarItinerario = (props) => {

    const [arrDadosItinerario, setArrDadosItinerario] = React.useState([]);
    const [isLoadingVisualizar, setIsLoadingVisualizar] = React.useState(true);
    const [center, setCenter] = React.useState({ lat: -25.5003678, lng: -54.5684169 });
    const [zoom, setZoom] = React.useState(15);
    const [map, setMap] = React.useState(null);
    const [maps, setMaps] = React.useState(null);
    const [geoCoder, setGeoCoder] = React.useState(null);
    const [directionsRenderer, setDirectionsRenderer] = React.useState(null);
    const [directionsService, setDirectionsService] = React.useState(null);
    const [arrRotasNoMapa, setArrRotasNoMapa] = React.useState(null);

    useEffect(() => {
        carregarDadosItinerario();
    }, [props.codItinerario]);

    const createMapOptions = (maps) => {
        return {
            panControl: true,
            mapTypeControl: true,
            scrollwheel: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
        }
    }

    const carregarDadosItinerario = async () => {
        setIsLoadingVisualizar(true);
        if (props.codItinerario === null || props.codItinerario == undefined) {
            return false;
        }
        const arrDadosItinerario = await ItinerarioService.carregarDadosItinerario(props.codItinerario);
        setArrDadosItinerario(arrDadosItinerario);

        if (arrDadosItinerario.rotas) {
            setArrRotasNoMapa(arrDadosItinerario.rotas);
        }

        setIsLoadingVisualizar(false);
    }

    const addZero = (i) => {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    const handleApiLoaded = (map, maps) => {
        const directionsService = new maps.DirectionsService();
        const directionsRenderer = new maps.DirectionsRenderer();
        const geoCoder = new maps.Geocoder();
        setMap(map);
        setMaps(maps);
        setDirectionsService(directionsService);
        setDirectionsRenderer(directionsRenderer);
        setGeoCoder(geoCoder);
        if (arrRotasNoMapa) {
            calculateAndDisplayRoute(maps, directionsService, directionsRenderer, arrRotasNoMapa, map);
            directionsRenderer.setMap(map);
        }
    }

    const rad = function(x) {
        return x * Math.PI / 180;
     };
    
     //Get distance in meter
     const getDistance = function(p1, p2) {
         var R = 6378137; // Earth’s mean radius in meter
        var dLat = rad(p2.lat() - p1.lat());
        var dLong = rad(p2.lng() - p1.lng());
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(rad(p1.lat())) * Math.cos(rad(p2.lat())) *
          Math.sin(dLong / 2) * Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d; // returns the distance in meter
      };

    const isLocationOnEdge = (maps, response, map) => {
        
        var decodedPath = maps.geometry.encoding.decodePath(response.routes[0].overview_polyline);

        //if((!arrDadosItinerario.json_rota || arrDadosItinerario.json_rota == null) && decodedPath.length > 0)
        //{
            ItinerarioService.atualizarJsonRota(props.codItinerario, decodedPath);
        //}

        /*var polyline = new maps.Polyline(decodedPath);

        // (-25.501686129344257, -54.57281632995607)
        // (-25.50034, -54.573510000000006)
        const location = new maps.LatLng(-25.50034, -54.573510000000006);
        //const location = decodedPath[getRandomIntInclusive(0,decodedPath.length)];

        if(decodedPath.length > 0)
        {
            for (let index = 0; index < decodedPath.length; index++) {
                                
                if(getDistance(decodedPath[index],location) < 200)
                {
                    alert("Relocate! total of " + getDistance(decodedPath[index],location) + ' index: ' + index);
                    new maps.Marker({
                        position: decodedPath[index],
                        map,
                        title: "Hello World! " + index,
                      });
                }
                
            }
        }
        
        if (maps.geometry.poly.isLocationOnEdge(location, polyline)) {
            alert("Relocate 2!");
        }
        alert('end');
        return false;*/
        return true;
    };

    const calculateAndDisplayRoute = (maps, directionsService, directionsRenderer, arrRotasAtualizadas, map) => {
        const waypts = [];
        const arrPontos = arrRotasAtualizadas ?? [...arrRotasNoMapa];
        if (arrPontos.length > 0) {
            arrPontos.map((dado, index) => {
                if (index !== 0 && index !== (arrPontos.length - 1)) {
                    waypts.push({
                        location: new maps.LatLng(dado.num_latitude_local, dado.num_longitude_local),
                        stopover: true,
                    });
                }
            });
            directionsService
                .route({
                    // origin: new maps.LatLng(-25.4941118, -54.565236),
                    // destination: new maps.LatLng(-25.4914718, -54.561436),
                    origin: new maps.LatLng(arrPontos[0].num_latitude_local, arrPontos[0].num_longitude_local),
                    destination: new maps.LatLng(arrPontos[arrPontos.length - 1].num_latitude_local, arrPontos[arrPontos.length - 1].num_longitude_local),
                    waypoints: waypts,
                    optimizeWaypoints: true,
                    travelMode: maps.TravelMode.DRIVING,
                })
                .then((response) => {
                    isLocationOnEdge(maps,response, map);
                    directionsRenderer.setDirections(response);
                    const route = response.routes[0];
                })
                .catch((e) => console.log("Directions request failed due to", e));
        }
    }

    return (
        <>
            {isLoadingVisualizar === true &&
                <Grid container spacing={2} style={{ marginTop: 25 }} >
                    <Grid item xs={10} sm={10} style={{ textAlign: 'center' }} >
                        {mensagemPadraoCarregando}
                    </Grid>
                </Grid>
            }
            {isLoadingVisualizar === false &&
                <Grid container spacing={2} style={{ marginTop: 25 }} >
                    <SubTitulo>Dados do Itinerário</SubTitulo>
                    <Grid item xs={3} sm={3} >
                        <b>Caminhão</b>
                    </Grid>
                    <Grid item xs={9} sm={9} >
                        {arrDadosItinerario.str_identificador}
                    </Grid>
                    <Grid item xs={3} sm={3} >
                        <b>Dias de Coleta</b>
                    </Grid>
                    <Grid item xs={9} sm={9} >
                        {arrDadosItinerario.json_dia_coleta.replace('[', '').replace(']', '').replace(/"/g, '').replace(/,/g, '/')}
                    </Grid>
                    <SubTitulo>Locais de passagem</SubTitulo>
                    <TabelaPaginada
                        arrCabecalho={['#', 'Local', 'Horário']}
                        isLoading={isLoadingVisualizar}
                        arrDados={arrDadosItinerario.rotas}
                        exibePaginacao={false}
                        mensagemSemRegistros="Nenhum local de passagem inserido."
                    />
                    <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
                        <div style={{ height: '400px', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: REACT_APP_KEY_GOOGLE_MAPS_API, libraries:['places', 'geometry', 'drawing', 'visualization'] }}
                                defaultCenter={center}
                                defaultZoom={zoom}
                                options={createMapOptions}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                            >
                            </GoogleMapReact>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} style={{ textAlign: 'center' }} >
                        <Button variant="text" onClick={props.onVoltar} >Voltar</Button>
                    </Grid>
                </Grid>
            }
        </>
    );
};


export default VisualizarItinerario;