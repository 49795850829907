import React from 'react';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import FilledInput from '@material-ui/core/FilledInput';
import { Button } from '@material-ui/core';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';


const useStyle = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    //minWidth: 350,
    //maxWidth: 400,
  },
  button: {
    color: '#000',
    backgroundColor: '#FFF',
    border: 'solid 1px #005b96',
    //maxWidth: 100,
    height: '42px',
    marginTop: '25px',
    '&:hover': {
      color: '#005b96',
      backgroundColor: '#FFF',
      border: 'solid 1px #005b96',
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '85%',
  },
  iconButton: {
    padding: 10,
    right: '-4%'
  },
}));

export default function InputPesquisarPadrao(props) {

  const classes = useStyle();

  const handleEnviarDadosParaComponentePai = (event) => {
    if (typeof props.onChange === 'function') {
      props.onChange(event.target.value);
    }
  };

  const handlePressEnter = (e) => {
    e.key === 'Enter' && e.preventDefault();
    if (typeof props.onChange === 'function') {
      props.onChange(e.target.value);
    }
  };

  return (
    <>
      <Paper component="form" className={classes.root} style={props.stylePaper ?? {}} >
        <InputBase
          style={props.stylePaper ?? {}}
          className={classes.input}
          id={props.id}
          name={props.id}
          value={props.value}
          onKeyPress={handlePressEnter}
          onChange={handleEnviarDadosParaComponentePai}
          placeholder={props.placeholder || 'Pesquisar'}
          inputProps={{
            'aria-label': props.placeholder || 'Pesquisar',
          }}
        />
        <IconButton className={props.iconButton??classes.iconButton} aria-label="search" onClick={props.onClick} >
          <SearchIcon />
        </IconButton>
      </Paper>
      {props.exibirBotao === true &&
        <Button variant="contained" onClick={props.onClick} className={classes.button} color="primary">
          {props.labelBotao || 'Pesquisar'}
        </Button>
      }
    </>
  )
}
