import 'date-fns';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import {ThemeProvider} from '@material-ui/core';
import theme from './config/theme';
import {Provider} from "react-redux";
import store from "./store";
import {render} from 'react-dom';
import Footer from './components/layout/Footer';

startApp();

function startApp() {
  render(
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={theme}>
          <App/>
          <Footer/>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById('root'),
  )
}

serviceWorker.unregister();
