import axios from "axios";
import authHeader from './auth-header';
import {history} from "../helpers/history";
import trataErroApi from './trata-erro-api';

const API_URL = process.env.REACT_APP_API_URL
const authenticateTimeout = '';

class UserRegisterService {

  register(
    image,
    str_nome_pessoa,
    str_sobrenome,
    dat_nascimento,
    flg_genero,
    num_cpf,
    str_email,
    str_email_adicional,
    str_telefone,
    num_cep,
    cod_unidade_federacao,
    str_municipio,
    str_bairro,
    str_logradouro,
    str_complemento_endereco,
    num_residencial,
    cod_usuario,
    cod_pessoa,
    fazUploadImagem
  ) {
    return axios.post(API_URL + 'usuario/interno/cadastro', {
      image,
      str_nome_pessoa,
      str_sobrenome,
      dat_nascimento,
      flg_genero,
      num_cpf,
      str_email,
      str_email_adicional,
      str_telefone,
      num_cep,
      cod_unidade_federacao,
      str_municipio,
      str_bairro,
      str_logradouro,
      str_complemento_endereco,
      num_residencial,
      cod_usuario,
      cod_pessoa,
      fazUploadImagem,
      cod_usiuario_cadastro: JSON.parse(localStorage.getItem("user")).cod_usuario
    },
    {headers: authHeader()}
    );
  }

  async getAllByInputSearch(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "usuario/getAllByInputSearch", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }
}

export default new UserRegisterService();
