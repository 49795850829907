import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from './TypesAction';


import ColetaSeletivaService from '../services/coleta-seletiva.service';

const API_URL = process.env.REACT_APP_API_URL;


export const coletaSeletiva = (
  cod_instrucao_coleta,
  str_desc_instrucao,
  str_desc_instrucao_fmt,
  is_null,
) => (dispatch) => {
  return ColetaSeletivaService.coletaSeletiva(
    cod_instrucao_coleta,
    str_desc_instrucao,
    str_desc_instrucao_fmt,
    is_null,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};

export const pontoColeta = (
  cod_ponto_coleta,
  cod_unidade_federacao,
  str_nome_ponto_coleta,
  num_cep,
  str_logradouro,
  num_endereco,
  str_bairro,
  str_municipio,
  str_contato,
  str_link,
  json_dia_funcionamento,
) => (dispatch) => {
  return ColetaSeletivaService.pontoColeta(
    cod_ponto_coleta,
    cod_unidade_federacao,
    str_nome_ponto_coleta,
    num_cep,
    str_logradouro,
    num_endereco,
    str_bairro,
    str_municipio,
    str_contato,
    str_link,
    json_dia_funcionamento,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};
