import styled from 'styled-components';

export const Titulo = styled.h1`
    color: #3e3e3e;
    font-size: 23px;
`;

export const SubTitulo = styled.h2`
    width: 100%;
    color: #3e3e3e;
    font-size: 15px;
    border-bottom: 1px solid #ccc;
    padding: 0 10px 10px 10px;
    font-weight: 600;
`;
