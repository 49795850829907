import axios from "axios";
import {history} from "../helpers/history";
import authHeader from './auth-header';

const API_URL =  process.env.REACT_APP_API_URL
const authenticateTimeout = '';

class AuthService {

  login(username, password, manter_conectado) {
    return axios
      .post(API_URL + "auth_check", {username, password, manter_conectado})
      .then((response) => {
        if (response.data.token) {
          response.data.accessToken = response.data.token;
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  async isTokenValid() {
    return axios
      .post(API_URL + "usuario/isTokenValid", {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if(error.response.data.code === 401) {
          localStorage.clear();
          return 'error';
        }
     });
  }

  apiPostTest() {
    return axios
      .post(API_URL + "usuario/testedeapi", {'a' : 'a'})
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

  apiGetTest() {
    return axios
      .get(API_URL + "usuario/testedeapiget")
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }

   facebookAuthenticate(authResponse) {
    return axios
      .post(API_URL + "auth_facebook", {authResponse})
      .then((response) => {
        if (response.data.token) {
          response.data.accessToken = response.data.token;
          response.data.facebook = true;
          response.data.loginSocial = true;
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      })
  }

  googleAuthenticate(authResponse) {
    return axios
        .post(API_URL + "auth_google", {authResponse})
        .then((response) => {
          if (response.data.token) {
            response.data.accessToken = response.data.token;
            response.data.loginSocial = true;
            localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
        });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(str_email, emailConfirmation, str_nome_pessoa, str_sobrenome, dat_nascimento, flg_genero, password, passwordConfirmation, strongPassword) {
    return axios.post(API_URL + "usuario/cadastro", {
      str_email, emailConfirmation, str_nome_pessoa, str_sobrenome, dat_nascimento, flg_genero, password, passwordConfirmation, strongPassword,
      url: window.origin,via_web: true
      })
  }

  sendEmail(str_email, str_nome_pessoa, acao) {
    return axios.post(API_URL + "mailer/enviar_email", {str_email, str_nome_pessoa, url: window.origin, via_web: true, acao: acao});
  }

  registerConfirmation(token) {
    let params = {
      token: token,
      via_web: true
    }

    return axios.post(API_URL + "usuario/confirmar_cadastro", params);
  }

  registerConfirmationApp(token) {
    return axios.post(API_URL + "usuario/confirmar_cadastro", {token});
  }
}

export default new AuthService();
