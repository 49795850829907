import axios from "axios";
import authHeader from './auth-header';
import trataErroApi from './trata-erro-api';

const API_URL =  process.env.REACT_APP_API_URL

class NotificacoesService {

    async getAll(arrFiltros) {
      return axios
        .post(API_URL + "notificacao/getNotificacoes", arrFiltros, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async salvarNotificacao(arrDados) {
      return axios
        .post(API_URL + "notificacao/salvarNotificacao", arrDados, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async carregarDadosNotificacao(codNotificacao) {
      return axios
        .post(API_URL + "notificacao/carregarDadosNotificacao", {codNotificacao : codNotificacao}, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async excluirNotificacao(codNotificacao) {
      return axios
        .post(API_URL + "notificacao/excluirNotificacao", {codNotificacao : codNotificacao}, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async testarTokenMobile() {
      return axios
        .post(API_URL + "usuario/tokenMobile", {token : 'sadsadsadsadsadsadsadsa'}, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async enviar(codNotificacao) {
      return axios
        .post(API_URL + "notificacao/enviar", {codNotificacao : codNotificacao}, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

}export default new NotificacoesService();
