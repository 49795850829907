import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArquivoService from "../../../services/arquivo.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Titulo, SubTitulo } from '../../../views/style/styles';

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
    )

const HistoricoNotificacao = (props) => {

    const [arrDadosNotificacao, setArrDadosNotificacao] = React.useState(props.arrDadosNotificacao);
    const [isLoadingFile, setIsLoadingFile] = React.useState(false);

    const downloadArquivo = async (codArquivo, setLoading = 'upload') => {
        setIsLoadingFile(true);
        await ArquivoService.download(codArquivo);
        setIsLoadingFile(false);
    }

    const exibeHistorico = () => {

        const dataAlteracao = new Date(arrDadosNotificacao.historico.data_alteracao.replace(".", "").replace(".", ""));
        const dataInicio = new Date(arrDadosNotificacao.historico.data_inicial);
        const dataFim = new Date(arrDadosNotificacao.historico.data_final);
        const campoVazio = <span>-</span>;
        let nomeArquivo = campoVazio;
        if (arrDadosNotificacao.historico.cod_arquivo) {
            if (isLoadingFile === false)
                nomeArquivo = <a style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={() => downloadArquivo(arrDadosNotificacao.historico.cod_arquivo, 'historico')} >{arrDadosNotificacao.historico.str_descricao_arq}</a>;
            else
                nomeArquivo = mensagemPadraoCarregando;
        }
        let horarioFimExibicao = '';
        if (arrDadosNotificacao.historico.hora_fim) {
            const dataHoje = new Date();
            dataHoje.setHours(parseInt(arrDadosNotificacao.historico.hora_fim.split(":")[0]));
            dataHoje.setMinutes(parseInt(arrDadosNotificacao.historico.hora_fim.split(":")[1]));
            horarioFimExibicao = dataHoje.toLocaleTimeString();
        }
        let horarioInicioExibicao = '';
        if (arrDadosNotificacao.historico.hora_inicio) {
            const dataHoje = new Date();
            dataHoje.setHours(parseInt(arrDadosNotificacao.historico.hora_inicio.split(":")[0]));
            dataHoje.setMinutes(parseInt(arrDadosNotificacao.historico.hora_inicio.split(":")[1]));
            horarioInicioExibicao = dataHoje.toLocaleTimeString();
        }

        return (
            <Grid container spacing={2} style={{ marginTop: 25 }} >
                <SubTitulo>Histórico de alterações</SubTitulo>
                <Grid item xs={4} sm={4}>
                    <b>Alterado em</b> {dataAlteracao.toLocaleDateString()} {dataAlteracao.toLocaleTimeString()}
                </Grid>
                <Grid item xs={4} sm={4} style={{minWidth:200}}>
                    <b>Período de Exibição</b> {dataInicio.toLocaleDateString()} {horarioInicioExibicao} a {dataFim.toLocaleDateString()} {horarioFimExibicao}
                </Grid>
                <Grid item xs={4} sm={4} style={{ wordBreak: 'break-all' }} >
                    <b>Upload de Documentos</b> {nomeArquivo}
                </Grid>
                <Grid item xs={4} sm={4} style={{minWidth:250}}>
                    <b>Alterado por</b> {arrDadosNotificacao.historico.pessoa_alteracao}
                </Grid>
                <Grid item xs={8} sm={8} style={{ wordBreak: 'break-all', minWidth:317 }} >
                    <b>Link de Vídeo </b>
                    {arrDadosNotificacao.historico.str_link && arrDadosNotificacao.historico.str_link.length != 0 &&
                        <a href={arrDadosNotificacao.historico.str_link} target="_blank">{arrDadosNotificacao.historico.str_link}</a>
                    }
                    {(!arrDadosNotificacao.historico.str_link || arrDadosNotificacao.historico.str_link.length == 0) &&
                      <span> -</span>
                    }
                </Grid>
                <Grid item xs={4} sm={4} style={{wordBreak: 'break-all', minWidth:250}}>
                    <b>Título</b> {arrDadosNotificacao.historico.str_titulo}
                </Grid>
                <Grid item xs={4} sm={4}>
                    <b>Notificação Reenviada?</b> {arrDadosNotificacao.flg_reenvio_notificacao ? 'Sim' : 'Não'}
                </Grid>
                <Grid item xs={12} sm={12} style={{wordBreak: 'break-all', marginBottom: 15}}>
                    <b>Descrição</b> {arrDadosNotificacao.historico.str_descricao}
                </Grid>
            </Grid>
        );

    }

    return arrDadosNotificacao.historico ? exibeHistorico() : null;
};


export default HistoricoNotificacao;