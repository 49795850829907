import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import userPerfilDefault from '../../../assets/images/user-perfil-default.png';

const useStyles = makeStyles({
  root: {
    minWidth: 300,
  },
  title: {
    fontSize: 16,
  },
  paragraph: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardEquipe: {
    maxWidth: 336,
    flex: '1 1 auto',
    padding: '5px 5px 5px',
    margin: '5px 25px 25px 0',
    borderRadius: '5px',
    backgroundColor: 'rgba(242, 242, 242, 1)',
    border: 'solid 1px rgba(228, 228, 228, 1)',
    color: '#000'
  },
  buttonCard: {
    fontSize: 10,
    color: '#333333',
    backgroundColor: 'unset',
    border: 'solid 1px #333333',
    textTransform: 'initial',
    borderRadius: '5px',
    '&:hover': {
      color: '#333333',
      backgroundColor: '#FFF',
      border: 'solid 1px #333333',
    },
  },
  imgPerfil:{
    width: '80px',
    height: '80px',
    borderRadius: '100px',
  },
});

export default function SimpleCard(props) {
  const classes = useStyles();

  const visualizarEquipesDoUsuario = (valor, nome, sobrenome) => {
    if (typeof props.onClickVisualizarEquipesUsuario === 'function') {
      props.onClickVisualizarEquipesUsuario(valor, nome, sobrenome);
    }
  }

  const removerUsuarioDaEquipe = (valor) => {
    if (typeof props.removerUsuarioDaEquipe === 'function') {
      props.removerUsuarioDaEquipe(valor);
    }
  }

  return (
    <Card className={classes.cardEquipe} key={props.equipe.cod_usuario} >
      <CardContent>

        <Grid container spacing={3} style={{textAlign: 'center'}}>
          <Grid item xs={12}>
            {props.equipe.str_imagem_perfil == null || props.equipe.str_imagem_perfil == '' ? <AccountCircleIcon style={{fontSize: '80px'}}/> :  <img src={props.equipe.str_imagem_perfil} className={classes.imgPerfil} />}

          </Grid>
          <Grid item xs={12}>
              <Typography className={classes.title} color="textPrimary"
                          gutterBottom>
                <strong>{props.equipe.str_nome_pessoa} {props.equipe.str_sobrenome}</strong>
              </Typography>
              <Typography className={classes.paragraph} color="textPrimary">
                Email: {props.equipe.str_email}
              </Typography>
              <Typography color="textPrimary" style={{fontSize: 12}}>
                Último Acesso em {props.equipe.data_ultimo_acesso}
              </Typography>
          </Grid>
        </Grid>

      </CardContent>
      <CardActions style={{ textAlign: 'center', display: 'block' }}>
        <Button className={classes.buttonCard} onClick={() => visualizarEquipesDoUsuario(props.equipe.cod_usuario, props.equipe.str_nome_pessoa, props.equipe.str_sobrenome)} >Visualizar Equipes</Button>
        {props.tipoPesquisa !== 'pesquisa_por_usuarios' &&
          <Button className={classes.buttonCard} onClick={() => removerUsuarioDaEquipe(props.equipe.cod_usuario)} >Remover da Equipe</Button>
        }
      </CardActions>
    </Card>
  );
}
