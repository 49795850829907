import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Button, FormControl, Grid, Select} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import {FaPlus} from 'react-icons/fa';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import {SubTitulo} from '../../views/style/styles';
import {cpfMask, dateFormatterAmericano, formatDataComponent} from '../util';
import ModalNewPesquisa from '../partials/metricas/modalNovaPesquisa';
import Conteudo from '../partials/metricas/Conteudo';
import Graficos from '../partials/metricas/Graficos';
import InfGerais from '../partials/metricas/InfGerais';
import NuvemPalavras from '../partials/metricas/NuvemPalavras';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import SelectPadrao from '../partials/SelectPadrao';
import RedeSocialService from '../../services/rede-social';
import {timestampFormatter} from '../util';
import ModalPadrao from '../partials/ModalPadrao';
import UsuarioService from '../../services/usuario.service';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import '../../views/style/input-select-metricas.css';
import {Edit, EditAttributes, EditSharp} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import {REGISTER_SUCCESS, SET_MESSAGE} from '../../actions/TypesAction';
import moment from 'moment';
import {cpf} from 'cpf-cnpj-validator';

const styles = theme => ({
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  buttonCadastro: theme.pagina.buttonCadastro,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,
  buttonPesquisar: theme.pagina.buttonPesquisar,
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
    maxWidth: 400,
  },
  button: {
    color: '#000',
    backgroundColor: '#FFF',
    border: 'solid 1px #005b96',
    maxWidth: 100,
    height: '42px',
    marginTop: '25px',
    '&:hover': {
      color: '#005b96',
      backgroundColor: '#FFF',
      border: 'solid 1px #005b96',
    },
  },
  inputBuscar: {
    width: 400,
    margin: '15px 0',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '800px',
    minHeight: '400px',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
    fontFamily: theme.typography.fontFamily,
  },
  classNameLabel: {
    color: '#FFF !important',
    '&.Mui-focused': {
      color: '#FFF !important',
    },
  },
  classNameFormControl: {
    color: '#FFF !important',
    borderColor: 'red',
    margin: theme.spacing(1),
    width: '100%',
    MuiInputBase: {
      root: {
        color: '#FFF !important',
      },
      color: '#FFF !important',
    },
    '&.MuiInputBase-root': {
      color: '#FFF !important',
      input: {
        color: '#FFF !important',
      },
    },
  },
  spanHashtag: {
    backgroundColor: '#D2D5D8',
    color: '#FFF',
    padding: 5,
    margin: 5,
    borderRadius: 3,
    cursor: 'pointer',
  },
  spanHashtagAtivo: {
    backgroundColor: '#585858',
    color: '#FFF',
    padding: 5,
    margin: 5,
    borderRadius: 3,
    cursor: 'pointer',
  },
  mensagemInformativa: {
    color: '#FFF',
    backgroundColor: '#1976d2',
    padding: '10px 10px 10px',
    textAlign: 'center',
  },
  formCadastro: {
    margin: '10px',
  },
});

const mensagemPadraoCarregando =
  (
    <span>
      <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
    </span>
  );

const arrCoresHashtag = [
  '#C0392B',
  '#AF7AC5',
  '#2980B9',
  '#1ABC9C',
  '#229954',
  '#F4D03F',
  '#BA4A00',
  '#34495E',
  '#2ECC71',
  '#138D75',
  '#2E86C1',
  '#A569BD',
  '#F1948A',
];

class MetricasApp extends Component {
  constructor(props) {
    super(props);
    this.carregarMetricasApp = this.carregarMetricasApp.bind(this);
    this.onClickPesquisar = this.onClickPesquisar.bind(this);
    this.onChangeCodPesquisa = this.onChangeCodPesquisa.bind(this);

    this.state = {
      user: localStorage.getItem('user'),
      flg_login_social: JSON.parse(localStorage.getItem('user')).login_social,
      mensagemAlert: {mensagem: null, severity: 'success', closeButton: true},
      isLoading: true,
      openModalPesquisa: false,
      openModalExcluir: false,
      isExcluirPesquisa: false,

      dt_inicio: null,
      dt_fim: null,
      dt_max: null,
      dt_min: null,

      cod_pesquisa: null,
      arrPesquisaIsLoaded: false,
      arrDataPesquisa: [],
      arrComboPesaquisa: [],
      arrHashtag: [],
      arrPesquisa: [],
      arrConteudo: [],
      arrDadosGraficos: [],

      arrUltimaPesquisa: [],
      arrPeriodoSelecionado: [],

      //arrInfGerais: [{periodo_selecionado: [], ultima_pesquisa: []}],
      arrDadosNuvem: [],
      arrNotSelect: [],

      mensagemInformativa: 'Selecione uma pesquisa.',
      dat_ultima_pesquisa: null,

      conteudo: 'T',
      password: '',
      num_cpf: '',
      passwordErro: null,
      messageModal: {mensagem: null, severity: 'success'},
    };
  }

  componentDidMount() {
    this.carregarMetricasApp();
  }

  carregarMetricasApp = () => {
    this.setState({arrPesquisaIsLoaded: false});

    const data = Promise.resolve(RedeSocialService.getPesquisa({order: {tmsCriacao: 'DESC'}}));
    data.then((v) => {
      this.populate(v);
    });
  };

  populate = (data) => {
    if (data != '') {
      this.setState({arrComboPesaquisa: data, arrPesquisaIsLoaded: true});
      this.setState({isLoading: false});
    } else {

      this.setState({arrComboPesaquisa: [], arrPesquisaIsLoaded: true});
      this.setState({arrPesquisa: []});
      this.setState({isLoading: false});
    }
  };

  openModal = () => {
    this.setState({
      openModalPesquisa: true,
      arrDataPesquisa: [],
    });
  };

  handleClose = () => {
    this.setState({
      openModalPesquisa: false,
    });
  };

  openModalExcluir = () => {
    this.setState({
      openModalExcluir: true,
      isExcluirPesquisa: false,
    });
  };

  handleCloseExcluir = () => {
    this.setState({
      openModalExcluir: false,
      conteudo: 'T',
      password: '',
      passwordErro: null,
      messageModal: {mensagem: null, severity: 'success'},
    });
  };

  onChangeDtInicio = (value) => {
    this.setState({
      dt_inicio: value,
      dt_fim: null,
      dt_max: value ? formatDataComponent(dateFormatterAmericano(value.toLocaleDateString())) : null,
      mensagemAlert: {mensagem: null, severity: null, closeButton: false},
    });
  };

  onChangeDtFim = (value) => {
    this.setState({
      dt_fim: value,
      mensagem_erro_data: null,
      mensagemAlert: {mensagem: null, severity: null, closeButton: false},
    });
  };

  onClickPesquisar = () => {
    this.setState({
      mensagemAlert: {mensagem: null, severity: null, closeButton: false},
    });

    if (this.state.dt_inicio == null || this.state.dt_fim == null) {
      this.setState({
        mensagemAlert: {
          mensagem: 'Por favor preencha as datas do período a ser pesquisado.',
          severity: 'error',
          closeButton: true,
        },
      });
      return;
    }

    if (this.state.dt_inicio >= this.state.dt_fim) {
      this.setState({
        mensagemAlert: {
          mensagem: 'Data Fim não pode ser inferior ou igual a data atual',
          severity: 'error',
          closeButton: true,
        },
      });
      return;
    }

    let data = new Date(this.state.dt_min);
    data = data.setDate(data.getDate() - 1);
    data = new Date(data);

    if (this.state.dt_inicio.toLocaleDateString() < data.toLocaleDateString()) {
      this.setState({
        mensagemAlert: {
          mensagem: 'Data Inicio não pode ser menor que ' + data.toLocaleDateString() + '.',
          severity: 'error',
          closeButton: true,
        },
      });
      return;
    }

    this.carregaDadosMetricas(this.state.cod_pesquisa);
  };

  onChangeCodPesquisa = (e) => {
    this.setState({
      cod_pesquisa: e.target.value,
      mensagemAlert: {mensagem: null, severity: null, closeButton: false},
      dt_inicio: null,
      dt_fim: null,
    });

    if (e.target.value != undefined && e.target.value != null && e.target.value != '') {
      this.carregaDadosMetricas(e.target.value);
    } else {
      this.setState({
        arrPesquisa: '',
        mensagemInformativa: 'Selecione a Pesquisa.',
        dat_ultima_pesquisa: null,
      });
    }
  };

  carregaDadosMetricas = async (value) => {
    this.setState({isLoading: true});

    let parans = {
      codPesquisaRedeSocial: value,
      dt_inicio: this.state.dt_inicio,
      dt_fim: this.state.dt_fim,
      notInSelect: this.state.arrNotSelect,
    };

    await RedeSocialService.getDadosMetricas(parans).then(
      (response) => {
        let arrDados = response.data;

        if (arrDados.status != undefined && !arrDados.status) {
          this.setState({
            mensagemAlert: {
              mensagem: arrDados.messagem??"Não foi possível processar essa pesquisa no momento, tente novamente mais tarde.",
              severity: 'error',
              closeButton: true,
            },
            isLoading: false,
            mensagemInformativa: null,
            arrPesquisa: [],
          });
          return;
        }

        if (arrDados.status != undefined && arrDados.status) {
          this.setState({
            mensagemAlert: {
              mensagem: arrDados.messagem,
              severity: 'info',
              closeButton: true,
            },
            isLoading: false,
            mensagemInformativa: null,
            arrPesquisa: [],
          });
          return;
        }

        this.setState({arrHashtag: arrDados.arrHashtags.arrTags});
        this.setState({arrPesquisa: arrDados.arrHashtags});
        this.setState({arrConteudo: arrDados.conteudo});
        this.setState({arrDadosGraficos: arrDados.dadosGraficos});
        this.setState({dat_ultima_pesquisa: this.state.arrPesquisa.tms_atualizacao ? timestampFormatter(this.state.arrPesquisa.tms_atualizacao) : timestampFormatter(this.state.arrPesquisa.tms_criacao)});
        this.setState({arrDadosNuvem: arrDados.arrNuuvemPalavras});
        this.setState({arrPeriodoSelecionado: arrDados.infosGerais});
        this.setState({
          mensagemAlert: {
            mensagem: null,
            severity: null,
            closeButton: null,
          },
        });
        this.setState({dt_min: arrDados.arrHashtags.tms_criacao});

        if (arrDados.infosGerais.total < 1) {
          // this.setState({
          //   mensagemAlert: {
          //     mensagem: 'Essa pesquisa não possui dados no momento.',
          //     severity: 'info',
          //     closeButton: true,
          //     isLoading: false,
          //   },
          // });
          this.setState({
            arrPesquisa: '',
            mensagemInformativa: 'Essa pesquisa não possui dados no momento.',
          });
          return;
        }

        if (arrDados !== '') {
          this.getDadosPesquisa();
        }
      },
      (error) => {
        this.setState({
          isLoading: false,
          mensagemAlert: {
            mensagem: error.response.data.messagem ?? 'Não foi possível processar essa pesquisa no momento, tente novamente mais tarde.',
            severity: 'error',
            closeButton: true,
          },
          mensagemInformativa: null,
          arrPesquisa: [],
          dat_ultima_pesquisa: null,
        });
      },
    );

    this.setState({isLoading: false});
  };

  onClickEditPesquisa = async () => {
    if (this.state.cod_pesquisa == null) {

      this.setState({
        mensagemAlert: {
          mensagem: 'Selecione uma pesquisa para poder realizar a edição',
          severity: 'error',
          closeButton: true,
        },
      });
      return;
    }

    this.setState({
      mensagemAlert: {mensagem: null, severity: null, closeButton: null},
    });

    let dadosPesquisa = await RedeSocialService.getDadosPesquisa({codPesquisaRedeSocial: this.state.cod_pesquisa});
    this.setState({openModalPesquisa: true, arrDataPesquisa: dadosPesquisa});
  };

  onClickDelete = async () => {
    this.setState({
      isExcluirPesquisa: true,
      messageModal: {mensagem: null, severity: 'success'},
    });

    if (this.state.cod_pesquisa == null) {
      this.setState({
        mensagemAlert: {
          mensagem: 'Selecione uma pesquisa para poder apagar',
          severity: 'error',
          closeButton: true,
        },
        openModalExcluir: false,
      });
      return;
    }

    if (this.state.password.length < 1) {
      this.setState({
        passwordErro: 'Campo Obrigatório.',
        isExcluirPesquisa: false,
      });
      return;
    }

    if(this.state.flg_login_social && !cpf.isValid(this.state.password)){
      this.setState({
        passwordErro: 'CPF inválido.',
        isExcluirPesquisa: false,
      });
      return;
    }

    let validaPassword = await UsuarioService.validarSenha({
      password: this.state.password,
      num_cpf: this.state.password,
      login_social: this.state.flg_login_social,
      });

    if (!validaPassword.status) {
      this.setState({
        messageModal: {
          mensagem: this.state.flg_login_social ? 'CPF incorreta' : 'Senha incorreta.',
          severity: 'error',
        }, isExcluirPesquisa: false,
      });
      return;
    } else {
      this.setState({
        messageModal: {
          mensagem: 'Aguarde uns instantes, estamos deletando essa pesquisa do sistema.',
          severity: 'success',
        },
      });
    }

    let isDelete = await RedeSocialService.deletePesquisa({cod_pesquisa_rede_social: this.state.cod_pesquisa});
    if (isDelete['status']) {
      this.setState({isLoading: true});
      this.setState({
        mensagemAlert: {
          mensagem: isDelete['message'],
          severity: 'success',
          closeButton: true,
        },
        cod_pesquisa: null,
        arrPesquisa: '',
        mensagemInformativa: 'Selecione uma Pesquisa.',
      });
      this.carregarMetricasApp();
    } else {
      this.setState({
        mensagemAlert: {
          mensagem: isDelete['message'],
          severity: 'error',
          closeButton: true,
        },
      });
    }

    this.handleCloseExcluir();
  };

  onClickHashtag = async (event) => {
    let arrNot = this.state.arrNotSelect;

    if (arrNot.indexOf(event) > -1) {
      let index = arrNot.indexOf(event);
      arrNot.splice(index, 1);
    } else {
      arrNot.push(event);
    }

    this.setState({arrNotSelect: arrNot});

    this.carregaDadosMetricas(this.state.cod_pesquisa);
  };

  getDadosPesquisa = () => {
    const pesquisa = Promise.resolve(RedeSocialService.getDadosMetricas({
        codPesquisaRedeSocial: this.state.cod_pesquisa,
        notInSelect: this.state.arrNotSelect,
        acao: 'ultima_pesquisa',
      },
    ));

    pesquisa.then((result) => {
      this.setState({
        arrUltimaPesquisa: result.data.infosGerais,
        arrDadosNuvem: result.data.arrNuuvemPalavras,
      });
    });
  };

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root} style={{minWidth: '1054px'}}>
        <Grid item xs={12} sm={12}
              style={{
                color: '#FFF',
                backgroundColor: '#1976d2',
                padding: 15,
                borderRadius: '5px 5px 0px 0px',
                margin: '25px 15px 0px',
                width: '96.6%',
              }}>
          <Grid container>
            <Grid item xs={4} style={{paddingTop: 20}}>
              <TrendingUpIcon className={classes.iconeTitulo}
                              style={{color: '#FFF'}}/>
              <span className={classes.tituloPadrao} style={{color: '#FFF'}}>Métricas Aplicativo</span>
            </Grid>

            <Grid item xs={2} style={{
              textAlign: 'right',
              paddingTop: 20,
              paddingRight: 20,
            }}>
              <FaPlus onClick={() => {
                this.openModal();
              }} style={{
                marginLeft: 15,
                cursor: 'pointer',
                fontSize: 28,
                color: '#FFF',
              }}/>
            </Grid>
            <Grid item xs={3} style={{textAlign: 'right'}}>
              <FormControl className={classes.classNameFormControl}>
                <InputLabel className={classes.classNameLabel}
                            id="demo-simple-select-outlined-label">{this.state.cod_pesquisa == null || this.state.cod_pesquisa == '' ? 'Selecione a Pesquisa' : null}</InputLabel>
                <Select
                  MenuProps={{
                    style: {
                      maxWidth: 0,
                      maxHeight: 300,
                      position: 'absolute',
                    }, disableScrollLock: true,
                  }}
                  labelId="demo-simple-select-outlined-label"
                  id="cod_pesquisa"
                  fullWidth
                  name="cod_pesquisa"
                  value={this.state.cod_pesquisa}
                  onChange={this.onChangeCodPesquisa}
                >
                  {this.state.arrComboPesaquisa.length === 0 &&
                    <MenuItem key="no-data-found" disabled>Não foram encontrados
                      registros.</MenuItem>
                  }

                  {this.state.arrComboPesaquisa.length > 0 &&
                    <MenuItem value="">
                      <em>Selecione a pesquisa</em>
                    </MenuItem>
                  }

                  {this.state.arrComboPesaquisa.length > 0 &&
                    Object.values(this.state.arrComboPesaquisa).map(
                      (row, index) =>
                        <MenuItem value={row.codPesquisaRedeSocial}
                                  key={row.codPesquisaRedeSocial}>{row.strNomePesquisa}</MenuItem>,
                    )
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} style={{paddingTop: 25, textAlign: 'right'}}>
              {this.state.dat_ultima_pesquisa != null &&
                <>
                  Última
                  pesquisa: {this.state.dat_ultima_pesquisa}
                </>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.conteudoFundoBranco} style={{
          margin: '0px 15px 35px 15px',
          boxShadow: '0px 0px 0px 1px #efefef',
          borderRadius: '0px 0px 5px 5px',
          overflowWrap: 'anywhere',
        }}>
          {this.state.isLoading === true &&
            <Grid item xs={12} sm={12}
                  style={{marginTop: 25, textAlign: 'center'}}>
              {mensagemPadraoCarregando}
            </Grid>
          }

          {this.state.mensagemAlert.mensagem !== null &&
            <Grid item xs={12} style={{marginBottom: 15, marginTop: 15}}>
              <Alert
                severity={this.state.mensagemAlert.severity}
                action={
                  this.state.mensagemAlert.closeButton === true &&
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({
                        mensagemAlert: {
                          mensagem: null,
                          severity: 'success',
                          closeButton: true,
                        },
                      });
                    }}
                  >
                    <CloseIcon fontSize="inherit"/>
                  </IconButton>
                }
              >
                {this.state.mensagemAlert.mensagem}
              </Alert>
            </Grid>
          }

          {this.state.isLoading === false && this.state.arrPesquisa != '' &&
            <>
              <Grid container spacing={3} style={{paddingTop: '10px'}}>
                <Grid xs={6} style={{padding: '12px 0 0 15px'}}>
                  {this.state.arrPesquisa.str_nome_pesquisa && <>
                    <label htmlFor="">
                      <strong>{this.state.arrPesquisa.str_nome_pesquisa} :</strong>
                    </label>
                    {this.state.arrHashtag.map((hash, index) => (
                      <span className={classes.spanHashtagAtivo}
                            id={hash.str_hashtag}
                            style={{backgroundColor: this.state.arrNotSelect.indexOf(hash.str_hashtag) > -1 ? '#D2D5D8' : arrCoresHashtag[index] ? arrCoresHashtag[index] : '#FFFFFF'}}
                            onClick={() => {
                              this.onClickHashtag(hash.str_hashtag);
                            }}
                      >#{hash.str_hashtag.toUpperCase()}
                      </span>
                    ))}
                  </>}
                </Grid>
                <Grid xs={6} style={{textAlign: 'right'}}>
                  <EditSharp onClick={() => {
                    this.onClickEditPesquisa();
                  }} style={{
                    margin: '10px 0 0 0',
                    cursor: 'pointer',
                    fontSize: 30,
                    color: '#7E84A3',
                  }}
                             titleAccess="Editar Pesquisa"
                  />
                  <DeleteIcon onClick={() => {
                    this.openModalExcluir();
                  }}
                              style={{
                                margin: '10px 0 0 0',
                                cursor: 'pointer',
                                fontSize: 30,
                                color: '#7E84A3',
                              }}
                              titleAccess="Excluir Pesquisa"
                  />
                </Grid>
              </Grid>

              <SubTitulo></SubTitulo>
              <Grid container spacing={3}>
                <Grid item xs={3} sm={4}></Grid>

                <Grid item xs={12} sm={2} style={{paddingTop: 34}}>
                  <label htmlFor="">
                    <strong>Apresentar dados por data:</strong>
                  </label>
                </Grid>

                <Grid item xs={12} sm={2} style={{paddingTop: 22}}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}
                                           locale={ptBrLocale}>
                    <KeyboardDatePicker
                      label="Data Início"
                      autoOk={true}
                      name="dataInicio"
                      id="dataInicio"
                      fullWidth
                      variant="inline"
                      invalidDateMessage="Data inválida"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      minDateMessage="A data início não pode ser menor que a data atual"
                      InputAdornmentProps={{position: 'end'}}
                      value={this.state.dt_inicio}
                      onChange={this.onChangeDtInicio}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={2} style={{paddingTop: 22}}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}
                                           locale={ptBrLocale}>
                    <KeyboardDatePicker
                      label="Data Fim"
                      autoOk={true}
                      name="dataFim"
                      id="dataFim"
                      fullWidth
                      variant="inline"
                      invalidDateMessage="Data inválida"
                      inputVariant="outlined"
                      format="dd/MM/yyyy"
                      minDateMessage={'Data Fim não pode ser inferior a data atual'}
                      minDate={this.state.dt_max}
                      InputAdornmentProps={{position: 'end'}}
                      value={this.state.dt_fim}
                      onChange={this.onChangeDtFim}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} sm={2}>
                  <Button style={{width: 150}}
                          type="button"
                          variant="outlined"
                          className={classes.buttonCadastro}
                          onClick={() => {
                            this.onClickPesquisar();
                          }}
                  >
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <InfGerais
                  arrUltimaPesquisa={this.state.arrUltimaPesquisa}
                  arrPeriodoSelecionado={this.state.arrPeriodoSelecionado}
                  arrPesquisa={this.state.arrPesquisa}>
                </InfGerais>
              </Grid>

              <Grid container>
                <Graficos arrCoresHashtag={arrCoresHashtag}
                          arrDadosGraficos={this.state.arrDadosGraficos}
                          arrHashtag={this.state.arrHashtag}></Graficos>
              </Grid>

              <Grid container>
                <NuvemPalavras arrDadosNuvem={this.state.arrDadosNuvem}
                               codPesquisaRedeSocial={this.state.cod_pesquisa}
                               dt_incio={this.state.dt_inicio}
                               dt_fim={this.state.dt_fim}
                               arrNotSelect={this.state.arrNotSelect}>
                </NuvemPalavras>
              </Grid>

              <Grid container>
                <Conteudo arrConteudo={this.state.arrConteudo}
                          acao={'metricas'}>
                </Conteudo>
              </Grid>
            </>
          }
          {
            this.state.arrPesquisa == '' && this.state.isLoading === false && this.state.mensagemInformativa != null && <>
              <Grid item xs={12} className={classes.formCadastro}>
                <Paper
                  className={classes.mensagemInformativa}>{this.state.mensagemInformativa}</Paper>
              </Grid>

            </>
          }
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={this.state.openModalPesquisa}
          onClose={this.handleClose}
          closeAfterTransition
          disableScrollLock
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          disableBackdropClick={true}
        >
          <Fade in={this.state.openModalPesquisa}>
            <Grid item xs={5}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <Grid container alignItems="center">
                    <Grid item xs={10}>
                      <h5>{this.state.cod_pesquisa == null ? ' Nova Pesquisa' : 'Editar Pesquisa'} </h5>
                    </Grid>
                    <Grid item xs={2} style={{textAlign: 'right'}}>
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={this.handleClose}
                      >
                        <CloseIcon fontSize="inherit"/>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <hr></hr>
                  <ModalNewPesquisa
                    onVoltar={this.handleClose}
                    arrDataPesquisa={this.state.arrDataPesquisa}></ModalNewPesquisa>
                </Paper>
              </Grid>
            </Grid>
          </Fade>
        </Modal>
        <ModalPadrao
          open={this.state.openModalExcluir}>
          <h5>{this.state.conteudo === 'T' ? 'Aviso!' : 'Confirme sua senha'} </h5>

          {this.state.messageModal.mensagem !== null &&
            <Alert severity={this.state.messageModal.severity}>
              {this.state.messageModal.mensagem}
            </Alert>
          }

          <hr></hr>
          {
            <>
              {this.state.conteudo === 'T' &&
                <p>
                  Deseja mesmo deletar essa pesquisa? <br/>
                  <b>Todos</b> os dados relacionados a ela
                  serão <b>excluídos</b> do
                  sistema. <br/>
                  Recomendamos que <b>baixe o XLS</b> com os dados na página de
                  conteúdo antes de <b>excluir</b>.
                </p>
              }

              {this.state.conteudo === 'P' &&
                <>
                  {this.state.flg_login_social &&
                    <TextField
                      error={this.state.passwordErro != undefined && this.state.passwordErro ? true : false}
                      helperText={this.state.passwordErro != undefined && this.state.passwordErro ? this.state.passwordErro : null}
                      label="Digite seu cpf"
                      id="num_cpf"
                      type="num_cpf"
                      variant="outlined" fullWidth
                      value={this.state.password}
                      onChange={(event) =>
                        (this.setState({
                          password: cpfMask(event.target.value),
                          passwordErro: null,
                        }))
                      }
                    />
                  }

                  {!this.state.flg_login_social &&
                    <TextField
                      error={this.state.passwordErro != undefined && this.state.passwordErro ? true : false}
                      helperText={this.state.passwordErro != undefined && this.state.passwordErro ? this.state.passwordErro : null}
                      label="Digite sua senha"
                      id="password"
                      type="password"
                      variant="outlined" fullWidth
                      value={this.state.password}
                      onChange={(event) =>
                        (this.setState({
                          password: event.target.value,
                          passwordErro: null,
                        }))
                      }
                    />
                  }
                </>
              }
            </>
          }

          <hr></hr>
          <>
            {this.state.conteudo === 'T' &&
              <>
                <Button variant="outlined"
                        onClick={() => this.setState({conteudo: 'P'})}
                        style={{width: 80}}>Sim</Button> &nbsp;
                <Button variant="text"
                        onClick={() => {
                          this.handleCloseExcluir();
                        }}>Não</Button>
              </>
            }

            {this.state.conteudo === 'P' &&
              <div style={{textAlign: 'right'}}>
                {this.state.isExcluirPesquisa === false &&
                  <>
                    <Button variant="outlined"
                            onClick={() => this.onClickDelete()}
                            style={{width: 80}}>Confirmar</Button> &nbsp;
                    <Button variant="text"
                            onClick={() => {
                              this.handleCloseExcluir();
                            }}>Cancelar</Button>
                  </>
                }
                {this.state.isExcluirPesquisa === true &&
                  <>
                    <Button variant="outlined" disabled style={{width: 80}}>
                      <CircularProgress
                        style={{width: 20, height: 20, marginRight: 15}}/>
                    </Button> &nbsp;
                    <Button variant="text" disabled>Cancelar</Button>
                  </>
                }
              </div>
            }
          </>
        </ModalPadrao>

      </div>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(MetricasApp));
