import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import ColetaSeletivaService from '../../services/coleta-seletiva.service';
import CloseIcon from '@material-ui/icons/Close';
import {Alert} from '@material-ui/lab';
import TabelaPaginada from '../partials/DataTablePaginadapadrao';
import {FaMap} from 'react-icons/fa';
import EditIcon from '@material-ui/icons/Edit';
import ModalPadrao from '../partials/ModalPadrao';
import {telefoneMask} from '../util';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

import Visualizar from '../partials/ponto-coleta/Visualizar';
import Cadastrar from '../partials/ponto-coleta/Cadastrar';
import ColetaService from '../../services/coleta-seletiva.service';

const API_URL = process.env.REACT_APP_API_URL;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,
  buttonCadastro: theme.pagina.buttonCadastro,
  mainBox: {
    overflowY: 'auto',
    width: '100%',
    position: 'fixed',
    margin: 0,
    padding: 0,
    top: 0,
    left: 0,
    height: '100%',
  },
  modalExclusao: {
    boxShadow: theme.shadows[5],
  },
  titleModal: {
    borderBottom: '1px solid #ccc',
  },
  actionsModal: {
    borderTop: '1px solid #ccc',
  },
  headerPanel: {
    backgroundColor: '#185b8d',
    width: '100%',
    height: 176,
  },
  logo: {
    width: 350,
    lineHeight: 94,
  },
  logoDiv: {
    width: '100%',
    textAlign: 'center',
  },
  button: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    background: theme.palette.background.button,
    padding: '10px 10px',
    lineHeight: 1.4,
    borderRadius: 30,
    width: '100%',
  },
  content: {
    backgroundColor: '#FFF',
    marginTop: '20px',
  },
  buttonDiv: {
    textAlign: '-webkit-center',
  },
  buttonContainer: {
    marginTop: 20,
    width: 300,
    marginBottom: 20,
  },
  link: {
    color: '#979797',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  storeButton: {
    width: 150,
    lineHeight: 94,
  },
  contentFooterDiv: {
    marginTop: '1%',
    marginBottom: '1%',
  },
  sMediaDiv: {
    width: '10%',
  },
  sMediaButton: {
    width: 50,
    marginRight: 10,
  },
  footerDiv: {
    textAlign: 'center',
    marginTop: '1%',
  },
  buttonGreen: {
    color: '#fff',
    backgroundColor: '#28a745',
    borderColor: '#28a745',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#28A745',
      border: '0px',
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    width: '85%',
  },
  iconButton: {
    padding: 10,
    right: '-4%'
  },
  message: {
    marginBottom: '10px',
  },
});

class ListarIntrucao extends Component {
  constructor(props) {
    super(props);
    this.getListPaginated = this.getListPaginated.bind(this);
    this.onClickExcluir = this.onClickExcluir.bind(this);
    this.onClickVisualizar = this.onClickVisualizar.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.excluirIntrucao = this.excluirIntrucao.bind(this);
    this.handleConfirmaExclusao = this.handleConfirmaExclusao.bind(this);

    this.state = {
      open: null,
      codPontoColeta: null,
      codPontoColetaModal: null,
      redirect: false,
      arrList: [],
      arrListOriginais: false,
      isLoadingVisualizarUsuario: true,
      isLoading: true,
      message: '',
      loading: false,
      successful: '',
      showForm: 'listar',
    };
  }

  componentDidMount() {
    this.getListPaginated();
  }

  getListPaginated = () => {
    const data = Promise.resolve(ColetaService.getAll());
    data.then((v) => {
      this.populate(v);
    });
  };

  populate = (data) => {
    this.setState({
      arrList: data, isLoading: false,
    });
  }

  onClickExcluir(codPontoColeta) {
    this.setState({
      message: '',
      open: true,
      codPontoColetaModal: codPontoColeta,
    });
  }

  onClickEditar = (codPontoColeta) => {
    this.setState({showForm: 'editar', codPontoColeta: codPontoColeta});
  };

  onClickVisualizar = (codPontoColeta) => {
    this.setState({showForm: 'visualzar', codPontoColeta: codPontoColeta});
  };

  onClickCadastrar = (codPontoColeta) => {
    this.setState({showForm: 'cadastrar', codPontoColeta: codPontoColeta});
  };

  onVoltar = () => {
    this.setState({
      showForm: 'listar',
      codPontoColeta: null,
      isLoading: true,
    }, this.getListPaginated());
  };

  handleConfirmaExclusao(e) {
    this.setState({
      open: false,
      successful: false,
    });
    if (this.state.open) {
      this.excluirIntrucao(this.state.codPontoColetaModal);
    }
  };

  handleClose(e) {
    this.setState({
      open: false,
    });
  }

  // filtrarPontoColeta = (event) => {
  //   if (event.target.value.length === 0) {
  //     return this.setState({arrList: this.state.arrListOriginais});
  //   }
  //   let value = event.target.value.toString().toLowerCase();
  //   let result = [];
  //
  //   if (this.state.arrListOriginais === false) {
  //     this.setState({arrListOriginais: this.state.arrList});
  //   }
  //
  //   if (this.state.arrList.length == 0) {
  //     result = this.state.arrListOriginais.filter((data) => {
  //       console.log(data);
  //       return (
  //         (data && data.str_nome_ponto_coleta && data.str_nome_ponto_coleta.toLowerCase().search(value.toLowerCase()) != -1)
  //       );
  //     });
  //   } else {
  //     result = this.state.arrList.filter((data) => {
  //       return (
  //         (data && data.str_nome_ponto_coleta && data.str_nome_ponto_coleta.toLowerCase().search(value.toLowerCase()) != -1)
  //       );
  //     });
  //   }
  //   this.setState({arrList: result});
  // }

  filtrarPontoColeta = (event) => {
    if (event.target.value.length === 0) {
      return this.setState({arrList: this.state.arrListOriginais});
    }
    let value = event.target.value.toString().toLowerCase();
    let result = [];

    if (this.state.arrListOriginais === false) {
      this.setState({arrListOriginais: this.state.arrList});
    }

    if (this.state.arrListOriginais.length > 0) {
      result = this.state.arrListOriginais.filter((data) => {
        return (
          (data && data.str_nome_ponto_coleta && data.str_nome_ponto_coleta.toLowerCase().search(value.toLowerCase()) != -1)
        );
      });
    } else {
      result = this.state.arrList.filter((data) => {
        return (
          (data && data.str_nome_ponto_coleta && data.str_nome_ponto_coleta.toLowerCase().search(value.toLowerCase()) != -1)
        );
      });
    }
    this.setState({arrList: result});
  }

  excluirIntrucao = async (id) => {
    const retorno = await ColetaSeletivaService.excluirPontoColeta(id);
    if (retorno.status) {
      this.setState({
        message: 'Ponto de coleta excluído com sucesso.',
        successful: true,
      });
      this.getListPaginated();
    } else {
      this.setState({
        successful: false,
        message: 'Não foi possível excluir o ponto de coleta.',
      });
    }
  };

  linkFormater = (value) => {
    let strLink = value != '' || value != undefined || value != null ? value : ' - ';
    return (
      strLink
    );
  };

  contatoFormater = (value) => {
    return telefoneMask(value);
  };

  render() {
    const {message, classes} = this.props;

    return (
      <>
        {this.state.showForm === 'cadastrar' &&
        <Cadastrar codPontoColeta={this.state.codPontoColeta}
                   onVoltar={this.onVoltar}/>
        }
        {this.state.showForm === 'editar' &&
        <Cadastrar codPontoColeta={this.state.codPontoColeta}
                   onVoltar={this.onVoltar}/>
        }
        {this.state.showForm === 'visualzar' &&
        <Visualizar codPontoColeta={this.state.codPontoColeta}
                    onVoltar={this.onVoltar}/>
        }
        {
          this.state.showForm === 'listar' &&
          <div className={classes.root}>
            <Grid container className={classes.conteudoFundoBranco} style={{minWidth: '471px'}}>
              <Grid item xs={12} className={classes.formCadastroTitulo}>
                <FaMap className={classes.iconeTitulo}/> <span
                className={classes.tituloPadrao}>{'Gerenciar Pontos de Coleta'}</span>
              </Grid>
              {this.state.message && (
                <Grid container className={classes.message}>
                  <Grid item xs={12}>
                    <Alert
                      severity={this.state.successful ? 'success' : 'error'}>{this.state.message}</Alert>
                  </Grid>
                </Grid>
              )}
              {this.state.codPontoColeta == null &&
              <>
                <Grid container>
                  <Grid item xs={12} sm={6} style={{padding: '15px 0', minWidth: 331}}>
                    <Paper>
                      <InputBase
                        name="Busca"
                        id="Busca"
                        className={classes.input}
                        placeholder="Pesquisar Postos de Coleta"
                        inputProps={{'aria-label': 'search google maps'}}
                        onChange={this.filtrarPontoColeta}
                        autoComplete="off"
                      />
                      <IconButton className={classes.iconButton}
                                  aria-label="search">
                        <SearchIcon/>
                      </IconButton>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button onClick={() => {this.onClickCadastrar(null);}}
                            variant="outlined"
                            style={{minWidth: '142px'}}
                            className={classes.buttonCadastro}>Cadastrar Pontos de Coleta
                    </Button>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid container spacing={3}>
                    <Grid item xs={12} id="tabela-listar-usuario" name="tabela-sem-borda">
                      <TabelaPaginada
                        arrCabecalho={['Ponto', 'Endereço', 'Contato', 'Ações']}
                        isLoading={this.state.isLoading}
                        style={{boxShadow: 'none!important'}}
                        mensagemSemRegistros="Nenhum ponto de coleta cadastrado"
                        arrDados={
                          this.state.arrList
                        }
                        arrFuncoes={
                          {
                            str_contato: {
                              chave: 'str_contato',
                              funcao: this.contatoFormater,
                            },
                            str_link: {
                              chave: 'str_link',
                              funcao: this.linkFormater,
                            },
                          }
                        }
                        arrAcoes={
                          {
                            cod_ponto_coleta: [
                              {
                                chave: 'cod_ponto_coleta',
                                funcao: this.onClickVisualizar,
                                icone: SearchIcon,
                              },
                              {
                                chave: 'cod_ponto_coleta',
                                funcao: this.onClickEditar,
                                icone: EditIcon,
                              },
                              {
                                chave: 'cod_ponto_coleta',
                                funcao: this.onClickExcluir,
                                icone: CloseIcon,
                              },
                            ],
                          }
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
              }
              <ModalPadrao open={this.state.open} handleCloseModal={this.handleClose} >
                  <h5>Aviso!</h5>
                  <hr></hr>
                  <p>Deseja Excluir este Ponto de Coleta?</p>
                  <hr></hr>
                  <Button onClick={this.handleConfirmaExclusao}
                          variant="outlined">
                    Sim
                  </Button> &nbsp;
                  <Button onClick={this.handleClose}>
                    Não
                  </Button>
              </ModalPadrao>
            </Grid>
          </div>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(ListarIntrucao));
