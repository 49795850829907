import React, {useEffect} from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { chromeTabsStylesHook } from '@mui-treasury/styles/tabs';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(  {
    fundoAba : {
        backgroundColor: '#ECECEC',
        color: "#aaaaaa",
        borderTop: 'solid 1px #000',
        borderLeft: 'solid 1px #000',
        borderRight: 'solid 1px #000',
        margin: '0px 1px 0px',
        '&.Mui-selected' : {
            backgroundColor: '#FFF',
            color: "#000",
        }
    },
    tabIndicator: {
        backgroundColor: '#52CBE9'
    },
});

const AbasEquipe = (props) => {
    const arrDados = props.arrDados ?? [];
    const [tabIndex, setTabIndex] = React.useState(false);
    const tabsStyles = chromeTabsStylesHook.useTabs();
    const tabItemStyles = chromeTabsStylesHook.useTabItem();
    const classes = useStyles();


    const onChangeAba = (event, index) => {
        if(index === tabIndex){
            return true;
        }
        setTabIndex(index);
        if (typeof props.onChange === 'function') {
            props.onChange(index);
          }
    }

    // For componentDidUpdate
    useEffect(() => {
        if(arrDados[0])
            setTabIndex(arrDados[0][Object.keys(arrDados[0])[0]]);
    }, [arrDados]);

    return (
        <Tabs
            classes={{indicator: classes.tabIndicator}}
            value={tabIndex}
            onChange={props.valorInicial || onChangeAba}
        >
        {arrDados.length > 0 && arrDados.map(dado => {
            const valor = dado[props.option && props.option.valor ? props.option.valor : Object.keys(dado)[0]];
            const texto = dado[props.texto && props.option.texto ? props.option.texto : Object.keys(dado)[1]];
            return (
                    <Tab key={valor} value={valor} label={texto} />
                )
            })
        }
        </Tabs>
    );
};


export default AbasEquipe;
