import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import ItinerarioService from '../../../services/itinerario.service';
import CaminhaoService from '../../../services/caminhoes.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Titulo, SubTitulo } from '../../../views/style/styles';
import TextField from '@material-ui/core/TextField';
import SelectPadrao from '../SelectPadrao';
import { cepMaks } from '../../../components/util';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    DateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import TabelaPaginada from '../DataTablePaginadapadrao';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import GoogleMapReact from 'google-map-react';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import InfoIcon from '@material-ui/icons/Info';
import ModalPadrao from '../../partials/ModalPadrao';

const REACT_APP_KEY_GOOGLE_MAPS_API = process.env.REACT_APP_KEY_GOOGLE_MAPS_API;

const useStyle = makeStyles((theme) => ({
    formButton: theme.pagina.formButton,
    button: {
        color: '#000',
        backgroundColor: '#FFF',
        border: 'solid 1px #005b96',
        maxWidth: 100,
        height: '42px',
        marginTop: '25px',
        '&:hover': {
            color: '#005b96',
            backgroundColor: '#FFF',
            border: 'solid 1px #005b96',
        },
    },
    span: {
        '&. MuiFormControlLabel-label': {
            color: '#000',
        },
    },
    label: {
        marginTop: 30,
    },
    item: {
        margin: theme.spacing(1),
    },
}));

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
    );

const botaoPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20 }} />
        </span>
    );


const CadastrarItinerario = (props) => {

    const classes = useStyle();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSaving, setIsSaving] = React.useState(false);
    const [LoadingMapa, setLoadingMapa] = React.useState(false);
    const [isLoadingEditar, setIsLoadingEditar] = React.useState(false);
    const [edicao, setEdicao] = React.useState('false');
    const [formCadastro, setFormCadastro] = React.useState({});
    const [errosCampo, setErrosCampo] = React.useState([]);
    const [codItinerario, setCodItinerario] = React.useState(null);
    const [codCaminhao, setCodCaminhao] = React.useState('');
    const [logradouro, setLogradouro] = React.useState('');
    const [cep, setCep] = React.useState('');
    const [bairro, setBairro] = React.useState('Vila A');
    const [arrDadosItinerario, setArrDadosItinerario] = React.useState([]);
    const [arrCaminhoes, setArrCaminhoes] = React.useState([]);
    const [segunda, setSegunda] = React.useState(null);
    const [terca, setTerca] = React.useState(null);
    const [quarta, setQuarta] = React.useState(null);
    const [quinta, setQuinta] = React.useState(null);
    const [sexta, setSexta] = React.useState(null);
    const [sabado, setSabado] = React.useState(null);
    const [domingo, setDomingo] = React.useState(null);
    const [horario, setHorario] = React.useState(null);
    const [jsonDiasColeta, setJsonDiasColeta] = React.useState([]);
    const [jsonRota, setJsonRota] = React.useState([]);
    const [arrRotasNoMapa, setArrRotasNoMapa] = React.useState([]);
    const [indexRota, setIndexRota] = React.useState(null);
    const [center, setCenter] = React.useState({ lat: -25.5003678, lng: -54.5684169 });
    const [zoom, setZoom] = React.useState(15);
    const [map, setMap] = React.useState(null);
    const [maps, setMaps] = React.useState(null);
    const [geoCoder, setGeoCoder] = React.useState(null);
    const [directionsRenderer, setDirectionsRenderer] = React.useState(null);
    const [directionsService, setDirectionsService] = React.useState(null);
    const [arrLagLong, setArrLagLong] = React.useState([]);
    const [botaoLoadingRota, setBotaoLoadingRota] = React.useState(false);
    const [arrRotasEditadasNoMapa, setArrRotasEditadasNoMapa] = React.useState([]);
    const [arrLatLngUsadas, setArrLatLngUsadas] = React.useState([]);
    const [erroLink, setErroLink] = React.useState(false);
    const [pontoMapa, setPontoMapa] = React.useState(null);
    const [openModalInfo, setOpenModalInfo] = React.useState(false);

    useEffect(() => {
        carregarDadosItinerario();
        carregarCaminhoes();
    }, [props.codItinerario]);

    useEffect(() => {
        atualizaListaDeRotas();
    }, [arrLatLngUsadas]);

    const createMapOptions = (maps) => {
        return {
            panControl: true,
            mapTypeControl: true,
            scrollwheel: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
        }
    }

    const getHorarioPorGeolocalizacao = (lat, lng) => {
        return arrRotasNoMapa.filter((rota) => {
            return rota.num_latitude_local == lat && rota.num_longitude_local == lng;
        });
    }

    const atualizaListaDeRotas = async () => {

        // console.log('arrLatLngUsadas', arrLatLngUsadas);
        // console.log('arrRotasNoMapa', arrRotasNoMapa);

        // Inclusão de novos locais
        if (geoCoder != undefined) {
            let arrRotas = [];
            let arrMap = arrLatLngUsadas.map(async (rota, index) => {
                let lat = rota.split(',')[0] * 1;
                let lng = rota.split(',')[1] * 1;

                let jaExiste = [];
                if (arrRotasNoMapa[index]) {
                    jaExiste = getHorarioPorGeolocalizacao(arrRotasNoMapa[index].num_latitude_local, arrRotasNoMapa[index].num_longitude_local);
                }
                if (jaExiste.length === 0) {
                    jaExiste = getHorarioPorGeolocalizacao(lat, lng);
                }

                const jaExisteAproveitamentoRota = getHorarioPorGeolocalizacao(lat, lng);

                if (!arrRotasNoMapa[index] || (arrRotasNoMapa[index] && arrRotasNoMapa[index].num_latitude_local != lat && arrRotasNoMapa[index].num_longitude_local != lng)) {
                    let novaRota = jaExisteAproveitamentoRota.length > 0 ? jaExisteAproveitamentoRota[0] : await geocodeLatLng(geoCoder, index + 1, lat, lng);
                    if (novaRota) {
                        novaRota.cod_rota = index + 1;
                    }
                    if (novaRota && arrRotasNoMapa[index] && jaExisteAproveitamentoRota.length === 0) {
                        novaRota.horario = arrRotasNoMapa[index].horario ?? novaRota.horario;
                    }
                    if (novaRota) {
                        //novaRota.horario = jaExiste.length > 0 ? jaExiste[0].horario : novaRota.horario;
                        arrRotas.push(novaRota);
                    }
                } else {
                    // arrRotasNoMapa[index].horario = jaExiste[0].horario ?? arrRotasNoMapa[index].horario;
                    arrRotas.push(arrRotasNoMapa[index]);
                }
            });
            await Promise.all(arrMap).then(() => {
                const arrRotasOrdenadas = arrRotas.sort((a, b) => a.cod_rota > b.cod_rota ? 1 : -1);
                const arrCampos = formCadastro;
                setArrRotasNoMapa(arrRotasOrdenadas);
                arrCampos['arrRotas'] = arrRotasOrdenadas;
                setFormCadastro(arrCampos);
            })
        }

    }

    const carregarDadosItinerario = async () => {
        if (props.codItinerario === null || props.codItinerario == undefined) {
            return false;
        }

        const arrCampos = formCadastro;
        arrCampos['codItinerario'] = props.codItinerario;

        setIsLoadingEditar(true);
        const arrDadosItinerario = await ItinerarioService.carregarDadosItinerario(props.codItinerario);
        setArrDadosItinerario(arrDadosItinerario);
        if (arrDadosItinerario.cod_caminhao_coleta) {
            arrCampos['codCaminhao'] = arrDadosItinerario.cod_caminhao_coleta;
            setCodCaminhao(arrDadosItinerario.cod_caminhao_coleta);
        }
        if (arrDadosItinerario.json_dia_coleta) {
            const arrDiasDasemana = arrDadosItinerario.json_dia_coleta.replace('[', '').replace(']', '').replace(/"/g, '').split(',');
            arrDiasDasemana.map((dia, index) => {
                marcaDiaSemana(dia);
            });
            setJsonDiasColeta(arrDiasDasemana);
            arrCampos['jsonDiasColeta'] = arrDiasDasemana;
        }
        if (arrDadosItinerario.json_rota) {
            arrCampos['jsonRota'] = arrDadosItinerario.json_rot;
            setJsonRota(arrDadosItinerario.json_rota);
        }
        if (arrDadosItinerario.rotas) {
            setArrRotasNoMapa(arrDadosItinerario.rotas);
            arrCampos['arrRotas'] = arrDadosItinerario.rotas;
        }
        setIsLoadingEditar(false);
        setFormCadastro(arrCampos);
    };

    const carregarCaminhoes = async () => {
        setIsLoading(true);
        const arrCaminhoes = await CaminhaoService.getAll({ flgExcluido: 'N' , flgInativo : 'N' });
        setArrCaminhoes(arrCaminhoes);
        setIsLoading(false);
    };

    const onVoltar = () => {
        props.onVoltar();
    };

    const onChangeCampo = (event) => {
        const arrCampos = formCadastro;
        arrCampos[event.target.id] = event.target.value;
        setFormCadastro(arrCampos);
        switch (event.target.id) {
            case 'logradouro':
                setErrosCampo({logradouro : null});
                setLogradouro(event.target.value);
                break;
            case 'cep':
                if (event.target.value.length < 11) {
                    setCep(cepMaks(event.target.value));
                    // onChangeNumCep(event.target.value);
                }
                break;
            case 'bairro':
                setBairro(event.target.value);
                break;
        }
    };


    function onChangeNumCep(cep) {

        if (!cep || cep.length !== 8) {
            return;
        }

        const url = `https://viacep.com.br/ws/${cep}/json/`;

        const ok = onChangeCheckLinkOnline(url, setErroLink(true), setErroLink(false));

        if (!erroLink) {
            fetch(url)
                .then((res) => res.json())
                .then((data) => {
                    if (data.erro) {
                        setErrosCampo({ cep: 'CEP não encontrado.' });
                    } else {
                        setErrosCampo({});
                        setLogradouro(data.logradouro + ' ' + data.complemento + ' ' + data.bairro + ', ' + data.localidade);
                    }
                });
        } else {
            setErrosCampo({});
            console.log({
                error_cep: 'Serviço temporariamente indisponível, por favor, tente novamente em alguns instantes.',
            });
        }
    }

    const verificaCamposObrigatorios = () => {
        const arrErros = [];
        let erro = false;
        if (!codCaminhao) {
            arrErros.codCaminhao = 'Campo obrigatório.';
            erro = true;
        }
        
        if (jsonDiasColeta.length == 0) {
            arrErros.jsonDiasColeta = 'Selecione pelo menos um dia da semana.';
            erro = true;
        }
        if (arrRotasNoMapa.length < 2) {
            arrErros.arrRotasNoMapa = 'Informe pelo menos duas rotas.';
            erro = true;
        }
        setErrosCampo(arrErros);
        return !erro;
    };

    const selecionaCaminhao = (event) => {
        setCodCaminhao(event.target.value);
        const arrCampos = formCadastro;
        arrCampos['codCaminhao'] = event.target.value;
        setFormCadastro(arrCampos);
    };

    function onChangeCheckLinkOnline(url, error, ok) {
        try {
            var scriptElem = document.createElement('script');
            scriptElem.type = 'text/javascript';
            scriptElem.onerror = error;
            scriptElem.onload = ok;
            scriptElem.src = url;
            document.getElementsByTagName('body')[0].appendChild(scriptElem);
            return true;
        } catch (err) {
            error(err);
            return false;
        }
    }

    const salvarItinerario = async (evt) => {
        evt.preventDefault();
        if (verificaCamposObrigatorios() !== true) {
            return false;
        }
        setIsSaving(true);
        const retornoSalvarItinerario = await ItinerarioService.salvarItinerario(formCadastro);
        if (retornoSalvarItinerario === 'ok') {
            if (props.codItinerario === null || props.codItinerario == undefined) {
                props.exibeMensagemAlert({ mensagem: 'Itinerário cadastrado com sucesso.' });
            } else {
                props.exibeMensagemAlert({ mensagem: 'Itinerário atualizado com sucesso.' });
            }
        } else {
            props.exibeMensagemAlert({
                mensagem: 'Erro ao cadastrar o itinerário. Tente novamente mais tarde',
                severity: 'error',
            });
        }
        props.recarregarLista();
        props.onVoltar();
        setIsSaving(false);
    };

    const marcaDiaSemana = (dia) => {
        switch (dia) {
            case "SEG":
                setSegunda(true);
                break;
            case "TER":
                setTerca(true);
                break;
            case "QUA":
                setQuarta(true);
                break;
            case "QUI":
                setQuinta(true);
                break;
            case "SEX":
                setSexta(true);
                break;
            case "SAB":
                setSabado(true);
                break;
            case "DOM":
                setDomingo(true);
                break;
        }
    }

    const removeDoArrDias = (dia, value) => {
        let arrCampos = formCadastro;
        let arrDias = jsonDiasColeta;
        if (!value) {
            arrDias.push(dia);
        } else {
            arrDias.splice(arrDias.indexOf(dia), 1);
        }
        setJsonDiasColeta(arrDias);
        arrCampos['jsonDiasColeta'] = arrDias;
        setFormCadastro(arrCampos);
    }

    const onClickSegunda = (value) => {
        setSegunda(!value);
        removeDoArrDias('SEG', value);
    }

    const onClickTerca = (value) => {
        setTerca(!value);
        removeDoArrDias('TER', value);
    }

    const onClickQuarta = (value) => {
        setQuarta(!value);
        removeDoArrDias('QUA', value);
    }

    const onClickQuinta = (value) => {
        setQuinta(!value);
        removeDoArrDias('QUI', value);
    }

    const onClickSexta = (value) => {
        setSexta(!value);
        removeDoArrDias('SEX', value);
    }

    const onClickSabado = (value) => {
        setSabado(!value);
        removeDoArrDias('SAB', value);
    }

    const onClickDomingo = (value) => {
        setDomingo(!value);
        removeDoArrDias('DOM', value);
    }

    const limparRota = () => {
        setLogradouro('');
        setHorario(null);
    }

    const adicionarRota = async () => {
        setEdicao('false')
        let arrCampos = formCadastro;
        setErrosCampo([]);
        if (!logradouro) {
            setErrosCampo({ logradouro: 'Campo obrigatório' });
            return false;
        }
        
        // seta a mensagem de horário invalido no click do botão add
        if (errosCampo['horario'] == 'Horário inválido'){
            setErrosCampo({ horario: 'Horário inválido' });
            setHorario (null);
            setErrosCampo({ horario: 'Digite um horário válido!' });
            return false;
        }

        // seta o obrigatório ao clickar add
        if (horario === null){ 
            setErrosCampo({ horario: 'Campo obrigatório' });
            return false;
        }
        let isExiste = arrRotasNoMapa.filter((item) => item.local == logradouro);
        if (isExiste.length > 0 && edicao == 'false') {
            setErrosCampo({logradouro : 'Este logradouro já foi adicionado.'});
            return false
        }
        setBotaoLoadingRota(true);

        let arrRotasParaInserir = [...arrRotasNoMapa];

        if (arrRotasParaInserir.length === 1 && pontoMapa != null) {
            let marker = pontoMapa;
            marker.setMap(null);
            setPontoMapa(null);
        }

        // Cria o ponto no mapa
        const retornoLatLng = await getLatLongPorEndereco(logradouro + ' ' + bairro + ' , foz do iguaçu', (indexRota !== null ? indexRota : arrRotasNoMapa.length + 1));
        if (retornoLatLng !== false) {
            if (indexRota !== null) {
                arrRotasParaInserir[indexRota] = { cod_rota: indexRota + 1, local: logradouro, horario: addZero(horario.getHours()) + ':' + addZero(horario.getMinutes()), acao: '', num_latitude_local: retornoLatLng.lat, num_longitude_local: retornoLatLng.lng }
                setArrRotasNoMapa(arrRotasParaInserir);
                arrCampos['arrRotas'] = arrRotasParaInserir;
                setFormCadastro(arrCampos);
            } else {
                arrRotasParaInserir = [...arrRotasParaInserir, { cod_rota: arrRotasNoMapa.length + 1, local: logradouro, horario: addZero(horario.getHours()) + ':' + addZero(horario.getMinutes()), acao: '', num_latitude_local: retornoLatLng.lat, num_longitude_local: retornoLatLng.lng }];
                setArrRotasNoMapa(arrRotasParaInserir);
                arrCampos['arrRotas'] = arrRotasParaInserir;
                setFormCadastro(arrCampos);
            }
            setIndexRota(null);
            setCep('');
            setLogradouro('');
            setHorario(null);
            setErrosCampo({ logradouro: null });

            // Atualiza o Mapa de Rotas
            exibirRotasNoMapa(maps, directionsService, directionsRenderer, arrRotasParaInserir);
            // directionsRenderer.setMap(arrCampos['arrRotas'] ? map : null);

        } else {
            setErrosCampo({ logradouro: 'Endereço não encontrado.' });
        }
        setBotaoLoadingRota(false);
    }

    const onClickEditarRota = (rota, index) => {
        setIndexRota(index);
        setLogradouro(rota.local);
        setEdicao('true');
        const dataHoje = new Date();
        dataHoje.setHours(parseInt(rota.horario.split(':')[0]));
        dataHoje.setMinutes(parseInt(rota.horario.split(':')[1]));
        setHorario(dataHoje);
    }

    const onClickExcluirRota = async (cod_rota, index) => {
        let arrRotas = [...arrRotasNoMapa];
        let arrCampos = formCadastro;
        arrRotas.splice(index, 1);
        const rotasOrdenadas = await resetarIndexCodRota(arrRotas);
        setArrRotasNoMapa(rotasOrdenadas);
        arrCampos['arrRotas'] = rotasOrdenadas;
        setFormCadastro(arrCampos);
        // Atualiza o Mapa de Rotas
        exibirRotasNoMapa(maps, directionsService, directionsRenderer, rotasOrdenadas);
        //directionsRenderer.setMap(rotasOrdenadas.length > 0 ? map : null);
    }

    const atualizaMapaComRotas = (arrRotasAtualizadas = null) => {
        let arrRotas = arrRotasAtualizadas ? arrRotasAtualizadas : [...arrRotasNoMapa];
        let arrCampos = formCadastro;
        setArrRotasNoMapa(arrRotas);
        arrCampos['arrRotas'] = arrRotas;
        setFormCadastro(arrCampos);
        // Atualiza o Mapa de Rotas
        exibirRotasNoMapa(maps, directionsService, directionsRenderer, arrRotas);
        //directionsRenderer.setMap(arrRotas.length > 0 ? map : null);
    }

    const onClickSubirRota = async (cod_rota, index) => {
        if (index < 1) {
            return false;
        }
        let arrRotas = [...arrRotasNoMapa];
        let arrPontos = [...arrLatLngUsadas];
        let arrCampos = formCadastro;
        arrRotas[index] = arrRotasNoMapa[index - 1];
        arrRotas[index - 1] = arrRotasNoMapa[index];
        arrPontos[index] = arrLatLngUsadas[index - 1];
        arrPontos[index - 1] = arrLatLngUsadas[index];
        const rotasOrdenadas = await resetarIndexCodRota(arrRotas);
        setArrRotasNoMapa(rotasOrdenadas);
        arrCampos['arrRotas'] = rotasOrdenadas;
        setFormCadastro(arrCampos);
        setArrLatLngUsadas(arrPontos);
        // Atualiza o Mapa de Rotas
        exibirRotasNoMapa(maps, directionsService, directionsRenderer, rotasOrdenadas);
        //directionsRenderer.setMap(rotasOrdenadas.length > 0 ? map : null);
    }

    const onClickBaixarRota = async (cod_rota, index) => {
        if (index >= arrRotasNoMapa.length - 1) {
            return false;
        }
        let arrRotas = [...arrRotasNoMapa];
        let arrPontos = [...arrLatLngUsadas];
        let arrCampos = formCadastro;
        arrRotas[index + 1] = arrRotasNoMapa[index];
        arrRotas[index] = arrRotasNoMapa[index + 1];
        arrPontos[index + 1] = arrLatLngUsadas[index];
        arrPontos[index] = arrLatLngUsadas[index + 1];
        const rotasOrdenadas = await resetarIndexCodRota(arrRotas);
        setArrRotasNoMapa(rotasOrdenadas);
        arrCampos['arrRotas'] = rotasOrdenadas;
        setArrLatLngUsadas(arrPontos);
        setFormCadastro(arrCampos);
        // Atualiza o Mapa de Rotas
        exibirRotasNoMapa(maps, directionsService, directionsRenderer, rotasOrdenadas);
        //directionsRenderer.setMap(rotasOrdenadas.length > 0 ? map : null);
    }

    const resetarIndexCodRota = (arrDados) => {
        return arrDados;
        let arrDadosLoop = arrDados;
        arrDadosLoop.map((dado, index) => {
            arrDados[index]['cod_rota'] = index + 1;
        });
        return arrDados;
    }

    const validaHorario = (hora, campo) => {
        if (!hora) {
            return true;
        }
        if (parseInt(hora.split(':')[0]) > 23 || parseInt(hora.split(':')[0]) < 0) {
            
            campo == 'horario' ? setErrosCampo({ horario: 'Horário inválido' }) : setErrosCampo({ horario: 'Horário inválido' });
            campo == 'horario' ? setHorario(hora) : setHorario(hora);
            return false;
        }

        if (parseInt(hora.split(':')[1]) > 59 || parseInt(hora.split(':')[1]) < 0) {

            horario == 'horario' ? setErrosCampo({ horario: 'Horário inválido' }) : setErrosCampo({ horario: 'Horário inválido' });
            campo == 'horario' ? setHorario(null) : setHorario(null);
            return false;
        }

        return true;     
    };

    const onChangeHorario = (data, value) => {
        const arrCampos = formCadastro;

        if (value && value.search('_') < 0) {
            if (validaHorario(value, 'horario')) {
                const dataHoje = new Date();
                setErrosCampo({ horario: null});
                dataHoje.setHours(parseInt(value.split(':')[0]));
                dataHoje.setMinutes(parseInt(value.split(':')[1]));
                setHorario(dataHoje);
                arrCampos['horario'] = value.split(':')[0] + ':' + value.split(':')[1];
                setFormCadastro(arrCampos);
            }
        }

    };

    const addZero = (i) => {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    const handleApiLoaded = (map, maps) => {
        const directionsService = new maps.DirectionsService();
        const directionsRenderer = new maps.DirectionsRenderer({ draggable: true, stopover: true });
        const geoCoder = new maps.Geocoder();
        setGeoCoder(geoCoder);
        setMap(map);
        setMaps(maps);
        setDirectionsService(directionsService);
        setDirectionsRenderer(directionsRenderer);
        if (arrRotasNoMapa) {
            exibirRotasNoMapa(maps, directionsService, directionsRenderer, arrRotasNoMapa, geoCoder, map);
            // directionsRenderer.setMap(map);
        }

        if (geoCoder != null) {
            directionsRenderer.addListener("directions_changed", () => {
                const directions = directionsRenderer.getDirections();
                inserirRotasPeloMapa(directions, geoCoder);
            });
        }

    }

    const bkpInserirRotasPeloMapa = async (result, geoCoder) => {
        const arrLatLngUsadasNoMapa = [];
        for (let i = 0; i < result.routes[0].legs.length; i++) {

            let latWayStart = result.routes[0].legs[i].start_location.lat();
            let lngWayStart = result.routes[0].legs[i].start_location.lng();
            if (!arrLatLngUsadasNoMapa.includes(latWayStart + ',' + lngWayStart)) {
                arrLatLngUsadasNoMapa.push(latWayStart + ',' + lngWayStart);
            }

            if (result.routes[0].legs[i].via_waypoints.length > 0) {
                for (let j = 0; j < result.routes[0].legs[i].via_waypoints.length; j++) {
                    let latWay = result.routes[0].legs[i].via_waypoints[j].lat();
                    let lngWay = result.routes[0].legs[i].via_waypoints[j].lng();
                    if (!arrLatLngUsadasNoMapa.includes(latWay + ',' + lngWay)) {
                        arrLatLngUsadasNoMapa.push(latWay + ',' + lngWay);
                    }
                }
            }

            let latWayEnd = result.routes[0].legs[i].end_location.lat();
            let lngWayEnd = result.routes[0].legs[i].end_location.lng();
            if (!arrLatLngUsadasNoMapa.includes(latWayEnd + ',' + lngWayEnd)) {
                arrLatLngUsadasNoMapa.push(latWayEnd + ',' + lngWayEnd);
            }
        }
        setArrLatLngUsadas(arrLatLngUsadasNoMapa);
        setLoadingMapa(false);

    }

    const inserirRotasPeloMapa = async (result, geoCoder) => {

        const arrLatLngUsadasNoMapa = [];

        let latWayStart = result.request.origin.location ? result.request.origin.location.lat() : result.request.origin.lat();
        let lngWayStart = result.request.origin.location ? result.request.origin.location.lng() : result.request.origin.lng();
        if (!arrLatLngUsadasNoMapa.includes(latWayStart + ',' + lngWayStart)) {
            arrLatLngUsadasNoMapa.push(latWayStart + ',' + lngWayStart);
        }
        for (let i = 0; i < result.request.waypoints.length; i++) {

            let latWay = result.request.waypoints[i].location.location ? result.request.waypoints[i].location.location.lat() : result.request.waypoints[i].location.lat();
            let lngWay = result.request.waypoints[i].location.location ? result.request.waypoints[i].location.location.lng() : result.request.waypoints[i].location.lng();
            if (!arrLatLngUsadasNoMapa.includes(latWay + ',' + lngWay)) {
                arrLatLngUsadasNoMapa.push(latWay + ',' + lngWay);
            }
        }

        let latWayEnd = result.request.destination.location ? result.request.destination.location.lat() : result.request.destination.lat();
        let lngWayEnd = result.request.destination.location ? result.request.destination.location.lng() : result.request.destination.lng();
        if (!arrLatLngUsadasNoMapa.includes(latWayEnd + ',' + lngWayEnd)) {
            arrLatLngUsadasNoMapa.push(latWayEnd + ',' + lngWayEnd);
        }

        setArrLatLngUsadas(arrLatLngUsadasNoMapa);
        setLoadingMapa(false);

    }

    function sleep(milliSeconds) {
        var startTime = new Date().getTime();
        while (new Date().getTime() < startTime + milliSeconds);
    }

    const geocodeLatLng = async (geocoder, order, lat, lng) => {
        const dataHoje = new Date();
        sleep(500);
        // console.log('busca novo');
        return await geocoder
            .geocode({ location: { lat: lat, lng: lng } })
            .then((response) => {
                if (response.results[0]) {
                    const novaRota =
                    {
                        cod_rota: order,
                        local: response.results[0].formatted_address,
                        horario: addZero(dataHoje.getHours()) + ':' + addZero(dataHoje.getMinutes()),
                        acao: '',
                        num_latitude_local: lat,
                        num_longitude_local: lng
                    };
                    return novaRota;
                } else {
                    return false;
                }
            })
            .catch((e) => {
                console.log("Geocoder failed due to: " + e);
                return false;
            });
    }

    const getLatLongPorEndereco = async (endereco, idMarker) => {

        const geocoder = geoCoder;
        const address = endereco;
        let retorno = false;

        retorno = await geocoder
            .geocode({ address: address })
            .then(({ results }) => {
                if (results[0].geometry.location) {
                    return { lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() };
                } else {
                    return false;
                }

            })
            .catch((e) => {
                console.log("Geocode was not successful for the following reason: " + e);
                return false;
            }
            );
        return retorno;
    }

    const isLocationOnEdge = (maps, response) => {
        
        let decodedPath = maps.geometry.encoding.decodePath(response.routes[0].overview_polyline);
        const arrCampos = formCadastro;
        setJsonRota(decodedPath);
        arrCampos['JsonRota'] = decodedPath;
        setFormCadastro(arrCampos);
        return true;
    };

    const exibirRotasNoMapa = (maps, directionsService, directionsRenderer, arrRotasAtualizadas, geoCoder = null, mapa = null) => {
        const waypts = [];
        const arrPontos = arrRotasAtualizadas ?? arrRotasNoMapa;
        const mapaGoogle = mapa ?? map;
        // console.log('arrPontos',arrPontos);
        if (arrPontos.length > 0) {
            arrPontos.map((dado, index) => {

                if (index === 0 && arrPontos.length === 1) {
                    let marker = new maps.Marker({
                        position: new maps.LatLng(arrPontos[0].num_latitude_local, arrPontos[0].num_longitude_local),
                    });
                    setPontoMapa(marker);
                    marker.setMap(mapaGoogle);
                    directionsRenderer.setMap(null);
                    return true;
                } else {
                    if (pontoMapa !== null) {
                        let marker = pontoMapa;
                        marker.setMap(null);
                        setPontoMapa(null);
                    }
                }

                if (index !== 0 && index !== (arrPontos.length - 1)) {
                    waypts.push({
                        location: new maps.LatLng(dado.num_latitude_local, dado.num_longitude_local),
                        stopover: true,
                    });
                }
            });
            directionsService
                .route({
                    origin: new maps.LatLng(arrPontos[0].num_latitude_local, arrPontos[0].num_longitude_local),
                    destination: new maps.LatLng(arrPontos[arrPontos.length - 1].num_latitude_local, arrPontos[arrPontos.length - 1].num_longitude_local),
                    waypoints: waypts,
                    optimizeWaypoints: true,
                    travelMode: maps.TravelMode.DRIVING,
                })
                .then((response) => {
                    isLocationOnEdge(maps,response);
                    directionsRenderer.setDirections(response);
                    directionsRenderer.setMap(arrPontos.length > 1 ? mapaGoogle : null);
                })
                .catch((e) => console.log("Directions request failed due to", e));
        } else {
            if (pontoMapa !== null) {
                let marker = pontoMapa;
                marker.setMap(null);
                setPontoMapa(null);
            }
        }
    }

    const getLetraAlfabelo = (rota, index) => {

        const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        return alphabet[index];

    }

    const exibeModalInfo = () => {
        setOpenModalInfo(true);
    }

    const handleCloseModalInfo = () => {
        setOpenModalInfo(false);
    }

    return (
        <>
            {isLoadingEditar === true &&
                <Grid container spacing={2} style={{ marginTop: 25 }}>
                    <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                        {mensagemPadraoCarregando}
                    </Grid>
                </Grid>
            }
            {isLoadingEditar === false &&
                <form style={{ width: '100%' }} onSubmit={salvarItinerario} autoComplete="off">
                    <Grid container style={{ marginTop: 25 }}>
                        <SubTitulo>Dados do Itinerário</SubTitulo>
                        <Grid className={classes.label} item xs={6} sm={2}><strong>Caminhão*</strong></Grid>
                        <Grid item xs={6} sm={4}>
                            <SelectPadrao
                                label="Selecione o Caminhão"
                                id="id-caminhao"
                                value={codCaminhao}
                                onChange={selecionaCaminhao}
                                arrDados={arrCaminhoes}
                                isLoaded={!isLoading}
                                required={true}
                                error={errosCampo.codCaminhao != undefined && errosCampo.codCaminhao ? true : false}
                                helperText={errosCampo.codCaminhao != undefined && errosCampo.codCaminhao ? errosCampo.codCaminhao : null}
                            />
                        </Grid>
                        <Grid item xs={2} sm={12}></Grid>
                        <Grid style={{ marginTop: 15 }} item xs={12} sm={12}></Grid>
                        <Grid className={classes.label} item xs={6} sm={2}><strong>Dias de Coleta*</strong></Grid>
                        <Grid item xs={8} sm={8} className={classes.item} style={{ marginTop: 15 }} >
                            <Button id="S" hidden={false} variant="contained"
                                color={segunda === true ? 'primary' : 'default'}
                                onClick={() => { onClickSegunda(segunda); }}
                            >SEG</Button> {' '}

                            <Button id="S" hidden={false} variant="contained"
                                color={terca === true ? 'primary' : 'default'}
                                onClick={() => { onClickTerca(terca); }}
                            >TER</Button> {' '}

                            <Button id="S" hidden={false} variant="contained"
                                color={quarta === true ? 'primary' : 'default'}
                                onClick={() => { onClickQuarta(quarta); }}
                            >QUA</Button> {' '}

                            <Button id="S" hidden={false} variant="contained"
                                color={quinta === true ? 'primary' : 'default'}
                                onClick={() => { onClickQuinta(quinta); }}
                            >QUI</Button> {' '}

                            <Button id="S" hidden={false} variant="contained"
                                color={sexta === true ? 'primary' : 'default'}
                                onClick={() => { onClickSexta(sexta); }}
                            >SEX</Button> {' '}

                            <Button id="S" hidden={false} variant="contained"
                                color={sabado === true ? 'primary' : 'default'}
                                onClick={() => { onClickSabado(sabado); }}
                            >SAB</Button> {' '}

                            <Button id="S" hidden={false} variant="contained"
                                color={domingo === true ? 'primary' : 'default'}
                                onClick={() => { onClickDomingo(domingo); }}
                            >DOM</Button> {' '}
                        </Grid>
                        {errosCampo.jsonDiasColeta != undefined && errosCampo.jsonDiasColeta &&
                            <>
                                <Grid style={{ color: 'red', marginTop: 10 }} item xs={6} sm={2}></Grid>
                                <Grid style={{ color: 'red', marginTop: 10 }} item xs={6} sm={6} ><span>{errosCampo.jsonDiasColeta}</span></Grid>
                            </>
                        }
                        <SubTitulo>Locais de passagem <InfoIcon style={{ cursor: 'pointer' }} onClick={() => exibeModalInfo()} /></SubTitulo>
                        <Grid className={classes.label} item xs={6} sm={1} style={{ minWidth: '100px'}}><strong>Logradouro*</strong></Grid>
                        <Grid item xs={6} sm={5} className={classes.item} style={{ minWidth: '210px'}}>
                            <TextField
                                label="Logradouro"
                                error={errosCampo.logradouro != undefined && errosCampo.logradouro ? true : false}
                                helperText={errosCampo.logradouro != undefined && errosCampo.logradouro ? errosCampo.logradouro : null}
                                id="logradouro"
                                variant="outlined" fullWidth
                                value={logradouro}
                                onChange={onChangeCampo}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}></Grid>
                        <Grid className={classes.label} item xs={6} sm={1} style={{ minWidth: '100px'}}><strong>Bairro*</strong></Grid>
                        <Grid item xs={6} sm={3} className={classes.item} style={{ minWidth: '210px'}}>
                            <TextField
                                label="Bairro"
                                error={errosCampo.bairro != undefined && errosCampo.bairro ? true : false}
                                helperText={errosCampo.bairro != undefined && errosCampo.bairro ? errosCampo.bairro : null}
                                id="bairro"
                                variant="outlined" fullWidth
                                value={bairro}
                                onChange={onChangeCampo}
                            />
                        </Grid>
                        <Grid className={classes.label} item xs={6} sm={1} style={{ minWidth: '100px'}}><strong>Horário*</strong></Grid>
                        <Grid item xs={6} sm={2} className={classes.item} style={{ minWidth: '210px'}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                                <KeyboardTimePicker
                                    autoOk={true}
                                    ampm={false}
                                    name="horario"
                                    id="horario"
                                    fullWidth
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="HH:mm"
                                    label="Horário"
                                    error={errosCampo.horario != undefined && errosCampo.horario ? true : false}
                                    helperText={errosCampo.horario != undefined && errosCampo.horario ? errosCampo.horario : null}
                                    invalidDateMessage="Horário inválido"
                                    value={horario}
                                    onChange={onChangeHorario}
                                    InputAdornmentProps={{ position: 'end' }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{ marginTop: 15 }} >
                            <Button type="button" variant="outlined" style={{ width: 100 , marginLeft: 10 }} onClick={() => adicionarRota()} >
                                {indexRota !== null && botaoLoadingRota === false ? 'Salvar' : ''}
                                {indexRota === null && botaoLoadingRota === false ? 'Adicionar' : ''}
                                {botaoLoadingRota === true ? botaoPadraoCarregando : ''}
                            </Button> &nbsp;
                            <Button type="button" variant="text" onClick={() => limparRota()} >Limpar</Button>
                        </Grid>
                        <Grid item xs={12} sm={12} name="tabela-sem-borda">
                            <TabelaPaginada
                                arrCabecalho={['#', 'Local', 'Horário', 'Ações']}
                                isLoading={isLoading}
                                arrDados={arrRotasNoMapa}
                                exibePaginacao={false}
                                mensagemSemRegistros="Nenhum local de passagem inserido."
                                arrFuncoes={{ cod_rota: { funcao: getLetraAlfabelo } }}
                                arrAcoes={
                                    {
                                        acao: [
                                            {
                                                funcao: onClickEditarRota,
                                                icone: EditIcon
                                            },
                                            {
                                                chave: 'cod_rota',
                                                funcao: onClickExcluirRota,
                                                icone: CloseIcon
                                            },
                                            {
                                                chave: 'cod_rota',
                                                funcao: onClickSubirRota,
                                                icone: ArrowUpwardIcon
                                            },
                                            {
                                                chave: 'cod_rota',
                                                funcao: onClickBaixarRota,
                                                icone: ArrowDownwardIcon
                                            }
                                        ]

                                    }
                                }
                            />
                        </Grid>
                        {errosCampo.arrRotasNoMapa != undefined && errosCampo.arrRotasNoMapa &&

                            <Grid style={{ color: 'red', marginTop: 10, textAlign: 'center' }} item xs={12} sm={12} ><span>{errosCampo.arrRotasNoMapa}</span></Grid>
                        }
                        <SubTitulo>Pontos e Rotas no Mapa</SubTitulo>
                        <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
                            <div style={{ height: '400px', width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: REACT_APP_KEY_GOOGLE_MAPS_API }}
                                    defaultCenter={center}
                                    defaultZoom={zoom}
                                    options={createMapOptions}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                                >
                                </GoogleMapReact>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                            {(isLoading === false && isSaving === false) &&
                                <>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        className={classes.formButton}>
                                        {props.codItinerario === null || props.codItinerario == undefined ? 'Cadastrar' : 'Salvar'}
                                    </Button> &nbsp;
                                    <Button
                                        variant="text"
                                        className={classes.formButton}
                                        onClick={onVoltar}>Cancelar
                                    </Button>
                                </>
                            }
                            {(isLoading || isSaving) &&
                                <>
                                    <Button variant="outlined" disabled style={{ width: 100 }}>
                                        <CircularProgress
                                            style={{ width: 20, height: 20, marginRight: 15 }} />
                                    </Button> &nbsp;
                                    <Button variant="text" disabled>Cancelar</Button>
                                </>
                            }
                        </Grid>
                    </Grid>
                    <ModalPadrao open={openModalInfo} handleCloseModal={handleCloseModalInfo} >
                        <h5>Como montar o itinerário?</h5>
                        <hr></hr>
                        <div style={{ textAlign: 'justify', width: '100%' }}>
                            Adicionando os primeiros pontos:<br />
                            <ul>
                                <li>Para adicionar os pontos preencha o logradouro, o bairro, defina o horário de passagem e clique em adicionar. O ponto irá aparecer no mapa de acordo com o endereço preenchido.</li>
                                <li>Ao preencher o logradouro não esqueça que quanto mais informações (Tipo da via, nome e número) ajudam a marcar mais corretamente o ponto no mapa.</li>
                            </ul>
                            Adicionando novos pontos no mapa:<br />
                            <ul>
                                <li>Assim que houverem pelo menos dois pontos no mapa é possível adicionar novos pontos arrastando a linha azul que os liga. Isso criará novos pontos e os locais de passagem serão adicionados automaticamente na lista.</li>
                            </ul>
                            Editando pontos:<br />
                            <ul>
                                <li>Para editar um ponto clique no ícone de <EditIcon /> e edite os dados no campo de logradouro, bairro e horário OU</li>
                                <li>Arraste o ponto diretamente no mapa - essa edição não altera o horário do itinerário.</li>
                            </ul>
                            Alterando a ordem dos pontos:<br />
                            <ul>
                                <li>Para alterar a ordem dos pontos clique nos botões <ArrowUpwardIcon /> <ArrowDownwardIcon /> do ponto que deseja alterar. Ao clicar para cima, ele irá voltar um na ordem, o para baixo irá avançar um na ordem.</li>
                                <li>Essa alteração irá influenciar a rota desenhada no mapa.</li>
                            </ul>
                        </div>
                        <hr></hr>
                        <div style={{ textAlign: 'right', width: '100%' }}>
                            <Button variant="text" onClick={() => handleCloseModalInfo()}>Fechar</Button>
                        </div>
                    </ModalPadrao>
                </form>
            }
        </>
    );
};

export default CadastrarItinerario;
