import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import Description from '@material-ui/icons/Description';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextoRicoPadrao from '../partials/TextoRicoPadrao';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Form from 'react-validation/build/form';
import {Alert} from '@material-ui/lab';
import {SubTitulo} from '../../views/style/styles';
import Paper from '@material-ui/core/Paper';
import TermoPoliticaService from '../../services/termo-politica.service';
import {politica, termo} from '../../actions/TermoPoliticaAction';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,
  formButton : theme.pagina.formButton,
  tabIndicator: theme.pagina.tabIndicator,
  formCadastro: {
    margin: '15px',
  },
  modalExclusao: {
    boxShadow: theme.shadows[5],
  },
  titleModal: {
    borderBottom: '1px solid #ccc',
  },
  actionsModal: {
    borderTop: '1px solid #ccc',
  },
  editorClassDisabled: {
    backgroundColor: '#ECECEC',
    padding: '1rem;',
    border: '1px solid #ccc;',
  },
  editorClass: {
    padding: '1rem;',
    border: '1px solid #ccc;',
  },
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
    </span>
  );

class TermoPolitica extends Component {
  constructor(props) {
    super(props);
    this.handleRegisterTermo            = this.handleRegisterTermo.bind(this);
    this.handleRegisterPolitica         = this.handleRegisterPolitica.bind(this);
    this.onEditorTermoStateChange       = this.onEditorTermoStateChange.bind(this);
    this.onEditorTermoPoliticaChange    = this.onEditorTermoPoliticaChange.bind(this);
    this.onChangeTermo                  = this.onChangeTermo.bind(this);
    this.onChangePolitica               = this.onChangePolitica.bind(this);
    this.handleClose                    = this.handleClose.bind(this);
    this.onChangeCodPoliticaPrivacidade = this.onChangeCodPoliticaPrivacidade.bind(this);
    this.onChangeCodTermoUso            = this.onChangeCodTermoUso.bind(this);
    this.handleChange            = this.handleChange.bind(this);

    this.state = {
      editorStateTermo: '',
      cod_termo_uso: '',
      str_termo_uso: '',
      str_termo_uso_tag: '',
      is_null_termo: '',

      editorStatePolitica: '',
      cod_politica_privacidade: '',
      str_politica_privacidade: '',
      str_politica_privacid_tag: '',
      is_null_politica: '',

      isDisabled: true,
      isLoadingEdit: true,
      alert: '',
      loading: false,
      successful: '',
      redirect: false,
      showForm: 'termo',
      value: 0,
      open: null,
    };
  }

  componentDidMount() {
    this.getDados();
  }

  componentDidUpdate() {
    // on update component
  }

  onChangeCodPoliticaPrivacidade = (e) => {
    this.setState({
      cod_politica_privacidade: e.target.value,
    });
  }

  onChangeCodTermoUso = (e) => {
    this.setState({
      cod_termo_uso: e.target.value,
    });
  }

  getDados = () => {
    const data = Promise.resolve(TermoPoliticaService.getDadosTermo());

    if (data) {
      data.then((v) => {
        this.populateTermoForm(v.termo);
        this.populatePoliticaForm(v.politica);
      });
    } else {
      this.setState({isLoadingEdit: false});
    }
  };

  populateTermoForm = (dados) => {
    if (dados != undefined && dados.str_termo_uso != '' && dados.str_termo_uso != undefined) {
      let row = convertFromRaw(dados.str_termo_uso);
      this.setState({
        editorStateTermo: EditorState.createWithContent(row),
        str_termo_uso_tag: dados.str_termo_uso_tag,
        cod_termo_uso: dados.cod_termo_uso,
      });
    }else{
      this.setState({editorStateTermo: ''});
    }

    this.setState({isLoadingEdit: false});
  };

  populatePoliticaForm = (dados) => {
    if (dados != undefined && dados.str_politica_privacidade != '' && dados.str_politica_privacidade != undefined) {
      let row = convertFromRaw(dados.str_politica_privacidade);
      this.setState({
        editorStatePolitica: EditorState.createWithContent(row),
        str_politica_privacidade_tag: dados.str_politica_privacidade_tag,
        cod_politica_privacidade: dados.cod_politica_privacidade,
      });
    }else{
      this.setState({editorStatePolitica: ''});
    }
    this.setState({isLoadingEdit: false});
  };

  onEditorTermoStateChange = (editorStateTermo) => {
    let element = convertToRaw(editorStateTermo.getCurrentContent());
    let text = element.blocks[0].text;

    this.setState({
      editorStateTermo,
      str_termo_uso: convertToRaw(editorStateTermo.getCurrentContent()),
      str_termo_uso_tag: draftToHtml(convertToRaw(editorStateTermo.getCurrentContent())),
      is_null_termo: text === '' ? true : false,
    });
  };

  onEditorTermoPoliticaChange = (editorStatePolitica) => {
    let element = convertToRaw(editorStatePolitica.getCurrentContent());
    let text = element.blocks[0].text;

    this.setState({
      editorStatePolitica,
      str_politica_privacidade: convertToRaw(editorStatePolitica.getCurrentContent()),
      str_politica_privacid_tag: draftToHtml(convertToRaw(editorStatePolitica.getCurrentContent())),
      is_null_politica: text === '' ? true : false,
    });
  };

  onChangeTermo = () => {
    this.setState({
      showForm: 'termo',
      isDisabled: true,
      value: 0,
    });
    this.getDados();
  };

  onChangePolitica = () => {
    this.setState({
      showForm: 'politica',
      isDisabled: true,
      value: 1,
    });
    this.getDados();
  };

  handleRegisterTermo = (e) =>{
    e.preventDefault();

    this.setState({
      successful: '',
      isLoadingEdit: true,
      open: '',
    });

    this.form.validateAll();
    this.props
      .dispatch(
        termo(
          this.state.cod_termo_uso,
          this.state.str_termo_uso,
          this.state.str_termo_uso_tag,
          this.state.is_null_termo,
          this.state.showForm,
        ),
      )
      .then((e) => {
        this.setState({
          successful: true,
          isLoadingEdit: false,
        });
        this.timeout = setTimeout(() => {
          this.setState({
            redirect: false,
            successful: '',
          });
          this.getDados();
          this.onChangeTermo();
        }, 4000);
      })
      .catch(() => {
        this.setState({
          successful: false,
          redirect: false,
          isLoadingEdit: false,
        });
      });
  }

  handleRegisterPolitica = (e) => {
    e.preventDefault();

    this.setState({
      successful: '',
      isLoadingEdit: true,
      open: '',
    });

    this.form.validateAll();
    this.props
      .dispatch(
        politica(
          this.state.cod_politica_privacidade,
          this.state.str_politica_privacidade,
          this.state.str_politica_privacid_tag,
          this.state.is_null_politica,
          this.state.showForm,
        ),
      )
      .then((e) => {
        this.setState({
          successful: true,
          isLoadingEdit: false,
        });
        this.timeout = setTimeout(() => {
          this.setState({
            redirect: false,
            successful: '',
          });
          this.getDados();
          this.onChangePolitica();
        }, 4000);
      })
      .catch(() => {
        this.setState({
          successful: false,
          redirect: false,
          isLoadingEdit: false,
        });
      });
  }

  handleClose = (e) => {
    this.setState({
      open: false,
    });
  }

  onClickEditar = () => {
    this.setState({
      message: '',
      open: true,
    });
  }

  handleChange = (event, newValue) => {
    this.setState({
      newValue: newValue,
    })
  }

  render() {
    const {successful, isLoggedIn, message, classes} = this.props;

    const {editorStateTermo} = this.state;
    const {editorStatePolitica} = this.state;

    const alertModal = this.state.showForm === 'politica' ? 'Deseja realmente efetuar as alterações na política de privacidade?' : 'Deseja realmente efetuar as alterações nos Termos de Uso?';

    return (
      <>
        {this.state.isLoadingEdit === true &&
        <Grid container spacing={2} style={{marginTop: 25}}>
          <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
            {mensagemPadraoCarregando}
          </Grid>
        </Grid>
        }
        {this.state.isLoadingEdit === false && <div className={classes.root}>
          <Grid container className={classes.conteudoFundoBranco}style={{minWidth: '471px'}}>
            <Grid item xs={12} className={classes.formCadastroTitulo}>
              <Description className={classes.iconeTitulo}/>
              <span className={classes.tituloPadrao}> Termos de Uso e Política de Privacidade do Aplicativo</span>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.content}>
                <Grid className={classes.formCadastro}>
                  <Tabs
                    classes={{indicator: classes.tabIndicator}}
                    value={this.state.value}>
                    <Tab label="Termos de Uso"
                         onClick={() =>
                         {this.setState({successful: ''},
                           this.onChangeTermo)}}>
                    </Tab>
                    <Tab label="Política de Privacidade"
                         onClick={() =>
                         {this.setState({successful: ''},
                           this.onChangePolitica)}}>
                    </Tab>
                  </Tabs>
                  <Grid className={classes.formCadastro}>
                      <Form onSubmit={this.state.showForm === 'termo' ? this.handleRegisterTermo : this.handleRegisterPolitica} ref={(c) => {
                        this.form = c;
                      }} className={classes.formCadastro}>
                        {this.state.successful == false && this.state.successful !== '' && message != '' && (
                          <div className="row">
                            <div className="col mb-2">
                              <Alert severity={'error'}>{message}</Alert>
                            </div>
                          </div>
                        )}
                        {this.state.successful == true && this.state.successful !== '' && message != '' && (
                          <div className="row">
                            <div className="col mb-2">
                              <Alert severity={'success'}>{message}</Alert>
                            </div>
                          </div>
                        )}
                        <SubTitulo>{this.state.showForm === 'termo' ? 'Termo de Uso' : 'Politica de Privacidade'}</SubTitulo>
                        <Grid container spacing={3}>
                            <input
                              type="hidden"
                              id="cod_termo_uso"
                              name="cod_termo_uso"
                              value={this.state.cod_termo_uso}
                              onChange={this.onChangeCodTermoUso}
                            />
                            <input
                              type="hidden"
                              id="cod_politica_privacidade"
                              name="cod_politica_privacidade"
                              value={this.state.cod_politica_privacidade}
                              onChange={this.onChangeCodPoliticaPrivacidade}
                            />
                          <Grid item xs={12} sm={12} style={{cursor: this.state.isDisabled ? 'not-allowed' : 'alias'}}>
                            <TextoRicoPadrao
                              readOnly={this.state.isDisabled}
                              editorClassName={this.state.isDisabled ? classes.editorClassDisabled : classes.editorClass}
                              toolbarHidden={this.state.isDisabled}
                              editorState={this.state.showForm === 'termo' ? editorStateTermo: editorStatePolitica}
                              onEditorStateChange={this.state.showForm === 'termo' ? this.onEditorTermoStateChange: this.onEditorTermoPoliticaChange}
                              maxLength={'20000'}
                            />
                          </Grid>
                        </Grid>
                        {
                          this.state.isDisabled &&
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <div style={{textAlign: 'center'}}>
                                <Button
                                  className={classes.formButton}
                                  variant="text" onClick={() => {
                                  this.setState({isDisabled: false})
                                  }}>Editar
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        }
                        {!this.state.isDisabled &&
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <div style={{textAlign: 'center'}}>
                              <Button
                                className={classes.formButton}
                                type="button"
                                onClick={() => {
                                  this.onClickEditar();
                                }}
                                variant="outlined"> Salvar
                              </Button> &nbsp;
                              <Button
                                className={classes.formButton}
                                variant="text" onClick={() => {
                                this.setState({isDisabled: true}, this.getDados());
                                }}>Cancelar
                              </Button>
                            </div>
                          </Grid>
                        </Grid>
                        }
                      </Form>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        }
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.modalExclusao}
        >
          <DialogTitle id="alert-dialog-title"
                       className={classes.titleModal}>{'Aviso!'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {alertModal}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actionsModal}>
            <Button
              type="submit" className={classes.formButton}
              onClick={this.state.showForm === 'politica' ? this.handleRegisterPolitica : this.handleRegisterTermo}
              variant="outlined">Sim
            </Button> &nbsp;
            <Button
              variant="text"
              className={classes.formButton}
              onClick={this.handleClose}>
              Não
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  const {message} = state.message;

  return {user, message};
}

export default connect(mapStateToProps)(withStyles(styles)(TermoPolitica));
