import {createTheme , ThemeProvider} from '@material-ui/core/styles';
import {ptBR} from '@material-ui/core/locale';

const theme = createTheme ({
  palette: {
    background: {
      default: '#EEEEEE',
      secondary: '#f9f9f9',
      containers: '#181818',
      gradient: 'linear-gradient(to bottom, #181819, #313133)',
      login: '#fff',
      shadow: '#F2F2F2',
      button: '#039ED5',
    },
    primary: {
      light: '#383838',
      main: '#005b96',
      dark: '#000000',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FFFFFF',
      dark: '#D80909',
      contrastText: '#000',
    },
    text: {
      primary: '#000',
      secondary: '#000',
    },
    colors: {
      blue: '#325584',
      green: '#56BD66',
      red: '#EA3D32',
      darkblue: '#325584',
      white: '#fff',
    },
  },

  typography: {
    fontFamily: 'Source Sans Pro, Trebuchet MS, sans serif',
  },

  pagina: {
    tituloPadrao: {
      paddingLeft: 3,
      color: '#1976d2',
      fontSize: '21px',
    },
    conteudoFundoBranco: {
      backgroundColor: '#FFF',
      padding: '10px 25px',
      margin: '25px 15px 35px 15px',
      borderRadius: '5px',
      boxShadow: '0px 0px 5px 1px #efefef',
      maxWidth: '96.6%',
    },
    iconeTitulo: {
      fontSize: 28,
      marginTop: '-7px',
      color: '#1976d2'
    },
    menu: {
      color: '#1976d2',
      backgroud: '#115293',
    },
    formCadastroTitulo: {
      margin: '15px 0 15px 0',
    },
    formButton: {
      width: 100,
      height: 35
    },
    buttonCadastro: {
      float: 'right',
      margin: '15px 0 10px 0',
      padding: '1%',
      width: '40%',
      height: 40,
    },
    buttonPesquisar: {
      width: 100,
      height:40,
      margin: '15px 10px',
      textAlign: 'center',
    },
    tabIndicator: {
      backgroundColor: '#52CBE9'
    },
  },


  overrides: {
    MuiPickersToolbarText: {
      '&:toolbarBtnSelected': {
        color: '#000',
      },
    },
    MuiInput: {
      underline: {
        border: 'solid 1px #000',
        padding: '5px 5px 5px',
        borderRadius: '5px',
      },
      input: {
        color: '#000',
      },
    },
    MuiButton: {
      contained: {
        color: '#000',
        backgroundColor: '#FFF',
        border: 'solid 1px #7E84A3',
        '&:hover': {
          color: '#005b96',
          backgroundColor: '#FFF',
          border: 'solid 1px #7E84A3',
        },
      },
      outlined: {
        color: '#FFF',
        backgroundColor: '#28A745',
        border: 'solid 1px #FFF',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
          color: '#FFF',
          backgroundColor: '#28A745',
          border: 'solid 1px #FFF',
          boxShadow: '0px 0px 5px 5px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        },
      },
      text: {
        color: '#7E84A3',
        backgroundColor: '#FFF',
        border: 'solid 1px #7E84A3',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
          color: '#FFF',
          backgroundColor: '#7E84A3',
          border: 'solid 1px #FFF',
          boxShadow: '0px 0px 5px 5px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        },
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        backgroundColor: '#005b96',
        border: 'unset',
        boxShadow: 'unset',
        '&:hover': {
          backgroundColor: '#005b96',
          border: 'unset',
          boxShadow: 'unset',
        },
      },
    },
    MuiFilledInput: {
      adornedEnd: {
        backgroundColor: '#FFF',
        border: 'solid 1px #000',
        minHeight: '42px',
        borderRadius: '5px',
        marginTop: '18px',
      },
      input: {
        padding: '10px 12px 10px',
        minWidth: 300,
        maxWidth: 350,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: '10px !important',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000',
        paddingLeft: 10,
        paddingTop: 5,
        '&.Mui-focused': {
          color: '#000',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        color: '#9DB3D4',
      },
    },
    MuiLink: {
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
      root: {
        color: '#EDEDED',
        fontSize: '1rem',
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: '#979797',
      },
      thumb: {
        backgroundColor: '#FFF',
      },
    },
    MuiFormControlLabel: {
      label: {
        color: '#FFF',
      },
    },
  },

  custom: {
    scroll: {
      '&::-webkit-scrollbar': {
        width: 12,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#979797',
        borderRadius: 5,
        borderLeft: '3px solid #313131',
        borderRight: '3px solid #313131',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 7.5,
        backgroundColor: '#EDEDED',
      },
    },
  },
}, ptBR);

export default theme;
