import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import EquipeService from "../../../services/equipe.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ModalPadrao from '../ModalPadrao';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '800px',
    minHeight: '400px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
    fontFamily: theme.typography.fontFamily
  },
  table: {
    minWidth: 400,
    color: '#000',
    border: 'unset',
    borderBottom: 'solid 1px #797979',
    '&& .MuiTableCell-root': {
      color: '#000',
    },
    '&& .MuiTableCell-head': {
      fontWeight: 'bold'
    },
    '&& .MuiTableCell-body': {
      color: '#797979'
    }
  },
  buttonExcluir: {
    fontSize: 10,
    color: '#FFF',
    backgroundColor: '#D80909',
    border: 'solid 1px #FFF',
    textTransform: 'initial',
    borderRadius: '5px',
    width: '110px',
    textAlign: 'center',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#D80909',
      border: 'solid 1px #333333',
    },
  }
}));

export default function VisualizarEquipesModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.isOpened);
  const [arrEquipesUsuario, setArrEquipesUsuario] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [mensagemSucesso, setMensagemSucesso] = React.useState(null);
  const [mensagemFalha, setMensagemFalha] = React.useState(null);
  const [modalConfirmacaoExclusao, setModalConfirmacaoExclusao] = React.useState(false);
  const [dadosParaExclusao, setDadosParaExclusao] = React.useState([]);

  useEffect(() => {
    setOpen(props.isOpened);
    if (props.codUsuario !== false) {
      carregarEquipesUsuario();
    }
  }, [props.isOpened]);

  const carregarEquipesUsuario = async (e) => {
    setIsLoading(true);
    setMensagemSucesso(null);
    setMensagemFalha(null);
    const arrEquipes = await EquipeService.getEquipesUsuario(props.codUsuario);
    setArrEquipesUsuario(arrEquipes);
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setOpen(false);
    props.onCloseModal();
  };

  const conformarExcluirUsuarioDaEquipe = async () => {

    let arrDadosParaExclusao = dadosParaExclusao;
    let codUsuarioEquipePerfil = arrDadosParaExclusao.codUsuarioEquipePerfil;
    let codPerfil = arrDadosParaExclusao.codPerfil;
    let codEquipe = arrDadosParaExclusao.codEquipe;
    let index = arrDadosParaExclusao.index;
    setIsSaving(codUsuarioEquipePerfil);
    setMensagemSucesso(null);
    setMensagemFalha(null);
    const retorno = await EquipeService.removerUsuarioDaEquipe(codUsuarioEquipePerfil);
    if (retorno === 'ok') {
      let arrEquipes = [...arrEquipesUsuario];
      arrEquipes.splice(index, 1);
      setArrEquipesUsuario(arrEquipes);
      setMensagemSucesso('Perfil do usuário removido com sucesso.')
      //Se o perfil removido for o da tela, precisa remover o card da tela
      if (codPerfil === props.codPerfilEmTela && codEquipe === props.codEquipeEmTela) {
        props.onRemoverCardDaTela(props.codUsuario);
      }
    } else {
      setMensagemFalha('Erro ao tentar remover o perfil do usuário');
    }
    setIsSaving(false);
    setDadosParaExclusao([]);
    setModalConfirmacaoExclusao(false);
  };

  const removerUsuarioDaEquipe = (codUsuarioEquipePerfil, codPerfil, codEquipe, index) => {

    setDadosParaExclusao({ codUsuarioEquipePerfil: codUsuarioEquipePerfil, codPerfil: codPerfil, codEquipe: codEquipe, index: index });
    setModalConfirmacaoExclusao(true);

  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        disableScrollLock
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Grid item xs={5}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container alignItems="center" >
                  <Grid item xs={10}>
                    <h5>Equipes do usuário {props.nome} {props.sobrenome}</h5>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }} >
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleCloseModal}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </Grid>
                </Grid>
                <hr></hr>
                {mensagemSucesso !== null &&
                  <Grid item xs={12} style={{ marginBottom: 15, marginTop: 15 }} >
                    <Alert
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setMensagemSucesso(null);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <p>{mensagemSucesso}</p>
                    </Alert>
                  </Grid>
                }
                {mensagemFalha !== null &&
                  <Grid item xs={12} style={{ marginBottom: 15, marginTop: 15 }} >
                    <Alert
                      severity="error"
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setMensagemFalha(null);
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }
                    >
                      <p>{mensagemFalha}</p>
                    </Alert>
                  </Grid>
                }
                <TableContainer component={Paper} className={classes.listaTabela} >
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Equipe(s)</TableCell>
                        <TableCell align="right">Perfil</TableCell>
                        <TableCell align="right">Opções</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading === true &&
                        <TableRow key="loading">
                          <TableCell component="th" colSpan={3} scope="row" style={{ textAlign: 'center' }} >
                            <div><CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...</div>
                          </TableCell>
                        </TableRow>
                      }
                      {(isLoading === false || isLoading === undefined) && (arrEquipesUsuario.length === 0) &&
                        <TableRow key="no-records">
                          <TableCell component="th" colSpan={3} scope="row" style={{ textAlign: 'center' }} >
                            <div>Nenhuma equipe encontrada.</div>
                          </TableCell>
                        </TableRow>
                      }
                      {isLoading === false && arrEquipesUsuario.map((row, index) => (
                        <TableRow key={index} >
                          <TableCell component="th" scope="row">
                            {row.str_nome_equipe}
                          </TableCell>
                          <TableCell align="right">{row.str_nome_perfil}</TableCell>
                          <TableCell align="right">
                            {isSaving === row.cod_usuario_equipe_perfil &&
                              <Button className={classes.buttonExcluir} disabled ><CircularProgress style={{ color: '#FFF', width: 15, height: 15 }} /></Button>
                            }
                            {isSaving !== row.cod_usuario_equipe_perfil &&
                              <Button className={classes.buttonExcluir} onClick={() => removerUsuarioDaEquipe(row.cod_usuario_equipe_perfil, row.cod_perfil, row.cod_equipe, index)} >Remover da Equipe</Button>
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <hr></hr>
                <div style={{ textAlign: 'right' }}>
                  {isSaving === false &&
                    <Button variant="text" onClick={handleCloseModal} >Fechar</Button>
                  }
                  {isSaving !== false &&
                    <Button variant="text" disabled >Fechar</Button>
                  }
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
      <ModalPadrao open={modalConfirmacaoExclusao} >
        <h5>Aviso!</h5>
        <p>Deseja retirar este usuário da equipe?</p>
        <hr></hr>
        <div style={{ textAlign: 'right' }}>
          {isSaving === false &&
            <>
              <Button variant="outlined" onClick={() => conformarExcluirUsuarioDaEquipe()} style={{ width: 80 }} >Sim</Button> &nbsp;
              <Button variant="text" onClick={() => setModalConfirmacaoExclusao(false)}>Não</Button>
            </>
          }
          {isSaving !== false &&
            <>
              <Button variant="outlined" disabled style={{ width: 80 }} ><CircularProgress style={{ color: '#FFF', width: 20, height: 20 }} /></Button> &nbsp;
              <Button variant="text" disabled >Não</Button>
            </>
          }
        </div>
      </ModalPadrao>
    </div >
  );
}
