import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Button, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { Titulo, SubTitulo } from '../../views/style/styles';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ModalPadrao from '../partials/ModalPadrao';
import CircularProgress from '@material-ui/core/CircularProgress';
import BotaoPanicoService from '../../services/botao-panico.service';
import UsuarioBloqueadoService from '../../services/usuario-bloqueado.service';
import SearchIcon from '@material-ui/icons/Search';
import UsuariosBloqueados from '../partials/usuario-bloqueado/UsuarioBloqueado';
import '../../views/style/style-telaConfiguracao.css';
import {limpaCaracter} from '../util';

const styles = theme => ({
    formCadastroTitulo: {
        margin: '15px 0 15px 0',
    },
    conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
    tituloPadrao: theme.pagina.tituloPadrao,
    iconeTitulo: theme.pagina.iconeTitulo,
    formControl: {
        margin: theme.spacing(1),
        minWidth: 350,
        maxWidth: 400,
    },
    divTempoAtivacao: {
        marginTop: 20,
        display: 'flex',
        width: '20%',
        paddingLeft: '1%'
    },
    button: {
        color: '#000',
        backgroundColor: '#FFF',
        border: 'solid 1px #005b96',
        maxWidth: 100,
        height: '42px',
        marginTop: '25px',
        '&:hover': {
            color: '#005b96',
            backgroundColor: '#FFF',
            border: 'solid 1px #005b96',
        },
    },
});

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
    )

class ConfiguracaoBotaoPanico extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: localStorage.getItem("user"),
            mensagemAlert: { mensagem: null, severity: 'success', closeButton: true },
            isLoading: true,
            isSaving: false,
            campoTempoAtivacao: 0,
            campoTempoAtivacaoDesativado: true,
            modalCampoTempoAtivacao: false,
            totalUsuariosBloqueados: 0,
            isLoadingVisualizarUsuariosBloqueados: false,
            isExibeUsuarioBloqueado: false,
            arrUsuariosBloqueados: null,

            isSavingTempoMaximo: false,
            numTempoMaximoEnvio: null,
            numTempoMaximoEnvioDesativado: true,
            modalCampoTempoMaximoEnvio: false,
        };

    }

    componentDidMount() {
        this.carregaConfig()
    }

    carregaConfig = async () => {
        const retorno = await BotaoPanicoService.getConfig();
        const totalUsuariosBloqueados = await UsuarioBloqueadoService.contarUsuariosBloqueados();
        this.setState({ isLoading: false, campoTempoAtivacao: retorno != undefined && retorno.num_tempo_ativacao != undefined ? retorno.num_tempo_ativacao : 3 });
        this.setState({ isLoading: false, numTempoMaximoEnvio: retorno != undefined && retorno.num_tempo_maximo_envio != undefined ? retorno.num_tempo_maximo_envio : 48 });
        this.setState({ totalUsuariosBloqueados: totalUsuariosBloqueados != undefined ? totalUsuariosBloqueados : 0 })
    }

    onChangeCampoTempoAtivacao = (event) => {
          if (event.target.value < 0) {
              return false;
          }
        this.setState({ campoTempoAtivacao: limpaCaracter(event.target.value)
             });
    }

    onClickEditarCampoTempoAtivacao = () => {
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
        this.setState({ modalCampoTempoAtivacao: true });
    }

    onClickSalvarCampoTempoAtivacao = async () => {
        if(this.state.campoTempoAtivacao == null || this.state.campoTempoAtivacao == ''){
            this.setState({ mensagemAlert: { mensagem: '"Tempo de Ativação" não pode ficar em branco.', severity: 'error', closeButton: true }});
            return;
        }

        this.setState({ isSaving: true });
        this.setState({ campoTempoAtivacaoDesativado: true });
        const retorno = await BotaoPanicoService.salvarConfig({ numTempoAtivacao: this.state.campoTempoAtivacao });
        if (retorno == "ok") {
            this.setState({ mensagemAlert: { mensagem: 'Tempo de ativação salvo com sucesso.', severity: 'success', closeButton: true } });
        } else {
            this.setState({ mensagemAlert: { mensagem: 'Não foi possível salvar o tempo de ativação.', severity: 'error', closeButton: true } });
        }
        this.setState({ isSaving: false });
    }

    habilitarEdicaoCampoTempoAtivacao = () => {
        this.setState({ campoTempoAtivacaoDesativado: false });
        this.setState({ modalCampoTempoAtivacao: false });
    }

    visualizarUsuariosBloqueados = async () => {
        this.setState({ isLoadingVisualizarUsuariosBloqueados: true, isExibeUsuarioBloqueado: true });
        const arrUsuariosBloqueados = await UsuarioBloqueadoService.carregarLista();
        this.setState({ isLoadingVisualizarUsuariosBloqueados: false, arrUsuariosBloqueados: arrUsuariosBloqueados });
    }

    atualizarTotalBloqueados = (total) => {
        this.setState({ totalUsuariosBloqueados: total })
    }

    onVoltar = () => {
        this.setState({ isLoadingVisualizarUsuariosBloqueados: false, isExibeUsuarioBloqueado: false });
    }

    onChangeCampoTempoMaximoInf = (event) => {
        if (event.target.value < 0) {
            return false;
        }
        this.setState({ numTempoMaximoEnvio: limpaCaracter (event.target.value) });
    }

    onClickEditarTempoMaximoInf = () => {
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
        this.setState({ modalCampoTempoMaximoEnvio: true });
    }

    habilitarEdicaoCampoTempoMaximoInf = () => {
        this.setState({ numTempoMaximoEnvioDesativado: false });
        this.setState({ modalCampoTempoMaximoEnvio: false });
    }

    onClickSalvarCampoTempoMaximoInf = async () => {
        if(this.state.numTempoMaximoEnvio == null || this.state.numTempoMaximoEnvio == ''){
            this.setState({ mensagemAlert: { mensagem: '"Tempo Máximo" não pode ficar em branco.', severity: 'error', closeButton: true }});
            return;
        }

        this.setState({ isSavingTempoMaximo: true });
        this.setState({ numTempoMaximoEnvioDesativado: true });
        const retorno = await BotaoPanicoService.salvarConfig({ numTempoMaximoEnvio: this.state.numTempoMaximoEnvio});
        if (retorno == "ok") {
            this.setState({ mensagemAlert: { mensagem: 'Tempo máximo de envio de informações salvo com sucesso.', severity: 'success', closeButton: true } });
        } else {
            this.setState({ mensagemAlert: { mensagem: 'Não foi possível salvar o tempo máximo de envio de informações.', severity: 'error', closeButton: true } });
        }
        this.setState({ isSavingTempoMaximo: false });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container className={classes.conteudoFundoBranco} style={{minWidth: '670px'}}>
                    {(this.state.isLoading === true || this.state.isLoadingVisualizarUsuariosBloqueados === true) &&
                        <Grid item xs={12} sm={12} style={{ marginTop: 25, textAlign: 'center' }} >
                            {mensagemPadraoCarregando}
                        </Grid>
                    }
                    {this.state.isExibeUsuarioBloqueado === true && this.state.isLoadingVisualizarUsuariosBloqueados === false &&
                        <UsuariosBloqueados classes={classes} atualizarTotalBloqueados={this.atualizarTotalBloqueados} onVoltar={this.onVoltar} arrUsuariosBloqueados={this.state.arrUsuariosBloqueados} />
                    }
                    {this.state.isLoading === false && this.state.isExibeUsuarioBloqueado == false &&
                        <>
                            <Grid item xs={12} className={classes.formCadastroTitulo}>
                                <SettingsIcon className={classes.iconeTitulo} />
                                <span className={classes.tituloPadrao} >Configurações do Botão do Pânico</span>
                            </Grid>
                            {this.state.mensagemAlert.mensagem !== null &&
                                <Grid item xs={12} style={{ marginBottom: 15, marginTop: 15 }} >
                                    <Alert
                                        severity={this.state.mensagemAlert.severity}
                                        action={
                                            this.state.mensagemAlert.closeButton === true &&
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                    >
                                        {this.state.mensagemAlert.mensagem}
                                    </Alert>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <SubTitulo style={{ marginTop: 25 }} >Tempo de ativação do Botão do Pânico</SubTitulo>
                            </Grid>
                            <Grid item xs={12} className={classes.divTempoAtivacao}>
                                <Grid item >
                                    Tempo de Ativação
                                </Grid>
                                <Grid item xs={1} style={{ marginTop: -10, marginLeft: 5 }}>
                                    <TextField
                                        error={this.state.erroCampoTempoAtivacao ? true : false}
                                        helperText={this.state.erroTempoCampoAtivacao ?? null}
                                        id="campoTempoAtivacao"
                                        variant={this.state.campoTempoAtivacaoDesativado && !this.state.isSaving ? 'filled': 'standard'} fullWidth required
                                        value={this.state.campoTempoAtivacao}
                                        onChange={this.onChangeCampoTempoAtivacao}
                                        onInput = {(e) =>{
                                            e.target.value = Math.max(1, parseInt(e.target.value) ).toString().slice(0,2)
                                          }}
                                        inputProps={{ min: 1 , max: 99  }}
                                        type="number"
                                        disabled={this.state.campoTempoAtivacaoDesativado}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{marginLeft: 5}}>
                                    segundos.
                                    {this.state.campoTempoAtivacaoDesativado && !this.state.isSaving &&
                                    <EditIcon style={{ cursor: 'pointer' }} onClick={this.onClickEditarCampoTempoAtivacao} />
                                    }
                                    {!this.state.campoTempoAtivacaoDesativado && !this.state.isSaving &&
                                    <CheckIcon style={{ cursor: 'pointer' }} onClick={this.onClickSalvarCampoTempoAtivacao} />
                                    }
                                    {this.state.isSaving &&
                                    <CircularProgress style={{ color: '#000', width: 20, height: 20 }} />
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <SubTitulo style={{ marginTop: 25 }} >Tempo máximo de envio de informações do aplicativo para a API</SubTitulo>
                            </Grid>
                            <Grid item xs={12} className={classes.divTempoAtivacao}>
                                <Grid item >
                                    Tempo Máximo:
                                </Grid>
                                <Grid item xs={1} style={{ marginTop: -10, marginLeft: 5 }}>
                                    <TextField
                                      error={this.state.erroCampoTempoAtivacao ? true : false}
                                      helperText={this.state.erroTempoCampoAtivacao ?? null}
                                      id="campoTempoAtivacao"
                                      variant={this.state.numTempoMaximoEnvioDesativado && !this.state.isSavingTempoMaximo ? 'filled': 'standard'} fullWidth required
                                      value={this.state.numTempoMaximoEnvio}
                                      onChange={this.onChangeCampoTempoMaximoInf}
                                      onInput = {(e) =>{
                                        e.target.value = Math.max(1, parseInt(e.target.value) ).toString().slice(0,2)
                                      }}
                                      inputProps={{ min: 1 , max: 99  }}
                                      type="number"
                                      disabled={this.state.numTempoMaximoEnvioDesativado}
                                    />
                                </Grid>
                                <Grid item xs={2} style={{marginLeft: 5}}>
                                    horas.
                                    {this.state.numTempoMaximoEnvioDesativado && !this.state.isSavingTempoMaximo &&
                                    <EditIcon style={{ cursor: 'pointer' }} onClick={this.onClickEditarTempoMaximoInf} />
                                    }
                                    {!this.state.numTempoMaximoEnvioDesativado && !this.state.isSavingTempoMaximo &&
                                    <CheckIcon style={{ cursor: 'pointer' }} onClick={this.onClickSalvarCampoTempoMaximoInf} />
                                    }
                                    {this.state.isSavingTempoMaximo &&
                                    <CircularProgress style={{ color: '#000', width: 20, height: 20 }} />
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <SubTitulo style={{ marginTop: 25 }} >Bloquear Usuários</SubTitulo>
                            </Grid>
                            <Grid item xs={12} className={classes.divTempoAtivacao}>
                                {this.state.totalUsuariosBloqueados} usuário(s) bloqueado(s)
                                <SearchIcon onClick={this.visualizarUsuariosBloqueados} style={{ cursor: 'pointer', marginLeft: 5 }} />
                            </Grid>
                            <ModalPadrao open={this.state.modalCampoTempoAtivacao} handleCloseModal={() => this.setState({ modalCampoTempoAtivacao: false })} >
                                <h5>Aviso!</h5>
                                <hr></hr>
                                <p>Deseja mesmo alterar o tempo de ativação do Botão do pânico?</p>
                                <hr></hr>
                                <Button variant="outlined" onClick={this.habilitarEdicaoCampoTempoAtivacao} style={{ width: 80 }} >Sim</Button> &nbsp;
                                <Button variant="text" onClick={() => this.setState({ modalCampoTempoAtivacao: false })}>Não</Button>
                            </ModalPadrao>

                            <ModalPadrao open={this.state.modalCampoTempoMaximoEnvio} handleCloseModal={() => this.setState({ modalCampoTempoMaximoEnvio: false })} >
                                <h5>Aviso!</h5>
                                <hr></hr>
                                <p>Deseja mesmo alterar o tempo maximo de envio de informações?</p>
                                <hr></hr>
                                <Button variant="outlined" onClick={this.habilitarEdicaoCampoTempoMaximoInf} style={{ width: 80 }} >Sim</Button> &nbsp;
                                <Button variant="text" onClick={() => this.setState({ modalCampoTempoMaximoEnvio: false })}>Não</Button>
                            </ModalPadrao>
                        </>
                    }
                </Grid>

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(ConfiguracaoBotaoPanico));
