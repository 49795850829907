import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Button, FormControl, Grid, Select, TextField} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import Paper from '@material-ui/core/Paper';
import {
  KeyboardDatePicker, KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import {SubTitulo} from '../../views/style/styles';
import {dateFormatterAmericano, formatDataComponent} from '../util';
import SelectPadrao from '../partials/SelectPadrao';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import RedeSocialService from '../../services/rede-social';
import Conteudo from '../partials/metricas/Conteudo';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = theme => ({
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  buttonCadastro: theme.pagina.buttonCadastro,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,
  buttonPesquisar: theme.pagina.buttonPesquisar,
  formButton: theme.pagina.formButton,

  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
    maxWidth: 400,
  },
  button: {
    color: '#000',
    backgroundColor: '#FFF',
    border: 'solid 1px #005b96',
    maxWidth: 100,
    height: '42px',
    marginTop: '25px',
    '&:hover': {
      color: '#005b96',
      backgroundColor: '#FFF',
      border: 'solid 1px #005b96',
    },
  },
  inputBuscar: {
    width: 400,
    margin: '15px 0',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '800px',
    minHeight: '400px',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
    fontFamily: theme.typography.fontFamily,
  },

  mensagemInformativa: {
    color: '#000',
    backgroundColor: '#039ED5',
    padding: '10px 10px 10px',
    textAlign: 'center',
  },
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );

class MetricasApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: localStorage.getItem('user'),
      mensagemAlert: {mensagem: null, severity: 'success', closeButton: true},
      mensagemInformativa: null,
      isLoading: true,
      isloadingConteudo: true,

      dat_inicial: null,
      dat_final: null,
      str_palavra: null,
      dt_max: null,

      cod_hashtag: null,
      arrListHashtag: [],
      isLoadHashtag: false,
      arrStrHashTag: [],

      cod_pesquisa: null,
      arrListPesquisa: [],
      isLoadLisPesquisa: false,

      arrConteudo: [],

      disabledInsta: false,
      disabledTwiter: false,

      disableInputs: true,
      isLoadConteudo: false,
    };
  }

  componentDidMount() {
    this.carregaCombom();
  }

  componentWillMount() {
    window.addEventListener('scroll', this.loadMore);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
  }

  loadMore = async () => {
    if (window.innerHeight + document.documentElement.scrollTop == document.scrollingElement.scrollHeight && this.state.arrConteudo.length > 0) {

      this.setState({isLoadConteudo: true});

      let conteudo = await RedeSocialService.getMetricasConteudo({
        'dat_inicial': this.state.dat_inicial,
        'dat_final': this.state.dat_final,
        'cod_pesquisa': this.state.cod_pesquisa,
        'cod_hashtag': this.state.cod_hashtag,
        'str_palavra': this.state.str_palavra,
        'disabledInsta': this.state.disabledInsta,
        'disabledTwiter': this.state.disabledTwiter,
        'acao': 'pesquisa',
        'arrStrHashtag': this.state.arrStrHashTag,
        limit: 10,
        offset: this.state.arrConteudo.length + 1,
      });

      if (conteudo.arrConteudos.length > 0) {
        this.setState({
          arrConteudo: this.state.arrConteudo.concat(conteudo.arrConteudos),
        });
      }

      this.setState({isLoadConteudo: false});
    }
  };

  carregaCombom = async () => {
    const arrPesquisas = await RedeSocialService.getPesquisa();

    this.setState({arrListPesquisa: arrPesquisas, isLoadLisPesquisa: true});
    this.setState({mensagemInformativa: 'Selecione uma Pesquisa.'});
    this.setState({isLoading: false});
    this.setState({isloadingConteudo: false});
  };

  disabledInstagran = () => {
    this.setState({
      disabledInsta: this.state.disabledInsta ? false : true,
    });
  };

  disabledTwitter = () => {
    this.setState({
      disabledTwiter: this.state.disabledTwiter ? false : true,
    });
  };

  onChangeDtInicio = (value) => {
    this.setState({
      dat_inicial: value,
      dat_final: null,
      dt_max: value ? formatDataComponent(dateFormatterAmericano(value.toLocaleDateString())) : null,
      mensagemAlert: {mensagem: null, severity: null, closeButton: false},
    });
  };

  onChangeDtFim = (value) => {
    this.setState({
      dat_final: value,
      mensagem_erro_data: null,
      mensagemAlert: {mensagem: null, severity: null, closeButton: false},
    });
  };

  onChangeCodPesquisa = async (e) => {
    this.setState({
      cod_pesquisa: e.target.value,
      disabledTwiter: false,
      disabledInsta: false,
    });

    if (e.target.value != null) {
      this.setState({arrConteudo: [], disableInputs: false});
      this.setState({mensagemInformativa: null});

      const arrTag = await RedeSocialService.getCombohashtags({
        filtros: {codPesquisaRedeSocial: e.target.value},
        distinct: true,
      });
      this.setState({arrListHashtag: arrTag, isLoadHashtag: true});
    } else {
      this.setState({disableInputs: true});
    }
  };

  onChangeStrPalavra = (e) => {
    this.setState({
      str_palavra: e.target.value,
    });
  };

  onClickPesquisar = async () => {
    this.setState({isloadingConteudo: true});

    if (this.state.cod_pesquisa === null || this.state.cod_pesquisa === '') {
      this.setState({
        mensagemAlert: {
          mensagem: 'Selecione uma Pesquisa',
          severity: 'error',
          closeButton: true,
        },
        isloadingConteudo: false,
      });
      return false;
    }

    this.setState({
      mensagemAlert: {
        mensagem: null,
        severity: null,
        closeButton: null,
      },
    });

    let conteudo = await RedeSocialService.getMetricasConteudo({
      'dat_inicial': this.state.dat_inicial,
      'dat_final': this.state.dat_final,
      'cod_pesquisa': this.state.cod_pesquisa,
      'cod_hashtag': this.state.cod_hashtag,
      'str_palavra': this.state.str_palavra,
      'disabledInsta': this.state.disabledInsta,
      'disabledTwiter': this.state.disabledTwiter,
      'acao': 'pesquisa',
      'arrStrHashtag': this.state.arrStrHashTag,
      limit: 10,
      offset: 0,
    });

    if (conteudo.arrConteudos <= 0) {
      this.setState({mensagemInformativa: 'Nenhum conteúdo encontrado!'});
    }

    this.setState({arrConteudo: conteudo.arrConteudos});
    this.setState({isloadingConteudo: false});
  };

  handleOnChangeHashtag = (event, value) => {
    this.setState({arrStrHashTag: value});
  };

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <Grid container className={classes.conteudoFundoBranco}
              style={{maxWidth: '96.6%', overflowWrap: 'anywhere'}}>
          {this.state.isLoading === true &&
            <Grid item xs={12} sm={12}
                  style={{marginTop: 25, textAlign: 'center'}}>
              {mensagemPadraoCarregando}
            </Grid>
          }
          {this.state.isLoading === false &&
            <>
              <Grid item xs={12} sm={12} className={classes.formCadastroTitulo}
                    style={{
                      color: '#FFF',
                      backgroundColor: '#1976d2',
                      padding: 15,
                      borderRadius: 5,
                    }}>
                <Grid container>
                  <Grid item xs={4} style={{minWidth: 200}}>
                    <TrendingUpIcon className={classes.iconeTitulo}
                                    style={{color: '#FFF'}}/>
                    <span className={classes.tituloPadrao}
                          style={{color: '#FFF'}}>Métricas Aplicativo</span>
                  </Grid>
                </Grid>
              </Grid>
              {this.state.mensagemAlert.mensagem !== null &&
                <Grid item xs={12} style={{marginBottom: 15, marginTop: 15}}>
                  <Alert
                    severity={this.state.mensagemAlert.severity}
                    action={
                      this.state.mensagemAlert.closeButton === true &&
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.setState({
                            mensagemAlert: {
                              mensagem: null,
                              severity: 'success',
                              closeButton: true,
                            },
                          });
                        }}
                      >
                        <CloseIcon fontSize="inherit"/>
                      </IconButton>
                    }
                  >
                    {this.state.mensagemAlert.mensagem}
                  </Alert>
                </Grid>
              }
              <Grid container>
                <SubTitulo>FILTROS</SubTitulo>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}
                        style={{paddingLeft: 0, minWidth: 230}}>
                    <SelectPadrao
                      required={true}
                      label="Selecione a Pesquisa *"
                      id="id-pesaquisa"
                      value={this.state.cod_pesquisa}
                      onChange={this.onChangeCodPesquisa}
                      isLoaded={this.state.isLoadLisPesquisa}
                      arrDados={this.state.arrListPesquisa}
                    />
                  </Grid>

                  {this.state.disableInputs === false &&
                    <>
                      <Grid item xs={12} sm={5}
                            style={{minWidth: 261, marginTop: '7px'}}>
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          options={this.state.arrListHashtag}
                          getOptionLabel={(option) => option.strHashtag}
                          onChange={this.handleOnChangeHashtag}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Selecione a Hashtag"
                              // placeholder="Favorites"
                              variant="outlined" fullWidth
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={1}
                            style={{marginTop: '8px', minWidth: 239}}>
                        <label htmlFor="">
                          <strong>Redes Sociais:</strong>
                        </label>
                        <IconButton onClick={() => {
                          this.disabledInstagran();
                        }}>
                          <InstagramIcon style={{
                            color: this.state.disabledInsta ? '#D2D5D8' : '#ED33AE',
                            fontSize: 30,
                          }}/>
                        </IconButton>

                        <IconButton onClick={() => {
                          this.disabledTwitter();
                        }}>
                          <TwitterIcon style={{
                            color: this.state.disabledTwiter ? '#D2D5D8' : '#00ACEE',
                            fontSize: 30,
                          }}/>
                        </IconButton>
                      </Grid>

                      <Grid item xs={12} sm={6} style={{minWidth: 261}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                 locale={ptBrLocale}>
                          <KeyboardDatePicker
                            label="Data Inicial"
                            autoOk={true}
                            name="dat_inicial"
                            id="dat_inicial"
                            fullWidth
                            variant="inline"
                            invalidDateMessage="Data inválida"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            InputAdornmentProps={{position: 'end'}}
                            value={this.state.dat_inicial}
                            onChange={this.onChangeDtInicio}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={12} sm={6} style={{minWidth: 261}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                 locale={ptBrLocale}>
                          <KeyboardDatePicker
                            label="Data Final"
                            autoOk={true}
                            name="dat_final"
                            id="dat_final"
                            fullWidth
                            variant="inline"
                            invalidDateMessage="Data inválida"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            minDateMessage={'A Data Fim não pode ser anterior a Data Início.'}
                            minDate={this.state.dt_max}
                            InputAdornmentProps={{position: 'end'}}
                            value={this.state.dat_final}
                            onChange={this.onChangeDtFim}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={12} sm={10} style={{marginTop: 8}}>
                        <TextField
                          id="str_palavra"
                          name="str_palavra"
                          label="Pesquisar por Palavra"
                          fullWidth
                          autoComplete={false}
                          variant="outlined"
                          value={this.state.str_palavra}
                          onChange={this.onChangeStrPalavra}
                          inputProps={{maxLength: 50}}
                        />
                      </Grid>

                      <Grid item xs={12} sm={2} style={{minWidth: 140}}>
                        <Button
                          className={classes.buttonCadastro}
                          type="button"
                          variant="outlined"
                          style={{
                            color: 'white',
                            backgroundColor: '#039ED5',
                            minWidth: '120px',
                          }}
                          onClick={() => {
                            this.onClickPesquisar();
                          }}
                        > PESQUISAR
                        </Button>&nbsp;
                      </Grid>
                    </>
                  }

                  <SubTitulo></SubTitulo>
                  {this.state.isloadingConteudo === true &&
                    <Grid item xs={12} sm={12}
                          style={{marginTop: 25, textAlign: 'center'}}>
                      {mensagemPadraoCarregando}
                    </Grid>
                  }
                  {this.state.isloadingConteudo === false && <>
                    {this.state.arrConteudo == '' &&
                      <Grid item xs={12} sm={12}>
                        {this.state.mensagemInformativa !== null &&
                          <Paper
                            className={classes.mensagemInformativa}>{this.state.mensagemInformativa}</Paper>
                        }
                      </Grid>
                    }
                    {this.state.arrConteudo != '' &&
                      <Grid container>
                        <Conteudo arrConteudo={this.state.arrConteudo}
                                  acao={'conteudo'}
                                  codPesquisa={this.state.cod_pesquisa}
                                  codTag={this.state.cod_hashtag}
                                  disabledInsta={this.state.disabledInsta}
                                  disabledTwiter={this.state.disabledTwiter}
                                  isLoadConteudo={this.state.isLoadConteudo}
                        >
                        </Conteudo>
                      </Grid>
                    }
                  </>}
                </Grid>
              </Grid>
            </>
          }
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  return {user};
}

export default connect(mapStateToProps)(withStyles(styles)(MetricasApp));
