import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from './TypesAction';


import TermoPoliticaService from '../services/termo-politica.service';

const API_URL = process.env.REACT_APP_API_URL;


export const termo = (
  cod_termo_uso,
  str_termo_uso,
  str_termo_uso_tag,
  is_null_termo,
  showForm,
) => (dispatch) => {
  return TermoPoliticaService.termo(
    cod_termo_uso,
    str_termo_uso,
    str_termo_uso_tag,
    is_null_termo,
    showForm,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};

export const politica = (
  cod_politica_privacidade,
  str_politica_privacidade,
  str_politica_privacidade_tag,
  is_null_politica,
  showForm,
) => (dispatch) => {
  return TermoPoliticaService.politica(
    cod_politica_privacidade,
    str_politica_privacidade,
    str_politica_privacidade_tag,
    is_null_politica,
    showForm,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};
