import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import Form from 'react-validation/build/form';
import {Titulo, SubTitulo} from '../../../views/style/styles';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {coletaSeletiva} from '../../../actions/ColetaSeletivaAction';
import {Alert} from '@material-ui/lab';
import {FaBook} from 'react-icons/fa';
import {EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ColetaSeletivaService from '../../../services/coleta-seletiva.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextoRicoPadrao from '../TextoRicoPadrao';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
    tituloPadrao: theme.pagina.tituloPadrao,
    iconeTitulo: theme.pagina.iconeTitulo,
    formCadastroTitulo: theme.pagina.formCadastroTitulo,
    mainBox: {
        overflowY: 'auto',
        width: '100%',
        position: 'fixed',
        margin: 0,
        padding: 0,
        top: 0,
        left: 0,
        height: '100%',
    },
    headerPanel: {
        backgroundColor: '#185b8d',
        width: '100%',
        height: 176,
    },
    logo: {
        width: 350,
        lineHeight: 94,
    },
    logoDiv: {
        width: '100%',
        textAlign: 'center',
    },
    formCadastro: {
        margin: '15px',
    },
    userCicle: {
        width: '150px',
    },
    button: {
        color: '#FFF',
        textTransform: 'none',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        padding: '10px 10px',
        lineHeight: 1.4,
        borderRadius: 30,
        width: '100%',
    },
    content: {
        backgroundColor: '#FFF',
        padding: '5px 5px 5px',
        borderRadius: '5px',
    },
    buttonDiv: {
        textAlign: '-webkit-center',
    },
    buttonContainer: {
        marginTop: 20,
        width: 300,
        marginBottom: 20,
    },
    link: {
        color: '#979797',
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    storeButton: {
        width: 150,
        lineHeight: 94,
    },
    contentFooterDiv: {
        marginTop: '1%',
        marginBottom: '1%',
    },
    sMediaDiv: {
        width: '10%',
    },
    sMediaButton: {
        width: 50,
        marginRight: 10,
    },
    footerDiv: {
        textAlign: 'center',
        marginTop: '1%',
    },
    invalidFeedback: {
        display: 'none',
    },
    formContainer: {
        position: 'relative',
        padding: '5% 10% 5% 10%',
        opacity: 1,
        borderRadius: '20px',
        backgroundColor: '#fff',
    },
    dataPicker: {
        fontcolor: '#4AE2D2',
    },
    inputText: {
        color: 'red',
    },

    seletor: {
        border: '4px solid #f9f9f9;',
        borderRadius: '5px',
    },

    wrapperClass: {
        padding: '1rem;',
        bordered: '1px solid #ccc;',
    },

    editorClass: {
        // backgroundColor: 'lightgray;',
        padding: '1rem;',
        // border: '1px solid #ccc;',
    },

    toolbarClass: {
        border: '1px solid #ccc;',
    },
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );

class Visualizar extends Component {
    constructor(props) {
        super(props);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onClickVoltar = props.onVoltar;

        this.state = {
            cod_instrucao_coleta: props.codInstrucaoColeta,
            editorState: '',
            str_desc_instrucao: '',
            str_desc_instrucao_fmt: '',
            message: '',
            isLoading: true,
            successful: false,
            redirect: false,
        };
    }

    componentDidMount() {
     const data = Promise.resolve(ColetaSeletivaService.getDadosIntrucao(this.state.cod_instrucao_coleta));
        if (data) {
            data.then((v) => {
                this.populateForm(v[0]);
            });
        }
    }

    populateForm(dados) {
        let row = convertFromRaw(dados.str_desc_instrucao);

        dados.editorState = EditorState.createWithContent(row);
        dados.isLoading = false;
        this.setState(dados);
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            str_desc_instrucao: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
            str_desc_instrucao_fmt: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        });
    };

    onChangeCodInstrucaoColeta(e) {
        this.setState({
            cod_instrucao_coleta: e.target.value,
        });
    }

    render() {
        const {successful, isLoggedIn, message, classes} = this.props;

        if (this.state.redirect) {
            window.location = '/coleta-seletiva/listar';
        }

        const {editorState} = this.state;
        const titulo = 'Visualizar Instruções de Coleta';

        return (
          <>
              {this.state.isLoading === true &&
              <Grid container spacing={2} style={{marginTop: 25}}>
                  <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
                      {mensagemPadraoCarregando}
                  </Grid>
              </Grid>
              }
              {this.state.isLoading === false && <div className={classes.root}>
                  <Grid container className={classes.conteudoFundoBranco} style={{ minWidth: 421 }} >
                      <Grid item xs={12} className={classes.formCadastroTitulo}>
                          <FaBook className={classes.iconeTitulo}/>
                          <span className={classes.tituloPadrao}> {titulo}</span>
                      </Grid>
                      <Grid container>
                          <Grid item xs={12} className={classes.content}>
                              <Form onSubmit={this.handleRegister} ref={(c) => {
                                  this.form = c;
                              }} className={classes.formCadastro}>
                                  <Grid container spacing={2} style={{marginTop: 25}}>
                                      <SubTitulo>Instruções</SubTitulo>
                                      <Grid item xs={12} sm={2}>
                                          <label htmlFor=""
                                                 style={{marginTop: 25}}>Instruções</label>
                                      </Grid>
                                      <Grid item xs={12} sm={10}>
                                          <TextoRicoPadrao
                                            editorState={editorState}
                                            wrapperClassName={classes.wrapperClass}
                                            editorClassName={classes.editorClass}
                                            toolbarClassName={classes.toolbarClass}
                                          />
                                      </Grid>
                                  </Grid>
                                  <Grid container spacing={3}>
                                      <Grid item xs={12}>
                                          <div style={{textAlign: 'center'}}>
                                              <Button onClick={this.onClickVoltar}>Voltar </Button>
                                          </div>
                                      </Grid>
                                  </Grid>
                              </Form>
                          </Grid>
                      </Grid>
                  </Grid>
              </div>
              }
          </>
        );
    }
}

function mapStateToProps(state) {
    const {
        isLoggedIn,
    }

      = state.auth;
    const {message} = state.message;

    return {isLoggedIn, message};
}

export default connect(mapStateToProps)(withStyles(styles)(Visualizar));
