import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    Select,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import * as Icon from '@material-ui/icons';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {faFacebook} from '@fortawesome/free-brands-svg-icons';
import Form from 'react-validation/build/form';
import CheckButton from 'react-validation/build/button';
import {connect} from 'react-redux';
import {
    facebookLogin,
    googleLogin,
    login,
    register,
    registerConfirmation,
    sendEmail,
} from '../actions/AuthAction';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import validator from 'validator';
import GoogleLogin from 'react-google-login';
import FacebookLogin
    from 'react-facebook-login/dist/facebook-login-render-props';

import {gapi} from 'gapi-script';

import Equipe from '../services/equipe.service';
import {validateEmail} from '../components/util';
import ModalPadrao from "../components/partials/ModalPadrao";

const API_URL = process.env.REACT_APP_API_URL;

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
    },
    mainBox: {
        width: '100%',
        position: 'fixed',
        margin: 0,
        padding: 0,
        top: 0,
        left: 0,
        display: 'flex',
        height: '100%',
    },
    leftPanel: {
        backgroundColor: '#fff',
        background: 'url(images/background_login1.png)',
        width: '40%',
        height: '100%',
    },
    formPanel: {
        width: '60%',
        overflowY: 'auto',
        height: '100%',
        backgroundColor: '#ececec',
    },
    form: {
        '&::before': {
            content: '\'\'',
            background: '#f2f2f2',
            opacity: 0.9,
            backdropFilter: 'blur(1.5rem)',
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderRadius: '1.5em',
        },
        '& .MuiInput-underline': {
            border: 'unset !important',
        },
        position: 'relative',
        width: '25em',
        minHeight: '18em',
        boxShadow: '0px 4px 8px #00000059',
        borderRadius: 22,
        margin: '4% 1% 7% 30%',
    },
    formContainer: {
        position: 'relative',
        padding: '5% 10% 5% 10%',
        opacity: 1,
        borderRadius: '20px',
        backgroundColor: '#fff',
    },
    formTabs: {
        background: '#F2F2F2',
        position: 'relative',
        padding: '0 10%',
        borderRadius: '20px 20px 0px 0px',
        display: 'flex',
        justifyContent: 'space-around',
    },
    button: {
        color: '#FFF',
        textTransform: 'none',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        background: theme.palette.background.button,
        padding: '10px 10px',
        lineHeight: 1.4,
        borderRadius: 30,
        width: '100%',
        height: '2.7em',
        borderWidth: 0,
        border: 'solid 1px',
        borderColor: theme.palette.background.button,
        '&:hover': {
            color: theme.palette.background.button,
            border: 'solid 1px',
        },
    },
    buttonSocial: {
        color: '#858585',
        textTransform: 'none',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        padding: '10px 10px',
        lineHeight: 1.4,
        borderRadius: 30,
        border: '2px solid #c5c5c5',
        width: '100%',
        '&:hover': {
            color: '#858585',
            backgroundColor: '#c5c5c5',
        },
        height: '3em',
    },
    content: {
        marginBottom: 3,
    },
    buttonContainer: {
        marginTop: 20,
        marginBottom: 20,
    },
    link: {
        color: '#979797',
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '42px',
    },
    linkR: {
        color: '#75CAE8',
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '42px',
    },
    loginFormTop: {
        textAlign: 'center',
        fontSize: '19px',
        color: '#7d7d7d',
        fontWeight: 'bold',
        marginBottom: 5,
    },
    image: {
        width: '100%',
    },
    logoDiv: {
        width: '100%',
        height: '350px',
        textAlign: 'center',
    },
    logo: {
        width: '450px',
        lineHeight: '94px',
        marginTop: '15%',
    },
    loginTab: {
        fontSize: '14px',
        height: '48px',
        width: '55px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingTop: '24px',
        textAlign: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
    },
    newAccountTab: {
        fontSize: '14px',
        height: '48px',
        width: '91px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        paddingTop: '24px',
        textAlign: 'center',
        cursor: 'pointer',
        fontWeight: 'bold',
    },
    loginTabSpan: {
        marginBottom: '12px',
        color: '#9a9a9a',
    },
    loginTabSpanActive: {
        marginBottom: '5px',
        paddingBottom: '3px',
        borderBottom: 'solid 4px #38DFCE',
    },
    loginTabDivActive: {
        width: '100%',
        height: '6px',
        backgroundColor: '#4AE2D2',
        borderRadius: '10px',
    },
    loginTabDiv: {
        width: '100%',
        height: '6px',
        backgroundColor: '#F2F2F2',
        borderRadius: '10px',
    },
    loginInput: {
        '&$focused': {
            color: '#FFF',
        },
        '& .MuiInput-underline': {
            border: 'unset !important',
        },
        fontWeight: 'bold',
        color: '#FFF',
        margin: '20px 0px',
        border: 'unset',
    },
    inputGenero: {
        width: 280,
    },
    fontGenero: {
        color: '#6f6f6f',
    },
    circularProgress: {
        width: '25px !important',
        height: '25px !important',
    },
    dataPicker: {
        fontcolor: '#4AE2D2',
    },
    spanRecuperar: {
        fontSize: 11,
        textAlign: 'center',
        color: '#7d7d7d',
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: 14,
    },
    iconRecuperar: {
        paddingTop: 9,
    },
    buttonFacebook: {
        color: '#fff',
        backgroundColor: '#1877F2',
        borderColor: '#1877F2',
        textTransform: 'none',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        padding: '10px 10px',
        lineHeight: 1.4,
        borderRadius: 30,
        border: '2px solid #c5c5c5',
        width: '100%',
        '&:hover': {
            color: '#1877F2',
            backgroundColor: '#fff2',
            borderColor: '#fff',
        },
        height: '3em',
    },
    buttonGoogle: {
        color: '#000',
        backgroundColor: '#fff',
        borderColor: '#fff',
        textTransform: 'none',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        padding: '10px 10px',
        lineHeight: 1.4,
        borderRadius: 30,
        border: '2px solid #c5c5c5',
        width: '100%',
        '&:hover': {
            color: '#7d7d7d',
            backgroundColor: '#fff',
            borderColor: '#fff',
        },
        height: '3em',
    },
});

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleRecuperarSenha = this.handleRecuperarSenha.bind(this);
        this.handleFacebook = this.handleFacebook.bind(this);
        this.handleGoogle = this.handleGoogle.bind(this);
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.validarSenha = this.validarSenha.bind(this);
        this.validarConfirmacaoSenha = this.validarConfirmacaoSenha.bind(this);
        this.validarEmail = this.validarEmail.bind(this);
        this.validarConfirmacaoEmail = this.validarConfirmacaoEmail.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeLastname = this.onChangeLastname.bind(this);
        this.onChangeRegisterEmail = this.onChangeRegisterEmail.bind(this);
        this.onChangeRegisterEmailConfirmation = this.onChangeRegisterEmailConfirmation.bind(this);
        this.onChangeBirthDate = this.onChangeBirthDate.bind(this);
        this.onChangeRegisterPassword = this.onChangeRegisterPassword.bind(this);
        this.onChangeRegisterPasswordConfirm = this.onChangeRegisterPasswordConfirm.bind(this);
        this.onChangeGender = this.onChangeGender.bind(this);
        this.onChangeEmailRecuperar = this.onChangeEmailRecuperar.bind(this);
        this.onChangeManterConectado = this.onChangeManterConectado.bind(this);

        this.state = {
            username: '',
            password: '',
            str_nome_pessoa: '',
            str_sobrenome: '',
            registerPassword: '',
            passwordConfirmation: '',
            str_email: '',
            emailConfirmation: '',
            dat_nascimento: null,
            flg_genero: '',
            str_email_recuperar: '',
            loading: false,
            successful: false,
            showForm: 'login',
            login: false,
            register: false,
            strongPassword: false,
            tokenPessoa: '',
            loginFace: false,
            loginGoogle: false,
            mRegPassword: 'A senha deve conter entre 8 e 32 caracteres, e ao menos uma letra maiúscula, uma minúscula e um número.',
            errorPassoword: false,
            errorRegConfPassword: false,
            mRegEmail: '',
            errorRegEmail: false,
            mRegConfEmail: '',
            errorRegConfEmail: false,
            mRegDataNasc: '',
            errorRegDataNasc: false,
            mEmailRecuperacao: '',
            errorEmailRecuperacao: false,
            emailNaoEncontrado: true,
            checkedManterConectado: false,
            manterConectado: false,
            dt_max: '',
            message_erro: null,
            isValidPerfil: false,
            error_date: null,
            maxDate: null,
            minDate: null,
            checkTermos: null,
            checkPolitica: null
        };
    }

    componentDidMount() {
        let today = new Date();
        let dtAtual = new Date().getFullYear() - 1 + '-' + '12-31';

        this.setState({maxDate: dtAtual});
        this.setState({minDate: today.getFullYear() - 100 + '-' + '12-31'});

        //Carrega dados do login do google
        gapi.load('client:auth2', () => {
            gapi.client.init({
                clientId: process.env.REACT_APP_CLIENT_GOOGLE_ID,
            });
        });
    }

    onChangeUsername(e) {
        this.setState({
            username: e.target.value,
        });
    }

    onChangePassword(e) {
        this.setState({
            password: e.target.value,
        });
    }

    onChangeName(e) {
        this.setState({
            str_nome_pessoa: e.target.value,
        });
    }

    onChangeLastname(e) {
        this.setState({
            str_sobrenome: e.target.value,
        });
    }

    onChangeRegisterEmail(e) {
        this.setState({
            str_email: e.target.value,
        });
    }

    validarEmail() {

        if (this.state.str_email) {

            if (!validateEmail(this.state.str_email)) {
                this.setState({
                    mRegEmail: 'Formato de email inválido',
                    errorRegEmail: true,
                    str_email: '',
                });
                return;
            }

            fetch(API_URL + 'usuario/interno/verifica-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({str_email: this.state.str_email}),
            }).then((res) => res.json())
                .then((res) => {

                    if (res.message) {
                        this.setState({
                            mRegEmail: res.message,
                            errorRegEmail: true,
                        });
                    } else {
                        this.setState({
                            mRegEmail: '',
                            errorRegEmail: false,
                        });
                    }
                });
        } else {
            this.setState({
                mRegEmail: '',
                errorRegEmail: false,
            });
        }
    }

    onChangeRegisterEmailConfirmation(e) {
        this.setState({
            emailConfirmation: e.target.value,
        });
    }

    validarConfirmacaoEmail() {

        if (!validateEmail(this.state.emailConfirmation)) {
            this.setState({
                mRegConfEmail: 'Formato de email inválido',
                errorRegConfEmail: true,
                emailConfirmation: '',
            });
            return;
        }

        if (this.state.str_email && this.state.emailConfirmation) {
            if (this.state.str_email === this.state.emailConfirmation) {
                this.setState({
                    errorRegConfEmail: false,
                    mRegConfEmail: '',
                });
            } else {
                this.setState({
                    errorRegConfEmail: true,
                    mRegConfEmail: 'Os campos de Email e Confirmação de Email devem ser iguais.',
                });
            }
        } else {
            this.setState({
                errorRegConfEmail: false,
                mRegConfEmail: '',
            });
        }
    }

    onChangeBirthDate(date) {
        let today = new Date();
        if (date == null || date == undefined) {
            return true;
        }

        if (date == 'Invalid Date' || today.getFullYear() <= date.getFullYear()) {
            this.setState({
                error_date: 'Data de nascimento inválida',
            });
        } else {
            if (date != null) {
                let today = new Date();
                let dtAtual = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDate());
                let dtInf = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + (date.getDate());

                if ((parseInt(dtAtual.split('-')[0]) - parseInt(dtInf.split('-')[0])) <= 100) {
                    this.setState({
                        error_date: null,
                    });
                }
            } else {
                this.setState({
                    error_date: null,
                });
            }
        }

        this.setState({
            dat_nascimento: date,
        });
    }

    onChangeRegisterPassword(e) {
        this.setState({
            registerPassword: e.target.value,
        });
    }

    validarSenha() {
        if (this.state.registerPassword) {
            if (validator.isStrongPassword(this.state.registerPassword, {
                minLength: 8, maxLength: 33, minLowercase: 1,
                minUppercase: 1, minNumbers: 1, minSymbols: 0,
            })) {
                this.setState({
                    strongPassword: 'true',
                    errorPassoword: false,
                    mRegPassword: '',
                });
            } else {
                this.setState({
                    strongPassword: 'false',
                    errorPassoword: true,
                    mRegPassword: 'A senha deve conter entre 8 e 32 caracteres, e ao menos uma letra maiúscula, uma minúscula e um número.',
                });
            }
        } else {
            this.setState({
                errorPassoword: false,
                mRegPassword: 'A senha deve conter entre 8 e 32 caracteres, e ao menos uma letra maiúscula, uma minúscula e um número.',
            });
        }
    }

    onChangeRegisterPasswordConfirm(e) {
        this.setState({
            passwordConfirmation: e.target.value,
        });
    }

    validarConfirmacaoSenha() {
        if (this.state.registerPassword && this.state.passwordConfirmation) {
            if (this.state.registerPassword === this.state.passwordConfirmation) {
                this.setState({
                    errorRegConfPassword: false,
                    mRegConfPassword: '',
                });
            } else {
                this.setState({
                    errorRegConfPassword: true,
                    mRegConfPassword: 'As senhas devem ser iguais.',
                });
            }
        } else {
            this.setState({
                errorRegConfPassword: false,
                mRegConfPassword: '',
            });
        }
    }

    onChangeGender(e) {
        this.setState({
            flg_genero: e.target.value,
        });
    }

    onChangeManterConectado(e) {
        this.setState({
            manterConectado: e.target.checked,
        });
    }

    onChangeEmailRecuperar(e) {
        this.setState({
            str_email_recuperar: e.target.value,
        });
    }

    onChecksPerfil = async (strEmial) => {
        this.setState({loading: true});

        let perfeil = await Equipe.getPerfiluserPublic({
            str_email: strEmial,
            via_web: true,
        });
        if (perfeil.status) {
            this.setState({isValidPerfil: true});
        } else {
            this.setState({isValidPerfil: false, message_erro: perfeil.message});
        }
    };

    handleLogin = async (e) => {
        e.preventDefault();

        await this.onChecksPerfil(this.state.username);
        this.setState({loading: true});
        this.setState({login: true});
        this.setState({message_erro: null});

        this.form.validateAll();

        const {dispatch, history} = this.props;

        if (this.checkBtn.context._errors.length === 0) {
            if (!this.state.isValidPerfil) {
                this.setState({message_erro: 'Você não tem permissão de acesso.'});
                this.setState({loading: false});
            } else {
                dispatch(login(this.state.username, this.state.password, this.state.manterConectado))
                    .then(() => {
                        window.location.href = '/home';
                        this.setState({successful: true});
                    })
                    .catch(() => {
                        this.setState({successful: false});
                        this.setState({loading: false});
                        this.setState({login: false});
                    });
            }
        } else {
            this.setState({successful: false});
            this.setState({loading: false});
            this.setState({login: false});
        }
    };

    handleRegister(e) {
        e.preventDefault();

        if (this.state.error_date || this.state.mRegEmail || this.state.mRegConfEmail
            || this.state.mRegPassword || this.state.mRegConfPassword) {

            return false;
        }

        this.setState({
            successful: false,
            loading: true,
            register: true,
        });

        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            this.props
                .dispatch(
                    register(this.state.str_email,
                        this.state.emailConfirmation,
                        this.state.str_nome_pessoa,
                        this.state.str_sobrenome,
                        this.state.dat_nascimento,
                        this.state.flg_genero,
                        this.state.registerPassword,
                        this.state.passwordConfirmation,
                        this.state.strongPassword),
                )
                .then(() => {
                    // this.sendEmailRegister();
                    this.setState({
                        showForm: 'login',
                        loading: false,
                        successful: true,
                        register: false,
                    });
                    this.setState({
                        str_email: '',
                        emailConfirmation: '',
                        str_nome_pessoa: '',
                        str_sobrenome: '',
                        dat_nascimento: '',
                        flg_genero: '',
                        registerPassword: '',
                        passwordConfirmation: '',
                        strongPassword: '',
                    });
                })
                .catch(() => {
                    this.setState({loading: false, register: false});
                });
        }
    }

    sendEmailRegister() {
        this.props
            .dispatch(
                sendEmail(this.state.str_email, this.state.str_nome_pessoa),
            );
    }

    handleRecuperarSenha(e) {
        e.preventDefault();

        this.setState({recuperarSenha: true});

        if (this.checkBtn.context._errors.length === 0) {
            this.props
                .dispatch(
                    sendEmail(this.state.str_email_recuperar, null),
                )
                .then(() => {
                    this.setState({
                        showForm: 'login',
                        successful: true,
                        recuperarSenha: false,
                    });
                })
                .catch(() => {
                    this.setState({
                        successful: false,
                        recuperarSenha: false,
                    });
                });
        }
    }

    handleFacebook = async (response) => {
        this.setState({loading: true, loginFace: true});

        if (response.accessToken) {

            let authResponse = {
                'id': response.id,
                'accessToken': response.accessToken,
                'nome': response.first_name,
                'sobrenome': response.last_name,
                'email': response.email,
                'foto': response.picture.data.url,
            };

            await this.onChecksPerfil(response.email);
            if (!this.state.isValidPerfil) {
                this.setState({loading: false});
                return;
            }

            this.props
                .dispatch(
                    facebookLogin(authResponse),
                )
                .then(() => {
                    this.setState({loading: false, loginFace: false});
                })
                .catch(() => {
                    this.setState({loading: false, loginFace: false});
                });
        } else {
            this.setState({loading: false, loginFace: false});
        }
    };

    handleGoogle = async (response) => {
        this.setState({loading: true, loginGoogle: true});

        if (response.profileObj) {

            let authResponse = {
                'id': response.profileObj.googleId,
                'accessToken': response.accessToken,
                'nome': response.profileObj.givenName,
                'sobrenome': response.profileObj.familyName,
                'email': response.profileObj.email,
                'foto': response.profileObj.imageUrl,
            };

            await this.onChecksPerfil(response.profileObj.email);
            if (!this.state.isValidPerfil) {
                this.setState({loading: false});
                return;
            }

            this.props
                .dispatch(
                    googleLogin(authResponse),
                )
                .then(() => {
                    this.setState({loading: false, loginGoogle: false});
                })
                .catch(() => {
                    this.setState({loading: false, loginGoogle: false});
                });
        } else {
            this.setState({loading: false, loginGoogle: false});
        }
    };

    componentDidMount() {
        this.ativarUsuario();
    }

    ativarUsuario() {
        let token = '';
        let url = window.location.href;
        if (url.includes('login?token=')) {
            token = url.replace(window.origin + '/login?token=', '');
        }
        if (token) {
            this.props
                .dispatch(
                    registerConfirmation(token),
                )
                .then(() => {
                    this.setState({successful: true});
                });
        }
    }

    openTermo = (e) => {
        e.preventDefault();
        window.open(window.location.href + 'termo-uso-public', '_blank');
        this.setState({checkTermos: true});
    }

    openPolitica = (e) => {
        e.preventDefault();
        window.open(window.location.href + 'termo-politica-public', '_blank');
        this.setState({checkPolitica: true});
    }

    render() {
        const {isLoggedIn, message, classes} = this.props;

        if (isLoggedIn) {
            if (localStorage.getItem('user')) {
                let dadosToken = JSON.parse(localStorage.getItem('user'));
                if (dadosToken && dadosToken.expiration && dadosToken.expiration.date) {
                    let dataAual = new Date();
                    let dataExpiracao = new Date(dadosToken.expiration.date);
                    if (dataAual <= dataExpiracao) {
                        window.location.href = '/home';
                    }
                }
            }
        }

        const emailInput = {
            '& ::placeholder': {
                color: '#9DB3D4',
                opacity: 1,
            },
        };

        return (<Box className={classes.mainBox}>
                <Box className={classes.leftPanel}>
                    <div className={classes.logoDiv}>
                        <img className={classes.logo} src={'images/logo_blue.png'}
                             alt="Logo"/>
                    </div>
                    <div>
                        <img src={'images/background_login2.png'}/>
                    </div>
                </Box>
                <Box className={classes.formPanel}>
                    <Box className={classes.form}>
                        <Box className={classes.formTabs}>
                            <div className={classes.loginTab}
                                 onClick={() => this.setState({
                                     showForm: 'login',
                                     str_email: '',
                                     emailConfirmation: '',
                                     str_nome_pessoa: '',
                                     str_sobrenome: '',
                                     dat_nascimento: '',
                                     flg_genero: '',
                                     registerPassword: '',
                                     passwordConfirmation: '',
                                     strongPassword: '',
                                 })}>
              <span
                  className={this.state.showForm === 'login' ? classes.loginTabSpanActive : classes.loginTabSpan}>Login</span>
                                <div
                                    className={this.state.showForm === 'login' ? classes.loginTabDivActive : classes.loginTabDiv}></div>
                            </div>
                            <div className={classes.newAccountTab}
                                 onClick={() => this.setState({
                                     showForm: 'cadastro',
                                     username: '',
                                     password: '',
                                     manterConectado: false,
                                 })}>
              <span
                  className={this.state.showForm === 'cadastro' ? classes.loginTabSpanActive : classes.loginTabSpan}>Criar Conta</span>
                                <div
                                    className={this.state.showForm === 'cadastro' ? classes.loginTabDivActive : classes.loginTabDiv}></div>
                            </div>
                        </Box>
                        {this.state.showForm === 'login' &&
                            <Box className={classes.formContainer}>
                                <Box className={classes.loginFormTop}>
                                    Acesse sua conta
                                </Box>
                                <Form
                                    onSubmit={this.handleLogin}
                                    ref={(c) => {
                                        this.form = c;
                                    }}
                                >
                                    {message && (
                                        <FormControl className={classes.content} fullWidth>
                                            <Alert
                                                severity={this.state.successful ? 'success' : 'error'}>{message}</Alert>
                                        </FormControl>
                                    )}
                                    {this.state.message_erro && (
                                        <FormControl className={classes.content} fullWidth>
                                            <Alert
                                                severity="error">{this.state.message_erro}</Alert>
                                        </FormControl>
                                    )}
                                    <FormControl className={classes.content} fullWidth>
                                        <TextField className={classes.loginInput}
                                                   style={emailInput}
                                                   type="email"
                                                   placeholder="Email"
                                                   name="username"
                                                   required
                                                   value={this.state.username}
                                                   onChange={this.onChangeUsername}
                                                   InputProps={{
                                                       endAdornment: (<Icon.Mail/>),
                                                   }}/>
                                    </FormControl>
                                    <FormControl className={classes.content} fullWidth>
                                        <TextField className={classes.loginInput}
                                                   type="password"
                                                   placeholder="********"
                                                   name="password"
                                                   required
                                                   value={this.state.password}
                                                   onChange={this.onChangePassword}
                                                   InputProps={{
                                                       endAdornment: (<Icon.VpnKey/>),
                                                   }}/>
                                    </FormControl>
                                    <Grid container justifyContent="space-between">
                                        <FormControlLabel
                                            style={{marginRight: -10}}
                                            control={
                                                <Checkbox
                                                    style={{marginRight: -10}}
                                                    name="keepAlive"
                                                    color="primary"
                                                    checked={this.state.manterConectado}
                                                    onChange={this.onChangeManterConectado}
                                                />
                                            }
                                            label={<Typography className={classes.link}>Manter
                                                Conectado</Typography>}
                                        />
                                        <label className={classes.linkR}
                                               onClick={() => this.setState({showForm: 'recuperar'})}>
                                            Recuperar Senha
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <React.Fragment>
                                                        <Typography color="inherit" style={{fontSize: 12}}>Esqueceu
                                                            sua
                                                            senha?<br/>Clique aqui para
                                                            recuperá-la.</Typography>
                                                    </React.Fragment>
                                                }
                                            >
                                                <Icon.Help
                                                    style={{width: 15, marginLeft: 3, marginBottom: 3}}/>
                                            </Tooltip>
                                        </label>
                                    </Grid>
                                    <Grid className={classes.buttonContainer} container
                                          justifyContent="center">
                                        <Button variant="outlined" color="primary"
                                                type="submit"
                                                className={classes.button}
                                                disabled={this.state.loading}
                                        >
                                            {this.state.loading && this.state.login && (
                                                    <CircularProgress className={classes.circularProgress}/>
                                                ) ||
                                                <span>Entrar</span>
                                            }
                                        </Button>
                                    </Grid>
                                    <Grid className={classes.buttonContainer}
                                          container justifyContent="center">
                                        <GoogleLogin
                                            //clientId="416384668070-ac18vhs3ug3o1braeu3s0cr04qfbejmp.apps.googleusercontent.com"
                                            clientId={process.env.REACT_APP_CLIENT_GOOGLE_ID}
                                            render={renderProps => (
                                                <Button className={classes.buttonGoogle}
                                                        onClick={renderProps.onClick}
                                                        startIcon={
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/headergugo-icon.png'}
                                                                alt="ícone" style={{height: 25}}/>
                                                        }
                                                        disabled={renderProps.disabled}
                                                >
                                                    {this.state.loading && this.state.loginGoogle && (
                                                            <CircularProgress
                                                                className={classes.circularProgress}/>
                                                        ) ||
                                                        <span>Entrar com o Google</span>
                                                    }
                                                </Button>
                                            )}
                                            onSuccess={this.handleGoogle}
                                            onFailure={this.handleGoogle}
                                            scope="https://www.googleapis.com/auth/user.gender.read
                           https://www.googleapis.com/auth/userinfo.profile
                           https://www.googleapis.com/auth/user.birthday.read"
                                            buttonText="Entrar com o Google"
                                            cookiePolicy="single_host_origin"
                                        />
                                    </Grid>
                                    <Grid className={classes.buttonContainer} container
                                          justifyContent="center">
                                        <FacebookLogin
                                            // appId="744935886699166" //Id de homologação e prod
                                            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                            fields="name,first_name,last_name,email,picture,gender,birthday"
                                            scope="email,user_gender,user_birthday"
                                            callback={this.handleFacebook}
                                            render={renderProps => (
                                                <Button className={classes.buttonFacebook}
                                                        onClick={renderProps.onClick}
                                                        startIcon={
                                                            <FontAwesomeIcon icon={faFacebook}
                                                            />}
                                                        disabled={renderProps.disabled}
                                                >
                                                    {this.state.loading && this.state.loginFace && (
                                                            <CircularProgress
                                                                className={classes.circularProgress}/>
                                                        ) ||
                                                        <span>Entrar com o Facebook</span>
                                                    }
                                                </Button>
                                            )}
                                        />
                                    </Grid>
                                    <CheckButton
                                        style={{display: 'none'}}
                                        ref={(c) => {
                                            this.checkBtn = c;
                                        }}
                                    />
                                </Form>
                            </Box>
                        }
                        {this.state.showForm === 'cadastro' &&
                            <Box className={classes.formContainer}>
                                <Box className={classes.loginFormTop}>
                                    Novo Cadastro
                                </Box>
                                <Form
                                    onSubmit={this.handleRegister}
                                    ref={(c) => {
                                        this.form = c;
                                    }}
                                >
                                    {message && (
                                        <FormControl className={classes.content} fullWidth>
                                            <Alert
                                                severity={this.state.successful ? 'success' : 'error'}>{message}</Alert>
                                        </FormControl>
                                    )}
                                    <FormControl className={classes.content} fullWidth>
                                        <TextField type="text" label="Nome" name="nome"
                                                   required
                                                   inputProps={{maxLength: 100}}
                                                   value={this.state.str_nome_pessoa}
                                                   onChange={this.onChangeName}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.content} fullWidth>
                                        <TextField type="text" label="Sobrenome" name="sobrenome"
                                                   required
                                                   inputProps={{maxLength: 100}}
                                                   value={this.state.str_sobrenome}
                                                   onChange={this.onChangeLastname}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.content} fullWidth>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                                 locale={ptBrLocale}>
                                            <KeyboardDatePicker
                                                error={this.state.error_date}
                                                helperText={this.state.error_date !== '' ? this.state.error_date : ''}
                                                autoOk={true}
                                                variant="inline"
                                                required
                                                format="dd/MM/yyyy"
                                                openTo="year" //abre direto na escolha do ano
                                                views={['year', 'month', 'date']} // formato de sequência do calendário
                                                disableFuture="true"
                                                invalidDateMessage="Data inválida"
                                                allowKeyboardControl={false}
                                                label="Data de Nascimento"
                                                maxDateMessage={'Data não pode ser superior a data atual'}
                                                minDateMessage={'Data inválida'}
                                                maxDate={new Date().getFullYear() - 1 + '-' + '12-31'}
                                                minDate={new Date().getFullYear() - 100 + '-' + '12-31'}
                                                value={this.state.dat_nascimento}
                                                onChange={this.onChangeBirthDate}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                    <FormControl className={classes.content}>
                                        <InputLabel>Gênero *</InputLabel>
                                        <Select className={classes.inputGenero}
                                                placeholder="Selecione"
                                                required
                                                value={this.state.flg_genero}
                                                onChange={this.onChangeGender}
                                        >
                                            <MenuItem className={classes.fontGenero}
                                                      value={'M'}>Masculino</MenuItem>
                                            <MenuItem className={classes.fontGenero}
                                                      value={'F'}>Feminino</MenuItem>
                                            <MenuItem className={classes.fontGenero} value={'N'}>Desejo
                                                não
                                                informar</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl className={classes.content} fullWidth>
                                        <TextField type="email" label="Email" name="email"
                                                   required
                                                   error={this.state.errorRegEmail}
                                                   inputProps={{maxLength: 150}}
                                                   helperText={this.state.mRegEmail}
                                                   value={this.state.str_email}
                                                   onBlur={this.validarEmail}
                                                   onChange={this.onChangeRegisterEmail}/>
                                    </FormControl>
                                    <FormControl className={classes.content} fullWidth>
                                        <TextField type="email" label="Confirmação de Email"
                                                   name="emailConfirmation"
                                                   required
                                                   inputProps={{maxLength: 150}}
                                                   error={this.state.errorRegConfEmail}
                                                   helperText={this.state.mRegConfEmail}
                                                   onBlur={this.validarConfirmacaoEmail}
                                                   onChange={this.onChangeRegisterEmailConfirmation}
                                                   value={this.state.emailConfirmation}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.content} fullWidth>
                                        <TextField type="password" label="Senha" name="password"
                                                   required
                                                   inputProps={{maxLength: 32}}
                                                   error={this.state.errorPassoword}
                                                   helperText={this.state.mRegPassword}
                                                   value={this.state.registerPassword}
                                                   onChange={this.onChangeRegisterPassword}
                                                   onBlur={this.validarSenha}
                                        />
                                    </FormControl>
                                    <FormControl className={classes.content} fullWidth>
                                        <TextField type="password" label="Confirmação de Senha"
                                                   name="passwordConfirmation"
                                                   required
                                                   inputProps={{maxLength: 32}}
                                                   error={this.state.errorRegConfPassword}
                                                   helperText={this.state.mRegConfPassword}
                                                   value={this.state.passwordConfirmation}
                                                   onBlur={this.validarConfirmacaoSenha}
                                                   onChange={this.onChangeRegisterPasswordConfirm}
                                        />
                                    </FormControl>

                                    {/*Termo de Uso e Política de Privacidade*/}
                                    <FormControlLabel
                                        style={{marginRight: -10}}
                                        control={
                                            <Checkbox
                                                style={{marginRight: -10}}
                                                name="keepAlive"
                                                color="primary"
                                                checked={this.state.checkTermos}
                                                onChange={() => {
                                                    this.setState({checkTermos: !this.state.checkTermos})
                                                }}
                                            />
                                        }
                                        label={<Typography className={classes.link}>
                                            <a href="#"
                                               onClick={this.openTermo}
                                            >Aceitar Termo de Uso</a>
                                        </Typography>}
                                    />

                                    <FormControlLabel
                                        style={{marginRight: -10, marginTop: '-25px'}}
                                        control={
                                            <Checkbox
                                                style={{marginRight: -10}}
                                                name="keepAlive"
                                                color="primary"
                                                checked={this.state.checkPolitica}
                                                onChange={() => {
                                                    this.setState({checkPolitica: !this.state.checkPolitica})
                                                }}
                                            />
                                        }
                                        label={<Typography className={classes.link}>
                                            <a href="#"
                                               onClick={this.openPolitica}
                                            >Política de Privacidade do Aplicativo </a>
                                        </Typography>}
                                    />

                                    {this.state.checkTermos && this.state.checkPolitica && <Grid>
                                        <Grid className={classes.buttonContainer}
                                              container justifyContent="center">
                                            <Button variant="outlined" color="primary"
                                                    type="submit"
                                                    className={classes.button}
                                                    disabled={this.state.loading}
                                            >
                                                {this.state.loading && this.state.register && (
                                                        <CircularProgress className={classes.circularProgress}/>
                                                    ) ||
                                                    <span>Cadastrar</span>
                                                }
                                            </Button>
                                        </Grid>
                                        <Grid className={classes.buttonContainer} container
                                              justifyContent="center">
                                            <GoogleLogin
                                                // clientId="416384668070-ac18vhs3ug3o1braeu3s0cr04qfbejmp.apps.googleusercontent.com"
                                                clientId={process.env.REACT_APP_CLIENT_GOOGLE_ID}
                                                render={renderProps => (
                                                    <Button className={classes.buttonGoogle}
                                                            onClick={renderProps.onClick}
                                                            startIcon={
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/headergugo-icon.png'}
                                                                    alt="ícone" style={{height: 25}}/>
                                                            }
                                                            disabled={renderProps.disabled}
                                                    >
                                                        {this.state.loading && this.state.loginGoogle && (
                                                                <CircularProgress
                                                                    className={classes.circularProgress}/>
                                                            ) ||
                                                            <span>Cadastrar com o Google</span>
                                                        }
                                                    </Button>
                                                )}
                                                onSuccess={this.handleGoogle}
                                                onFailure={this.handleGoogle}
                                                buttonText="Entrar com o Google"
                                                cookiePolicy="single_host_origin"
                                            />
                                        </Grid>
                                        <Grid className={classes.buttonContainer} container
                                              justifyContent="center">
                                            <FacebookLogin
                                                // appId="316224343377424"
                                                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                                fields="name,first_name,last_name,email,picture"
                                                callback={this.handleFacebook}
                                                render={renderProps => (
                                                    <Button className={classes.buttonFacebook}
                                                            onClick={renderProps.onClick}
                                                            startIcon={
                                                                <FontAwesomeIcon icon={faFacebook}
                                                                />}
                                                            disabled={renderProps.disabled}
                                                    >
                                                        {this.state.loading && this.state.loginFace && (
                                                                <CircularProgress
                                                                    className={classes.circularProgress}/>
                                                            ) ||
                                                            <span>Cadastrar com o Facebook</span>
                                                        }
                                                    </Button>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>}
                                    <CheckButton
                                        style={{display: 'none'}}
                                        ref={(c) => {
                                            this.checkBtn = c;
                                        }}
                                    />
                                </Form>
                            </Box>
                        }
                        {this.state.showForm === 'recuperar' &&
                            <Box className={classes.formContainer}>
                                <Box className={classes.loginFormTop}>
                                    Recuperação de Senha
                                </Box>
                                <Box className={classes.spanRecuperar}>
                                    Para recuperar sua senha, informe o email de acesso no campo
                                    abaixo.<br/>
                                    Você receberá um email de ajuda para cadastrar uma nova senha.
                                </Box>
                                <Form
                                    onSubmit={this.handleRecuperarSenha}
                                    ref={(c) => {
                                        this.form = c;
                                    }}
                                >
                                    {message && (
                                        <FormControl className={classes.content} fullWidth>
                                            <Alert
                                                severity={this.state.successful ? 'success' : 'error'}>{message}</Alert>
                                        </FormControl>
                                    )}
                                    <FormControl className={classes.content} fullWidth>
                                        <TextField
                                            required
                                            type="email"
                                            style={emailInput}
                                            label="Digite o email cadastrado"
                                            InputProps={{
                                                endAdornment: (<Icon.Mail/>),
                                            }}
                                            onChange={this.onChangeEmailRecuperar}
                                        />
                                    </FormControl>
                                    <Grid className={classes.buttonContainer} container
                                          justifyContent="center">
                                        <Button type="submit" color="primary"
                                                className={classes.button}>
                                            {this.state.recuperarSenha && (
                                                    <CircularProgress className={classes.circularProgress}/>
                                                ) ||
                                                <span>Recuperar Senha</span>
                                            }
                                        </Button>
                                    </Grid>
                                    <CheckButton
                                        style={{display: 'none'}}
                                        ref={(c) => {
                                            this.checkBtn = c;
                                        }}
                                    />
                                </Form>
                            </Box>
                        }

                    </Box>
                </Box>
            </Box>
        );
    }
}

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    const {message} = state.message;
    return {
        isLoggedIn,
        message,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Login));
