import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, FormControl, Grid, Select} from '@material-ui/core';
import Form from 'react-validation/build/form';
import {Titulo, SubTitulo} from '../../../views/style/styles';
import {connect} from 'react-redux';
import {Alert} from '@material-ui/lab';
import {FaUsers} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import AutoCompletePadrao from '../AutoCompletePadrao';
import Service from '../../../services/dpo.service';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import userPerfilDefault from '../../../assets/images/user-perfil-default.png';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import {validaInputDataComponente} from '../../util';
import {salvar} from '../../../actions/DpoAction';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,
  formButton: theme.pagina.formButton,
  formCadastro: {
    margin: '15px',
  },
  cardEquipe: {
    //maxWidth: 336,
    flex: '1 1 auto',
    padding: '5px 5px 5px',
    //margin: '5px 0 25px 0',
    borderRadius: '10px',
    backgroundColor: 'white',
    //'rgba(242, 242, 242, 1)',
    border: 'solid 1px rgba(228, 228, 228, 1)',
    color: '#000',
    boxShadow: '5px 5px 5px rgba(0,0,0,0.5)',
  },
  imgPerfil: {
    width: '70px !important',
    height: '70px',
    borderRadius: '100px',
    margin: '12px 0 0 50px',
  },
  linhaVertical: {
    height: '200px',
    borderRight: '1px solid #333333',
    marginTop: 12
  },
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );

class GerarSolicitacao extends Component {
  constructor(props) {
    super(props);
    this.salvar = this.salvar.bind(this);
    //this.onVoltar = props.onVoltar;

    this.state = {
      errosCampo: [],

      cod_solic_anonimizacao: props.codSolicAnonimizacao,
      cod_usuario_anonimizacao: null,
      str_observacao: null,
      dat_requerimento: null,

      arrUsuarios: [],
      arrUserOrig: false,
      usuario_anonimizacao: null,
      tipo_usuario: null,

      isLoadingEdit: true,
      isLoading: false,
      loading: false,
      successful: '',
      redirect: false,
    };
  }

  componentDidMount() {
    this.getAutoComplete();
    this.setState({isLoadingEdit: false});
  }

  getAutoComplete = async () => {
    const arrUser = await Service.getUserAutoComplete({});
    this.setState({arrUsuarios: arrUser});
  };

  salvar(e) {
    e.preventDefault();

    this.setState({
      successful: '',
      isLoading: true,
    });

    if (!this.onValidateForm()) {
      window.scrollTo(0, 0);
      this.setState({
        isLoading: false,
      });
      return;
    }

    this.form.validateAll();
    this.props
      .dispatch(
        salvar(
          this.state.cod_solic_anonimizacao,
          this.state.usuario_anonimizacao.id,
          this.state.str_observacao,
          new Date(this.state.dat_requerimento).toLocaleDateString(),
        ),
      )
      .then(() => {
        this.setState({
          successful: true,
          isLoading: false,
        });
        this.timeout = setTimeout(() => {
          this.props.onVoltar();
          this.setState({
            redirect: false,
          });
        }, 3000);
      })
      .catch(() => {
        this.setState({
          successful: false,
          redirect: false,
          isLoading: false,
        });
      });
  }

  onValidateForm = () => {
    const errorMessages = {};

    const datRequerimento = validaInputDataComponente(this.state.dat_requerimento, true, false, null, true, new Date(), 'Data de Requerimento não pode ser maior que a data atual');
    if (!datRequerimento.status) {
      errorMessages['data'] = datRequerimento.message;
    }

    this.setState({ errosCampo: errorMessages });

    return Object.keys(errorMessages).length === 0;
  };

  getPerilUsuario = async (value) => {
    const tipo_usuario = await Service.getPerilUsuario(value);

    let html = <div className="content"
                    dangerouslySetInnerHTML={{__html: tipo_usuario}}></div>;
    this.setState({tipo_usuario: html});
  };

  filterLoadedUsers = (event) => {
    if (event === null ||  event.length === 0) {
      return this.setState({ arrUsuarios: this.state.arrUserOrig });
    }
    let value = event.toString().toLowerCase();
    let result = [];
    if (this.state.arrUserOrig === false) {
      this.setState({ arrUserOrig: this.state.arrUsuarios });
    }

    if(this.state.arrUserOrig.length > 0){
      result = this.state.arrUserOrig.filter((data) => {
        return (
          (data && data.str_nome_pessoa && data.str_nome_pessoa.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_sobrenome && data.str_sobrenome.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_email && data.str_email.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_nome_pessoa && data.str_sobrenome && (data.str_nome_pessoa.toString() + ' ' + data.str_sobrenome.toString()).toLowerCase().search(value.toLowerCase()) != -1)
        )
      })
    }else{
      result = this.state.arrUsuarios.filter((data) => {
        return (
          (data && data.str_nome_pessoa && data.str_nome_pessoa.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_sobrenome && data.str_sobrenome.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_email && data.str_email.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_nome_pessoa && data.str_sobrenome && (data.str_nome_pessoa.toString() + ' ' + data.str_sobrenome.toString()).toLowerCase().search(value.toLowerCase()) != -1)
        )
      })
    }

    this.setState({ arrUsuarios: result });
  }

  render() {
    const {successful, isLoggedIn, message, classes} = this.props;

    if (this.state.redirect) {
      this.onClickVoltar();
    }

    const titulo = 'Registrar Solicitação';
    const labelButon = this.state.cod_solic_anonimizacao == null ? 'Cadastrar' : 'Salvar';

    return (
      <>
        {this.state.isLoadingEdit === true &&
          <Grid container spacing={2} style={{marginTop: 25}}>
            <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
              {mensagemPadraoCarregando}
            </Grid>
          </Grid>
        }
        {this.state.isLoadingEdit === false && <div className={classes.root}>
          <Grid container className={classes.conteudoFundoBranco}
                style={{minWidth: 613}}>
            <Grid item xs={12} className={classes.formCadastroTitulo}>
              <FaUsers className={classes.iconeTitulo}/>
              <span className={classes.tituloPadrao}> {titulo}</span>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.content}>
                <Form onSubmit={this.salvar} ref={(c) => {
                  this.form = c;
                }} className={classes.formCadastro}>
                  {this.state.successful == false && this.state.successful !== '' && (
                    <div className="row">
                      <div className="col mb-2">
                        <Alert severity={'error'}>{message}</Alert>
                      </div>
                    </div>
                  )}
                  {this.state.successful == true && this.state.successful !== '' && (
                    <div className="row">
                      <div className="col mb-2">
                        <Alert severity={'success'}>{message}</Alert>
                      </div>
                    </div>
                  )}
                  <SubTitulo>Solicitação</SubTitulo>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} className={classes.linhaVertical}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <AutoCompletePadrao
                            required={true}
                            label={'Selecione o usuário da solicitação'}
                            value={this.state.usuario_anonimizacao}
                            noOptionsText={'Nenhum usuário  encontrado.'}
                            arrDados={this.state.arrUsuarios}
                            onChange={(e, value) => {
                              this.filterLoadedUsers(value);

                              if (value == null || value == '' || value == undefined) {
                                this.setState({usuario_anonimizacao: null});
                                return;
                              }

                              this.getPerilUsuario(value);
                              this.setState({usuario_anonimizacao: value});
                            }}
                          />
                        </Grid>

                        {this.state.usuario_anonimizacao &&
                          <Grid item xs={12} sm={12}>
                            <Card className={classes.cardEquipe} key={0}>
                              <CardContent>
                                <Grid item xs={12} sm container>
                                  <Grid item xs>
                                    <img
                                      src={this.state.usuario_anonimizacao.str_imagem_perfil ? this.state.usuario_anonimizacao.str_imagem_perfil : userPerfilDefault}
                                      className={classes.imgPerfil}
                                      style={{objectFit: 'cover'}}/>
                                  </Grid>
                                  <Grid item xs container direction="column"
                                        spacing={2}>
                                    <Grid item xs style={{marginTop: '15px'}}>
                                      <Typography gutterBottom
                                                  variant="subtitle1">
                                        <p
                                          style={{fontSize: 15}}>{this.state.usuario_anonimizacao.str_nome_pessoa + ' ' + this.state.usuario_anonimizacao.str_sobrenome}</p>
                                      </Typography>
                                      <Typography variant="body2"
                                                  color="textSecondary">
                                        <p>{this.state.tipo_usuario ?? null}</p>
                                      </Typography>
                                      <Typography variant="body2"
                                                  color="textSecondary">
                                        <p>
                                          <strong>Email</strong> : {this.state.usuario_anonimizacao.str_email}
                                        </p>
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        }
                      </Grid>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                   locale={ptBrLocale}>
                            <KeyboardDatePicker
                              error={this.state.errosCampo.data != undefined && this.state.errosCampo.data ? true : false}
                              helperText={this.state.errosCampo.data != undefined && this.state.errosCampo.data ? this.state.errosCampo.data : null}
                              required
                              autoOk={true}
                              label="Data de requerimento"
                              name="dat_requerimento"
                              id="dat_requerimento"
                              fullWidth
                              variant="inline"
                              inputVariant="outlined"
                              format="dd/MM/yyyy"
                              InputAdornmentProps={{position: 'end'}}
                              value={this.state.dat_requerimento}
                              onChange={(e) => {
                                this.setState({errosCampo: {}});

                                if (e == null) {
                                  this.setState({dat_requerimento: null});
                                  return;
                                }

                                if(e == 'Invalid Date'){
                                  this.setState({errosCampo:{data: 'Data Inválida'}});
                                  this.setState({dat_requerimento: e});
                                  return;
                                }

                                let datRequerimento = validaInputDataComponente(e, true, false, null, true, new Date(), null);
                                if (!datRequerimento.status) {
                                  this.setState({errosCampo:{data: 'Data de Requerimento não pode ser maior que a data atual'}});
                                }

                                this.setState({dat_requerimento: e});
                              }}
                              maxDate={new Date()}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            label="Observação"
                            inputProps={{maxLength: 1000}}
                            id="str_observacao"
                            multiline
                            rows={5}
                            variant="outlined"
                            fullWidth
                            value={this.state.str_observacao}
                            onChange={(e) => {
                              this.setState({str_observacao: e.target.value});
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{marginTop: 30}}>
                      <div style={{textAlign: 'center'}}>
                        {this.state.isLoading === false &&
                          <>
                            <Button
                              type="submit"
                              className={classes.formButton}
                              variant="outlined"> {labelButon}
                            </Button> &nbsp;
                            <Button
                              className={classes.formButton}
                              onClick={() => {
                                this.props.onVoltar();
                              }}>Cancelar </Button>
                          </>
                        }
                        {this.state.isLoading === true &&
                          <>
                            <Button variant="outlined" disabled
                                    style={{width: 100}}>
                              <CircularProgress style={{
                                width: 20,
                                height: 20,
                                marginRight: 15,
                              }}/>
                            </Button> &nbsp;
                            <Button>Cancelar </Button>
                          </>
                        }
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          </Grid>
        </div>}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {isLoggedIn} = state.auth;
  const {message} = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(GerarSolicitacao));
