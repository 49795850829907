
import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextoRicoPadrao from '../components/partials/TextoRicoPadrao';
import draftToHtml from 'draftjs-to-html';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import '../views/style/termos-publicos.css';
import TermoPoliticaService from '../services/termo-politica.service';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  
  userCicle: {
    width: '150px',
  },
  
  contentFooterDiv: {
    marginTop: '1%',
    marginBottom: '1%',
  },
  footerDiv: {
    textAlign: 'center',
    marginTop: '1%',
  },

  editorClassDisabled: {
    backgroundColor: 'white',
    padding: '1rem;',
    border: '0',
    textAlign: 'justify'
  },
});


const mensagemPadraoCarregando =
(
    <span>
        <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
    </span>
)

class TermoUso extends Component {
  constructor(props) {
    super(props);
    this.onEditorTermoStateChange = this.onEditorTermoStateChange.bind(this);

    this.state = {
      redirect: false,
      editorStateTermo: '',
      cod_termo_uso: '',
      str_termo_uso_tag: '',
      isLoadingEdit: true,
    };
  }

  componentDidMount() {
    this.getDados();
  }


  getDados = () => {
    const data = Promise.resolve(TermoPoliticaService.getDadosTermo());

    if (data) {
      data.then((v) => {
        this.populateTermoForm(v.termo);
      });
    }
  };

  populateTermoForm = (dados) => {
    if (dados != undefined && dados.str_termo_uso != '' && dados.str_termo_uso != undefined) {
      let row = convertFromRaw(dados.str_termo_uso);
      this.setState({
        editorStateTermo: EditorState.createWithContent(row),
        str_termo_uso_tag: dados.str_termo_uso_tag,
        cod_termo_uso: dados.cod_termo_uso,
      });
    }else{
      this.setState({editorStateTermo: ''});
    }

    this.setState({isLoadingEdit: false});
  };

  onEditorTermoStateChange = (editorStateTermo) => {
    let element = convertToRaw(editorStateTermo.getCurrentContent());
    let text = element.blocks[0].text;

    this.setState({
      editorStateTermo,
      str_termo_uso: convertToRaw(editorStateTermo.getCurrentContent()),
      str_termo_uso_tag: draftToHtml(convertToRaw(editorStateTermo.getCurrentContent())),
      is_null_termo: text === '' ? true : false,
    });
  };

  render() {
    const {classes} = this.props;
    const {editorStateTermo} = this.state;

    return (
      <>
        {this.state.isLoadingEdit === true &&
          <Grid container spacing={2} style={{marginTop: 25}}>
          <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
                        {mensagemPadraoCarregando}
          </Grid>
          </Grid>
        }
        {this.state.isLoadingEdit === false &&
          <div className={classes.root}>
            <Grid>
              <Grid container style={{ minWidth: 330 }}>
                <Grid item xs={12} className={classes.content}>
              
                    <Grid container spacing={3}>
                        <TextoRicoPadrao
                          editorState={editorStateTermo}
                          editorClassName={classes.editorClassDisabled}
                        />
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {isLoggedIn} = state.auth;
  return {
    isLoggedIn,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(TermoUso));
