import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import Description from '@material-ui/icons/Description';
import {LocalShipping} from '@material-ui/icons';
import TabelaPaginada from '../partials/DataTablePaginadapadrao';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import {Alert} from '@material-ui/lab';
import ColetaService from '../../services/coleta-seletiva.service';
import CadastrarCaminhao from '../partials/caminhoes/Cadastrar';
import VisualizarCaminhao from '../partials/caminhoes/Visualizar';
import ModalPadrao from '../partials/ModalPadrao';
import CircularProgress from '@material-ui/core/CircularProgress';
import {history} from '../../helpers/history';
import {clearMessage} from '../../actions/MessageAction';
import CaminhoesService from '../../services/caminhoes.service';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,
  buttonCadastro: theme.pagina.buttonCadastro,

  buttonGreen: {
    color: '#fff',
    backgroundColor: '#28a745',
    borderColor: '#28a745',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#28A745',
      border: '0px',
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    width: '85%',
  },
  iconButton: {
    padding: 10,
  },
});

class CaminhaoColeta extends Component {
  constructor(props) {
    super(props);
    history.listen((location) => {
      props.dispatch(clearMessage());
    });

    this.state = {
      codCaminhao: null,
      campoPesquisar: null,
      arrCaminhoes: [],
      arrCaminhoesOriginal: false,
      isLoading: true,
      isLoadingCaminhao: true,
      isLoadingVisualizarCaminhao: true,
      operacaoEmtela: 'listar',
      mensagemAlert: { mensagem: null, severity: 'success', closeButton: true },
      modalExclusao: false,
      index: null,
      isSavingExclusao: false,
    };
  }

  componentDidMount() {
    this.carregarCaminhoes();
  }

  componentDidUpdate() {
    // on update component
  }

  carregarCaminhoes = async () => {
    const arrCaminhoes = await CaminhoesService.getCaminhoes();
    this.setState({ arrCaminhoes: arrCaminhoes, arrCaminhoesOriginal: arrCaminhoes, isLoading: false });
  }

  recarregarLista = () => {
    this.setState({ arrCaminhoes: [], isLoading: true });
    this.carregarCaminhoes();
  }

  handleClickCadastrarCaminhao = () => {
    this.setState({ operacaoEmtela: 'cadastrar', isLoadingCaminhao: true, codCaminhao: null });
    this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
  }

  onVoltar = () => {
    this.setState({ isLoadingVisualizarCaminhao: false, isLoadingCaminhao: false, operacaoEmtela: 'listar', codCaminhao: null });
  }

  onClickVisualizar = (codCaminhao) => {
    this.setState({ operacaoEmtela: 'visualizar', isLoadingCaminhao: false, codCaminhao: codCaminhao });
    this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
  }

  onClickEditar = (codCaminhao) => {
    this.setState({ operacaoEmtela: 'editar', isLoadingCaminhao: true, codCaminhao: codCaminhao });
    this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
  }

  onClickExcluir = (codCaminhao, index) => {
    this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
    this.setState({ codCaminhao: codCaminhao, index: index, modalExclusao: true });
  }

  exibeMensagemAlert = (mensagem) => {
    this.setState({ mensagemAlert: { mensagem: mensagem.mensagem, severity: mensagem.severity ?? 'success', closeButton: true } });
  }

  confirmarExcluirCaminhao = async () => {
    this.setState({isSavingExclusao: true});
    const retorno = await CaminhoesService.excluirCaminhao(this.state.codCaminhao);
    if (retorno === 'ok') {
      let arrCaminhoes = [...this.state.arrCaminhoes];
      arrCaminhoes.splice(this.state.index, 1);
      this.setState({ arrCaminhoes: arrCaminhoes });
      this.setState({ mensagemAlert: { mensagem: 'Caminhão excluído com sucesso.', severity: 'success', closeButton: true } });
    } else {
      this.setState({ mensagemAlert: { mensagem: 'Não foi possível excluir o caminhão.', severity: 'error', closeButton: true } });
    }
    this.setState({ codCaminhao: null, index: null, modalExclusao: false , isSavingExclusao: false});
  }

  handleCloseModal = () => {
    this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
    this.setState({ codCamihao: null, index: null, modalExclusao: false });
  }

  formatStatus = (flgInativo) => {
    return (
      <>
        {flgInativo !== 'N' ? 'Inativo' : 'Ativo'}
      </>
    );
  }

  // filtrarCaminhao = (event) => {
  //   if (event.target.value.length === 0) {
  //     return this.setState({arrCaminhoes: this.state.arrCaminhoesOriginal});
  //   }
  //   let value = event.target.value.toString().toLowerCase();
  //   let result = [];
  //
  //   if (this.state.arrCaminhoesOriginal === false) {
  //     this.setState({arrCaminhoesOriginal: this.state.arrCaminhoes});
  //   }
  //
  //   if (this.state.arrCaminhoes.length !== 0) {
  //     result = this.state.arrCaminhoesOriginal.filter((data) => {
  //       return ((
  //           data &&
  //           data.strIdentificador &&
  //           data.strIdentificador.toLowerCase().search(value.toLowerCase()) !== -1 ||
  //           data.strPlaca &&
  //           data.strPlaca.toLowerCase().search(value.toLowerCase()) !== -1
  //       ));
  //     });
  //   } else {
  //     result = this.state.arrCaminhoes.filter((data) => {
  //       return ((
  //         data &&
  //         data.strIdentificador &&
  //         data.strIdentificador.toLowerCase().search(value.toLowerCase()) !== -1 ||
  //         data.strPlaca &&
  //         data.strPlaca.toLowerCase().search(value.toLowerCase()) !== -1
  //       ));
  //     });
  //   }
  //   this.setState({arrCaminhoes: result});
  // };

  filtrarCaminhao = (event) => {
    if (event.target.value.length === 0) {
      return this.setState({arrCaminhoes: this.state.arrCaminhoesOriginal});
    }
    let value = event.target.value.toString().toLowerCase();
    let result = [];

    if (this.state.arrCaminhoesOriginal === false) {
      this.setState({arrCaminhoesOriginal: this.state.arrCaminhoes});
    }

    if (this.state.arrCaminhoesOriginal.length > 0) {
      result = this.state.arrCaminhoesOriginal.filter((data) => {
        return ((
          data &&
          data.strIdentificador &&
          data.strIdentificador.toLowerCase().search(value.toLowerCase()) !== -1 ||
          data.strPlaca &&
          data.strPlaca.toLowerCase().search(value.toLowerCase()) !== -1 || 
          data.strMacAddr &&
          data.strMacAddr.toLowerCase().search(value.toLowerCase()) !== -1
        ));
      });
    } else {
      result = this.state.arrCaminhoes.filter((data) => {
        return ((
          data &&
          data.strIdentificador &&
          data.strIdentificador.toLowerCase().search(value.toLowerCase()) !== -1 ||
          data.strPlaca &&
          data.strPlaca.toLowerCase().search(value.toLowerCase()) !== -1 || 
          data.strMacAddr &&
          data.strMacAddr.toLowerCase().search(value.toLowerCase()) !== -1
        ));
      });
    }
    this.setState({arrCaminhoes: result});
  };

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <Grid container className={classes.conteudoFundoBranco} style={{minWidth: '471px'}}>
          <Grid item xs={12} className={classes.formCadastroTitulo}>
            <LocalShipping className={classes.iconeTitulo}/>
            <span className={classes.tituloPadrao}>
              {this.state.operacaoEmtela === 'listar' ? 'Gerenciar Caminhões' : ''}
              {this.state.operacaoEmtela === 'cadastrar' ? 'Cadastrar Caminhão' : ''}
              {this.state.operacaoEmtela === 'visualizar' ? 'Visualizar Caminhão' : ''}
              {this.state.operacaoEmtela === 'editar' ? 'Editar Caminhão' : ''}
            </span>
          </Grid>
          {(this.state.operacaoEmtela === 'cadastrar' || this.state.operacaoEmtela === 'editar') &&
            <CadastrarCaminhao codCaminhao={this.state.codCaminhao} onVoltar={this.onVoltar} exibeMensagemAlert={this.exibeMensagemAlert} recarregarLista={this.recarregarLista} />
          }
          {this.state.operacaoEmtela === 'visualizar' &&
            <VisualizarCaminhao codCaminhao={this.state.codCaminhao} onVoltar={this.onVoltar} exibeMensagemAlert={this.exibeMensagemAlert} recarregarLista={this.recarregarLista} />
          }
          {this.state.operacaoEmtela === 'listar' &&
            <>
              <Grid container>
                <Grid item xs={12} sm={6} style={{padding: '15px 0'}}>
                  <Paper>
                    <InputBase
                      name="Busca"
                      id="Busca"
                      className={classes.input}
                      placeholder="Pesquisar Caminhão"
                      inputProps={{'aria-label': 'search google maps'}}
                      onChange={this.filtrarCaminhao}
                      autoComplete="off"
                    />
                    <IconButton className={classes.iconButton}
                                aria-label="search">
                      <SearchIcon/>
                    </IconButton>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={this.handleClickCadastrarCaminhao}
                    variant="outlined"
                    className={classes.buttonCadastro}>Cadastrar Caminhão
                  </Button>
                </Grid>
              </Grid>
              {this.state.mensagemAlert.mensagem !== null &&
              <Grid item xs={12} style={{ marginBottom: 15, marginTop: 15 }} >
                <Alert
                  severity={this.state.mensagemAlert.severity}
                  action={
                    this.state.mensagemAlert.closeButton === true &&
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {this.state.mensagemAlert.mensagem}
                </Alert>
              </Grid>
              }
              <Grid container>
                <Grid container spacing={3}>
                  <Grid item xs={12} id="tabela-listar-caminhoes" name="tabela-sem-borda">
                    <TabelaPaginada
                      arrCabecalho={['Caminhão', 'Placa', 'Identificador', 'Status', 'Ações']}
                      isLoading={this.state.isLoading}
                      arrTamanhoColuna={['45%', '15%', '15%', '15%', '10%']}
                      arrDados={this.state.arrCaminhoes}
                      mensagemSemRegistros="Nenhum caminhão encontrado"
                      arrFuncoes={
                        {
                          flgInativo:{
                            chave: 'flgInativo',
                            funcao: this.formatStatus,
                          },
                        }
                      }
                      arrAcoes={
                        {
                          codCaminhao: [
                            {
                              chave: 'codCaminhao',
                              funcao: this.onClickVisualizar,
                              icone: SearchIcon,
                            },
                            {
                              chave: 'codCaminhao',
                              funcao: this.onClickEditar,
                              icone: EditIcon,
                            },
                            {
                              chave: 'codCaminhao',
                              funcao: this.onClickExcluir,
                              icone: CloseIcon,
                            },
                          ],
                        }
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          }
        </Grid>
        <ModalPadrao open={this.state.modalExclusao} handleCloseModal={this.handleCloseModal} >
          <h5>Aviso!</h5>
          <hr></hr>
          <p>Deseja Excluir este caminhão?</p>
          <hr></hr>
          {this.state.isSavingExclusao === false &&
          <>
            <Button variant="outlined" onClick={() => this.confirmarExcluirCaminhao()} style={{ width: 80 }} >Sim</Button> &nbsp;
            <Button variant="text" onClick={() => this.setState({ modalExclusao: false })}>Não</Button>
          </>
          }
          {this.state.isSavingExclusao !== false &&
          <>
            <Button variant="outlined" disabled style={{ width: 80 }} ><CircularProgress style={{ color: '#FFF', width: 20, height: 20 }} /></Button> &nbsp;
            <Button variant="text" disabled >Não</Button>
          </>
          }
        </ModalPadrao>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(CaminhaoColeta));
