import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import NotificacaoService from '../../../services/notificacoes.service';
import ArquivoService from '../../../services/arquivo.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Titulo, SubTitulo } from '../../../views/style/styles';
import TextField from '@material-ui/core/TextField';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    DateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HistoricoNotificacao from './HistoricoAlteracao';


const useStyle = makeStyles((theme) => ({
    formButton : theme.pagina.formButton,
    button: {
        color: '#000',
        backgroundColor: '#FFF',
        border: 'solid 1px #005b96',
        maxWidth: 100,
        height: '42px',
        marginTop: '25px',
        '&:hover': {
            color: '#005b96',
            backgroundColor: '#FFF',
            border: 'solid 1px #005b96',
        },
    },
    span: {
        '&. MuiFormControlLabel-label': {
            color: '#000',
        },
    },
}));

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
    );


const CadastrarNotificacao = (props) => {

    const classes = useStyle();
    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingEditar, setIsLoadingEditar] = React.useState(false);
    const [isLoadingFile, setIsLoadingFile] = React.useState(false);
    const [isLoadingFileHistorico, setIsLoadingFileHistorico] = React.useState(false);
    const [formCadastro, setFormCadastro] = React.useState({});
    const [campoTitulo, setCampoTitulo] = React.useState('');
    const [campoDescricao, setCampoDescricao] = React.useState('');
    const [dataInicio, setDataInicio] = React.useState(null);
    const [dataFim, setDataFim] = React.useState(null);
    const [campoHorarioFimExibicao, setCampoHorarioFimExibicao] = React.useState(null);
    const [campoHorarioInicioExibicao, setCampoHorarioInicioExibicao] = React.useState(null);
    const [campoHorarioFimExibicaohasError, setCampoHorarioFimExibicaohasError] = React.useState(false);
    const [campoHorarioInicioExibicaohasError, setCampoHorarioInicioExibicaohasError] = React.useState(false);
    const [campoUploadDocumentos, setCampoUploadDocumentos] = React.useState(null);
    const [campoLinkVideo, setCampoLinkVideo] = React.useState('');
    const [arquivoUpload, setArquivoUpload] = React.useState(null);
    const [erroUpload, setErroUpload] = React.useState(null);
    const [errosCampo, setErrosCampo] = React.useState([]);
    const [codNotificacao, setCodNotificacao] = React.useState([]);
    const [arrDadosNotificacao, setArrDadosNotificacao] = React.useState([]);
    const [campoReenviar, setCampoReenviar] = React.useState(false);
    const [isDisabledInput, setIsDisabledinput] = React.useState(false);
    const [isDisabledDate, setIsDisabledDate] = React.useState(true);

    useEffect(() => {
        carregarDadosNotificacao();
    }, [props.codNotificacao]);

    /*useEffect(() => {
      // testarTokenMobile();
      testarEnvioNotificacao();
    }, []);*/

    const carregarDadosNotificacao = async () => {
        if (props.codNotificacao === null || props.codNotificacao == undefined) {
            return false;
        }

        const arrCampos = formCadastro;
        arrCampos['codNotificacao'] = props.codNotificacao;

        setIsLoadingEditar(true);
        const arrDadosNotificacao = await NotificacaoService.carregarDadosNotificacao(props.codNotificacao);

        if (arrDadosNotificacao.cod_notificacao) {
            setCampoTitulo(arrDadosNotificacao.str_titulo);
            arrCampos['campoTitulo'] = arrDadosNotificacao.str_titulo;
            setCampoDescricao(arrDadosNotificacao.str_descricao);
            arrCampos['campoDescricao'] = arrDadosNotificacao.str_descricao;
            setDataInicio(new Date(arrDadosNotificacao.data_inicial));
            arrCampos['campoPeriodoExibicaoInicio'] = new Date(arrDadosNotificacao.data_inicial).toLocaleDateString();
            setDataFim(new Date(arrDadosNotificacao.data_final));
            arrCampos['campoPeriodoExibicaoFim'] = new Date(arrDadosNotificacao.data_final).toLocaleDateString();
            if (arrDadosNotificacao.hora_fim) {
                const dataHoje = new Date();
                dataHoje.setHours(parseInt(arrDadosNotificacao.hora_fim.split(':')[0]));
                dataHoje.setMinutes(parseInt(arrDadosNotificacao.hora_fim.split(':')[1]));
                setCampoHorarioFimExibicao(dataHoje);
                arrCampos['campoHorarioFimExibicao'] = addZero(dataHoje.getHours()) + ':' + addZero(dataHoje.getMinutes());
            }
            if (arrDadosNotificacao.hora_inicio) {
                const dataHoje = new Date();
                dataHoje.setHours(parseInt(arrDadosNotificacao.hora_inicio.split(':')[0]));
                dataHoje.setMinutes(parseInt(arrDadosNotificacao.hora_inicio.split(':')[1]));
                setCampoHorarioInicioExibicao(dataHoje);
                arrCampos['campoHorarioInicioExibicao'] = addZero(dataHoje.getHours()) + ':' + addZero(dataHoje.getMinutes());
            }

            arrCampos['campoLinkVideo'] = arrDadosNotificacao.str_link;
            setCampoLinkVideo(arrDadosNotificacao.str_link ?? null);

            if (arrDadosNotificacao.cod_arquivo) {
                setCampoUploadDocumentos({ name: arrDadosNotificacao.str_descricao_arq });
            }
            setIsDisabledinput(arrDadosNotificacao.isdisabled);
            setIsDisabledDate(arrDadosNotificacao.isdisabled ? false : true);
        }
        setArrDadosNotificacao(arrDadosNotificacao);
        setIsLoadingEditar(false);
        setFormCadastro(arrCampos);
    };

    const onVoltar = () => {
        props.onVoltar();
    };

    const onChangeCampo = (event) => {
        const arrCampos = formCadastro;
        arrCampos[event.target.id] = event.target.value;
        setFormCadastro(arrCampos);
        switch (event.target.id) {
            case 'campoTitulo':
                setCampoTitulo(event.target.value);
                break;
            case 'campoDescricao':
                setCampoDescricao(event.target.value);
                break;
            case 'campoLinkVideo':
                setCampoLinkVideo(event.target.value);
                break;
        }
    };

    const addZero = (i) => {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    const verificaCamposObrigatorios = () => {
        const arrErros = [];
        let erro = false;
        if (campoTitulo === null || campoTitulo === undefined || campoTitulo === '') {
            arrErros['campoTitulo'] = 'Campo obrigatório.';
            erro = true;
        }
        if (campoDescricao === null || campoDescricao === undefined || campoDescricao === '') {
            arrErros['campoDescricao'] = 'Campo obrigatório.';
            erro = true;
        }
        if (campoLinkVideo !== null && campoLinkVideo != undefined && campoLinkVideo !== '') {
            try {
                let url;
                url = new URL(campoLinkVideo);
                if (url.protocol !== 'http:' && url.protocol !== 'https:') {
                    arrErros['campoLinkVideo'] = 'Link inválido.';
                    erro = true;
                }
            } catch (_) {
                arrErros['campoLinkVideo'] = 'Link inválido.';
                erro = true;
            }
        }
        if (campoHorarioFimExibicaohasError === true) {
            arrErros['campoHorarioFimExibicao'] = 'Horário inválido';
            erro = true;
        }
        if (campoHorarioInicioExibicaohasError === true) {
            arrErros['campoHorarioInicioExibicao'] = 'Horário inválido';
            erro = true;
        }

        setErrosCampo(arrErros);
        return !erro;
    };

    const salvarNotificacao = async (evt) => {
        evt.preventDefault();
        if (verificaCamposObrigatorios() !== true) {
            return false;
        }
        setIsLoading(true);
        const retornoSalvarNotificacao = await NotificacaoService.salvarNotificacao(formCadastro);
        if (retornoSalvarNotificacao === 'ok') {
            const dataHoje = new Date();
            if (dataInicio.toLocaleDateString() === dataHoje.toLocaleDateString()) {
                props.exibeMensagemAlert({ mensagem: (props.codNotificacao === null ? 'Notificação cadastrada enviada aos usuários' : 'Dados salvos e enviados com sucesso.') });
            } else {
                props.exibeMensagemAlert({ mensagem: (props.codNotificacao === null ? 'A notificação foi cadastrada e será enviada aos usuários na data de início inserida.' : 'Dados salvos com sucesso. A notificação será enviada aos usuários na data de início inserida.') });
            }
        } else {
            props.exibeMensagemAlert({
                mensagem: (props.codNotificacao === null ? 'Erro ao cadastrar a notificação. Tente novamente mais tarde' : 'Erro ao salvar a notificação. Tente novamente mais tarde'),
                severity: 'error',
            });
        }
        props.recarregarLista();
        props.onVoltar();
        setIsLoading(false);
    };

    const onChangeCampoPeriodoExibicaoInicio = (data) => {
        if (data === null) {
            return true
        }
        const arrCampos = formCadastro;
        arrCampos['campoPeriodoExibicaoInicio'] = data.toLocaleDateString();
        setDataInicio(data);
        setFormCadastro(arrCampos);
    };

    const onChangeCampoPeriodoExibicaoFim = (data) => {
        if (data === null) {
            return true
        }
        const arrCampos = formCadastro;
        arrCampos['campoPeriodoExibicaoFim'] = data.toLocaleDateString();
        setDataFim(data);
        setFormCadastro(arrCampos);
    };

    const validaHorario = (hora, campo) => {
        if (!hora) {
            return true;
        }
        if (parseInt(hora.split(':')[0]) > 23 || parseInt(hora.split(':')[0]) < 0) {
            campo == 'fim' ? setErrosCampo({ campoHorarioFimExibicao: 'Horário inválido' }) : setErrosCampo({ campoHorarioInicioExibicao: 'Horário inválido' });
            campo == 'fim' ? setCampoHorarioFimExibicaohasError(true) : setCampoHorarioInicioExibicaohasError(true);
            return false;
        }
        if (parseInt(hora.split(':')[1]) > 59 || parseInt(hora.split(':')[1]) < 0) {
            campo == 'fim' ? setErrosCampo({ campoHorarioFimExibicao: 'Horário inválido' }) : setErrosCampo({ campoHorarioInicioExibicao: 'Horário inválido' });
            campo == 'fim' ? setCampoHorarioFimExibicaohasError(true) : setCampoHorarioInicioExibicaohasError(true);
            return false;
        }
        campo == 'fim' ? setCampoHorarioFimExibicaohasError(false) : setCampoHorarioInicioExibicaohasError(false);
        return true;
    };

    const onChangeCampoHorarioFimExibicao = (data, value) => {
        const arrCampos = formCadastro;
        if (value && value.search('_') < 0) {
            if (validaHorario(value, 'fim')) {
                const dataHoje = new Date();
                setErrosCampo({ campoHorarioFimExibicao: null });
                dataHoje.setHours(parseInt(value.split(':')[0]));
                dataHoje.setMinutes(parseInt(value.split(':')[1]));
                setCampoHorarioFimExibicao(dataHoje);
                arrCampos['campoHorarioFimExibicao'] = value.split(':')[0] + ':' + value.split(':')[1];
                setFormCadastro(arrCampos);
            }
        }

    };

    const onChangeCampoHorarioInicioExibicao = (data, value) => {
        const arrCampos = formCadastro;
        if (value && value.search('_') < 0) {
            if (validaHorario(value, 'inicio')) {
                const dataHoje = new Date();
                setErrosCampo({ campoHorarioInicioExibicao: null });
                dataHoje.setHours(parseInt(value.split(':')[0]));
                dataHoje.setMinutes(parseInt(value.split(':')[1]));
                setCampoHorarioInicioExibicao(dataHoje);
                arrCampos['campoHorarioInicioExibicao'] = value.split(':')[0] + ':' + value.split(':')[1];
                setFormCadastro(arrCampos);
            }
        }

    };

    const onFileChange = (event) => {

        const files = event.target.files;
        let arFile = files[0];

        let typeImage = arFile.type.split('/').pop();
        let size = arFile.size;
        let maxSize = 10242880;

        let isValidSize = true;
        let arrType = ['pdf', 'PDF'];
        let isValide = arrType.indexOf(typeImage);

        if (maxSize <= size) {
            isValidSize = false;
        }

        if (isValide < 0 || !isValidSize) {
            setErroUpload('Arquivo inválido. Apenas arquivos do tipo .PDF e com tamanho máximo de 10MB são aceitos.');
            setCampoUploadDocumentos(null);
        } else {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = (e) => {
                const arrCampos = formCadastro;
                arrCampos['setArquivoUpload'] = e.target.result;
                arrCampos['nomeArquivoUpload'] = arFile.name;
                setArquivoUpload(e.target.result);
                setFormCadastro(arrCampos);
                setCampoUploadDocumentos(files[0]);
                setErroUpload(null);
            };
        }

    };

    const downloadArquivo = async (codArquivo, setLoading = 'upload') => {
        if (setLoading == 'upload') {
            setIsLoadingFile(true);
        } else {
            setIsLoadingFileHistorico(true);
        }

        await ArquivoService.download(codArquivo);

        if (setLoading == 'upload') {
            setIsLoadingFile(false);
        } else {
            setIsLoadingFileHistorico(false);
        }
    };

    const onChangeCampoReenviar = () => {
        setCampoReenviar(!campoReenviar);
        const arrCampos = formCadastro;
        arrCampos['campoReenviar'] = !campoReenviar;
        setFormCadastro(arrCampos);
    };

    const informacoesArquivoSelecionado = () => {
        if (campoUploadDocumentos) {
            const dadosNotificacao = arrDadosNotificacao;
            let nomeArquivo = campoUploadDocumentos.name;
            if (dadosNotificacao.cod_arquivo && dadosNotificacao.str_descricao_arq == nomeArquivo) {
                nomeArquivo = <a style={{
                    textDecoration: 'underline',
                    color: 'blue',
                    cursor: 'pointer',
                }}
                    onClick={() => downloadArquivo(dadosNotificacao.cod_arquivo)}>{dadosNotificacao.str_descricao_arq}</a>;
            }
            return (
                <div>
                    {isLoadingFile === false ? nomeArquivo : mensagemPadraoCarregando}
                </div>
            );
        } else if (erroUpload) {
            return (
                <div>
                    <p style={{ color: 'red' }}>{erroUpload}</p>
                </div>
            );
        } else {
            return (
                <div>
                    <p>Selecione um arquivo.</p>
                </div>
            );
        }
    };

    return (
        <>
            {isLoadingEditar === true &&
                <Grid container spacing={2} style={{ marginTop: 25 }}>
                    <Grid item xs={10} sm={10} style={{ textAlign: 'center' }}>
                        {mensagemPadraoCarregando}
                    </Grid>
                </Grid>
            }
            {isLoadingEditar === false &&
                <form onSubmit={salvarNotificacao} autoComplete="off">
                    <Grid container spacing={2} style={{ marginTop: 25 }}>
                        <SubTitulo>Dados da Notificação</SubTitulo>


                        <Grid item xs={12} sm={2}></Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                label="Título"
                                inputProps={{ maxLength: 150 }}
                                error={errosCampo.campoTitulo ? true : false}
                                helperText={errosCampo.campoTitulo ?? null}
                                id="campoTitulo"
                                variant="outlined" fullWidth required
                                value={campoTitulo}
                                onChange={onChangeCampo}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}></Grid>

                        <Grid item xs={12} sm={2}></Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                label="Descrição"
                                inputProps={{ maxLength: 1000 }}
                                error={errosCampo.campoDescricao ? true : false}
                                helperText={errosCampo.campoDescricao ?? null}
                                id="campoDescricao" multiline rows={5} variant="outlined"
                                fullWidth
                                required value={campoDescricao} onChange={onChangeCampo}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}></Grid>


                        <Grid item xs={12} sm={2}></Grid>
                        <Grid item xs={12} sm={2} style={{ minWidth: 170 }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                                <KeyboardDatePicker
                                    required
                                    disabled={isDisabledInput}
                                    label="Data Início"
                                    autoOk={true}
                                    name="campoPeriodoExibicaoInicio"
                                    id="campoPeriodoExibicaoInicio"
                                    fullWidth
                                    variant="inline"
                                    invalidDateMessage="Data inválida"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    disablePast={isDisabledDate}
                                    minDateMessage="A data início não pode ser menor que a data atual"
                                    InputAdornmentProps={{ position: 'end' }}
                                    value={dataInicio}
                                    onChange={onChangeCampoPeriodoExibicaoInicio}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={2} style={{ minWidth: 170 }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                                <KeyboardTimePicker
                                    autoOk={true}
                                    ampm={false}
                                    name="campoHorarioInicioExibicao"
                                    id="campoHorarioInicioExibicao"
                                    fullWidth
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="HH:mm"
                                    label="Horário"
                                    error={errosCampo.campoHorarioInicioExibicao ? true : false}
                                    helperText={errosCampo.campoHorarioInicioExibicao ?? null}
                                    invalidDateMessage="Horário inválido"
                                    value={campoHorarioInicioExibicao}
                                    onChange={onChangeCampoHorarioInicioExibicao}
                                    InputAdornmentProps={{ position: 'end' }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={5}></Grid>
                        <Grid item xs={12} sm={2}></Grid>
                        <Grid item xs={12} sm={2} style={{ minWidth: 170 }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                                <KeyboardDatePicker
                                    required
                                    label="Data Fim"
                                    autoOk={true}
                                    name="campoPeriodoExibicaoFim"
                                    id="campoPeriodoExibicaoFim"
                                    fullWidth
                                    variant="inline"
                                    invalidDateMessage="Data inválida"
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    minDate={dataInicio}
                                    minDateMessage="A data de fim não pode ser anterior a data início."
                                    InputAdornmentProps={{ position: 'end' }}
                                    value={dataFim}
                                    onChange={onChangeCampoPeriodoExibicaoFim}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={2} style={{ minWidth: 170 }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                                <KeyboardTimePicker
                                    autoOk={true}
                                    ampm={false}
                                    name="campoHorarioFimExibicao"
                                    id="campoHorarioFimExibicao"
                                    fullWidth
                                    variant="inline"
                                    inputVariant="outlined"
                                    format="HH:mm"
                                    label="Horário"
                                    error={errosCampo.campoHorarioFimExibicao ? true : false}
                                    helperText={errosCampo.campoHorarioFimExibicao ?? null}
                                    invalidDateMessage="Horário inválido"
                                    value={campoHorarioFimExibicao}
                                    onChange={onChangeCampoHorarioFimExibicao}
                                    InputAdornmentProps={{ position: 'end' }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={1}></Grid>
                        <Grid item xs={12} sm={3}>
                            {props.codNotificacao && !isDisabledDate &&
                                <>
                                    <FormControlLabel
                                        control={<Checkbox id="campoReenviar" name="campoReenviar"
                                            checked={campoReenviar}
                                            onChange={onChangeCampoReenviar} />} /><label
                                                htmlFor="campoReenviar"
                                                style={{ cursor: 'pointer' }}>Reenviar</label>
                                </>
                            }
                        </Grid>
                        <Grid item xs={1} sm={2}></Grid>


                        <Grid item xs={12} sm={2}></Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                label="Link de Vídeo"
                                inputProps={{ maxLength: 150 }}
                                error={errosCampo.campoLinkVideo ? true : false}
                                helperText={errosCampo.campoLinkVideo ?? null}
                                id="campoLinkVideo" variant="outlined" fullWidth
                                value={campoLinkVideo} onChange={onChangeCampo}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}></Grid>


                        <Grid item xs={12} sm={2}></Grid>
                        <Grid item xs={12} sm={2} style={{ minWidth: 109 }}>
                            <label htmlFor="campoUploadDocumentos" style={{ marginTop: 5 }}>Upload
                                de Documentos</label>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Grid container>
                                <Grid item xs={12} sm={3} style={{ minWidth: 87 }}>
                                    <input
                                        accept="application/pdf"
                                        id="campoUploadDocumentos"
                                        onChange={onFileChange}
                                        type="file"
                                    />
                                    <div></div>
                                    <label htmlFor="campoUploadDocumentos">
                                        <Button component="span">Selecione</Button>
                                    </label>
                                </Grid>
                                <Grid item xs={9} sm={9}
                                    style={{ marginTop: 7 }}>{informacoesArquivoSelecionado()}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={1}></Grid>

                        <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                            {isLoading === false &&
                                <>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        className={classes.formButton}>
                                        {props.codNotificacao === null || props.codNotificacao == undefined ? 'Cadastrar' : 'Salvar'}
                                    </Button> &nbsp;
                                    <Button
                                        variant="text"
                                        className={classes.formButton}
                                        onClick={onVoltar}>Cancelar
                                    </Button>
                                </>
                            }
                            {isLoading === true &&
                                <>
                                    <Button variant="outlined" disabled style={{ width: 100 }}>
                                        <CircularProgress
                                            style={{ width: 20, height: 20, marginRight: 15 }} />
                                    </Button> &nbsp;
                                    <Button variant="text" disabled>Cancelar</Button>
                                </>
                            }
                        </Grid>
                        {props.codNotificacao && arrDadosNotificacao.historico != undefined && arrDadosNotificacao.historico != null &&
                            <HistoricoNotificacao arrDadosNotificacao={arrDadosNotificacao} />
                        }
                    </Grid>
                </form>

            }
        </>
    );
};

export default CadastrarNotificacao;
