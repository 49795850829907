import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(name, calories, fat) {
    return { name, calories, fat };
}

const rowsExample = [
    createData('Cupcake', 305, 3.7),
    createData('Donut', 452, 25.0),
    createData('Eclair', 262, 16.0),
    createData('Frozen yoghurt', 159, 6.0),
    createData('Gingerbread', 356, 16.0),
    createData('Honeycomb', 408, 3.2),
    createData('Ice cream sandwich', 237, 9.0),
    createData('Jelly Bean', 375, 0.0),
    createData('KitKat', 518, 26.0),
    createData('Lollipop', 392, 0.2),
    createData('Marshmallow', 318, 0),
    createData('Nougat', 360, 19.0),
    createData('Oreo', 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
});

export default function CustomPaginationActionsTable(props) {
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(props.exibePaginacao === false ? 1000000 : 10);

    const rows = props.arrDados ?? [];

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const mensagemPadraoCarregando = (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
    )

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
                <TableHead >
                    <TableRow key="header">
                        {props.arrCabecalho.map((dado, index) => {
                            return (
                                <TableCell style={{ fontWeight: 'bold', width: props.arrTamanhoColuna && props.arrTamanhoColuna[index] != undefined ? props.arrTamanhoColuna[index] : 'unset' }} key={index} >{dado}</TableCell>
                            )
                        })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(rowsPerPage > 0
                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : rows
                    ).map((row, indexRow) => {
                        let campos = []

                        Object.keys(row).map((campo, index) => {
                            campo = props.arrColunas && props.arrColunas[index] ? props.arrColunas[index] : campo;

                            // exibe apenas os campos do cabeçalho
                            if ((index + 1) > props.arrCabecalho.length) {
                                return;
                            }

                            if (props.arrAcoes && props.arrAcoes[campo]) {
                                let icones = [];
                                for (let i = 0; i < props.arrAcoes[campo].length; i++) {
                                    const funcao = () => {
                                        props.arrAcoes[campo][i].funcao((row[props.arrAcoes[campo][i]['chave']] ? row[props.arrAcoes[campo][i]['chave']] : row), indexRow);
                                    }
                                    const IconeComponente = props.arrAcoes[campo][i].icone;
                                    icones.push(<IconeComponente key={i} style={{ cursor: 'pointer' }} onClick={() => funcao()} />)
                                }
                                campos.push(
                                    <TableCell key={index} component="th" scope="row" style={{ whiteSpace: 'nowrap' }} >
                                        {icones}
                                    </TableCell>)
                            } else if (props.arrFuncoes && props.arrFuncoes[campo]) {
                                campos.push(
                                    <TableCell key={index} component="th" scope="row" >
                                        {props.arrFuncoes[campo].funcao((props.arrFuncoes[campo]['chave'] ? row[props.arrFuncoes[campo]['chave']] : row), indexRow)}
                                    </TableCell>)
                            } else {
                                campos.push(
                                    <TableCell key={index} component="th" scope="row" style={{wordBreak: 'break-word',minWidth:99}}>
                                        {row[campo]}
                                    </TableCell>
                                )
                            }
                        })
                        return (
                            <TableRow key={indexRow} >
                                {campos}
                            </TableRow>
                        )
                    }
                    )}

                    {props.isLoading !== true && rows.length <= 0 && (
                        <TableRow key="no-results">
                            <TableCell colSpan={props.arrCabecalho.length} style={{ textAlign: 'center' }} >
                                <span>{props.mensagemSemRegistros ?? 'Nenhum registro encontrado.'}</span>
                            </TableCell>
                        </TableRow>
                    )}
                    {props.isLoading === true && (
                        <TableRow key="is-loading">
                            <TableCell colSpan={props.arrCabecalho.length} style={{ textAlign: 'center' }} >
                                {mensagemPadraoCarregando}
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
                {props.exibePaginacao !== false &&
                    <TableFooter>
                        <TableRow key="pagination" >
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50]}
                                colSpan={props.arrCabecalho.length}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'registros por página' },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                }
            </Table>
        </TableContainer>
    );
}
