import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, FormControl, Select, Paper } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SubTitulo } from '../../views/style/styles';
import { LocalShipping } from '@material-ui/icons';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ColetaSeletiva from '../../services/coleta-seletiva.service';
import GoogleMapReact from 'google-map-react';
import IconeCaminhaoChamado from '../partials/mapa-chamado/IconeCaminhaoMapa';

import ReactDOM from 'react-dom';
import {
  FaMapMarkerAlt,
  FaArrowAltCircleRight,
  FaRoute,
  FaShippingFast,
} from 'react-icons/fa';
import { Alert } from '@material-ui/lab';
import caminhoesService from '../../services/caminhoes.service';

const REACT_APP_KEY_GOOGLE_MAPS_API = process.env.REACT_APP_KEY_GOOGLE_MAPS_API;

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,

  formCadastro: {
    margin: '15px 0 15px 0',
  },

  content: {
    backgroundColor: '#FFF',
    padding: '5px 5px 5px',
    borderRadius: '5px',
  },
});

const mensagemPadraoCarregando =
  (
    <span>
      <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
    </span>
  );

class DashboardColeta extends Component {
  constructor(props) {
    super(props);

    this.onChangeCaminhao = this.onChangeCaminhao.bind(this);
    this.createMapOptions = this.createMapOptions.bind(this);
    this.handleApiLoaded = this.handleApiLoaded.bind(this);
    this.getRotaCaminhao = this.getRotaCaminhao.bind(this);

    this.state = {
      codCaminhao: null,
      codItineraioColeta: null,
      arrListItinerario: null,
      arrListCaminhao: [],
      arrUltimosDadosCaminhoes: [],
      arrListRotas: [],
      geoCoder: null,
      map: null,
      maps: null,
      directionsService: null,
      directionsRenderer: null,
      isLoading: false,
      message: null,
      successful: true,
      exibeDadosCaminhao: null,
      dadoCaminhao: [],
      exibeCaminhaoNoMapa: true,
    };
  }

  static defaultProps = {
    center: {
      lat: -25.5003678,
      lng: -54.5684169,
    },
    zoom: 15,
  };

  componentDidMount() {
    // on mount component
    this.getCaminhoes();
    this.geUltimosDadosCaminhoes();
  }

  getCaminhoes = async () => {
    const arrList = await ColetaSeletiva.getAllCaimnhao();

    this.setState({ arrListCaminhao: arrList });
    this.setState({ isLoading: false });
  };

  geUltimosDadosCaminhoes = () => {
    setInterval(async () => {
      const arrUltimosDadosCaminhoes = await caminhoesService.getApiCaminhoesLastData([]);
      this.setState({ arrUltimosDadosCaminhoes: arrUltimosDadosCaminhoes });
    }, 10000)
  };

  getItinerario = async (codCaminhao) => {
    const arrItinerios = await ColetaSeletiva.getDiasParada({
      codCaminhaoColeta: codCaminhao, flgExcluido: 'N',
    });

    if (arrItinerios.length == 1) {
      let pk = arrItinerios[0].cod_itinerario_coleta;

      this.setState({ codItineraioColeta: pk });
      this.controleExibicao(true, pk);
    }

    if (arrItinerios != '') {
      this.setState({ arrListItinerario: arrItinerios });
    } else {
      this.controleExibicao(false, null, 'Nenhuma rota cadastrada para o caminhão selecionado.');
    }
  };


  getRotaCaminhao = async (codItinerarioColeta) => {
    const arrListRotas = await ColetaSeletiva.getRotaCaminhao({
      codItinerarioColeta: codItinerarioColeta, flgExcluido: 'N',
    });

    if (arrListRotas != undefined && arrListRotas != '') {
      this.setState({ arrListRotas: arrListRotas['rotas'] });
      this.setRotaMapa(this.state.maps, this.state.directionsService, this.state.directionsRenderer, arrListRotas['rotas']);
      this.state.directionsRenderer.setMap(this.state.map);
    } else {
      this.controleExibicao(false, null, 'Nenhuma rota cadastrada para o caminhão selecionado.');
    }
  };

  onChangeCaminhao = (e) => {
    this.controleExibicao(false);
    this.setState({
      message: null,
      successful: false,
      codCaminhao: e.target.value,
    });

    if (e.target.value != '' && e.target.value != null && e.target.value != undefined) {
      this.getItinerario(e.target.value);
    } else {
      this.controleExibicao(false, null, 'Selecione um caminhão para exibir seus locais de parada.');
    }
  };

  onChangeItinerario = (e) => {
    this.setState({
      codItineraioColeta: e.target.value,
    });

    if (e.target.value != '' && e.target.value != null && e.target.value != undefined) {
      this.controleExibicao(true, e.target.value);
    } else {
      //this.controleExibicao(false, null, 'Erro ao carregar a rota');

      this.setState({
        message: 'Seleciona uma rota para ser exibida no mapa.',
        successful: true,
      });
      this.state.directionsRenderer.setMap(null);
    }
  };

  controleExibicao = (isControle, codItinerarioColeta = null, msg = null) => {
    if (isControle) {
      document.getElementById('paper_filtros').style.display = 'block';
      this.getRotaCaminhao(codItinerarioColeta);

      this.escondeFiltros();
      this.escondeLegenda();

      document.getElementById('checkbox_caminhao').checked = true;
      document.getElementById('checkbox_parada').checked = true;
      document.getElementById('checkbox_rota').checked = true;

      this.setState({
        message: null,
        successful: false,
      });
    } else {
      if (document.getElementById('paper_filtros') != null && document.getElementById('paper_filtros') != undefined) {
        document.getElementById('paper_filtros').style.display = 'none';
      }
      this.state.directionsRenderer.setMap(null);
      this.setState({
        message: msg,
        successful: true,
        arrListItinerario: null,
        codItineraioColeta: null
      });
      return;
    }
  };

  createMapOptions = (maps) => {
    return {
      panControl: true,
      mapTypeControl: true,
      scrollwheel: true,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
    };
  };

  exibeLegenda = () => {
    document.getElementById('div_botao_legendas').style.display = 'none';
    document.getElementById('paper_legendas').style.display = '';
    document.getElementById('div_legendas').style.display = '';
    document.getElementById('div_botao_filtros').style.display = 'none';
    document.getElementById('paper_filtros').style.display = 'none';
    document.getElementById('div_filtros').style.display = 'none';
  };

  escondeLegenda = () => {
    document.getElementById('div_botao_legendas').style.display = '';
    document.getElementById('paper_legendas').style.display = '';
    document.getElementById('div_legendas').style.display = 'none';
    document.getElementById('div_botao_filtros').style.display = '';
    document.getElementById('paper_filtros').style.display = '';
    document.getElementById('div_filtros').style.display = 'none';
  };

  exibeFiltros = () => {
    document.getElementById('div_botao_legendas').style.display = 'none';
    document.getElementById('paper_legendas').style.display = 'none';
    document.getElementById('div_legendas').style.display = 'none';
    document.getElementById('div_botao_filtros').style.display = 'none';
    document.getElementById('paper_filtros').style.display = '';
    document.getElementById('div_filtros').style.display = '';
  };

  escondeFiltros = () => {
    document.getElementById('div_botao_legendas').style.display = '';
    document.getElementById('paper_legendas').style.display = '';
    document.getElementById('div_legendas').style.display = 'none';
    document.getElementById('div_botao_filtros').style.display = '';
    document.getElementById('paper_filtros').style.display = '';
    document.getElementById('div_filtros').style.display = 'none';
  };

  filtraMapa = (event) => {
    const filtro = event.target.value;

    if (filtro === 'caminhao') {
      if (event.target.checked) {
        document.getElementById('checkbox_caminhao').checked = true;
        this.setState({ exibeCaminhaoNoMapa: true });
      } else {
        document.getElementById('checkbox_caminhao').checked = false;
        this.setState({ exibeCaminhaoNoMapa: false });
      }
    }

    if (filtro === 'parada' && document.getElementById('checkbox_rota').checked == true) {
      if (event.target.checked) {
        this.setRotaMapa(this.state.maps, this.state.directionsService, this.state.directionsRenderer, this.state.arrListRotas, this.state.geoCoder, true);
        this.state.directionsRenderer.setMap(this.state.map);

        document.getElementById('checkbox_parada').checked = true;
      } else {
        this.setRotaMapa(this.state.maps, this.state.directionsService, this.state.directionsRenderer, this.state.arrListRotas, this.state.geoCoder, false);
        this.state.directionsRenderer.setMap(this.state.map);

        document.getElementById('checkbox_parada').checked = false;
      }
    }

    if (filtro === 'rota') {
      if (event.target.checked) {
        document.getElementById('checkbox_rota').checked = true;
        this.state.directionsRenderer.setMap(this.state.map);
      } else {
        this.state.directionsRenderer.setMap(null);
        document.getElementById('checkbox_rota').checked = false;
      }
    }
  };

  handleApiLoaded = (map, maps) => {
    const botaoLegenda = (
      <>
        <Paper id="paper_legendas" elevation={3} style={{
          margin: 10,
          minWidth: 150,
          textAlign: 'center',
          padding: 5,
          minWidth: 30,
        }}>
          <span id="div_botao_legendas"
            style={{ cursor: 'pointer', fontSize: '16px' }}
            onClick={this.exibeLegenda}>Legendas</span>
          <div id="div_legendas"
            style={{ display: 'none', textAlign: 'initial', fontSize: 13 }}>
            <div><FaArrowAltCircleRight onClick={this.escondeLegenda} style={{
              cursor: 'pointer',
              marginBottom: 8,
            }} /></div>
            <div><FaMapMarkerAlt style={{ color: '#000' }} /> Locais de Parada
            </div>
            <div><FaRoute style={{ color: '#000' }} /> Percurso</div>
            <div><FaShippingFast style={{ color: '#000' }} /> Localização em tempo
              real
            </div>
          </div>
        </Paper>
        <Paper id="paper_filtros" elevation={3} style={{
          display: 'none',
          margin: 10,
          minWidth: 150,
          textAlign: 'center',
          padding: 5,
          minWidth: 30,
          fontSize: 13,
        }}>
          <span id="div_botao_filtros"
            style={{ cursor: 'pointer', fontSize: '16px' }}
            onClick={this.exibeFiltros}>Exibição</span>
          <div id="div_filtros" style={{ display: 'none', textAlign: 'initial' }}>
            <div><FaArrowAltCircleRight onClick={this.escondeFiltros} style={{
              cursor: 'pointer',
              marginBottom: 8,
            }} /></div>
            <div><input defaultChecked={true} id="checkbox_caminhao" onChange={this.filtraMapa}
              value="caminhao" type="checkbox" /> Caminhão
            </div>
            <div><input defaultChecked={true} id="checkbox_parada"
              onChange={this.filtraMapa} value="parada"
              type="checkbox" /> Locais de Parada
            </div>
            <div><input defaultChecked={true} id="checkbox_rota"
              onChange={this.filtraMapa} value="rota"
              type="checkbox" /> Rota
            </div>
          </div>
        </Paper>
      </>
    );
    const controlButtonDiv = document.createElement('div');
    ReactDOM.render(botaoLegenda, controlButtonDiv);
    map.controls[maps.ControlPosition.RIGHT_TOP].push(controlButtonDiv);


    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();
    const geoCoder = new maps.Geocoder();

    this.setState({
      geoCoder: geoCoder,
      map: map,
      maps: maps,
      directionsService: directionsService,
      directionsRenderer: directionsRenderer,
    });

    if (this.state.arrListRotas) {
      this.setRotaMapa(maps, directionsService, directionsRenderer, this.state.arrListRotas, geoCoder);
      directionsRenderer.setMap(map);
    }
  };

  setRotaMapa = (maps, directionsService, directionsRenderer, arrRotasAtualizadas, geoCoder = null, isRota = true) => {
    const waypts = [];
    const arrPontos = arrRotasAtualizadas;
    if (arrPontos.length > 0) {
      arrPontos.map((dado, index) => {
        if (index !== 0 && index !== (arrPontos.length - 1) && isRota == true) {
          waypts.push({
            location: new maps.LatLng(dado.num_latitude_local, dado.num_longitude_local),
            stopover: true,
          });
        }
      });
      directionsService
        .route({
          origin: new maps.LatLng(arrPontos[0].num_latitude_local, arrPontos[0].num_longitude_local),
          destination: new maps.LatLng(arrPontos[arrPontos.length - 1].num_latitude_local, arrPontos[arrPontos.length - 1].num_longitude_local),
          waypoints: waypts,
          optimizeWaypoints: true,
          travelMode: maps.TravelMode.DRIVING,
        })
        .then((response) => {
          directionsRenderer.setDirections(response);
        })
        .catch((e) => console.log('Directions request failed due to', e));
    }
  };

  onClickExibeDadosCaminhao = (id) => {
    this.setState({ exibeDadosCaminhao: (id === this.state.exibeDadosCaminhao ? null : id), dadoCaminhao : this.getDadosCaminhao(id) });
  }

  getDadosCaminhao(mac_addr) {
    const dado = this.state.arrListCaminhao.find((dado) => {
      return dado.strMacAddr === mac_addr;
    });
    return dado ?? { strIdentificador : 'Não informado', strPlaca : 'Não informado' };
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.state.isLoading === true &&
          <Grid container spacing={2} style={{ marginTop: 25 }}>
            <Grid item xs={10} sm={10} style={{ textAlign: 'center' }}>
              {mensagemPadraoCarregando}
            </Grid>
          </Grid>
        }
        {!this.state.isLoading === true &&
          <Grid container className={classes.conteudoFundoBranco} style={{ minWidth: '430px' }}>
            <Grid item xs={12} className={classes.formCadastroTitulo}>
              <LocalShipping className={classes.iconeTitulo} />
              <span className={classes.tituloPadrao}> DashBoard</span>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {this.state.successful == true && this.state.message != null && (
                  <div className="row">
                    <div className="col mb-2">
                      <Alert severity={'error'}>{this.state.message}</Alert>
                    </div>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={4} md={4}
                className={classes.formCadastroTitulo}>
                <FormControl variant="outlined"
                  className={classes.formControl} fullWidth>
                  <InputLabel
                    id="demo-simple-select-outlined-label">Caminhão</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Caminhão"
                    MenuProps={{ style: { maxWidth: 0, maxHeight: 300, position: 'absolute', }, disableScrollLock: true, }}
                    fullWidth
                    name="codCaminhao"
                    value={this.state.codCaminhao}
                    onChange={this.onChangeCaminhao}
                  >
                    <MenuItem value="">
                      <em>Selecione o Caminhão</em>
                    </MenuItem>
                    {
                      this.state.arrListCaminhao.map(function (item, index) {
                        return (
                          <MenuItem
                            value={item.codCaminhao}
                            key={index}> {item.strIdentificador}
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={1} md={1}> </Grid>
              {this.state.arrListItinerario && <Grid item xs={12} sm={4} md={4}
                className={classes.formCadastroTitulo}>
                <FormControl variant="outlined"
                  className={classes.formControl} fullWidth>
                  <InputLabel
                    id="demo-simple-select-outlined-label">Itinerário</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Itinerario"
                    fullWidth
                    name="codItineraioColeta"
                    value={this.state.codItineraioColeta}
                    onChange={this.onChangeItinerario}
                  >
                    <MenuItem value="">
                      <em>Selecione o Itinerário</em>
                    </MenuItem>
                    {
                      this.state.arrListItinerario.map(function (item, index) {
                        return (
                          <MenuItem
                            value={item.cod_itinerario_coleta}
                            key={index}> {setDias(item.json_dia_coleta.toString())}
                          </MenuItem>
                        );
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              }
              <Grid item xs={12} className={classes.content}>
                <SubTitulo>Mapa em Tempo Real</SubTitulo>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
                <div style={{ height: '70vh', width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: REACT_APP_KEY_GOOGLE_MAPS_API }}
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    options={this.createMapOptions}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({
                      map,
                      maps,
                    }) => this.handleApiLoaded(map, maps)}
                  >
                    { this.state.exibeCaminhaoNoMapa === true && this.state.arrUltimosDadosCaminhoes.length > 0 && this.state.arrUltimosDadosCaminhoes.map(caminhao => {
                      return (
                        <IconeCaminhaoChamado key={caminhao.mac_addr}
                          lat={caminhao.location.latitude /** 1 + (0.005 + Math.random() * (0.005 - 0.001)) /* random temporário para apresentação. Remover posteriormente */}
                          lng={caminhao.location.longitude /** 1 + (0.005 + Math.random() * (0.005 - 0.001)) /* random temporário para apresentação. Remover posteriormente */}
                          caminhao={caminhao}
                          exibeInfoCaminhao={this.state.exibeDadosCaminhao}
                          onClickExibeDadosCaminhao={this.onClickExibeDadosCaminhao}
                          dadoCaminhao={this.state.dadoCaminhao}
                        />
                      )
                    })
                    }
                  </GoogleMapReact>
                </div>
              </Grid>
            </Grid>
          </Grid>
        }
      </>
    );
  }
}

function setDias(json_dias) {
  let arrJson = json_dias.split(',');
  let strDias = '';

  arrJson.map(function (v, i) {
    switch (v.replace(',', '')) {
      case 'SEG':
        strDias += 'Segunda, ';
        break;
      case 'TER':
        strDias += 'Terça, ';
        break;
      case 'QUA':
        strDias += 'Quarta, ';
        break;
      case 'QUI':
        strDias += 'Quinta, ';
        break;
      case 'SEX':
        strDias += 'Sexta, ';
        break;
      case 'SAB':
        strDias += 'Sábado, ';
        break;
      case 'DOM':
        strDias += 'Domingo, ';
        break;
      default:
        strDias += '';
    }
  });
  return strDias.slice(0, -2);
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(DashboardColeta));
