const trataErroApi = (data) => {
  const user = JSON.parse(localStorage.getItem('user'));

  //valida acesso do usuário.
  let dataAual = new Date();
  let dataExpiracao = new Date(user.expiration.date);

  if (dataExpiracao < dataAual) {
    localStorage.clear();
    window.location.href = '/login';
  }else{
    if (user != null && user.accessToken) {
      window.location.href = '/home?m=401';
    } else {
      localStorage.clear();
      window.location.href = '/login';
    }
  }
  //valida acesso do usuário.

  // if (user != null && user.accessToken) {
  //   window.location.href = '/home?m=401';
  // } else {
  //   localStorage.clear();
  //   window.location.href = '/login';
  // }

}
export default trataErroApi;
