import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Box, Button, Grid} from '@material-ui/core';
import Description from '@material-ui/icons/Description';
import {registerConfirmationApp} from '../actions/AuthAction';
import AuthActionservice from '../services/auth.service';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,

  root: {
    width: '100%',
    height: '100%',
  },
  mainBox: {
    overflowY: 'auto',
    width: '100%',
    position: 'fixed',
    margin: 0,
    padding: 0,
    top: 0,
    left: 0,
    height: '100%',
  },
  headerPanel: {
    backgroundColor: '#185b8d',
    width: '100%',
    height: 176,
  },
  logo: {
    width: 350,
    lineHeight: 94,
  },
  logoDiv: {
    width: '100%',
    textAlign: 'center',
  },
  button: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    background: theme.palette.background.button,
    padding: '10px 10px',
    lineHeight: 1.4,
    borderRadius: 30,
    width: '100%',
  },
  content: {
    backgroundColor: '#FFF',
    margin: '5% auto',
    borderRadius: '30px',
    boxShadow: '2px 2px 1px #cccaca',
    textAlign: 'center',
    fontSize: 12,
    width: 300,
    padding: '30px',
  },
  buttonDiv: {
    textAlign: '-webkit-center',
  },
  buttonContainer: {
    marginTop: 20,
    width: 300,
    marginBottom: 20,
  },
  link: {
    color: '#979797',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  storeButton: {
    width: 100,
    lineHeight: 94,
    margin: 5
  },
  contentFooterDiv: {
    marginTop: '5%',
    marginBottom: '5%'
  },
  sMediaDiv: {
    width: '10%'
  },
  sMediaButton: {
    width: 40,
    margin: 5,
    borderRadius: 9
  },
  footerDiv: {
    textAlign: 'center',
    marginTop: '10%',
  }
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );

class CadastroConfirmadoApp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: '',
      isloading: true,
    };
  }

  componentDidMount() {
    localStorage.removeItem("user");
    this.ativarUsuario();
  }

  ativarUsuario = async () => {
    let token = '';
    let url = window.location.href;
    if (url.includes('cadastroconfirmadoapp?token=')) {
      token = url.replace(window.origin + '/cadastroconfirmadoapp?token=', '');
    }

    if (token) {
      let ativar = await AuthActionservice.registerConfirmationApp(token);
      if(ativar.status && ativar.status === 200){
        this.setState({successful: true, message: ativar.data.message, isloading: false});
      }

      // this.props
      //   .dispatch(
      //     registerConfirmationApp(token),
      //   )
      //   .then((resp) => {
      //     console.log(resp);
      //     this.setState({successful: true});
      //   });
    }
  }

  componentDidUpdate() {
    // on update component
  }

  render() {
    const {classes, message} = this.props;

    return (
          <Box className={classes.mainBox}>
            <Box className={classes.headerPanel}>
              <div className={classes.logoDiv}>
                <img className={classes.logo}
                     src={'images/logo_white.png'}
                     alt="Logo"/>
              </div>
            </Box>
            <Box className={classes.content}>
              {this.state.isloading === true &&
                <CircularProgress style={{
                  width: 20,
                  height: 20,
                  marginRight: 15,
                }}/>
              }
              {this.state.isloading === false &&
                <Grid>{this.state.message}</Grid>
              }
              <Box className={classes.footerDiv}>
                Baixe nosso aplicativo
                <br/>
                <div className={classes.contentFooterDiv}>
                  <img className={classes.storeButton}
                       src={'images/disp_gplay.png'}
                       alt="Disponível no Google Play"/>
                  <img className={classes.storeButton} src={'images/disp_apstore.png'}
                       alt="Disponível na Apple Store"/>
                </div>
                <Box>
                  Siga a Vila A nas redes sociais<br/>
                  <div className={classes.contentFooterDiv}>
                    <img className={classes.sMediaButton} src={'images/logo_facebook.png'}
                         alt="Logo Facebook"/>
                    <img className={classes.sMediaButton} src={'images/logo_twitter.png'}
                         alt="Logo Twitter"/>
                    <img className={classes.sMediaButton} src={'images/logo_youtube.png'}
                         alt="Logo Youtube"/>
                  </div>
                </Box>
              </Box>

            </Box>
          </Box>
    );
  }
}

function mapStateToProps(state) {
  const {message} = state.message;

  return {message};
}

export default connect(mapStateToProps)(withStyles(styles)(CadastroConfirmadoApp));
