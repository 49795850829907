import axios from "axios";
import authHeader from './auth-header';
import trataErroApi from './trata-erro-api';

const API_URL = process.env.REACT_APP_API_URL

class ItinerarioService {

  async getAll(arrFiltros) {
    return axios
      .post(API_URL + "itinerario/getItinerarios", arrFiltros, { headers: authHeader() })
      .then((response) => {
        console.log(response);
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async salvarItinerario(arrDados) {
    return axios
      .post(API_URL + "itinerario/salvarItinerario", arrDados, { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async carregarDadosItinerario(codItinerario) {
    return axios
      .post(API_URL + "itinerario/carregarDadosItinerario", { codItinerario: codItinerario }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async excluirItinerario(codItinerarioColeta) {
    return axios
      .post(API_URL + "itinerario/excluirItinerario", { codItinerarioColeta: codItinerarioColeta }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async atualizarJsonRota(codItinerarioColeta, arrJsonRota) {
    return axios
      .post(API_URL + "itinerario/atualizarJsonRota", { codItinerarioColeta: codItinerarioColeta, arrJsonRota: arrJsonRota }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

} export default new ItinerarioService();
