import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { Button, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import userPerfilDefault from '../../../assets/images/user-perfil-default.png';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from "react-router-dom";
import {timestampFormatter} from '../../util';

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginLeft: 15, marginTop: 20 }} />
        </span>
    )

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            padding: 8,
            width: 350,
            minHeight: 80,
            position: 'absolute',
            top: -70,
            right: -380,
            zIndex: 1,
            cursor: 'default',
            fontSize: 14,
        },
    },
    fotoPerfil: {
        borderRadius: 30,
        width: 45,
        height: 45,
        objectFit: 'cover',
        marginTop: 15
    },
}));

const IconeMapaChamado = (props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(props.isLoading);
    const [exibe, setExibe] = React.useState(0);

    const history = useHistory();

    /*useEffect(() => {
        console.log(props.chamado);
    }, [props.chamado.cod_chamado]);*/

    const trataData = (data) => {
        let data_hora = '';
        const arrData = data.split('T')[0].split('-');
        data_hora = arrData[2] + '/' + arrData[1] + '/' + arrData[0] + ' ' + data.split('T')[1].substring(0, 5);
        return <span>{data_hora}</span>;
    }

    const visualizarChamado = (codChamado) => {
        history.push("/botao-panico/visualizar-chamado/" + codChamado);
    }

    return (
        <div>
            <PersonIcon style={{ width: 40, height: 40, cursor: 'pointer', color: props.chamado.flg_situacao === 'A' ? '#000' : '#CCC' }} onClick={() => props.exibeInfoChamado(props.chamado)} />
            {props.chamado.cod_chamado === props.exibeCodChamado &&
                <div className={classes.root} >
                    <Paper elevation={3}>
                        <Grid container>
                            <Grid key={props.chamado.cod_chamado + '_foto'} item xs={2} >
                                {props.isLoading === false && <img src={props.imagemUsuario ?? userPerfilDefault} className={classes.fotoPerfil} />}
                                {props.isLoading === true && mensagemPadraoCarregando}
                            </Grid>
                            <Grid key={props.chamado.cod_chamado + '_dados'} item xs={7} >
                                <strong>Dados do usuário</strong><br />
                                {props.chamado.str_nome_pessoa_chamado} {props.chamado.str_sobrenome_chamado}<br />
                                {props.chamado.str_telefone}<br />
                                Abertura do chamado:<br />
                                {timestampFormatter(props.chamado.tms_abertura_chamado)}<br />
                            </Grid>
                            <Grid key={props.chamado.cod_chamado + '_visualizar'} item xs={3} style={{ textAlign: 'center' }}>
                                <strong>Visualizar chamado</strong><br />
                                <SearchIcon style={{ cursor: 'pointer' }} onClick={() => visualizarChamado(props.chamado.cod_chamado)} />
                                <CloseIcon fontSize="inherit" style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }} onClick={() => props.exibeInfoChamado(props.chamado)} />
                            </Grid>
                        </Grid>
                    </Paper>
                </div>
            }
        </div>
    );
};


export default IconeMapaChamado;
