import React from 'react';

function Footer() {
    return (
        <div class="container-fluid footer-bar">
            <div class="row justify-content-center row-cols-3 align-items-center">
                <div class="col-sm-2">
                    <img id="logo-pti" src={process.env.PUBLIC_URL + '/images/logo_pti.svg'}
                         alt="Logo PTI - Fundação Parque Tecnológico de Itaipu" height="20"/>
                </div>
                <div class="col-sm-8">
                    2022 &copy; Todos os direitos Reservados - Versão 1.0.0
                </div>
                <div class="col-sm-2">
                    <img id="logo-websis" src={process.env.PUBLIC_URL + '/images/logo_websis.svg'} alt="Logo
WEBSis Tecnologia e Sistemas" height="20"/>
                </div>
            </div>
        </div>
    )
}

export default Footer;
