import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import TabelaPaginada from '../partials/DataTablePaginadapadrao';
import {FaUserShield} from 'react-icons/fa';
import Service from '../../services/dpo.service';
import userPerfilDefault from '../../assets/images/user-perfil-default.png';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Visualizar from '../partials/anonimizar-usuario/Visualizar';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  buttonCadastro: theme.pagina.buttonCadastro,
  formCadastroTitulo: {
    margin: '15px 0 15px 0',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '85%',
  },
  button: {
    color: '#000',
    backgroundColor: '#FFF',
    border: 'solid 1px #005b96',
    //maxWidth: 100,
    height: '42px',
    marginTop: '15px',
    '&:hover': {
      color: '#005b96',
      backgroundColor: '#FFF',
      border: 'solid 1px #005b96',
    },
  },
});

class SolicitacaoExclusaoUser extends Component {
  constructor(props) {
    super(props);
    this.carregaDados = this.carregaDados.bind(this);
    this.onClickVisualizar = this.onClickVisualizar.bind(this);
    this.onClickResposta = this.onClickResposta.bind(this);

    this.state = {
      cod_solic_anonimizacao: null,
      str_seach: null,
      cod_tipo_solicita_seach: null,
      redirect: false,
      arrList: [],
      arrListOriginais: false,
      isLoadingVisualizarUsuario: true,
      isLoading: true,
      message: '',
      loading: false,
      successful: '',
      showForm: 'listar',
      arrTipoSolicitacaoSeach: [{
        'valor': 'A',
        'texto': 'Anônimo',
      }, {'valor': 'I', 'texto': 'Inatividade'}, {
        'valor': 'U',
        'texto': 'Usuário',
      }],
      acao: null,
    };
  }

  componentDidMount() {
    this.carregaDados();
  }

  carregaDados = () => {
    const data = Promise.resolve(Service.getList({flg_situacao: ['A', 'C', 'N', 'F']}));
    data.then((v) => {
      this.populate(v);
    });
  };

  populate = (data) => {
    this.setState({
      arrList: data, isLoading: false,
    });
  };

  onClickResposta = (codSolicAnonimizacao) => {
    this.setState({
      showForm: 'resposta',
      cod_solic_anonimizacao: codSolicAnonimizacao,
    });
  };

  onClickVisualizar = (codSolicAnonimizacao) => {
    this.setState({
      showForm: 'visualzar',
      cod_solic_anonimizacao: codSolicAnonimizacao,
      acao: 'V'
    });
  };

  onClickAnonimizar = (codSolicAnonimizacao) => {
    this.setState({
      showForm: 'visualzar',
      cod_solic_anonimizacao: codSolicAnonimizacao,
      acao: 'A'
    });
  };


  onVoltar = () => {
    this.setState({
      showForm: 'listar',
      cod_solic_anonimizacao: null,
      isLoading: true,
      acao: null,
    }, this.carregaDados);
  };

  strImagemPerfilFormatter = (imagem) => {
    return (
      <img src={imagem ? imagem : userPerfilDefault} className="img-perfil"
           style={{objectFit: 'cover'}}/>
    );
  };

  acaoFormatter = (row) => {
    const {cod_solic_anonimizacao, flg_situacao} = row;

    if(flg_situacao == 'A'){
      return ([
        <SearchIcon style={{cursor: 'pointer'}}
                    onClick={() => this.onClickVisualizar(cod_solic_anonimizacao)}/>,
        <VerifiedUserOutlinedIcon style={{cursor: 'pointer'}}
                                  onClick={() => this.onClickAnonimizar(cod_solic_anonimizacao)}/>,
      ]);
    }

    return ([
      <SearchIcon style={{cursor: 'pointer'}}
                  onClick={() => this.onClickVisualizar(cod_solic_anonimizacao)}/>,
    ]);
  };

  filtrarDados = (event) => {
    if (event.target.value.length === 0) {
      return this.carregaDados();
    }
    let value = event.target.value.toString().toLowerCase();
    let result = [];
    if (this.state.arrListOriginais === false) {
      this.setState({arrListOriginais: this.state.arrList});
    }

    if (this.state.arrListOriginais.length > 0) {
      result = this.state.arrListOriginais.filter((data) => {
        return (
          (data && data.str_nome_solicitante && data.str_nome_solicitante.toLowerCase().search(value.toLowerCase()) != -1)
        );
      });
    } else {
      result = this.state.arrList.filter((data) => {
        return (
          (data && data.str_nome_solicitante && data.str_nome_solicitante.toLowerCase().search(value.toLowerCase()) != -1)
        );
      });
    }

    this.setState({arrList: result, isLoading: false});
  };

  render() {
    const {message, classes} = this.props;

    return (
      <>
        {this.state.showForm === 'visualzar' &&
          <Visualizar
            codSolicAnonimizacao={this.state.cod_solic_anonimizacao}
            acao={this.state.acao}
            onVoltar={this.onVoltar}/>
        }
        {
          this.state.showForm === 'listar' &&
          <div className={classes.root}>
            <Grid container className={classes.conteudoFundoBranco}
                  style={{minWidth: '558px'}}>
              <Grid item xs={12} className={classes.formCadastroTitulo}>
                <FaUserShield className={classes.iconeTitulo}/> <span
                className={classes.tituloPadrao}>Anonimizar dados de Usuários</span>
              </Grid>
              {this.state.codInstrucaoBotaoPanico == null &&
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                      <Paper style={{margin: '12px 0'}}>
                        <InputBase
                          value={this.state.str_seach}
                          name="Busca"
                          id="Busca"
                          className={classes.input}
                          placeholder="Pesquisar Solicitação"
                          inputProps={{'aria-label': 'search '}}
                          onChange={(e) => {
                            this.setState({
                              str_seach: e.target.value,
                              isLoading: true,
                            });
                            this.filtrarDados(e);
                          }}
                          autoComplete="off"
                        />
                        <IconButton className={classes.iconButton}
                                    aria-label="search">
                          <SearchIcon/>
                        </IconButton>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid container spacing={3}>
                      <Grid item xs={12} id="table" name="tabela-sem-borda">
                        <TabelaPaginada
                          arrCabecalho={['Foto', 'Nome', 'Data do requerimento da Exclusão', 'Tipo de solicitação', 'Status', '', 'Ações']}
                          arrColunas={['str_imagem_perfil', 'str_nome_solicitante', 'dat_requerimento_fmt', 'tp_solicitacao_fmt', 'flg_situacao_fmt', 'contador_resposta', 'cod_solic_anonimizacao']}
                          isLoading={this.state.isLoading}
                          style={{boxShadow: 'none!important'}}
                          mensagemSemRegistros="Nenhum usuário encontrado"
                          arrDados={
                            this.state.arrList
                          }
                          arrFuncoes={
                            {
                              str_imagem_perfil: {
                                chave: 'str_imagem_perfil',
                                funcao: this.strImagemPerfilFormatter,
                              },
                              cod_solic_anonimizacao: {
                                funcao: this.acaoFormatter,
                              },
                            }
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            </Grid>
          </div>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(SolicitacaoExclusaoUser));
