import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import TabelaPaginada from '../DataTablePaginadapadrao';
import { render } from 'react-dom';
import WordCloud from 'react-d3-cloud';
import SelectPadrao from '../SelectPadrao';
import RedeSocialService from '../../../services/rede-social';
import CircularProgress from '@material-ui/core/CircularProgress';

const mensagemPadraoCarregando =
  (
    <span>
      <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
    </span>
  );

export default function NuvemPalavras(props) {
  const [codLimit, setCodLimit] = React.useState(1);
  const [arrDadosNuvem, setArrDadosNuvem] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [arrNotSelect, setArrNotSelect] = React.useState([]);

  useEffect(() => {
    setLoading(false);
    setArrNotSelect(props.arrNotSelect);
  }, [props.arrDadosNuvem]);

  let arrNuvem = [];
  let codPesquisaRede = null;

  if (props.arrDadosNuvem !== undefined) {
    arrNuvem = props.arrDadosNuvem.result;
    codPesquisaRede = props.codPesquisaRedeSocial;
  }

  const arrLimit = [
    { valor: 0, 'texto': 5 },
    { valor: 1, 'texto': 10 },
    { valor: 2, 'texto': 15 },
    { valor: 3, 'texto': 25 },
    { valor: 5, 'texto': 50 },
  ];

  const rotate = (word) => 1;//(word.value % 90) - 25;
  const fontSize = (word) => (word.value * 0.1 > 50 ? 50 : word.value * 0.1);

  const getPalavrasNuvem = () => {
    const arrPalavras = arrDadosNuvem.length > 0 ? arrDadosNuvem : arrNuvem;

    let arrDadosParaNuvem = [];
    arrPalavras.map((valor, index) => (
      arrDadosParaNuvem.push({ text: valor.str_palavra, value: valor.qtd_repeticao })
    ))
    return arrDadosParaNuvem.length >= 1 ? arrDadosParaNuvem : [];
  }

  const onChangLimit = (event) => {
    setLoading(true);
    setCodLimit(event.target.value)

    if (event.target.value !== null) {
      onclickPaginacao(arrLimit[event.target.value].texto);
    }
  }

  const onclickPaginacao = async (value) => {
    const arrDados = await RedeSocialService.getDadosNuvemPalavras({
      codPesquisaRedeSocial: codPesquisaRede,
      limit: value,
      dt_inicio: props.dt_incio,
      dt_fim: props.dt_fim,
      notInSelect: arrNotSelect,
    });

    setArrDadosNuvem(arrDados.result);
    setLoading(false);
  }

  return (
    <>
      {loading === true &&
        <Grid container spacing={2} style={{ marginTop: 25 }}>
          <Grid item xs={10} sm={10} style={{ textAlign: 'center' }}>
            {mensagemPadraoCarregando}
          </Grid>
        </Grid>
      }
      {loading === false &&
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <h3>NUVEM DE PALAVRAS:</h3>
              </Grid>
              <Grid item xs={4}>
                <SelectPadrao
                  label="Paginação"
                  id="id-pesaquisa"
                  value={codLimit}
                  onChange={onChangLimit}
                  arrDados={arrLimit}
                />
              </Grid>
              <Grid item xs={12}>
                <WordCloud
                  width={400}
                  height={250}
                  padding={2}
                  fontSize={fontSize}
                  rotate={rotate}
                  data={getPalavrasNuvem()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <h3>PALAVRA X REDE SOCIAL:</h3>
            <TabelaPaginada
              arrCabecalho={['Palavra', 'Repetições', 'Twitter', 'Instagram']}
              arrTamanhoColuna={['40%', '20%', '20%', '20%']}
              isLoading={props.isLoading ?? false}
              // arrDados={arrNuvem ?? []}
              arrDados={arrDadosNuvem.length > 0 ? arrDadosNuvem : arrNuvem}
              exibePaginacao={false}
            />
          </Grid>
        </Grid>
      }
    </>
  );
}
