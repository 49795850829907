import React from 'react';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyle = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

export default function Input(props) {

  const classes = useStyle();

  const handleEnviarDadosParaComponentePai = (event) => {
    if (typeof props.onChange === 'function') {
      props.onChange(event);
    }
  };

  const arrDados = props.arrDados ?? [];

  return (
    <FormControl variant="outlined" className={props.classNameFormControl ?? classes.formControl} fullWidth>
      <InputLabel className={props.classNameLabel ?? ''} id={'id-label-' + props.id} >{props.label}</InputLabel>
      <Select
        labelId={'id-label-' + props.id}
        label={props.label}
        id={props.id}
        //MenuProps={{ style: { maxWidth: 0, maxHeight: 300, position: 'absolute', }, disableScrollLock: true, }}
        name={props.id}
        required={props.required}
        value={props.value}
        onChange={handleEnviarDadosParaComponentePai}
        fullWidth
        error={props.error}
      >
        {props.isLoaded === false &&
          <MenuItem key="loading..." disabled > <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...</MenuItem>
        }

        {arrDados.length > 0 && props.isValueNull &&
          <MenuItem key="no-data-found" value="" >Selecione</MenuItem>
        }


        {(props.isLoaded === true || props.isLoaded === undefined) && (arrDados.length === 0) &&
          <MenuItem key="no-data-found" disabled >Não foram encontrados registros.</MenuItem>
        }
        {arrDados.length > 0 && arrDados.map(dado => {
          const valor = dado[props.option && props.option.valor ? props.option.valor : Object.keys(dado)[0]];
          const texto = dado[props.texto && props.option.texto ? props.option.texto : Object.keys(dado)[1]];
          return (
            <MenuItem key={valor} value={valor}>{texto}</MenuItem>
          )
        })
        }
      </Select>
      {props.error && props.helperText &&
        <span style={{ color: 'red' }}>{props.helperText}</span>
      }
    </FormControl>
  )
}
