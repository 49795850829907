import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from './TypesAction';

import UserRegisterService from '../services/user-register.service';
import AlterarSenhaService from '../services/alterar-senha.service';

const API_URL = process.env.REACT_APP_API_URL;


export const alterarSenha = (
  str_new_senha,
  str_conf_senha,
  str_login,
  url_token,
) => (dispatch) => {
  return AlterarSenhaService.alterarSenha(
    str_new_senha,
    str_conf_senha,
    str_login,
    url_token,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};


export const register = (
  image,
  str_nome_pessoa,
  str_sobrenome,
  dat_nascimento,
  flg_genero,
  num_cpf,
  str_email,
  str_email_adicional,
  str_telefone,
  num_cep,
  cod_unidade_federacao,
  str_municipio,
  str_bairro,
  str_logradouro,
  str_complemento_endereco,
  num_residencial,
  cod_usuario,
  cod_pessoa,
  fazUploadImagem,
) => (dispatch) => {
  return UserRegisterService.register(
    image,
    str_nome_pessoa,
    str_sobrenome,
    dat_nascimento,
    flg_genero,
    num_cpf,
    str_email,
    str_email_adicional,
    str_telefone,
    num_cep,
    cod_unidade_federacao,
    str_municipio,
    str_bairro,
    str_logradouro,
    str_complemento_endereco,
    num_residencial,
    cod_usuario,
    cod_pessoa,
    fazUploadImagem,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};
