import axios from "axios";
import authHeader from './auth-header';
import trataErroApi from './trata-erro-api';

const API_URL =  process.env.REACT_APP_API_URL

class AduditoriaService {

    async getDadosListaAuditoria(arrFiltros) {
      return axios
        .post(API_URL + "auditoria/getDadosListaAuditoria", arrFiltros, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async carregarDadosLogAuditoria(codAuditoria) {
      return axios
        .post(API_URL + "auditoria/getById", {id : codAuditoria}, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async getListaFuncionalidades() {
      return axios
        .get(API_URL + "auditoria/getListaFuncionalidades", {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

}export default new AduditoriaService();
