import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyle = makeStyles((theme) => ({
  wrapperClass: {
    padding: '1rem;',
    bordered: '1px solid #ccc;',
  },

  editorClass: {
    padding: '1rem;',
    border: '1px solid #ccc;',
  },

  toolbarClass: {
    border: '1px solid #ccc;',
  },
}));

export default function TextoRicoPadrao(props) {
  const classes = useStyle();

  const editorLabels = {
    // Generic
    'generic.add': 'Adicionar',
    'generic.cancel': 'Cancelar',

    // BlockType
    'components.controls.blocktype.h1': 'H1',
    'components.controls.blocktype.h2': 'H2',
    'components.controls.blocktype.h3': 'H3',
    'components.controls.blocktype.h4': 'H4',
    'components.controls.blocktype.h5': 'H5',
    'components.controls.blocktype.h6': 'H6',
    'components.controls.blocktype.blockquote': 'Blockquote',
    'components.controls.blocktype.code': 'Code',
    'components.controls.blocktype.blocktype': 'Block Type',
    'components.controls.blocktype.normal': 'Normal',

    // Color Picker
    'components.controls.colorpicker.colorpicker': 'Cores',
    'components.controls.colorpicker.text': 'Cor da Fonte',
    'components.controls.colorpicker.background': 'Realce',

    // Embedded
    'components.controls.embedded.embedded': 'Integrar Link',
    'components.controls.embedded.embeddedlink': 'Titulo',
    'components.controls.embedded.enterlink': 'Link',

    // Emoji
    'components.controls.emoji.emoji': 'Emojis',

    // FontFamily
    'components.controls.fontfamily.fontfamily': 'Nome da Fonte',

    // FontSize
    'components.controls.fontsize.fontsize': 'Tamanho da Fonte',

    // History
    'components.controls.history.history': 'History',
    'components.controls.history.undo': 'Desfazer',
    'components.controls.history.redo': 'Refazer',

    // Image
    'components.controls.image.image': 'Imagem',
    'components.controls.image.fileUpload': 'File Upload',
    'components.controls.image.byURL': 'URL',
    'components.controls.image.dropFileText': 'Drop the file or click to upload',

    // Inline
    'components.controls.inline.bold': 'Negrito',
    'components.controls.inline.italic': 'Itálico',
    'components.controls.inline.underline': 'Sublinhado',
    'components.controls.inline.strikethrough': 'Tachado',
    'components.controls.inline.monospace': 'Monoespaço',
    'components.controls.inline.superscript': 'Sobrescrito',
    'components.controls.inline.subscript': 'Subscrito',

    // Link
    'components.controls.link.linkTitle': 'Titulo',
    'components.controls.link.linkTarget': 'Link',
    'components.controls.link.linkTargetOption': 'Abrir link em nova janela',
    'components.controls.link.link': 'Link',
    'components.controls.link.unlink': 'Remover Link',

    // List
    'components.controls.list.list': 'List',
    'components.controls.list.unordered': 'Marcadores',
    'components.controls.list.ordered': 'Nuremação',
    'components.controls.list.indent': 'Aumentar Recuo',
    'components.controls.list.outdent': 'Diminuir Recuo',

    // Remove
    'components.controls.remove.remove': 'Limpar Formatação',

    // TextAlign
    'components.controls.textalign.textalign': 'Alinhamento',
    'components.controls.textalign.left': 'Alinhas à Esqueda',
    'components.controls.textalign.center': 'Centralizar',
    'components.controls.textalign.right': 'Alinhas à Direita',
    'components.controls.textalign.justify': 'Justificar',
  };

  const _getLengthOfSelectedText = () => {
    const currentSelection = props.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = props.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength = startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      
      if (isStartAndEndBlockAreTheSame) {
        length += currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        };
      }
    }

    return length;
  }

  const handleBeforeInput = () => {
    const currentContent = props.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > props.maxLength - 1) {

      return 'handled';
    }
  }

  const handlePastedText = (pastedText) => {
    const currentContent = props.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = _getLengthOfSelectedText();

    if (currentContentLength + pastedText.length - selectedTextLength > props.maxLength) {

      return 'handled';
    }
  }

  const onEditorStateChangePai = (event) => {
    if (typeof props.onEditorStateChange === 'function') {
      props.onEditorStateChange(event);
    }
  };

  return (
    <Editor
      readOnly={props.readOnly ?? true}
      toolbarHidden={props.toolbarHidden ?? true}
      editorState={props.editorState}
      onEditorStateChange={onEditorStateChangePai}
      localization={{
        locale: 'en',
        translations: editorLabels,
      }}
      wrapperClassName={props.wrapperClassName ??classes.wrapperClass}
      editorClassName={props.editorClassName ??classes.editorClass}
      toolbarClassName={props.toolbarClassName??classes.toolbarClass}
      handleBeforeInput={handleBeforeInput}
      handlePastedText={handlePastedText}
    />
  )
}
