import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid, TextField} from '@material-ui/core';
import * as Icon from '@material-ui/icons';
import Form from 'react-validation/build/form';
import {SubTitulo} from './style/styles';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {alterarSenha} from '../actions/UserRegisterAction';
import {Alert} from '@material-ui/lab';
import validator from 'validator';

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    mainBox: {
        overflowY: 'auto',
        width: '100%',
        position: 'fixed',
        margin: 0,
        padding: 0,
        top: 0,
        left: 0,
        height: '100%',
    },
    headerPanel: {
        backgroundColor: '#185b8d',
        width: '100%',
        height: 176,
    },
    logo: {
        width: 350,
        lineHeight: 94,
    },
    logoDiv: {
        width: '100%',
        textAlign: 'center',
    },
    formCadastro: {
        margin: '15px',
    },
    userCicle: {
        width: '150px'
    },
    button: {
        color: '#FFF',
        textTransform: 'none',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        padding: '10px 10px',
        lineHeight: 1.4,
        borderRadius: 30,
        width: '100%',
    },
    content: {
        backgroundColor: "#FFF",
        padding: '5px 5px 5px',
        borderRadius: '5px'
    },
    buttonDiv: {
        textAlign: '-webkit-center',
    },
    buttonContainer: {
        marginTop: 20,
        width: 300,
        marginBottom: 20,
    },
    link: {
        color: '#979797',
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    storeButton: {
        width: 150,
        lineHeight: 94,
    },
    contentFooterDiv: {
        marginTop: '1%',
        marginBottom: '1%',
    },
    sMediaDiv: {
        width: '10%',
    },
    sMediaButton: {
        width: 50,
        marginRight: 10,
    },
    footerDiv: {
        textAlign: 'center',
        marginTop: '1%',
    },
    invalidFeedback: {
        display: 'none'
    },
    formContainer: {
        position: 'relative',
        padding: '5% 10% 5% 10%',
        opacity: 1,
        borderRadius: '20px',
        backgroundColor: '#fff'
    },
    dataPicker: {
        fontcolor: '#4AE2D2'
    },
    inputText: {
        color: 'red',
    },
    logoimg: {
        width: 125
    },

    alingRight: {
        marginLeft: '25% !important'
    },
});

class RecuperarSenha extends Component {
    constructor(props) {
        super(props);
        this.handleAlterarSernha = this.handleAlterarSernha.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onChangeConfPassword = this.onChangeConfPassword.bind(this);
        this.validarSenha = this.validarSenha.bind(this);
        this.validarConfirmacaoSenha = this.validarConfirmacaoSenha.bind(this);

        this.state = {
            str_new_senha: '',
            str_conf_senha: '',
            str_login: null,
            url_token: null,
            mRegPassword: '',
            mRegConfPassword: '',
            strongPassword: false,
            errorPassoword: false,
            errorRegConfPassword: false,
            message: '',
            loading: false,
            successful: false,
            redirect: false,
        };
    }

    onChangePassword(e) {
        this.setState({
            str_new_senha: e.target.value,
        });
    }

    onChangeConfPassword(e) {
        this.setState({
            str_conf_senha: e.target.value,
        });
    }

    componentDidMount() {

    }

    validarSenha() {
        if (this.state.str_new_senha) {
            if (validator.isStrongPassword(this.state.str_new_senha, {
                minLength: 8, maxLength: 33, minLowercase: 1,
                minUppercase: 1, minNumbers: 1, minSymbols: 0,
            })) {
                this.setState({
                    strongPassword: 'true',
                    errorPassoword: false,
                    mRegPassword: ''
                });
            } else {
                this.setState({
                    strongPassword: 'false',
                    errorPassoword: true,
                    mRegPassword: 'A senha deve conter entre 8 e 32 caracteres, uma letra maiúscula e um número.'
                });
            }
        } else {
            this.setState({
                errorPassoword: false,
                mRegPassword: ''
            });
        }
    }

    validarConfirmacaoSenha() {
        if (this.state.str_new_senha && this.state.str_conf_senha) {
            if (this.state.str_new_senha === this.state.str_conf_senha) {
                this.setState({
                    errorRegConfPassword: false,
                    mRegConfPassword: ''
                });
            } else {
                this.setState({
                    errorRegConfPassword: true,
                    mRegConfPassword: 'As senhas devem ser iguais.'
                });
            }
        } else {
            this.setState({
                errorRegConfPassword: false,
                mRegConfPassword: ''
            });
        }
    }


    handleAlterarSernha(e) {
        e.preventDefault();

        this.setState({
            successful: false,
        });

        this.form.validateAll();
        this.props
            .dispatch(
                alterarSenha(
                    this.state.str_new_senha,
                    this.state.str_conf_senha,
                    this.state.str_login,
                    this.state.url_token = window.location.href
                ),
            )
            .then(() => {
                logout();
                this.setState({
                    successful: true,
                });
                this.timeout = setTimeout(() => {
                    this.setState({
                        redirect: true,
                    });
                }, 3000);
            })
            .catch(() => {
                this.setState({
                    successful: false,
                    redirect: false,
                });
            });
    }

    render() {
        const {successful, isLoggedIn, message, classes} = this.props;

        if (this.state.redirect) {
            window.location.href = '/';
        }

        return (
            <div id='alinhamento-recuperacao' className={classes.alingRight}>
                <style>
                {
                    //Ajuste por conta que classe open-conteudo (está localizada em Navigation)
                    //está acima da alinhamento-recuperacao e com isso a correção só deve utilizada 
                    //nessa pagina, ignorando o css global já feito, de fato só sendo somente corrigido a parte mobile aqui
                }
                @media screen and (min-width: 320px) and (max-width: 720px) {'{.open-conteudo {margin-right: 0 !important;padding-left: 0 !important;padding-bottom: 12em }}'}
                </style>
                <Grid container>
                    <Grid item xs={12} className={classes.content} style={{ marginTop: 50 }}>
                        <img className={classes.logoimg} src='images/logo_blue.png' alt='logo'/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} className={classes.content}>
                        <Form onSubmit={this.handleAlterarSernha} ref={(c) => {
                            this.form = c;
                        }} className={classes.formCadastro}>
                            {this.state.message && (
                                <div className="row">
                                    <div className="col mb-2">
                                        <Alert
                                            severity={this.state.successful ? 'warning' : ''}>{this.state.message}</Alert>
                                    </div>
                                </div>
                            )}
                            {message && (
                                <div className="row">
                                    <div className="col mb-2">
                                        <Alert severity={this.state.successful ? 'success' : 'error'}>{message}</Alert>
                                    </div>
                                </div>
                            )}
                            <SubTitulo> <Icon.Lock/> Senha</SubTitulo>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={8} style={{ paddingTop: 20 }}>
                                    <TextField
                                        required
                                        error={this.state.errorPassoword}
                                        helperText={this.state.mRegPassword}
                                        type="password"
                                        id="str_new_senha"
                                        name="str_new_senha"
                                        label="Nova Senha"
                                        fullWidth
                                        autoComplete="given-name"
                                        variant="outlined"
                                        value={this.state.str_new_senha}
                                        onChange={this.onChangePassword}
                                        onBlur={this.validarSenha}
                                    />
                                </Grid>
                                <Grid className="mobile-space" item xs={12} sm={2}></Grid>
                                <Grid className="mobile-space" item xs={12} sm={2}></Grid>
                                <Grid item xs={12} sm={8}>
                                    <TextField
                                        required
                                        error={this.state.errorRegConfPassword}
                                        helperText={this.state.mRegConfPassword}
                                        type="password"
                                        id="str_conf_senha"
                                        name="str_conf_senha"
                                        label="Confirme a Nova Senha "
                                        fullWidth
                                        autoComplete="given-name"
                                        variant="outlined"
                                        value={this.state.str_conf_senha}
                                        onChange={this.onChangeConfPassword}
                                        onBlur={this.validarConfirmacaoSenha}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2}></Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <div style={{textAlign: 'center', padding: 10}}>
                                            <Button
                                                type="submit" style={{ width: 100, height: 35.4333 }}
                                                variant="outlined">Salvar
                                            </Button> &nbsp;
                                            <Button
                                                component={Link} to="/">Cancelar</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    </Grid>
                </Grid>
            </div>
        );
    }
}
function logout() {
    sessionStorage.clear();
    localStorage.removeItem("user");
}

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    const {message} = state.message;
    return {
        isLoggedIn,
        message,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(RecuperarSenha));
