import React, {Component, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, FormControl, Grid, Select, TextField} from '@material-ui/core';
import Form from 'react-validation/build/form';
import {Titulo, SubTitulo} from '../../../views/style/styles';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {coletaSeletiva, pontoColeta} from '../../../actions/ColetaSeletivaAction';
import {Alert} from '@material-ui/lab';
import {FaMap, FaPlusSquare} from 'react-icons/fa';
import ColetaSeletivaService from '../../../services/coleta-seletiva.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import {
    KeyboardTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {cepMaks, telefoneMask} from '../../util';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const API_URL = process.env.REACT_APP_API_URL;

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
    tituloPadrao: theme.pagina.tituloPadrao,
    iconeTitulo: theme.pagina.iconeTitulo,
    formCadastroTitulo: theme.pagina.formCadastroTitulo,
    formButton : theme.pagina.formButton,
    mainBox: {
        overflowY: 'auto',
        width: '100%',
        position: 'fixed',
        margin: 0,
        padding: 0,
        top: 0,
        left: 0,
        height: '100%',
    },
    headerPanel: {
        backgroundColor: '#185b8d',
        width: '100%',
        height: 176,
    },
    logo: {
        width: 350,
        lineHeight: 94,
    },
    logoDiv: {
        width: '100%',
        textAlign: 'center',
    },
    formCadastro: {
        margin: '15px',
    },
    userCicle: {
        width: '150px',
    },
    button: {
        color: '#FFF',
        textTransform: 'none',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        padding: '10px 10px',
        lineHeight: 1.4,
        borderRadius: 30,
        width: '100%',
    },
    content: {
        backgroundColor: '#FFF',
        padding: '5px 5px 5px',
        borderRadius: '5px',
    },
    buttonDiv: {
        textAlign: '-webkit-center',
    },
    buttonContainer: {
        marginTop: 20,
        width: 300,
        marginBottom: 20,
    },
    link: {
        color: '#979797',
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    storeButton: {
        width: 150,
        lineHeight: 94,
    },
    contentFooterDiv: {
        marginTop: '1%',
        marginBottom: '1%',
    },
    sMediaDiv: {
        width: '10%',
    },
    sMediaButton: {
        width: 50,
        marginRight: 10,
    },
    footerDiv: {
        textAlign: 'center',
        marginTop: '1%',
    },
    invalidFeedback: {
        display: 'none',
    },
    formContainer: {
        position: 'relative',
        padding: '5% 10% 5% 10%',
        opacity: 1,
        borderRadius: '20px',
        backgroundColor: '#fff',
    },
    dataPicker: {
        fontcolor: '#4AE2D2',
    },
    inputText: {
        color: 'red',
    },

    seletor: {
        border: '4px solid #f9f9f9;',
        borderRadius: '5px',
    },

    wrapperClass: {
        padding: '1rem;',
        bordered: '1px solid #ccc;',
    },

    editorClass: {
        // backgroundColor: 'lightgray;',
        padding: '1rem;',
        border: '1px solid #ccc;',
    },

    toolbarClass: {
        border: '1px solid #ccc;',
    },
    modalExclusao: {
        boxShadow: theme.shadows[5],
    },
    titleModal: {
        borderBottom: '1px solid #ccc',
    },
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );

class PontoColeta extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.onChangeCodPontoColeta = this.onChangeCodPontoColeta.bind(this);
        this.onChangeCodUnidadeFederacao = this.onChangeCodUnidadeFederacao.bind(this);
        this.onChangeNomePontoColeta = this.onChangeNomePontoColeta.bind(this);
        this.onChangeNumCep = this.onChangeNumCep.bind(this);
        this.onChangeStrLogradouro = this.onChangeStrLogradouro.bind(this);
        this.onChangeNumEndereco = this.onChangeNumEndereco.bind(this);
        this.onChangeStrBairro = this.onChangeStrBairro.bind(this);
        this.onChangeStrMunicipio = this.onChangeStrMunicipio.bind(this);
        this.onChangeStrContato = this.onChangeStrContato.bind(this);
        this.onChangeStrLink = this.onChangeStrLink.bind(this);
        this.onChangeHoraInicio = this.onChangeHoraInicio.bind(this);
        this.onChangeHoraFim = this.onChangeHoraFim.bind(this);
        this.onClickSegunda = this.onClickSegunda.bind(this);
        this.onClickTerca = this.onClickTerca.bind(this);
        this.onClickQuarta = this.onClickQuarta.bind(this);
        this.onClickQuinta = this.onClickQuinta.bind(this);
        this.onClickSexta = this.onClickSexta.bind(this);
        this.onClickSabado = this.onClickSabado.bind(this);
        this.onClickDomingo = this.onClickDomingo.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleConfirmaExclusao = this.handleConfirmaExclusao.bind(this);
        this.addInputButton = this.addInputButton.bind(this);
        this.onClickVoltar = props.onVoltar;
        this.onBlurCep = this.onBlurCep.bind(this);
        this.onBlurNome = this.onBlurNome.bind(this);

        this.state = {
            cod_ponto_coleta: props.codPontoColeta,
            cod_unidade_federacao: '',
            str_nome_ponto_coleta: '',
            num_cep: '',
            str_logradouro: '',
            num_endereco: '',
            str_bairro: '',
            str_municipio: '',
            str_contato: '',
            str_link: '',
            hora_inicio: null,
            hora_fim: null,
            json_dia_funcionamento: '',
            domingo: false,
            segunda: false,
            terca: false,
            quarta: false,
            quinta: false,
            sexta: false,
            sabado: false,
            hora_inicio_fmt: null,
            hora_fim_fmt: null,
            count: 0,
            idInput: [],
            arrDadosUnidadeFederacao: [],
            arrValues: [],
            error_cep: '',
            error_nome: '',
            error_contato: '',
            erroLink: '',
            codModal: '',
            open: null,
            isLoadingEdit: true,
            isLoading: false,
            loading: false,
            redirect: false,
            alert: '',
            successful: '',
            msg_error_func: '',
            error_func: false,
            error_data_incio: '',
            error_data_fim: '',
        };
    }

    componentDidMount() {
        this.getAllUfs();

        if (this.state.cod_ponto_coleta != null) {
            const data = Promise.resolve(ColetaSeletivaService.getDadosPontoColeta(this.state.cod_ponto_coleta));
            data.then((v) => {
                this.populateForm(v[0]);
            });
        }else{
            this.setState({isLoadingEdit: false});
        }
    }

    populateForm(dados) {
        dados.isLoadingEdit = false;
        dados.str_contato = telefoneMask(dados.str_contato);
        dados.num_cep = cepMaks(dados.num_cep);

        if(dados.arr_dia_funcionamento !== null){
            dados.arr_dia_funcionamento.map((v, i) => (
              this.state.idInput[i] = i,
                this.state.arrValues[i] = v.str,
                this.setState({count: i + 1})
            ))
        }

        this.setState({isLoadingEdit: false});
        this.setState(dados);
    }

    handleClose(e) {
        this.setState({
            open: false,
        });
    }

    handleConfirmaExclusao(e) {
        this.setState({
            open: false,
        });
        if (this.state.open) {
            this.removeFuncionamento(this.state.codModal);
        }
    };

    onClickExcluir(id) {
        this.setState({
            message: '',
            open: true,
            codModal: id,
        });
    }

    removeFuncionamento(id) {
        this.state.arrValues.splice(id, 1);
    }

    getAllUfs(valor) {
        fetch(API_URL + 'pontocoleta/lista-unidade-federacao', {
            method: 'GET',
        })
          .then((response) => response.json())
          .then((responseJson) => {
              this.setState({
                  arrDadosUnidadeFederacao: responseJson,
              });
          });
    }

    onChangeCodPontoColeta(e) {
        this.setState({
            cod_ponto_coleta: e.target.value,
        });
    }

    onChangeCodUnidadeFederacao(e) {
        this.setState({
            cod_unidade_federacao: e.target.value,
        });
    }

    onChangeNomePontoColeta(e) {
        this.setState({
            str_nome_ponto_coleta: e.target.value,
            error_nome: '',
        });
    }

    onBlurNome = async (e) => {

        if (e.target.value.length < 1) {
            return
        }
        const nomeSelecionado = await ColetaSeletivaService.getPontoColetaByNome({strNomePontoColeta : e.target.value , flgExcluido : 'N'});

        if (nomeSelecionado.message != null) {

            this.setState({error_nome : nomeSelecionado.message});

            return;
        }

        }

    onChangeNumCep(e) {
        this.setState({
            num_cep: e.target.value.replace(/[^\d\s-/]/g, '') ? cepMaks(e.target.value) : '',
            error_cep: '',
        });

        const {value} = e.target;
        const cep = value?.replace(/[^0-9]/g, '');

        if (cep?.length !== 8) {
            return;
        }

        const url = `https://viacep.com.br/ws/${cep}/json/`;

        this.onChangeCheckLinkOnline(url, this.setState({erroLink: true}), this.setState({erroLink: false}));

        if (!this.state.erroLink) {
            fetch(url)
              .then((res) => res.json())
              .then((data) => {
                  if (data.erro) {
                      this.setState({
                          cod_unidade_federacao: '',
                          str_municipio: '',
                          str_bairro: '',
                          str_logradouro: '',
                          error_cep: 'CEP não encontrado.',
                      });
                  } else {
                      this.setState({
                          cod_unidade_federacao: data.uf,
                          str_municipio: data.localidade,
                          str_bairro: data.bairro,
                          str_logradouro: data.logradouro,
                      });
                  }
              });
        } else {
            this.setState({
                error_cep: 'Serviço temporariamente indisponível, por favor, tente novamente em alguns instantes.',
            });
        }
    }

    onBlurCep(e){
        let {value} = e.target;

        let cepClear = value?.replace(/[^0-9]/g, '');

        fetch(API_URL + 'pontocoleta/verifica-cep', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({numCep: cepClear, flgExcluido: 'N'}),
        }).then((res) => res.json())
          .then((res) => {
              if(res.message == 'CEP já cadastrado na base de dados.')
              {
                this.setState({
                    num_cep: '',
                    cod_unidade_federacao: '',
                    str_municipio: '',
                    str_bairro: '',
                    str_logradouro: '',
                    error_cep: res.message,
                });
              }
          });
    }

    onChangeStrLogradouro(e) {
        this.setState({
            str_logradouro: e.target.value,
        });
    }

    onChangeNumEndereco(e) {
        this.setState({
            num_endereco: e.target.value,
        });
    }

    onChangeStrBairro(e) {
        this.setState({
            str_bairro: e.target.value,
        });
    }

    onChangeStrMunicipio(e) {
        this.setState({
            str_municipio: e.target.value,
        });
    }

    onChangeStrContato(e) {
        this.setState({
            str_contato: telefoneMask(e.target.value),
            error_contato: ''
        });
    }

    onChangeStrLink(e) {
        this.setState({
            str_link: e.target.value,
        });
    }

    onChangeCheckLinkOnline(url, error, ok) {
        try {
            var scriptElem = document.createElement('script');
            scriptElem.type = 'text/javascript';
            scriptElem.onerror = error;
            scriptElem.onload = ok;
            scriptElem.src = url;
            document.getElementsByTagName('body')[0].appendChild(scriptElem);
        } catch (err) {
            error(err);
        }
    }

    onChangeHoraInicio(data, value) {

        this.setState({
            hora_inicio: null,
        });

        if (value && value.search('_') < 0) {
            if (this.validaHorario(value)) {
                let dataHoje = new Date();

                dataHoje.setHours(parseInt(value.split(':')[0]));
                dataHoje.setMinutes(parseInt(value.split(':')[1]));

                this.setState({
                    hora_inicio: dataHoje,
                    hora_inicio_fmt: value.split(':')[0] + ':' + value.split(':')[1],
                    error_data_incio: '',
                });
            }else{
                this.setState({error_data_incio: 'Data Inválida'})
            }
        }
    }

    onChangeHoraFim(data, value) {

        this.setState({
            hora_fim: null,
        });

        if (value && value.search('_') < 0) {
            if (this.validaHorario(value)) {
                let dataHoje = new Date();

                dataHoje.setHours(parseInt(value.split(':')[0]));
                dataHoje.setMinutes(parseInt(value.split(':')[1]));

                this.setState({
                    hora_fim: dataHoje,
                    hora_fim_fmt: value.split(':')[0] + ':' + value.split(':')[1],
                    error_data_fim: '',
                });
            }else{
                this.setState({error_data_fim: 'Data Inválida'})
            }
        }
    }

    validaHorario(hora) {
        if (!hora) {
            return true;
        }
        if (parseInt(hora.split(':')[0]) > 23 || parseInt(hora.split(':')[0]) < 0) {
            return false;
        }
        if (parseInt(hora.split(':')[1]) > 59 || parseInt(hora.split(':')[1]) < 0) {
            return false;
        }
        return true;
    }

    onClickSegunda(value){
        value = value == true ? false : true

        this.setState({segunda: value});
    }

    onClickTerca(value){
        value = value == true ? false : true

        this.setState({terca: value});
    }

    onClickQuarta(value){
        value = value == true ? false : true

        this.setState({quarta: value});
    }

    onClickQuinta(value){
        value = value == true ? false : true

        this.setState({quinta: value});
    }

    onClickSexta(value){
        value = value == true ? false : true

        this.setState({sexta: value});
    }

    onClickSabado(value){
        value = value == true ? false : true

        this.setState({sabado: value});
    }

    onClickDomingo(value){
        value = value == true ? false : true

        this.setState({domingo: value});
    }

    addInputButton = () => {
        let inicio = this.state.hora_inicio_fmt;
        let fim = this.state.hora_fim_fmt;
        let contador = this.state.count;
        let arrDias = [];

        if (this.state.segunda) {
            arrDias[0] = 'Segunda';
        }else{
            arrDias.splice(0, 1);
        }

        if (this.state.terca) {
            arrDias[1] = 'Terça';
        }else{
            arrDias.splice(1, 1);
        }

        if (this.state.quarta) {
            arrDias[2] = 'Quarta';
        }else{
            arrDias.splice(2, 1);
        }

        if (this.state.quinta) {
            arrDias[3] = 'Quinta';
        }else{
            arrDias.splice(3, 1);
        }

        if (this.state.sexta) {
            arrDias[4] = 'Sexta';
        }else{
            arrDias.splice(4, 1);
        }

        if (this.state.sabado) {
            arrDias[5] = 'Sábado';
        }else{
            arrDias.splice(5, 1);
        }

        if (this.state.domingo) {
            arrDias[6] = 'Domingo';
        }else{
            arrDias.splice(6, 1);
        }

        if (!this.state.segunda && !this.state.terca && !this.state.quarta &&
          !this.state.quinta && !this.state.sexta && !this.state.sabado &&
          !this.state.domingo) {

            this.setState({
                msg_error_func: 'Escolha ao menos um dia da semana',
                error_func: true,
            });
            return;
        }

        if (inicio == null || fim == null) {
            this.setState({
                msg_error_func: 'Harário não pode ficar em branco',
                error_func: true,
            });
            return;
        }

        if (fim < inicio) {
            this.setState({
                msg_error_func: 'Hora Fim não pode ser menor que Hora Início',
                error_func: true,
            });
            return;
        }

        this.state.idInput[contador] = contador;
        this.state.arrValues[contador] = montaStringDias(arrDias) + inicio + ' às ' + fim;

        contador = contador + 1;
        this.setState({count: contador});
        this.limpaInput();
    }

    limpaInput() {
        this.setState({
            hora_inicio: null,
            hora_fim: null,
            hora_inicio_fmt: null,
            hora_fim_fmt: null,
            domingo: false,
            segunda: false,
            terca: false,
            quarta: false,
            quinta: false,
            sexta: false,
            sabado: false,
            msg_error_func: '',
            error_func: false,
        });
    }

    handleRegister(e) {
        e.preventDefault();

        if (this.state.hora_inicio !== null && this.state.hora_fim !== null && this.state.arrValues !== '') {
            this.setState({
                msg_error_func: 'Antes de prosseguir, clique em [+] para adicionar os horários de funcionamento.',
                error_func: true,
            });
            return false;
        }

        if (this.state.hora_inicio !== null && this.state.hora_fim !== null && this.state.arrValues == '') {
            this.setState({
                msg_error_func: 'Antes de prosseguir, clique em [+] para adicionar os horários de funcionamento.',
                error_func: true,
            });
            return false;
        }

        if (this.state.hora_inicio == null && this.state.hora_fim == null) {
            this.setState({
                msg_error_func: '',
                error_func: false,
            });
        }

        if(this.state.error_cep || this.state.error_nome){
            return false;
        }

        if(this.state.str_contato !== null && this.state.str_contato.length !== 0  && (this.state.str_contato.replace(/[^0-9]/g, '').length !== 11 && this.state.str_contato.replace(/[^0-9]/g, '').length !== 10)){
            this.setState({
                error_contato: 'Telefone inválido.',
            });
            return false;
          }else{
            this.setState({
                error_contato: '',
            });
          }

        this.setState({
            successful: '',
            isLoading: true,
        });

        this.form.validateAll();
        this.props
          .dispatch(
            pontoColeta(
              this.state.cod_ponto_coleta,
              this.state.cod_unidade_federacao,
              this.state.str_nome_ponto_coleta,
              this.state.num_cep?.replace(/[^0-9]/g, ''),
              this.state.str_logradouro,
              this.state.num_endereco,
              this.state.str_bairro,
              this.state.str_municipio,
              this.state.str_contato?.replace(/[^0-9]/g, ''),
              this.state.str_link,
              this.state.json_dia_funcionamento = this.state.arrValues,
            ),
          )
          .then((e) => {
              this.setState({
                  successful: true,
              });
              this.timeout = setTimeout(() => {
                  this.setState({
                      redirect: true,
                  });
              }, 3000);
          })
          .catch(() => {
              this.setState({
                  successful: false,
                  redirect: false,
                  isLoading: false,
              });
          });
    }

    render() {
        const {successful, isLoggedIn, message, classes} = this.props;

        if (this.state.redirect) {
            this.onClickVoltar();
        }

        const titulo = this.state.cod_ponto_coleta != null ? 'Editar Pontos de Coleta' : 'Cadastrar Pontos de Coleta';
        const labelButon = this.state.cod_ponto_coleta == null ? 'Cadastrar' : 'Salvar';

        return (
          <>
              {this.state.isLoadingEdit === true &&
              <Grid container spacing={2} style={{marginTop: 25}}>
                  <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
                      {mensagemPadraoCarregando}
                  </Grid>
              </Grid>
              }
              {this.state.isLoadingEdit === false && <div className={classes.root}>
                  <Grid container className={classes.conteudoFundoBranco}>
                      <Grid item xs={12} className={classes.formCadastroTitulo}>
                          <FaMap className={classes.iconeTitulo}/>
                          <span className={classes.tituloPadrao}> {titulo}</span>
                      </Grid>
                      <Grid container>
                          <Grid item xs={12} className={classes.content}>
                              <Form onSubmit={this.handleRegister} ref={(c) => {
                                  this.form = c;
                              }} className={classes.formCadastro}>
                                  {this.state.successful == false && this.state.successful !== '' && message != '' && (
                                    <div className="row">
                                        <div className="col mb-2">
                                            <Alert severity={'error'}>{message}</Alert>
                                        </div>
                                    </div>
                                  )}
                                  {this.state.successful == true && this.state.successful !== '' && message != '' && (
                                    <div className="row">
                                        <div className="col mb-2">
                                            <Alert severity={'success'}>{message}</Alert>
                                        </div>
                                    </div>
                                  )}
                                  <SubTitulo>Dados do Ponto de Coleta</SubTitulo>
                                  <Grid container spacing={3}>
                                      <input
                                        type="hidden"
                                        id="cod_ponto_coleta"
                                        name="cod_ponto_coleta"
                                        value={this.state.cod_ponto_coleta}
                                        onChange={this.onChangeCodPontoColeta}
                                      />

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={10}>
                                          <TextField
                                            error={this.state.error_nome != undefined && this.state.error_nome? true : false }
                                            helperText={this.state.error_nome != undefined && this.state.error_nome? this.state.error_nome : null }
                                            required
                                            id="str_nome_ponto_coleta"
                                            name="str_nome_ponto_coleta"
                                            label="Nome"
                                            inputProps={{ maxLength: 150 }}
                                            fullWidth
                                            variant="outlined"
                                            value={this.state.str_nome_ponto_coleta}
                                            onChange={this.onChangeNomePontoColeta}
                                            onBlur={this.onBlurNome}
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={5} style={{ minWidth: 216 }}>
                                          <TextField
                                            error={this.state.error_cep.length > 0}
                                            helperText={this.state.error_cep !== '' ? this.state.error_cep : ' '}
                                            required
                                            id="num_cep"
                                            name="num_cep"
                                            label="CEP"
                                            type="numeric"
                                            fullWidth
                                            variant="outlined"
                                            inputProps={{maxLength: 10}}
                                            value={this.state.num_cep}
                                            onChange={this.onChangeNumCep}
                                            onBlur={this.onBlurCep}
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={5} style={{ minWidth: 216 }}>
                                          <FormControl variant="outlined"
                                                       className={classes.formControl} fullWidth>
                                              <InputLabel
                                                id="demo-simple-select-outlined-label">Estado</InputLabel>
                                              <Select
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                label="Estado"
                                                fullWidth
                                                name="cod_unidade_federacao"
                                                value={this.state.cod_unidade_federacao}
                                                onChange={this.onChangeCodUnidadeFederacao}
                                              >
                                                  <MenuItem value="">
                                                      <em>Selecione o Estado</em>
                                                  </MenuItem>
                                                  {
                                                      Object.values(this.state.arrDadosUnidadeFederacao).map(
                                                        (row, index) =>
                                                          <MenuItem
                                                            value={row.str_sigla_uf}
                                                            key={row.uf}>{row.str_nome_uf}
                                                          </MenuItem>,
                                                      )
                                                  }
                                              </Select>
                                          </FormControl>
                                      </Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={5} style={{ minWidth: 216 }}>
                                          <TextField
                                            required
                                            id="str_logradouro"
                                            name="str_logradouro"
                                            label="Logradouro"
                                            inputProps={{ maxLength: 150 }}
                                            fullWidth
                                            variant="outlined"
                                            value={this.state.str_logradouro}
                                            onChange={this.onChangeStrLogradouro}
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={5} style={{ minWidth: 216 }}>
                                          <TextField
                                            required
                                            id="str_bairro"
                                            name="str_bairro"
                                            label="Bairro"
                                            inputProps={{ maxLength: 150 }}
                                            fullWidth
                                            variant="outlined"
                                            value={this.state.str_bairro}
                                            onChange={this.onChangeStrBairro}
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={5} style={{ minWidth: 216 }}>
                                          <TextField
                                            required
                                            id="str_municipio"
                                            name="str_municipio"
                                            label="Município"
                                            inputProps={{ maxLength: 200 }}
                                            fullWidth
                                            autoComplete="shipping address-level2"
                                            variant="outlined"
                                            value={this.state.str_municipio}
                                            onChange={this.onChangeStrMunicipio}
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={5} style={{ minWidth: 216 }}>
                                          <TextField
                                            required
                                            id="num_endereco"
                                            name="num_endereco"
                                            type="number"
                                            label="Número"
                                            onInput = {(e) =>{
                                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                              }}
                                              inputProps={{
                                                max: 9999999999,
                                                min: 0
                                              }}
                                            fullWidth
                                            variant="outlined"
                                            value={this.state.num_endereco}
                                            onChange={this.onChangeNumEndereco}
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={5} style={{ minWidth: 216 }}>
                                          <TextField
                                            error={this.state.error_contato.length > 0}
                                            helperText={this.state.error_contato !== '' ? this.state.error_contato : ' '}
                                            required
                                            id="str_contato"
                                            name="str_contato"
                                            label="Contato"
                                            fullWidth
                                            autoComplete="shipping address-line2"
                                            variant="outlined"
                                            value={this.state.str_contato}
                                            onChange={this.onChangeStrContato}
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={6}></Grid>

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={10}>
                                          <TextField
                                            id="str_link"
                                            type="url"
                                            name="str_link"
                                            label="Link"
                                            inputProps={{ maxLength: 500 }}
                                            fullWidth
                                            variant="outlined"
                                            value={this.state.str_link}
                                            onChange={this.onChangeStrLink}
                                          />
                                      </Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <SubTitulo>
                                          <div style={{textAlign: 'center'}}>
                                              Funcionamento
                                          </div>
                                      </SubTitulo>
                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={10}>
                                          {this.state.error_func && this.state.msg_error_func !== '' && (
                                            <div className="row">
                                                <div className="col mb-2">
                                                    <Alert
                                                      severity={'error'}>{this.state.msg_error_func}</Alert>
                                                </div>
                                            </div>
                                          )}
                                      </Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={10}>
                                          <Button id="S" hidden={false} variant="contained"
                                                  color={this.state.segunda === true ? 'primary' : 'default'}
                                                  onClick={() => {this.onClickSegunda(this.state.segunda);}}
                                          >SEG</Button> {' '}

                                          <Button id="S" hidden={false} variant="contained"
                                                  color={this.state.terca === true ? 'primary' : 'default'}
                                                  onClick={() => {this.onClickTerca(this.state.terca);}}
                                          >TER</Button> {' '}

                                          <Button id="S" hidden={false} variant="contained"
                                                  color={this.state.quarta === true ? 'primary' : 'default'}
                                                  onClick={() => {this.onClickQuarta(this.state.quarta);}}
                                          >QUA</Button> {' '}

                                          <Button id="S" hidden={false} variant="contained"
                                                  color={this.state.quinta === true ? 'primary' : 'default'}
                                                  onClick={() => {this.onClickQuinta(this.state.quinta);}}
                                          >QUI</Button> {' '}

                                          <Button id="S" hidden={false} variant="contained"
                                                  color={this.state.sexta === true ? 'primary' : 'default'}
                                                  onClick={() => {this.onClickSexta(this.state.sexta);}}
                                          >SEX</Button> {' '}

                                          <Button id="S" hidden={false} variant="contained"
                                                  color={this.state.sabado === true ? 'primary' : 'default'}
                                                  onClick={() => {this.onClickSabado(this.state.sabado);}}
                                          >SAB</Button> {' '}

                                          <Button id="S" hidden={false} variant="contained"
                                                  color={this.state.domingo === true ? 'primary' : 'default'}
                                                  onClick={() => {this.onClickDomingo(this.state.domingo);}}
                                          >DOM</Button> {' '}
                                      </Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={4} style={{ minWidth: 175 }}>
                                          <MuiPickersUtilsProvider utils={DateFnsUtils}  locale={ptBrLocale}>
                                              <KeyboardTimePicker
                                                error={this.state.error_data_incio.length > 0}
                                                helperText={this.state.error_data_incio !== '' ? this.state.error_data_incio : ' '}
                                                autoComplete="off"
                                                ampm={false}
                                                name="hora_inicio"
                                                id="hora_inicio"
                                                fullWidth
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="HH:mm"
                                                label="Hora Início"
                                                invalidDateMessage="Horário inválido"
                                                value={this.state.hora_inicio}
                                                onChange={this.onChangeHoraInicio}
                                                InputAdornmentProps={{position: 'end'}}
                                              />
                                          </MuiPickersUtilsProvider>
                                      </Grid>
                                      <Grid item xs={12} sm={1}style={{ minWidth: 108 }}>
                                          <div style={{margin: '15px 0 0 36px'}}>
                                              <strong>às</strong>
                                          </div>
                                      </Grid>
                                      <Grid item xs={12} sm={4} style={{ minWidth: 175 }}>
                                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                                              <KeyboardTimePicker
                                                error={this.state.error_data_fim.length > 0}
                                                helperText={this.state.error_data_fim !== '' ? this.state.error_data_fim : ' '}
                                                autoComplete="off"
                                                ampm={false}
                                                name="hora_fim"
                                                id="hora_fim"
                                                fullWidth
                                                variant="inline"
                                                inputVariant="outlined"
                                                format="HH:mm"
                                                label="Hora Fim"
                                                invalidDateMessage="Horário inválido"
                                                value={this.state.hora_fim}
                                                onChange={this.onChangeHoraFim}
                                                InputAdornmentProps={{position: 'end'}}
                                              />
                                          </MuiPickersUtilsProvider>
                                      </Grid>
                                      <Grid item xs={12} sm={1}>
                                          <IconButton
                                            color="primary"
                                            aria-label="add to shopping cart"
                                            onClick={() => {this.addInputButton()}}
                                          >
                                              <FaPlusSquare/>
                                          </IconButton>
                                      </Grid>
                                      <Grid item xs={12} sm={1}></Grid>


                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={10}>
                                          {this.state.arrValues != '' &&
                                          <>
                                              {
                                                  this.state.arrValues.map((button, i) => (
                                                    <Button
                                                      title='Remover'
                                                      type='button'
                                                      id={this.state.idInput[i]}
                                                      onClick={() => {this.onClickExcluir(this.state.idInput[i])}}
                                                      className='button-ponto-coleta'
                                                    >
                                                        {button}
                                                    </Button>
                                                  ))
                                              }
                                          </>
                                          }
                                      </Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <Grid container spacing={3}>
                                          <Grid item xs={12}>
                                              <div style={{textAlign: 'center'}}>
                                                  {this.state.isLoading === false &&
                                                  <>
                                                      <Button
                                                          type="submit"
                                                          variant="outlined"
                                                          className={classes.formButton}> {labelButon}
                                                      </Button>&nbsp;
                                                      <Button
                                                          variant="text"
                                                          className={classes.formButton}
                                                          onClick={this.onClickVoltar}>Cancelar </Button>
                                                  </>
                                                  }
                                                  {this.state.isLoading === true &&
                                                  <>
                                                      <Button variant="outlined" disabled
                                                              style={{width: 100}}>
                                                          <CircularProgress style={{
                                                              width: 20,
                                                              height: 20,
                                                              marginRight: 15,
                                                          }}/>
                                                      </Button> &nbsp;
                                                      <Button onClick={this.onClickVoltar}>Cancelar </Button>
                                                  </>
                                                  }
                                              </div>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              </Form>
                          </Grid>
                      </Grid>
                  </Grid>
              </div>}
              <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.modalExclusao}
              >
                  <DialogTitle id="alert-dialog-title"
                               className={classes.titleModal}>{'Aviso!'}</DialogTitle>
                  <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                          Deseja Remover este horário?
                      </DialogContentText>
                  </DialogContent>
                  <DialogActions className={classes.actionsModal}>
                      <Button onClick={this.handleConfirmaExclusao} variant="outlined">
                          Sim
                      </Button>
                      <Button onClick={this.handleClose}>
                          Não
                      </Button>
                  </DialogActions>
              </Dialog>
          </>
        );
    }
}

/**
 * Solução temporaria
 */
function montaStringDias(arrDias){

    let arr = [];
    let teste = [];

    //criar um array sem os valores nulos
    for(let i = 0; i < arrDias.length; i = i + 1 ) {
        if(arrDias[i] != undefined){
            arr.push(i);
            teste.push(i);
        }
    }

    //Pega o primeiro e ultimo valo do array
    let inicio =  teste.shift()
    let fim = teste.pop();

    let arrSequencia = [];
    //cria uma sequencia do valores escolhidos para fazer uma comparação
    for(let i = inicio; i <= fim; i = i + 1 ) {
        arrSequencia.push(i);
    }

    let sequencia = true;
    //verifica se os buttons escolhidos estão em sequencia ou não
    arrSequencia.map(function(v, i) {
        if(v != arr[i] ){
            sequencia = false;
            return;
        }
    })

    let dias = '';
    if(sequencia == false){
        arr.map(function(v, i) {
            dias += arrDias[v] + ' - '
        })
        dias = dias.slice(0, -3);
    }else{
        if(arrDias[inicio] == undefined){
            dias += arrDias[fim];
        }else if(arrDias[fim] == undefined){
            dias += arrDias[inicio];
        }else{
            dias += arrDias[inicio] + ' - ' + arrDias[fim];
        }
    }

    return dias + ': ';
}

function mapStateToProps(state) {
    const {isLoggedIn} = state.auth;
    const {message} = state.message;

    return {
        isLoggedIn,
        message,
    };

}

export default connect(mapStateToProps)(withStyles(styles)(PontoColeta));
