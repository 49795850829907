import axios from 'axios';
import authHeader from './auth-header';
import {history} from '../helpers/history';
import {salvarPesquisa} from '../actions/RedeSocial';
import trataErroApi from './trata-erro-api';

const API_URL = process.env.REACT_APP_API_URL;
const authenticateTimeout = '';
const sesion = localStorage.getItem('user');

class RedeSocialService {

  salvarPesquisa(
    cod_pesquisa_rede_social,
    str_nome_pesquisa,
    disabledInsta,
    disabledTwiter,
    arrHashtag,
    arrPalavras,
    salvar_json,
  ) {
    return axios.post(API_URL + 'redeSocial/salvar-pesquisa', {
        cod_pesquisa_rede_social,
        str_nome_pesquisa,
        disabledInsta,
        disabledTwiter,
        arrHashtag,
        arrPalavras,
        salvar_json,
      },
      {headers: authHeader()},
    );
  }

  async getPesquisa(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "redeSocial/get-pesquisa", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error.response.data);
      });
  }

  async getDadosMetricas(parans = {}) {
    const postParams = parans;
    return axios
      .post(API_URL + "redeSocial/get-dados-metricas", postParams, {headers: authHeader()})
      // .then((response) => {
      //   return response.data;
      // }).catch(error => {
      //   console.log(error.response.data);
      //   return error.response.data;
      // });
  }

  async getDadosPesquisa(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "redeSocial/get-dados-pesquisa", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error.response.data);
      });
  }
  async deletePesquisa(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "redeSocial/delete-pesquisa", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getCombohashtags(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "redeSocial/get-combo-hashtags", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error.response.data);
      });
  }

  async getMetricasConteudo(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "redeSocial/get-metricas-conteudo", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error.response.data);
      });
  }

  async getDadosNuvemPalavras(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "redeSocial/get-dados-nuvem-palavras", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error.response.data);
      });
  }

  async instagram(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "redeSocial/instagram", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error.response.data);
      });
  }

  async twitter(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "redeSocial/twitter", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error.response.data);
      });
  }

  async getPesquisaCuston(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "redeSocial/get-pesquisa-custon", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error.response.data);
      });
  }

  async criarNuvemPalavras(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "redeSocial/criar-nuvem-palavras", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        console.log(error.response.data);
      });
  }

  async exportDados(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "redeSocial/export", postParams, {headers: authHeader()})
      .then((response) => {
        if (response.data.base64) {
          const linkSource = response.data.base64;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = response.data.fileName;
          downloadLink.click();
        } else {
          alert('Erro ao recuperar o arquivo');
        }
      }).catch(error => {
        console.log(error.response.data);
      });
  }
}

export default new RedeSocialService();
