import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid, TextField} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Form from 'react-validation/build/form';
import {salvarPesquisa} from '../../../actions/RedeSocial';
import {Alert} from '@material-ui/lab';
import {
    FaTimesCircle,
    FaPlusSquare,
    FaInstagram,
    FaTwitterSquare,
} from 'react-icons/fa';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';


const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    formButton: theme.pagina.formButton,
    formCadastro: {
        margin: '15px',
    },
    link: {
        color: '#039ED5',
    },
    tag: {
        borderRadius: 7,
        color: '#FFFFFF',
        padding: 5,
        paddingLeft: 7,
        backgroundColor: '#039ED5',
        fontSize: 13,
        margin: 4,
        display: 'inline-flex',
        height: '28px',
        alignItems: 'center',
        justifyContent: 'center'
    },
});

const mensagemPadraoCarregando =
    (
        <div style={{textAlign: 'center', padding: 15, margin: '0 auto'}}>
            <CircularProgress
                style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </div>
    );

class Pesquisar extends Component {
    constructor(props) {
        super(props);
        this.handleRegister = this.handleRegister.bind(this);
        this.onClickVoltar = props.onVoltar;

        this.handleClose = this.handleClose.bind(this);
        this.handleConfirmaExclusao = this.handleConfirmaExclusao.bind(this);

        this.state = {
            user: localStorage.getItem('user'),
            cod_pesquisa_rede_social: props.arrDataPesquisa.cod_pesquisa_rede_social ?? null,
            str_nome_pesquisa: props.arrDataPesquisa.str_nome_pesquisa ?? null,
            salvar_json: true,

            open: false,
            codModalHashtag: null,
            str_hashtag: null,
            str_palavra: null,

            arrHashtag: [],
            idHashtag: [],
            countHashtag: 0,

            arrPalavras: [],
            idPalavras: [],
            countPalavras: 0,

            disabledInsta: props.arrDataPesquisa.flg_tipo_rede_social && props.arrDataPesquisa.flg_tipo_rede_social == 'T' ? false : true,
            disabledTwiter: props.arrDataPesquisa.flg_tipo_rede_social && props.arrDataPesquisa.flg_tipo_rede_social == 'I' ? false : true,

            isLoadingEdit: true,
            isLoading: false,
            alert: '',
            loading: false,
            successful: '',
            redirect: false,
            tpExclusao: null,
            mensagemInf: 'Aguarde um momento, estamos realizando a busca dos dados...',
            errosCompos: {},
        };
    }

    componentDidMount() {

        if (this.props.arrDataPesquisa.cod_pesquisa_rede_social != null) {
            this.onUpdateModal(this.props.arrDataPesquisa);
        } else {
            this.setState({isLoadingEdit: false});
        }
        // on mount component
    }

    componentDidUpdate() {
        // on update component
    }

    onUpdateModal = (data) => {
        if (data.arrTags.length > 0) {
            let count;
            data.arrTags.map((v, i) => (
                this.state.idHashtag[i] = i,
                    this.state.arrHashtag[i] = '#' + v.str_hashtag,
                    count = i
            ));
            this.setState({countHashtag: count + 1});
        }

        if (data.arrPalavras.length > 0) {
            let count;
            data.arrPalavras.map((v, i) => (
                this.state.idPalavras[i] = i,
                    this.state.arrPalavras[i] = v.str_palavra_bloqueada,
                    count = i
            ));
            this.setState({countPalavras: count + 1});
        }

        this.setState({isLoadingEdit: false});
    };

    onChangeHashtag = (e) => {
        this.setState({
            str_hashtag: e.target.value,
            errosCompos: {}
        });
    };

    onChangeStrPlavra = (e) => {
        this.setState({
            str_palavra: e.target.value,
            errosCompos: {}
        });
    };

    onChangestrNomePesquisa = (e) => {
        this.setState({
            str_nome_pesquisa: e.target.value,
        });
    };

    handleRegister(e) {
        e.preventDefault();

        this.setState({
            successful: '',
            isLoading: true,
        });

        this.form.validateAll();
        this.props
            .dispatch(
                salvarPesquisa(
                    this.state.cod_pesquisa_rede_social,
                    this.state.str_nome_pesquisa,
                    this.state.disabledInsta,
                    this.state.disabledTwiter,
                    this.state.arrHashtag,
                    this.state.arrPalavras,
                    this.state.salvar_json,
                ),
            )
            .then(() => {
                // this.setState({
                //     successful: true,
                //     redirect: false,
                // });
                this.timeout = setTimeout(() => {
                  this.setState({
                      successful: true,
                      redirect: false,
                  });
                    window.location.href = window.location;
                  //this.onChangeModal(this.state.str_nome_pesquisa);
                }, 3000);
            })
            .catch(() => {
                this.setState({
                    successful: false,
                    redirect: false,
                    isLoading: false,
                });
            });
    }

    handleClose(e) {
        this.setState({
            open: false,
        });
    }

    handleConfirmaExclusao(e) {
        this.setState({
            open: false,
        });
        if (this.state.open) {
            this.removeHashtag(this.state.codModalHashtag, this.state.tpExclusao);
        }
    };

    onClickExcluir(id, tipo) {
        this.setState({
            message: '',
            open: true,
            codModalHashtag: id,
            tpExclusao: tipo,
        });
    }

    removeHashtag(id, tipo) {
        if (tipo === 'H') {
            this.state.arrHashtag.splice(id, 1);
        } else {
            this.state.arrPalavras.splice(id, 1);
        }
    }

    limpaInput = () => {
        this.setState({
            str_hashtag: null,
            str_palavra: null,
        });

        document.getElementById('str_hashtag').value = null;
        document.getElementById('str_palavra').value = null;
    };

    addHasTag = () => {
        let contador = this.state.countHashtag;
        let hashtag = this.state.str_hashtag;

        if (hashtag == null) {
            this.setState({errosCompos: {hashtag: 'Campo Obrigatório.'}});
            return;
        }

        this.state.idHashtag[contador] = contador;

        hashtag = hashtag.replace('#', '');
        this.state.arrHashtag[contador] = '#' + hashtag.toLowerCase();

        contador = contador + 1;
        this.setState({
            countHashtag: contador,
        });

        this.limpaInput();
    };


    addClearPalavras = () => {
        let contador = this.state.countPalavras;
        let palavra = this.state.str_palavra;

        if (palavra == null) {
            this.setState({errosCompos: {palavra: 'Campo Obrigatório.'}});
            return;
        }

        this.state.idPalavras[contador] = contador;
        this.state.arrPalavras[contador] = palavra;

        contador = contador + 1;
        this.setState({
            countPalavras: contador,
        });

        this.limpaInput();
    };

    disabledInstagran = () => {
        this.setState({
            disabledInsta: this.state.disabledInsta ? false : true,
        });
    };

    disabledTwitter = () => {
        this.setState({
            disabledTwiter: this.state.disabledTwiter ? false : true,
        });
    };

    render() {
        const {successful, isLoggedIn, message, classes} = this.props;

        return (
            <>
                {this.state.isLoadingEdit === true &&
                    <Grid container spacing={2} style={{marginTop: 25}}>
                        <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
                            {mensagemPadraoCarregando}
                        </Grid>
                    </Grid>
                }
                {
                    this.state.isLoadingEdit === false &&
                    <Form onSubmit={this.handleRegister} ref={(c) => {
                        this.form = c;
                    }} className={classes.formCadastro}>
                        {/* {this.state.isLoading === true && */}
                        {/*     <div className="row"> */}
                        {/*         <div className="col mb-2"> */}
                        {/*             <Alert severity={'info'}>{this.state.mensagemInf}</Alert> */}
                        {/*         </div> */}
                        {/*     </div> */}
                        {/* } */}
                        {this.state.successful == false && this.state.successful !== '' && (
                            <div className="row">
                                <div className="col mb-2">
                                    <Alert severity={'error'}>{message}</Alert>
                                </div>
                            </div>
                        )}
                        {this.state.successful == true && this.state.successful !== '' && (
                            <div className="row">
                                <div className="col mb-2">
                                    <Alert severity={'success'}>{message}</Alert>
                                </div>
                            </div>
                        )}
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <label htmlFor="">
                                    <strong>Dê um nome para essa pesquisa</strong>
                                </label>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="str_nome_pessoa"
                                    name="str_nome_pessoa"
                                    label="Digite um Nome"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="outlined"
                                    value={this.state.str_nome_pesquisa}
                                    onChange={this.onChangestrNomePesquisa}
                                    autoComplete="off"
                                    inputProps={{maxLength: 50}}
                                />
                            </Grid>
                            <hr></hr>

                            <Grid item xs={12}>
                                <label htmlFor="">
                                    <strong>Adicione uma nova hashtag</strong>
                                </label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    error={this.state.errosCompos.hashtag != undefined && this.state.errosCompos.hashtag ? true : false}
                                    helperText={this.state.errosCompos.hashtag != undefined && this.state.errosCompos.hashtag ? this.state.errosCompos.hashtag : null}
                                    id="str_hashtag"
                                    name="str_hashtag"
                                    label="Digite um Hashtag"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="outlined"
                                    value={this.state.str_hashtag}
                                    onChange={this.onChangeHashtag}
                                    autoComplete="off"
                                    inputProps={{maxLength: 50}}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton
                                    color="default"
                                    aria-label="add to shopping cart"
                                    onClick={() => {
                                    }}
                                    style={{color: '#039ED5'}}
                                >
                                    <FaPlusSquare onClick={() => {
                                        this.addHasTag();
                                    }}
                                        title="Adicionar"
                                    />
                                </IconButton>
                            </Grid>

                            <Grid item xs={10} style={{marginTop: 5, marginBottom: 2}}>
                                {this.state.arrHashtag != '' &&
                                    <>
                                        {
                                            this.state.arrHashtag.map((item, index) => (
                                                <span
                                                    className={classes.tag}>
                        {item}
                                                    <FaTimesCircle
                                                        id={this.state.idHashtag[index]}
                                                        onClick={() => {
                                                            this.onClickExcluir(this.state.idHashtag[index], 'H');
                                                        }}
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: '#FFFFFF',
                                                            marginLeft: 6,
                                                            marginRight: 3
                                                        }}
                                                    />
                      </span>
                                            ))
                                        }
                                    </>
                                }
                            </Grid>
                            <hr></hr>
                            <Grid item xs={12}>
                                <label htmlFor="">
                                    <strong>Escolha as redes sociais pesquisadas</strong>
                                </label>
                            </Grid>
                            <Grid item xs={10}>
                                <IconButton onClick={() => {
                                    this.disabledInstagran();
                                }}>
                                    <InstagramIcon style={{
                                        color: this.state.disabledInsta ? '#ED33AE' : '#D2D5D8',
                                        fontSize: 30
                                    }}/>
                                </IconButton>

                                <IconButton onClick={() => {
                                    this.disabledTwitter();
                                }}>
                                    <TwitterIcon style={{
                                        color: this.state.disabledTwiter ? '#00ACEE' : '#D2D5D8',
                                        fontSize: 30
                                    }}/>
                                </IconButton>
                            </Grid>


                            <hr></hr>
                            <Grid item xs={12}>
                                <label htmlFor="">
                                    <strong>Retire uma palavra da nuvem de palavras</strong>
                                </label>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    error={this.state.errosCompos.palavra != undefined && this.state.errosCompos.palavra ? true : false}
                                    helperText={this.state.errosCompos.palavra != undefined && this.state.errosCompos.palavra ? this.state.errosCompos.palavra : null}
                                    id="str_palavra"
                                    name="str_palavra"
                                    label="Digite uma Palavra"
                                    fullWidth
                                    autoComplete="given-name"
                                    variant="outlined"
                                    value={this.state.str_palavra}
                                    onChange={this.onChangeStrPlavra}
                                    autoComplete="off"
                                    inputProps={{maxLength: 50}}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton
                                    color="default"
                                    aria-label="add to shopping cart"
                                    onClick={() => {
                                        this.addClearPalavras();
                                    }}
                                    style={{color: '#039ED5'}}
                                    title="Adicionar"
                                >
                                    <FaPlusSquare/>
                                </IconButton>
                            </Grid>

                            <Grid item xs={10}>
                                {this.state.arrPalavras != '' &&
                                    <>
                                        {
                                            this.state.arrPalavras.map((item, index) => (
                                                <span
                                                    className={classes.tag}>
                        {item}
                                                    <FaTimesCircle
                                                        id={this.state.idPalavras[index]}
                                                        onClick={() => {
                                                            this.onClickExcluir(this.state.idPalavras[index], 'P');
                                                        }}
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: '#FFFFFF',
                                                            marginLeft: 6,
                                                            marginRight: 3
                                                        }}
                                                    />
                      </span>
                                            ))
                                        }
                                    </>
                                }
                            </Grid>

                        </Grid>
                        <hr></hr>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div style={{textAlign: 'right', margin: '4px 0px -19px 0px'}}>
                                    {this.state.isLoading === false &&
                                        <>
                                            <Button
                                                type="submit"
                                                className={classes.formButton}
                                                variant="outlined">Salvar
                                            </Button> &nbsp;
                                            <Button
                                                className={classes.formButton}
                                                variant="text"
                                                onClick={this.onClickVoltar}>Cancelar
                                            </Button>
                                        </>
                                    }
                                    {this.state.isLoading === true &&
                                        <>
                                            <Button variant="outlined" disabled
                                                    style={{width: 100}}>
                                                <CircularProgress style={{
                                                    width: 20,
                                                    height: 20,
                                                    marginRight: 15,
                                                }}/>
                                            </Button> &nbsp;
                                            <Button type="button">Cancelar </Button>
                                        </>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                }

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.modalExclusao}
                >
                    <DialogTitle id="alert-dialog-title"
                                 className={classes.titleModal}>{'Aviso!'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.tpExclusao === 'H' ? 'Deseja Remover esta Hashtag?' : 'Deseja Remover esta Palavra?'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions className={classes.actionsModal}>
                        <Button onClick={this.handleConfirmaExclusao} variant="outlined">
                            Sim
                        </Button>
                        <Button onClick={this.handleClose}>
                            Não
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

function mapStateToProps(state) {
    const {user} = state.auth;
    const {message} = state.message;

    return {user, message};
}

export default connect(mapStateToProps)(withStyles(styles)(Pesquisar));
