import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import userPerfilDefault from '../../../assets/images/user-perfil-default.png';
import { SubTitulo } from '../../../views/style/styles';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import {timestampFormatter} from '../../util';
import RedeSocialService from '../../../services/rede-social';
import {Alert} from '@material-ui/lab';

const useStyle = makeStyles((theme) => ({
  formButton: theme.pagina.formButton,
  buttonCadastro: theme.pagina.buttonCadastro,

  button: {
    color: '#000',
    backgroundColor: '#FFF',
    border: 'solid 1px #005b96',
    maxWidth: 100,
    height: '42px',
    marginTop: '25px',
    '&:hover': {
      color: '#005b96',
      backgroundColor: '#FFF',
      border: 'solid 1px #005b96',
    },
  },
  span: {
    '&. MuiFormControlLabel-label': {
      color: '#000',
    },
  },

  cardEquipe: {
    width: '100%',
    flex: '1 1 auto',
    margin: '5px 25px 25px 0',
    borderRadius: 1,
    backgroundColor: 'rgba(242, 242, 242, 1)',
    border: 'solid 1px rgba(228, 228, 228, 1)',
    color: '#000',
  },
}));

export default function Conteudo(props) {
  const classes = useStyle();

  let flgInstagram = true;
  let flgTwitter = true;

  if (props.arrPesquisa !== undefined) {
    switch (props.arrPesquisa.flg_tipo_rede_social) {
      case 'A':
        flgInstagram = true;
        flgTwitter = true;
        break;
      case 'I':
        flgInstagram = true;
        flgTwitter = false;
        break;
      case 'T':
        flgInstagram = false;
        flgTwitter = true;
        break;
    }
  }

  return (
    <>
    
      <Grid item xs={12}>
        <label htmlFor="">
          <p><strong>Informações gerais:</strong></p>
        </label>
      </Grid>

      <Grid item xs={12}>
        <Card className={classes.cardEquipe} key={1}>
          <CardContent>
            <table style={{ width: '100%', textAlign: 'center' }}>
              <tr>
                <td style={{ textAlign: 'left' }} >
                  &nbsp;<br />
                  <strong>Última pesquisa:</strong><br />
                  <strong>Período selecionado:</strong>
                </td>
                <td>
                  Quantidade de posts:<br />
                  {props.arrUltimaPesquisa.total??0}<br />
                  {props.arrPeriodoSelecionado.total??0}<br />
                </td>
                <td>
                  Quantidade por rede social:<br />
                  <Grid container >
                    <Grid item xs={2} ></Grid>

                    {flgTwitter && <>
                          <Grid item xs={2} style={{ textAlign: 'right' }} >
                            <TwitterIcon style={{ color: '#00ACEE', fontSize: 30 }} />
                          </Grid>
                          <Grid item xs={2} style={{ textAlign: 'left', paddingRight: 5 }} >
                            {props.arrUltimaPesquisa.twit??0}<br />
                            {props.arrPeriodoSelecionado.twit??0}<br />
                          </Grid>
                        </>
                    }

                    {flgInstagram && <>
                      <Grid item xs={2} style={{ textAlign: 'right' }} >
                        <InstagramIcon style={{ color: '#ED33AE', fontSize: 30 }} />
                      </Grid>
                      <Grid item xs={2} style={{ textAlign: 'left', paddingLeft: 5 }} >
                        {props.arrUltimaPesquisa.insta??0}<br />
                        {props.arrPeriodoSelecionado.insta??0}<br />
                      </Grid>
                    </>
                    }
                    <Grid item xs={2} ></Grid>
                  </Grid>
                </td>
                <td>
                  Conteúdo mais antigo:<br />
                  {props.arrUltimaPesquisa.antiga? timestampFormatter(props.arrUltimaPesquisa.antiga) : '-' }<br />
                  {props.arrPeriodoSelecionado.antiga? timestampFormatter(props.arrPeriodoSelecionado.antiga) : '-'}
                </td>
                <td>
                  Conteúdo mais recente:<br />
                  {props.arrUltimaPesquisa.recente? timestampFormatter(props.arrUltimaPesquisa.recente) : '-'}<br />
                  {props.arrPeriodoSelecionado.recente? timestampFormatter(props.arrPeriodoSelecionado.recente) : '-'}
                </td>
              </tr>
            </table>
          </CardContent>
        </Card>

      </Grid>
    </>
  );
}
