import axios from 'axios';
import authHeader from './auth-header';
import trataErroApi from './trata-erro-api';

const API_URL = process.env.REACT_APP_API_URL;
const authenticateTimeout = '';

class ColetaSeletivaService {

  coletaSeletiva(
    cod_instrucao_coleta,
    str_desc_instrucao,
    str_desc_instrucao_fmt,
    is_null,
  ) {
    return axios.post(API_URL + 'coletaseletivainstrucao/formulario', {
        cod_instrucao_coleta,
        str_desc_instrucao,
        str_desc_instrucao_fmt,
        is_null,
      },
      {headers: authHeader()},
    );
  }

  async excluirIntrucao(cod_instrucao_coleta) {
    return axios
      .post(API_URL + 'coletaseletivainstrucao/delete', {cod_instrucao_coleta: cod_instrucao_coleta},
        {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getDadosIntrucao(cod_instrucao_coleta) {
    const postParams = {codInstrucaoColeta: cod_instrucao_coleta};
    return axios
      .post(API_URL + 'coletaseletivainstrucao/populate', postParams, {headers: authHeader()})
      .then((response) => {
        return response.data.data;
      });
  }

  pontoColeta(
    cod_ponto_coleta,
    cod_unidade_federacao,
    str_nome_ponto_coleta,
    num_cep,
    str_logradouro,
    num_endereco,
    str_bairro,
    str_municipio,
    str_contato,
    str_link,
    json_dia_funcionamento,
  ) {
    return axios.post(API_URL + 'pontocoleta/formulario', {
      cod_ponto_coleta,
      cod_unidade_federacao,
      str_nome_ponto_coleta,
      num_cep,
      str_logradouro,
      num_endereco,
      str_bairro,
      str_municipio,
      str_contato,
      str_link,
        json_dia_funcionamento,
      },
      {headers: authHeader()},
    );
  }

  async excluirPontoColeta(id) {
    return axios
      .post(API_URL + 'pontocoleta/delete', {cod_ponto_coleta: id},
        {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getDadosPontoColeta(cod_ponto_coleta) {
    const postParams = {codPontoColeta: cod_ponto_coleta};
    return axios
      .post(API_URL + 'pontocoleta/populate', postParams, {headers: authHeader()})
      .then((response) => {
        return response.data.data;
      });
  }

  async getAll() {
    const postParams = {};
    return axios
      .post(API_URL + 'pontocoleta/listar', postParams, {headers: authHeader()})
      .then((response) => {
        return response.data.data;
      });
  }

  async getAllIntucao() {
    const postParams = {};
    return axios
      .post(API_URL + 'coletaseletivainstrucao/listar', postParams, {headers: authHeader()})
      .then((response) => {
        return response.data.data;
      });
  }

  async getAllCaimnhao() {
    const postParams = {};
    return axios
      .post(API_URL + "dashboardcoleta/get-caminhao", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getRotaCaminhao(params) {
    const postParams = params;
    return axios
      .post(API_URL + "dashboardcoleta/get-rota-caminhao", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getDiasParada(params) {
    const postParams = params;
    return axios
      .post(API_URL + "dashboardcoleta/get-dias-parada", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getPontoColetaByNome(params = {}) {
    const postParams = params;
    return axios
      .post(API_URL + 'pontocoleta/verifica-nome', postParams, {headers: authHeader()})
      .then((response) => {
        // console.log(response.data);
        return response.data;
      });
  }

}

export default new ColetaSeletivaService();
