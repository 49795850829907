import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SubTitulo } from '../../../views/style/styles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CaminhaoService from '../../../services/caminhoes.service';
import NotificacaoService from '../../../services/notificacoes.service';
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SelectPadrao from '../SelectPadrao';
import {timestampFormatter} from '../../util';

const useStyle = makeStyles((theme) => ({
  formButton: theme.pagina.formButton,
  button: {
    color: '#000',
    backgroundColor: '#FFF',
    border: 'solid 1px #005b96',
    maxWidth: 100,
    height: '42px',
    marginTop: '25px',
    '&:hover': {
      color: '#005b96',
      backgroundColor: '#FFF',
      border: 'solid 1px #005b96',
    },
  },
  span: {
    '&. MuiFormControlLabel-label': {
      color: '#000',
    },
  },
  classSelecionarIdentificador: {
    padding: 0,
  },
}));

const mensagemPadraoCarregando =
  (
    <span>
      <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
    </span>
  );

const Cadastrar = (props) => {
  const classes = useStyle();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoadingEditar, setIsLoadingEditar] = React.useState(false);
  const [isPlacaEncontrada, setIsPlacaEncontrada] = React.useState(false);
  const [formCadastro, setFormCadastro] = React.useState({});
  const [campoCaminhao, setCampoCaminhao] = React.useState('');
  const [campoPlaca, setCampoPlaca] = React.useState('');
  const [campoModelo, setCampoModelo] = React.useState('');
  const [campoIdentificador, setCampoIdentificador] = React.useState('');
  const [campoInativar, setCampoInativar] = React.useState('');
  const [errosCampo, setErrosCampo] = React.useState([]);
  const [arrDadosCaminhao, setArrDadosCaminhao] = React.useState([]);
  const [arrMacAddr, setArrMacAddr] = React.useState([]);
  const [arrLastDataCaminhao, setArrLastDataCaminhao] = React.useState([]);

  useEffect(() => {
    carregarDadosCaminhao();
    carregarMacAddr();
  }, [props.codCaminhao]);

  const carregarDadosCaminhao = async () => {
    if (props.codCaminhao === null || props.codCaminhao == undefined) {
      return false;
    }

    const arrCampos = formCadastro;
    arrCampos['codCaminhao'] = props.codCaminhao;

    setIsLoadingEditar(true);
    const arrDadosCaminhao = await CaminhaoService.carregarDadosCaminhao(props.codCaminhao);

    if (arrDadosCaminhao.cod_caminhao) {
      setCampoCaminhao(arrDadosCaminhao.str_identificador);
      arrCampos['campoCaminhao'] = arrDadosCaminhao.str_identificador;
      setCampoPlaca(arrDadosCaminhao.str_placa);
      arrCampos['campoPlaca'] = arrDadosCaminhao.str_placa;
      setCampoModelo(arrDadosCaminhao.str_modelo);
      arrCampos['campoModelo'] = arrDadosCaminhao.str_modelo;
      setCampoInativar(arrDadosCaminhao.flg_inativo !== 'N')
      arrCampos['campoInativar'] = arrDadosCaminhao.flg_inativo !== 'N' ? 'S' : 'N';
      setCampoIdentificador(arrDadosCaminhao.str_mac_addr)
      arrCampos['campoIdentificador'] = arrDadosCaminhao.str_mac_addr;

      if (arrDadosCaminhao.str_mac_addr) {
        const arrLastDataCaminhao = await CaminhaoService.getApiCaminhoesLastData({ mac_addr: arrDadosCaminhao.str_mac_addr });
        setArrLastDataCaminhao(arrLastDataCaminhao);
      }


    }
    setArrDadosCaminhao(arrDadosCaminhao);
    setIsLoadingEditar(false);
    setFormCadastro(arrCampos);
  };

  const selecionaIdentificador = async (event) => {
    setCampoIdentificador(event.target.value);
    const arrCampos = formCadastro;
    arrCampos['campoIdentificador'] = event.target.value;
    setCampoIdentificador(event.target.value);
    setFormCadastro(arrCampos);

    if (event.target.value) {
      setArrLastDataCaminhao([]);
      const arrLastDataCaminhao = await CaminhaoService.getApiCaminhoesLastData({ mac_addr: event.target.value });
      setArrLastDataCaminhao(arrLastDataCaminhao);
    }

  };

  const carregarMacAddr = async () => {
    setIsLoading(true);
    let cod_caminhao = null;
    if (props.codCaminhao === null || props.codCaminhao == undefined) {
      cod_caminhao = null;
    } else {
      cod_caminhao = props.codCaminhao;
    }
    const arrMacAddr = await CaminhaoService.getAllMacAddr({ cod_caminhao: cod_caminhao });
    setArrMacAddr(arrMacAddr);
    setIsLoading(false);
  };

  const salvarCaminhao = async (evt) => {
    evt.preventDefault();
    if (verificaCamposObrigatorios() !== true) {
      return false;
    }
    setIsLoading(true);
    const retornoSalvarCaminhao = await CaminhaoService.salvarCaminhao(formCadastro);

    if (retornoSalvarCaminhao === 'ok') {
      props.exibeMensagemAlert({ mensagem: (props.codCaminhao === null ? 'Caminhão cadastrado com sucesso' : 'Dados salvos com sucesso.') });
      props.recarregarLista();
      props.onVoltar();
    } else if (retornoSalvarCaminhao === 'Placa encontrada') {
      props.exibeMensagemAlert({
        mensagem: ('Caminhão já cadastrado na base de dados'),
        severity: 'error',
      });
    } else if (retornoSalvarCaminhao === 'Mac_Addr encontrado') {
      props.exibeMensagemAlert({
        mensagem: ('Identificador já cadastrado na base de dados'),
        severity: 'error',
      });
    } else {
      props.exibeMensagemAlert({
        mensagem: (props.codCaminhao === null ? 'Erro ao cadastrar o Caminhão.' : 'Erro ao salvar o Caminhão.'),
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  const onChangeCampo = (event) => {
    const arrCampos = formCadastro;
    arrCampos[event.target.id] = event.target.value;
    setFormCadastro(arrCampos);
    switch (event.target.id) {
      case 'campoCaminhao':
        setCampoCaminhao(event.target.value);
        break;
      case 'campoPlaca':
        setCampoPlaca(event.target.value);
        break;
      case 'campoModelo':
        setCampoModelo(event.target.value);
        break;
    }
  };

  const validarPlaca = async (evt) => {
    let erro = false;
    const erroPlaca = [];
    evt.preventDefault();
    setIsPlacaEncontrada(false);
    const placaValidada = await CaminhaoService.validarPlaca(campoPlaca);

    if (placaValidada) {
      erroPlaca['campoPlaca'] = 'Placa já cadastrada na base de dados.';
      setIsPlacaEncontrada(true);
      erro = true;
    }

    setErrosCampo(erroPlaca);
    return !erro;
  }

  const verificaCamposObrigatorios = () => {
    const arrErros = [];
    let erro = false;
    if (campoCaminhao === null || campoCaminhao === undefined || campoCaminhao === '') {
      arrErros['campoCaminhao'] = 'Campo obrigatório.';
      erro = true;
    }
    if (campoPlaca === null || campoPlaca === undefined || campoPlaca === '') {
      arrErros['campoPlaca'] = 'Campo obrigatório.';
      erro = true;
    }
    if (isPlacaEncontrada) {
      arrErros['campoPlaca'] = 'Placa já cadastrada na base de dados.';
      erro = true;
    }
    if (campoModelo === null || campoModelo === undefined || campoModelo === '') {
      arrErros['campoModelo'] = 'Campo obrigatório.';
      erro = true;
    }
    if (campoIdentificador === null || campoIdentificador === undefined || campoIdentificador === '') {
      arrErros['campoIdentificador'] = 'Campo obrigatório.';
      erro = true;
    }
    setErrosCampo(arrErros);
    return !erro;
  };

  const onChangeCampoInativar = () => {
    setCampoInativar(!campoInativar);
    const arrCampos = formCadastro;
    arrCampos['campoInativar'] = !campoInativar ? 'S' : 'N';
    setFormCadastro(arrCampos);
  };

  const onVoltar = () => {
    props.onVoltar();
  };

  return (
    <>
      {isLoadingEditar === true &&
        <Grid container spacing={2} style={{ marginTop: 25 }}>
          <Grid item xs={10} sm={10} style={{ textAlign: 'center' }}>
            {mensagemPadraoCarregando}
          </Grid>
        </Grid>
      }
      {isLoadingEditar === false &&
        <form onSubmit={salvarCaminhao} style={{ width: '100%' }} autoComplete="off">
          <Grid container spacing={2} style={{ marginTop: 25 }}>
            <SubTitulo>Dados do caminhão</SubTitulo>

            <Grid item xs={12}></Grid>
            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                label="Caminhão"
                inputProps={{ maxLength: 150 }}
                error={!!errosCampo.campoCaminhao}
                helperText={errosCampo.campoCaminhao ?? null}
                id="campoCaminhao"
                variant="outlined" fullWidth required
                value={campoCaminhao}
                onChange={onChangeCampo}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                label="Placa"
                inputProps={{ maxLength: 10 }}
                error={!!errosCampo.campoPlaca}
                helperText={errosCampo.campoPlaca ?? null}
                id="campoPlaca"
                variant="outlined" fullWidth required
                value={campoPlaca}
                onChange={onChangeCampo}
                onBlur={validarPlaca}
              />
            </Grid>
            <Grid item xs={12} sm={1}></Grid>

            <Grid item xs={12} sm={1}></Grid>
            <Grid item xs={12} sm={5}>
              <TextField
                label="Modelo"
                inputProps={{ maxLength: 150 }}
                error={!!errosCampo.campoModelo}
                helperText={errosCampo.campoModelo ?? null}
                id="campoModelo"
                variant="outlined" fullWidth required
                value={campoModelo}
                onChange={onChangeCampo}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <SelectPadrao
                label="Identificador"
                id="campoIdentificador"
                classNameFormControl={classes.classSelecionarIdentificador}
                value={campoIdentificador}
                onChange={selecionaIdentificador}
                arrDados={arrMacAddr}
                isLoaded={!isLoading}
                required={true}
                error={errosCampo.campoIdentificador != undefined && errosCampo.campoIdentificador ? true : false}
                helperText={errosCampo.campoIdentificador != undefined && errosCampo.campoIdentificador ? errosCampo.campoIdentificador : null}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControlLabel
                control={<Checkbox id="campoInativar" name="campoInativar"
                  checked={campoInativar}
                  onChange={onChangeCampoInativar} />} /><label
                    htmlFor="campoInativar"
                    style={{ cursor: 'pointer' }}>Inativar</label>
            </Grid>
            <Grid item xs={12} sm={1}></Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 25 }}>
            <SubTitulo>Dados para Visualização no Mapa</SubTitulo>
            {(
              (arrDadosCaminhao.campoIdentificador !== null && arrDadosCaminhao.campoIdentificador !== undefined && arrDadosCaminhao.campoIdentificador !== '')
              || (campoIdentificador !== null && campoIdentificador !== undefined && campoIdentificador !== ''))
              &&
              <>
                {arrLastDataCaminhao.mac_addr !== undefined &&
                  <>
                    <Grid item xs={2} ms={6}><strong>mac_addr</strong></Grid>
                    <Grid item xs={4} ms={6}>{arrLastDataCaminhao.mac_addr !== undefined ? arrLastDataCaminhao.mac_addr : 'Não informado'}</Grid>
                    <Grid item xs={2} ms={6}><strong>Latitude</strong></Grid>
                    <Grid item xs={4} ms={6}>{arrLastDataCaminhao.location.latitude !== undefined ? arrLastDataCaminhao.location.latitude : 'Não informado'}</Grid>
                    <Grid item xs={2} ms={6}><strong>Último Update</strong></Grid>
                    <Grid item xs={4} ms={6}>{arrLastDataCaminhao.last_update !== undefined ? timestampFormatter(arrLastDataCaminhao.last_update) : 'Não informado'}</Grid>
                    <Grid item xs={2} ms={6}><strong>Longitude</strong></Grid>
                    <Grid item xs={4} ms={6}>{arrLastDataCaminhao.location.longitude !== undefined ? arrLastDataCaminhao.location.longitude : 'Não informado'}</Grid>
                  </>
                }
                {arrLastDataCaminhao.mac_addr === undefined &&
                  <>
                    <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} />
                    <span> Carregando...</span>
                  </>
                }
              </>
            }
            {((arrDadosCaminhao.campoIdentificador === undefined || arrDadosCaminhao.campoIdentificador === null || arrDadosCaminhao.campoIdentificador === '') && (campoIdentificador === undefined || campoIdentificador === null || campoIdentificador === '')) &&
              <Grid item xs={12} ms={12}>Nenhum identificador selecionado</Grid>
            }
          </Grid>
          <Grid item xs={12} ms={12} style={{ marginTop: 25 }} ></Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
            {isLoading === false &&
              <>
                <Button
                  type="submit"
                  variant="outlined"
                  className={classes.formButton}>
                  {props.codCaminhao === null || props.codCaminhao === undefined ? 'Cadastrar' : 'Salvar'}
                </Button> &nbsp;
                <Button
                  variant="text"
                  className={classes.formButton}
                  onClick={onVoltar}>Cancelar
                </Button>
              </>
            }
            {isLoading === true &&
              <>
                <Button variant="outlined" disabled style={{ width: 100 }}>
                  <CircularProgress
                    style={{ width: 20, height: 20, marginRight: 15 }} />
                </Button> &nbsp;
                <Button variant="text" disabled>Cancelar</Button>
              </>
            }
          </Grid>

        </form>
      }
    </>
  );
};


export default Cadastrar;
