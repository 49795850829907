import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import EquipeService from "../../../services/equipe.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '800px',
    minHeight: '400px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
    fontFamily: theme.typography.fontFamily
  },
  mensagemErro: {
    color: '#f44336',
    fontSize: '0.75rem'
  },
}));

export default function EquipeModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [codUsuariosExistentes, setCodUsuariosExistentes] = React.useState([]);
  const [arrUsuariosAlterados, setArrUsariosAlterados] = React.useState(false);
  const [arrUsariosNaBusca, setArrUsariosNaBusca] = React.useState([]);
  const arrUsuariosExistentes = props.arrUsuariosExistentes ?? [];
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [mensagemErro, setMensagemErro] = React.useState(null);

  // Filtrar os usuários já existentes no Perfil.
  // useEffect(() => {
  // }, [arrUsuariosExistentes]);

  const handleOpenModal = async (e) => {
    setIsLoading(true);
    let arrCodUsuariosExistentes = [];
    arrUsuariosExistentes.map(usuario => {
      arrCodUsuariosExistentes.push(usuario.cod_usuario);
    });
    setCodUsuariosExistentes(arrCodUsuariosExistentes);
    const arrUsariosNaBusca = await EquipeService.getUsuariosParaIncluirNaEquipe(arrCodUsuariosExistentes);
    setArrUsariosNaBusca(arrUsariosNaBusca);
    setIsLoading(false);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOnChangeUsuarios = (event, valor) => {
    setArrUsariosAlterados(valor);
    if (valor.length > 0) {
      setMensagemErro(null);
    }
  };

  const handleSalvarMembrosNaEquipe = async () => {
    if (arrUsuariosAlterados.length > 0) {
      setIsSaving(true);
      let resultado = await EquipeService.salvarUsuariosNaEquipe(props.codEquipe, props.codPerfil, arrUsuariosAlterados);
      if (resultado === 'ok' && typeof props.onChangeUsuarios === 'function') {
        props.onChangeUsuarios({ 'arrUsuarios': arrUsuariosAlterados, 'mensagem': 'Usuário(s) adicionado(s) com sucesso.' });
        setArrUsariosAlterados(false);
      }
      setIsSaving(false);
      handleCloseModal();
    } else {
      setMensagemErro('Selecione ao menos um usuário.');
    }
  };

  return (
    <div>
      {isLoading === true &&
        <Button variant="outlined" disabled style={{ width: 250, textAlign: 'center' }}>
          <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} />
        </Button>
      }
      {isLoading === false &&
        <Button variant="outlined" onClick={handleOpenModal} style={{ marginTop: 18, minWidth: 180, textAlign: 'center' }}>
          <span>Adicionar usuários à Equipe</span>
        </Button>
      }
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleCloseModal}
        closeAfterTransition
        disableScrollLock
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Grid item xs={5}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container alignItems="center" >
                  <Grid item xs={10}>
                    <h5>Adicionar usuários à Equipe</h5>
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right' }} >
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={handleCloseModal}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  </Grid>
                </Grid>
                <hr></hr>
                <p>Selecione um ou mais usuários a serem adicionados à Equipe:</p>
                <div>
                  <Autocomplete
                    multiple
                    noOptionsText="Nenhum usuário encontrado."
                    id="size-small-filled-multi"
                    size="small"
                    onChange={handleOnChangeUsuarios}
                    options={arrUsariosNaBusca}
                    getOptionLabel={(option) => option.str_nome_pessoa + ' ' + option.str_sobrenome + ' (' + option.str_email + ')'}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.str_nome_pessoa + ' ' + option.str_sobrenome}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField {...params} variant="filled" placeholder="Usuários" />
                    )}
                  />
                </div>
                {mensagemErro !== null &&
                  <span className={classes.mensagemErro}>{mensagemErro}</span>
                }
                <hr></hr>
                <div style={{ textAlign: 'right' }}>
                  {isSaving === false &&
                    <>
                      <Button variant="outlined" onClick={handleSalvarMembrosNaEquipe} style={{ width: 80 }} >Salvar</Button> &nbsp;
                      <Button variant="text" onClick={handleCloseModal} >Cancelar</Button>
                    </>
                  }
                  {isSaving === true &&
                    <>
                      <Button variant="outlined" disabled style={{ width: 80 }} >
                        <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} />
                      </Button> &nbsp;
                      <Button variant="text" disabled >Cancelar</Button>
                    </>
                  }
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </div >
  );
}
