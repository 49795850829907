import React, {useEffect} from 'react';
import styled from 'styled-components';
import {Button, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import UsuarioService from '../services/usuario.service';
import userPerfilDefault from '../assets/images/user-perfil-default.png';

import {dateFormatter, cepMaks, telefoneMask} from '../components/util';

const SubTitulo = styled.h2`
    width: 100%;
    color: #3e3e3e;
    font-size: 15px;
    border-bottom: 1px solid #ccc;
    padding: 0 10px 10px 10px;
    font-weight: 600;
`;

const useStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
    content: {
        backgroundColor: '#FFF',
        marginTop: '10px',
        padding: '10px'
      },
    button: {
        color: '#000',
        backgroundColor: '#FFF',
        border: 'solid 1px #005b96',
        maxWidth: 100,
        height: '42px',
        marginTop: '25px',
        '&:hover': {
            color: '#005b96',
            backgroundColor: '#FFF',
            border: 'solid 1px #005b96',
        },
    },
    gridLabel: {
        fontWeight: 'bold',
        textAlign: 'right'
    },
    gridText: {
        paddingLeft: '50px',
        wordWrap: 'break-word'
    },
    gridPhoto: {
        textAlign: 'right'
    },
    photoProfile: {
        width: '60px',
        height: '60px',
        borderRadius: '100%',
        position: 'absolute',
        marginTop: '40px'
    }
}));

const mensagemPadraoCarregando =
    (
        <div style={{ textAlign: 'center', padding: 15, margin: '0 auto' }}>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </div>
    )

const VisualizarUsuario = (props) => {
  const classes = useStyle();
  const [arrDadosUsuario, setArrDadosUsuario] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    carregarDadosUsuario();
}, [props.codUsuario]);

const carregarDadosUsuario = async () => {
    setIsLoading(true);
    const arrDadosUsuario = await UsuarioService.carregarDadosUsuario(props.codUsuario);
    console.log(arrDadosUsuario);
    setArrDadosUsuario(arrDadosUsuario);
    setIsLoading(false);
  }

  const onVoltar = () => {
    props.onVoltar();
  }

  return (
    <div className={classes.root}>
      <Grid item xs={12} className={classes.content}>
        <>
    {isLoading == false &&
            <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                <SubTitulo>Dados Pessoais</SubTitulo>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Grid className={classes.gridLabel} item xs={2} >Nome</Grid>
                    <Grid item xs={2} className={classes.gridText} >{arrDadosUsuario.str_nome_pessoa ? arrDadosUsuario.str_nome_pessoa : '-'}</Grid>
                    <Grid className={classes.gridLabel} item xs={3} >Cadastro Confirmado?</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.cadastro_confirmado ? arrDadosUsuario.cadastro_confirmado : '-'}</Grid>
                    <Grid item xs={2} style={{ display: 'flex', flexWrap: 'wrap' }} >
                        <Grid xs={2} container direction="row" justify="center" alignItems="center">
                            <img src={arrDadosUsuario.str_imagem_perfil ? arrDadosUsuario.str_imagem_perfil : userPerfilDefault} className={classes.photoProfile} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Grid className={classes.gridLabel} item xs={2} >Data de Nascimento</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.dat_nascimento ? dateFormatter(arrDadosUsuario.dat_nascimento) : '-'}</Grid>
                    <Grid className={classes.gridLabel} item xs={2} >Gênero</Grid>
                    <Grid item xs={3}className={classes.gridText}>{arrDadosUsuario.genero ? arrDadosUsuario.genero : '-'}</Grid>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Grid className={classes.gridLabel} item xs={2} >CPF</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.cpf ? arrDadosUsuario.cpf : '-'}</Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                <SubTitulo>Dados de Contato</SubTitulo>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Grid className={classes.gridLabel} item xs={2} >Email</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.str_email ? arrDadosUsuario.str_email : '-'}</Grid>
                    <Grid className={classes.gridLabel} item xs={2} >Email Adicional</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.str_email_adicional ? arrDadosUsuario.str_email_adicional : '-'}</Grid>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Grid className={classes.gridLabel} item xs={2} >Telefone</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.str_telefone ? telefoneMask(arrDadosUsuario.str_telefone) : '-'}</Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                <SubTitulo>Endereço</SubTitulo>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Grid className={classes.gridLabel} item xs={2} >CEP</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.num_cep ? cepMaks(arrDadosUsuario.num_cep) : '-'}</Grid>
                    <Grid className={classes.gridLabel} item xs={2} >Estado</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.estado ? arrDadosUsuario.estado : '-'}</Grid>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Grid className={classes.gridLabel} item xs={2} >Municipio</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.str_municipio ? arrDadosUsuario.str_municipio : '-'}</Grid>
                    <Grid className={classes.gridLabel} item xs={2} >Bairro</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.str_bairro ? arrDadosUsuario.str_bairro : '-'}</Grid>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Grid className={classes.gridLabel} item xs={2} >Logradouro</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.str_logradouro ? arrDadosUsuario.str_logradouro : '-'}</Grid>
                    <Grid className={classes.gridLabel} item xs={2} >Complemento</Grid>
                    <Grid item xs={3} style={{wordWrap: 'break-word', paddingLeft: 50, minWidth: 370}}>{arrDadosUsuario.str_complemento_endereco ? arrDadosUsuario.str_complemento_endereco : '-'}</Grid>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }} >
                    <Grid className={classes.gridLabel} item xs={2} >Número</Grid>
                    <Grid item xs={3} className={classes.gridText}>{arrDadosUsuario.num_residencial ? arrDadosUsuario.num_residencial : '-'}</Grid>
                </Grid>
            </Grid>
        </>
    }
    {isLoading == true && mensagemPadraoCarregando}
    <div style={{ textAlign: 'center', padding: 15, margin: '0 auto' }}>
        <Button variant="contained" className={classes.button} onClick={onVoltar} color="primary">Voltar</Button>
    </div>
    </>
                    </Grid>
</div>
  );
}

export default VisualizarUsuario;
