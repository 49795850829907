import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import ptBrLocale from "date-fns/locale/pt-BR";
const useStyle = makeStyles((theme) => ({

}));

export default function DatePickerPtBrPadrao(props) {

  const classes = useStyle();

  const onChangeData = (data) => {
    if (typeof props.onChange === 'function') {
      props.onChange(data);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale} >
        <KeyboardDatePicker
            fullWidth
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            inputVariant="outlined"
            disableFuture={props.disableFuture ?? false}
            disablePast={props.disablePast ?? false}
            invalidDateMessage="Data inválida"
            allowKeyboardControl={false}
            id={props.id}
            label={props.label}
            value={props.value}
            minDate={props.dataMinima}
            minDateMessage={props.erroDataMinima}
            onChange={onChangeData}
            KeyboardButtonProps={{
                className: classes.dataPicker,
                'aria-label': props.label,
            }}
            required={props.required??false}
        />
    </MuiPickersUtilsProvider>
  )
}
