import axios from "axios";
import authHeader from './auth-header';
import trataErroApi from './trata-erro-api';

const API_URL =  process.env.REACT_APP_API_URL

class UsuarioBloqueadoService {

    async carregarLista() {
      return axios
        .post(API_URL + "usuarioBloqueado/carregarLista", null, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async getUsuariosParaBloquear() {
      return axios
        .post(API_URL + "usuarioBloqueado/getUsuariosParaBloquear", null, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async contarUsuariosBloqueados() {
      return axios
        .post(API_URL + "usuarioBloqueado/contarUsuariosBloqueados", null, {headers: authHeader()})
        .then((response) => {
          return response.data.total;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async bloquearUsuario(arrCampos) {
      return axios
        .post(API_URL + "usuarioBloqueado/bloquearUsuario", arrCampos, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async desbloquearUsuario(codUsuarioBloqueado) {
      return axios
        .post(API_URL + "usuarioBloqueado/desbloquearUsuario", {codUsuarioBloqueado : codUsuarioBloqueado}, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }

    async carregarDadosUsuarioBloqueado(codUsuarioBloqueado) {
      return axios
        .post(API_URL + "usuarioBloqueado/carregarDadosUsuarioBloqueado", {codUsuarioBloqueado : codUsuarioBloqueado}, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
    }


    async buscarUsuarioBanco(codUsuarioBloqueado) {
      return axios
        .post(API_URL + "usuarioBloqueado/infoUsuarioBloqueado", {codUsuarioBloqueado : codUsuarioBloqueado}, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          console.log(error.response.data);
        });
    }

}export default new UsuarioBloqueadoService();
