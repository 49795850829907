import React, { Component } from 'react';
import {useTheme, withStyles} from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { clearMessage } from '../../actions/MessageAction';
import { history } from '../../helpers/history';
import * as Icon from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import CardEquipe from '../partials/equipe/CardEquipe';
import PermissoesEquipe from '../partials/equipe/PermissoesEquipe';
import EquipeModal from '../partials/equipe/EquipeModal';
import EquipeService from '../../services/equipe.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import AbasEquipe from '../partials/equipe/Abas';
import SelectPadrao from '../partials/SelectPadrao';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Build from '@material-ui/icons/Build';
import IconButton from '@material-ui/core/IconButton';
import VisualizarEquipesModal from '../partials/equipe/VisualizarEquipesModal';
import ModalPadrao from '../partials/ModalPadrao';
import InputPesquisarPadrao from '../partials/InputPesquisarPadrao';
import InputBase from '@material-ui/core/InputBase';

import TablePagination from '@material-ui/core/TablePagination';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

const styles = theme => ({
  formCadastro: {
    margin: '15px 0 15px 0',
  },
  conteudoCardBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,
  logoutButton: {
    height: 20,
  },
  formControl: {
    marginBottom: 8,
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  mensagemInformativa: {
    color: '#000',
    backgroundColor: '#039ED5',
    padding: '10px 10px 10px',
    textAlign: 'center',
  },
  containerEquipeCards: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  conteudoFundoBranco: {
    backgroundColor: '#FFF',
    borderRadius: '5px',
  },
  li: {
    color: '#000 !important',
  },
  '& .MuiListItem-root': {
    color: '#000 !important',
  },
  button: {
    color: '#000',
    backgroundColor: '#FFF',
    border: 'solid 1px #005b96',
    '&:hover': {
      color: '#005b96',
      backgroundColor: '#FFF',
      border: 'solid 1px #005b96',
    },
  },
  rooot: {
    flexGrow: 1,
  },
  input: {
    marginLeft: theme.spacing(1),
    block: 1,
    width: '90%'
  },
});

const arrUsuarios = [
  {
    id: 1,
    nome: 'Juliano',
    email: 'juliano@email.com',
    ultimoAcesso: '10/10/21 14:30:10',
  },
  {
    id: 2,
    nome: 'Abner',
    email: 'abner@email.com',
    ultimoAcesso: '11/06/21 16:32:02',
  },
  {
    id: 3,
    nome: 'Eduarda',
    email: 'eduarda@email.com',
    ultimoAcesso: '10/04/21 10:12:08',
  },
];

const arrTipoBusca = [
  {
    valor: 'equipes',
    texto: 'Por Equipes',
  },
  {
    valor: 'usuarios',
    texto: 'Por Usuários',
  },
];

const mensagemPadraoCarregando =
  (
    <span>
      <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
    </span>
  );

class Equipe extends Component {

  constructor(props) {
    super(props);
    history.listen((location) => {
      props.dispatch(clearMessage());
    });

    this.state = {
      count: 0,
      user: localStorage.getItem('user'),
      tipoBusca: '',
      equipe: null,
      mensagemInformativa: 'Você precisa selecionar um Tipo de Busca.',
      mensagemAlert: null,
      mensagemAlertSeverity: 'success',
      valorAba: 0,
      campoPesquisaUsuario: null,
      arrUsuariosCard: [],
      arrUsuarios: [],
      arrPermissoes: [],
      arrUsuariosCardOriginais: false,
      arrEquipe: [],
      arrEquipeIsLoaded: false,
      arrPerfilEquipe: [],
      arrUsuariosIsLoaded: false,
      codPerfilSelecionado: false,
      codEquipeSelecionada: false,
      visualizarUsuario: false,
      nome: null,
      sobrenome: null,
      modalRemoverUsuarioDaEquipe: null,
      isSavingExcluirUsuarioDaEquipe: false,

      page:0,
      rowsPerPage: 12,
      total: 0,
    };

  }

  componentDidMount() {
    // on mount component
  }

  componentDidUpdate() {
    // on update component
  }

  handleChangeTipoBusca = async (event) => {
    this.setState({ tipoBusca: event.target.value });
    if (event.target.value === 'equipes') {
      this.setState({ mensagemInformativa: 'Você precisa selecionar uma Equipe.' });
      this.setState({ equipe: null });
      if (this.state.arrEquipeIsLoaded === false) {
        const arrDadosEquipe = await EquipeService.getAllPost();
        this.setState({ arrEquipe: arrDadosEquipe, arrEquipeIsLoaded: true });
      }
    } else {
      this.setState({
        mensagemInformativa: 'Por favor, digite o nome ou email do usuário.',
        equipe: null,
      });
    }
  };

  handleChangeEquipe = async (event) => {
    this.setState({ mensagemInformativa: mensagemPadraoCarregando });
    this.setState({ equipe: null });
    const arrPerfilEquipe = await EquipeService.getPerfilPorEquipe(event.target.value);
    this.setState({ arrPerfilEquipe: arrPerfilEquipe });
    this.setState({ arrUsuariosCard: arrPerfilEquipe.arrUsuariosCard ?? [] });
    this.setState({ arrUsuariosCardOriginais: arrPerfilEquipe.arrUsuariosCard ?? [] });
    this.setState({ arrUsuarios: arrPerfilEquipe.arrUsuarios ?? [] });
    this.setState({ arrPermissoes: arrPerfilEquipe.arrPermissoes ?? [] });
    this.setState({ arrUsuariosIsLoaded: true });
    this.setState({ equipe: event.target.value });
    this.setState({ mensagemInformativa: null });
    this.setState({ codEquipeSelecionada: event.target.value });
    this.setState({ codPerfilSelecionado: ((arrPerfilEquipe.arrPerfis.length > 0 && arrPerfilEquipe.arrPerfis[0]['codPerfil']) ?? null) });
    this.setState({ total: arrPerfilEquipe.total ?? 0 });
  };

  handleClickPesquisarUsuario = async () => {
    // Todo fazer pesquisa na API
    if (this.state.campoPesquisaUsuario !== null && this.state.campoPesquisaUsuario !== '') {
      this.setState({ equipe: 'pesquisa_por_usuarios' });
      this.setState({ mensagemInformativa: mensagemPadraoCarregando });
      this.setState({ arrUsuariosIsLoaded: false });
      const arrUsuariosPorPerfil = await EquipeService.getUsuariosPorBuscaDeTexto(this.state.campoPesquisaUsuario);
      this.setState({ arrUsuariosCard: arrUsuariosPorPerfil ?? [] });
      this.setState({ arrUsuariosCardOriginais: arrUsuariosPorPerfil ?? [] });
      this.setState({total: arrUsuariosPorPerfil.total});
      this.setState({ arrUsuariosIsLoaded: true });
      this.setState({ codPerfilSelecionado: null });
      this.setState({ mensagemInformativa: null });
    }
  };

  handleChangeCampoPesquisaUsuario = (valor) => {
    this.setState({ campoPesquisaUsuario: valor });
  };

  handleAdicionarMembrosNaEquipe = (valor) => {
    if (valor) {
      this.getPaginacaoDelete('');

      // this.setState({ arrUsuariosCard: [...this.state.arrUsuariosCard, ...valor.arrUsuarios] });
      // this.setState({ arrUsuariosCardOriginais: [...this.state.arrUsuariosCardOriginais, ...valor.arrUsuarios] });
      // this.getPaginacao(valor);
      this.setState({ mensagemAlert: valor.mensagem });
    }
  };

  handleChangePerfilPorEquipe = async (cod_perfil) => {
    this.setState({ arrUsuariosIsLoaded: false });
    const arrUsuariosPorPerfil = await EquipeService.getUsuariosPorPerfil(cod_perfil, this.state.codEquipeSelecionada);
    // this.setState({ arrUsuariosCard: arrUsuariosPorPerfil.arrUsuarios ?? [] });
    // this.setState({ arrUsuariosCardOriginais: arrUsuariosPorPerfil.arrUsuarios ?? [] });
    this.setState({arrUsuariosCard: arrUsuariosPorPerfil.arrUsuariosCard ?? []});
    this.setState({arrUsuariosCardOriginais: arrUsuariosPorPerfil.arrUsuariosCard ?? []});
    this.setState({arrUsuarios: arrUsuariosPorPerfil.arrUsuarios ?? []});
    this.setState({arrPermissoes: arrUsuariosPorPerfil.arrPermissoes ?? []});
    this.setState({arrUsuariosIsLoaded: true});
    this.setState({codPerfilSelecionado: cod_perfil});
    this.setState({total: arrUsuariosPorPerfil.total ?? 0});
    this.setState({page: 0});
  };

  onClickVisualizarEquipesUsuario = (cod_usuario, nome, sobrenome) => {
    this.setState({ visualizarUsuario: cod_usuario });
    this.setState({ nome: nome });
    this.setState({ sobrenome: sobrenome });
  };

  removerUsuarioDaEquipe = (cod_usuario) => {
    this.setState({ modalRemoverUsuarioDaEquipe: cod_usuario });
  };

  excluirUsuarioDaEquipe = async (cod_usuario) => {
    this.setState({ isSavingExcluirUsuarioDaEquipe: true });
    const retorno = await EquipeService.removerUsuarioDaEquipePorCodigoEquipeCodPerfil(cod_usuario, this.state.codEquipeSelecionada, this.state.codPerfilSelecionado);
    if (retorno === 'ok') {
      this.onRemoverCardDaTela(cod_usuario);
      this.setState({ mensagemAlert: 'Usuário removido com sucesso.' });
      this.setState({ mensagemAlertSeverity: 'success' });
    } else {
      this.setState({ mensagemAlert: 'Não foi possível remover o usuário.' });
      this.setState({ mensagemAlertSeverity: 'error' });
    }
    this.setState({ modalRemoverUsuarioDaEquipe: null });
    this.setState({ isSavingExcluirUsuarioDaEquipe: false });

  };

  onCloseVisualizarEquipesUsuario = () => {
    this.setState({ visualizarUsuario: false });
  };

  onRemoverCardDaTela = (codUsuario) => {
    console.log('remove da tela', codUsuario);
    this.getPaginacaoDelete(codUsuario)

    // console.log('remove da tela', codUsuario);
    // let arrUsuariosCard = [...this.state.arrUsuariosCard];
    // let arrUsuariosCardNovo = arrUsuariosCard.filter(function(usuario) {
    //   return usuario.cod_usuario !== codUsuario;
    // });
    // this.setState({arrUsuariosCard: arrUsuariosCardNovo});
    // let arrUsuariosCardOriginal = [...this.state.arrUsuariosCardOriginais];
    // let arrUsuariosCardOriginalNovo = arrUsuariosCardOriginal.filter(function(usuario) {
    //   return usuario.cod_usuario !== codUsuario;
    // });
    // this.setState({arrUsuariosCardOriginais: arrUsuariosCardOriginalNovo});
  };

  getPaginacaoDelete = async (codUsuario) => {
    const arrUser = await EquipeService.montaPaginacao({
      acao: 'delete',
      codUsuario: codUsuario,
      codPerfilSelecionado: this.state.codPerfilSelecionado,
      codEquipeSelecionada: this.state.codEquipeSelecionada,
    });

    this.setState({arrUsuariosCard: arrUser.arrPagination ?? []});
    this.setState({arrUsuariosCardOriginais: arrUser.arrPagination ?? []});
    this.setState({arrUsuarios: arrUser.arrUsuarios ?? []});
    this.setState({total: arrUser.total});
    this.setState({page: 0});
    document.getElementById('Busca').value = "";
  }



  filtrarUsuariosCarregados = (event) => {
    if (event.target.value.length === 0) {
      return this.setState({ arrUsuariosCard: this.state.arrUsuariosCardOriginais });
    }
    let value = event.target.value.toString().toLowerCase();
    let result = [];
    if (this.state.arrUsuariosCardOriginais === false) {
      this.setState({ arrUsuariosCardOriginais: this.state.arrUsuariosCard });
    }

    if (this.state.arrUsuariosCard[this.state.page].length == 0) {
      result = this.state.arrUsuariosCardOriginais.filter((data) => {
        return (
          (data && data.str_nome_pessoa && data.str_nome_pessoa.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_sobrenome && data.str_sobrenome.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_email && data.str_email.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_nome_pessoa && data.str_sobrenome && (data.str_nome_pessoa.toString() + ' ' + data.str_sobrenome.toString()).toLowerCase().search(value.toLowerCase()) != -1)
        );
      });
    }else {
      result = this.state.arrUsuariosCard[this.state.page].filter((data) => {
        return (
          (data && data.str_nome_pessoa && data.str_nome_pessoa.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_sobrenome && data.str_sobrenome.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_email && data.str_email.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_nome_pessoa && data.str_sobrenome && (data.str_nome_pessoa.toString() + ' ' + data.str_sobrenome.toString()).toLowerCase().search(value.toLowerCase()) != -1)
        );
      });
    }

    let filter = {[this.state.page]: result};
    this.setState({ arrUsuariosCard: filter });
  };

  handleChangePage = (event, newPage) =>{
    this.setState({page: newPage});
  }

  handleChangeRowsPerPage =  (event) => {
    this.getPaginacao(+event.target.value, this.state.arrUsuariosCard);
    this.setState({rowsPerPage: +event.target.value,  page: 0});
  }

  getPaginacao = async (rows, arrUsuario) => {
    let row = rows != '' ? rows : '';

    const arrUser = await EquipeService.montaPaginacao({arrUser: arrUsuario, rows: row});

    this.setState({arrUsuariosCard: arrUser.arrPagination ?? []});
    this.setState({arrUsuariosCardOriginais: arrUser.arrPagination ?? []});
    this.setState({total: arrUser.total});
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container className={classes.conteudoCardBranco} style={{minWidth: '385px'}}>
          <Grid item xs={12} className={classes.formCadastroTitulo}>
              <Build className={classes.iconeTitulo} /><span
                className={classes.tituloPadrao}>Gerenciar Equipe</span>
          </Grid>
              <Grid container>
                <Grid item xs={3} style={{marginLeft: -8, minWidth: '200px'}}>
                  <SelectPadrao
                    label="Selecione o Tipo de Busca"
                    id="id-tipo-busca"
                    value={this.state.tipoBusca || ''}
                    onChange={this.handleChangeTipoBusca}
                    arrDados={arrTipoBusca}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                {this.state.tipoBusca === 'equipes' &&
                  <Grid item xs={3} style={{marginLeft: -8, minWidth: '200px'}}>
                    <SelectPadrao
                      label="Selecione uma Equipe"
                      id="id-equipe"
                      value={this.state.equipe || ''}
                      onChange={this.handleChangeEquipe}
                      isLoaded={this.state.arrEquipeIsLoaded}
                      arrDados={this.state.arrEquipe}
                    />
                  </Grid>
                }

                {this.state.tipoBusca === 'usuarios' &&
                  <>
                    <Grid item xs={3} style={{ marginTop: 7, minWidth: 355, marginBottom: 20  }} >
                      <InputPesquisarPadrao stylePaper={{ height: 52 }}
                        exibirBotao={false}
                        onChange={this.handleChangeCampoPesquisaUsuario}
                        value={this.state.campoPesquisaUsuario || ''}
                        label="Pesquisar Usuários"
                      />
                    </Grid>
                    <Grid item xs={2} style={{ marginLeft: 15 ,marginTop: 7, marginBottom: 20 }} >
                      <Button variant="contained" className={classes.button} style={{ height: 52 }}
                        onClick={this.handleClickPesquisarUsuario}
                        color="primary">
                        Pesquisar
                      </Button>
                    </Grid>
                  </>
                }
          </Grid>
          {this.state.mensagemInformativa !== null &&
            <Grid item xs={12} className={classes.conteudoFundoBranco}>
              <Paper
                className={classes.mensagemInformativa}>{this.state.mensagemInformativa}</Paper>
            </Grid>
          }
          {((this.state.equipe !== 'pesquisa_por_usuarios' && this.state.equipe !== null) || (this.state.arrUsuariosIsLoaded === true && this.state.equipe === 'pesquisa_por_usuarios')) &&
            <Grid container spacing={2} className={classes.conteudoFundoBranco} style={{marginLeft: 1, marginTop: 10}}>

              {this.state.mensagemAlert !== null &&
                <Grid item xs={12} style={{ marginBottom: 15, marginTop: 15 }}>
                  <Alert
                    severity={this.state.mensagemAlertSeverity}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.setState({ mensagemAlert: null });
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    <p>{this.state.mensagemAlert}</p>
                  </Alert>
                </Grid>
              }

              {this.state.equipe !== 'pesquisa_por_usuarios' &&
                <Grid item xs={12}>
                  <AbasEquipe valorInicial={false}
                    arrDados={this.state.arrPerfilEquipe.arrPerfis}
                    onChange={this.handleChangePerfilPorEquipe} />
                </Grid>
              }
              <Grid container spacing={2}>
                {this.state.equipe !== 'pesquisa_por_usuarios' &&
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <div style={{ margin: '10px 0 0 20px' }}>
                        <Paper component="form" className={classes.rooot}>
                          <InputBase
                            name="Busca"
                            id="Busca"
                            className={classes.input}
                            placeholder="Pesquisar Usuários"
                            onChange={this.filtrarUsuariosCarregados}
                            autoComplete="off"
                          />
                          <IconButton className={classes.iconButton} aria-label="search">
                            <SearchIcon />
                          </IconButton>
                        </Paper>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={1}></Grid>
                    <Grid item xs={12} sm={5}>
                      <div style={{ textAlign: 'right', width: '94%' }}>
                        {this.state.arrUsuariosIsLoaded === true &&
                          <EquipeModal codEquipe={this.state.codEquipeSelecionada}
                            codPerfil={this.state.codPerfilSelecionado}
                            arrUsuariosExistentes={this.state.arrUsuarios}
                            onChangeUsuarios={this.handleAdicionarMembrosNaEquipe}></EquipeModal>
                        }
                      </div>
                    </Grid>
                  </Grid>
                  // <>
                  //   <Grid item xs={6}>
                  //     <FormControl className={classes.formControl}>
                  //       <div>
                  //         <Grid container spacing={1} alignItems="flex-end" >
                  //           <Grid item>
                  //             <TextField onChange={this.filtrarUsuariosCarregados} id="input-with-icon-grid" label="Pesquisar Usuários" style={{ minWidth: 350 }} />
                  //           </Grid>
                  //           <Grid item>
                  //             <SearchIcon style={{ marginTop: "-10px" }} />
                  //           </Grid>
                  //         </Grid>
                  //       </div>
                  //     </FormControl>
                  //   </Grid>
                  //   <Grid item xs={6}>
                  //     <div style={{ textAlign: 'right', width: '100%' }} >
                  //       {this.state.arrUsuariosIsLoaded === true &&
                  //         <EquipeModal codEquipe={this.state.codEquipeSelecionada} codPerfil={this.state.codPerfilSelecionado} arrUsuariosExistentes={this.state.arrUsuariosCardOriginais} onChangeUsuarios={this.handleAdicionarMembrosNaEquipe} ></EquipeModal>
                  //       }
                  //     </div>
                  //   </Grid>
                  // </>
                }

                <Grid item xs={12}>
                  <h5>Usuários</h5>
                  <div className={classes.containerEquipeCards}>
                    {this.state.arrUsuariosIsLoaded === false && this.state.equipe !== 'pesquisa_por_usuarios' &&
                      <div><CircularProgress style={{
                        width: 20,
                        height: 20,
                        marginRight: 15,
                      }} /> Carregando...</div>
                    }
                    {(this.state.arrUsuariosIsLoaded === true || this.state.arrUsuariosIsLoaded === undefined) && (this.state.arrUsuariosCard.length === 0) &&
                      <div>Nenhum usuário encontrado.</div>
                    }
                    {this.state.arrUsuariosIsLoaded === true && this.state.arrUsuariosCard[this.state.page] !== undefined &&  this.state.arrUsuariosCard[this.state.page].map((data, index) => {
                      return (
                        <CardEquipe key={index} equipe={data}
                          tipoPesquisa={this.state.equipe}
                          removerUsuarioDaEquipe={this.removerUsuarioDaEquipe}
                          onClickVisualizarEquipesUsuario={this.onClickVisualizarEquipesUsuario}></CardEquipe>
                      );
                    })
                    }
                    {this.state.arrUsuariosCard[this.state.page] !== undefined &&
                      <Grid item xs={12} sm={12}>
                        <TableRow key="pagination">
                          <TablePagination
                            rowsPerPageOptions={[,12, 25, 50]}
                            component="div"
                            count={this.state.total}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            SelectProps={{
                              inputProps: {'aria-label': 'registros por página: 12'},
                              native: true,
                            }}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                            //ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </Grid>
                    }

                    <VisualizarEquipesModal
                      isOpened={this.state.visualizarUsuario ? true : false}
                      nome={this.state.nome} sobrenome={this.state.sobrenome}
                      onRemoverCardDaTela={this.onRemoverCardDaTela}
                      codEquipeEmTela={this.state.codEquipeSelecionada}
                      codPerfilEmTela={this.state.codPerfilSelecionado}
                      codUsuario={this.state.visualizarUsuario}
                      onCloseModal={this.onCloseVisualizarEquipesUsuario}></VisualizarEquipesModal>
                  </div>
                </Grid>

                {this.state.equipe !== 'pesquisa_por_usuarios' &&
                  <Grid item xs={12}>
                    <h5>Permissões Relacionadas a Equipe</h5>
                    <div>
                      <PermissoesEquipe isLoaded={this.state.arrUsuariosIsLoaded}
                        arrDados={this.state.arrPermissoes}></PermissoesEquipe>
                    </div>
                  </Grid>
                }
              </Grid>
            </Grid>
          }
        </Grid>
        <ModalPadrao
          open={this.state.modalRemoverUsuarioDaEquipe !== null ? true : false}>
          <h5>Aviso!</h5>
          <p>Deseja retirar este usuário da equipe?</p>
          <hr></hr>
          <div style={{ textAlign: 'right' }}>
            {this.state.isSavingExcluirUsuarioDaEquipe === false &&
              <>
                <Button variant="outlined"
                  onClick={() => this.excluirUsuarioDaEquipe(this.state.modalRemoverUsuarioDaEquipe)}
                  style={{ width: 80 }}>Sim</Button> &nbsp;
                <Button variant="text"
                  onClick={() => this.setState({ modalRemoverUsuarioDaEquipe: null })}>Não</Button>
              </>
            }
            {this.state.isSavingExcluirUsuarioDaEquipe === true &&
              <>
                <Button variant="outlined" disabled style={{ width: 80 }}>
                  <CircularProgress
                    style={{ width: 20, height: 20, marginRight: 15 }} />
                </Button> &nbsp;
                <Button variant="text" disabled>Não</Button>
              </>
            }
          </div>
        </ModalPadrao>
      </div >
    );
  }
}

function mapStateToProps(state) {
  const {
    user,
  }

    = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(Equipe));
