import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Button, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CircularProgress from '@material-ui/core/CircularProgress';
import TabelaPaginada from '../partials/DataTablePaginadapadrao';
import ItinerarioService from "../../services/itinerario.service";
import InputPesquisarPadrao from '../partials/InputPesquisarPadrao';
import CadastrarItinerario from '../partials/itinerario/Cadastrar';
import VisualizarItinerario from '../partials/itinerario/Visualizar';
import ModalPadrao from '../partials/ModalPadrao';

const styles = theme => ({
    conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
    tituloPadrao: theme.pagina.tituloPadrao,
    iconeTitulo: theme.pagina.iconeTitulo,
    buttonCadastro: theme.pagina.buttonCadastro,
    formCadastroTitulo: theme.pagina.formCadastroTitulo,
    buttonPesquisar: theme.pagina.buttonPesquisar,
    formControl: {
        margin: theme.spacing(1),
        minWidth: 350,
        maxWidth: 400,
    },
    button: {
        color: '#000',
        backgroundColor: '#FFF',
        border: 'solid 1px #005b96',
        maxWidth: 100,
        height: '42px',
        marginTop: '25px',
        '&:hover': {
            color: '#005b96',
            backgroundColor: '#FFF',
            border: 'solid 1px #005b96',
        },
    },
    inputBuscar: {
        width: 400,
        margin: '15px 0',
    },
});

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
    )

const loadingPadraoBotao =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} />
        </span>
    )

class GerenciarItinerario extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: localStorage.getItem("user"),
            mensagemAlert: { mensagem: null, severity: 'success', closeButton: true },
            isLoading: true,
            isLoadingTela: false,
            isSaving: false,
            arrItinerarios: null,
            codItinerario: null,
            operacaoEmtela: 'listar',
            isLoadingCadastrarItinerario: false,
            isLoadingVisualizarItinerario: false,
            index: null,
            modalExclusao: false,
            indexExclusao: null,
            campoPesquisar: null,
            isSavingExclusao: false,
        };

    }

    componentDidMount() {
        this.carregarItinerario()
    }

    carregarItinerario = async () => {
        const arrItinerarios = await ItinerarioService.getAll();
        this.setState({ arrItinerarios: arrItinerarios, isLoading: false });
    }

    onClickVisualizar = (codItinerario) => {
        this.setState({ operacaoEmtela: 'visualizar', isLoadingCadastrarItinerario: false, codItinerario: codItinerario });
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
    }

    onClickEditar = (codItinerario) => {
        this.setState({ operacaoEmtela: 'editar', isLoadingCadastrarItinerario: true, codItinerario: codItinerario });
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
    }

    onClickExcluir = (codItinerario, index) => {
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
        this.setState({ codItinerario: codItinerario, indexExclusao: index, modalExclusao: true, isSavingExclusao: false });
    }

    confirmarExcluirItinerario = async () => {

        this.setState({ isSavingExclusao: true });
        const retorno = await ItinerarioService.excluirItinerario(this.state.codItinerario);
        if (retorno === 'ok') {
            let arrItinerariosPosExclusao = [...this.state.arrItinerarios];
            arrItinerariosPosExclusao.splice(this.state.indexExclusao, 1);
            this.setState({ arrItinerarios: arrItinerariosPosExclusao });
            this.setState({ mensagemAlert: { mensagem: 'Itinerário excluído com sucesso.', severity: 'success', closeButton: true } });
        } else {
            this.setState({ mensagemAlert: { mensagem: 'Não foi possível excluir o itinerário.', severity: 'error', closeButton: true } });
        }
        this.setState({ codItinerario: null, indexExclusao: null, modalExclusao: false, isSavingExclusao: false });

    }

    handleCloseModal = () => {
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
        this.setState({ codItinerario: null, indexExclusao: null, modalExclusao: false });
    }

    handleClickCadastrarItinerario = () => {
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true }, operacaoEmtela: 'cadastrar' });
    }

    handleChangeCampoPesquisar = (valor) => {
        this.setState({ campoPesquisar: valor });
        this.pesquisarItinerarios(valor);
    }

    pesquisarItinerarios = async (busaca) => {
        this.setState({ isLoading: true, arrItinerarios: [] });
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
        const arrFiltros =
        {
            busca: busaca,
        }
        const arrItinerarios = await ItinerarioService.getAll(arrFiltros);
        this.setState({ arrItinerarios: arrItinerarios, isLoading: false });
    }

    onVoltar = () => {
        this.setState({ isLoadingVisualizarItinerario: false, isLoadingCadastrarItinerario: false, operacaoEmtela: 'listar', codItinerario: null });
    }

    recarregarLista = async () => {
        this.setState({ isLoading: true, arrItinerarios: [] });
        const arrItinerarios = await ItinerarioService.getAll();
        this.setState({ arrItinerarios: arrItinerarios, isLoading: false });
    }

    exibeMensagemAlert = (mensagem) => {
        this.setState({ mensagemAlert: { mensagem: mensagem.mensagem, severity: mensagem.severity ?? 'success', closeButton: true } });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container className={classes.conteudoFundoBranco} style={{minWidth: '471px'}}>
                    {this.state.isLoadingTela === true &&
                        <Grid item xs={12} sm={12} style={{ marginTop: 25, textAlign: 'center' }} >
                            {mensagemPadraoCarregando}
                        </Grid>
                    }
                    {this.state.isLoadingTela === false &&
                        <>
                            <Grid item xs={12} className={classes.formCadastroTitulo}>
                                <AccessTimeIcon className={classes.iconeTitulo} />
                                <span className={classes.tituloPadrao} >
                                    {this.state.operacaoEmtela === 'listar' ? 'Gerenciar Itinerário' : ''}
                                    {this.state.operacaoEmtela === 'visualizar' ? 'Visualizar Itinerário' : ''}
                                    {this.state.operacaoEmtela === 'cadastrar' ? 'Cadastrar Itinerário' : ''}
                                    {this.state.operacaoEmtela === 'editar' ? 'Editar Itinerário' : ''}
                                </span>
                            </Grid>
                            {this.state.mensagemAlert.mensagem !== null &&
                                <Grid item xs={12} style={{ marginBottom: 15, marginTop: 15 }} >
                                    <Alert
                                        severity={this.state.mensagemAlert.severity}
                                        action={
                                            this.state.mensagemAlert.closeButton === true &&
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                    >
                                        {this.state.mensagemAlert.mensagem}
                                    </Alert>
                                </Grid>
                            }
                            {(this.state.operacaoEmtela === 'cadastrar' || this.state.operacaoEmtela === 'editar') &&
                                <CadastrarItinerario codItinerario={this.state.codItinerario} onVoltar={this.onVoltar} exibeMensagemAlert={this.exibeMensagemAlert} recarregarLista={this.recarregarLista} />
                            }
                            {this.state.operacaoEmtela === 'visualizar' &&
                                <VisualizarItinerario codItinerario={this.state.codItinerario} onVoltar={this.onVoltar} exibeMensagemAlert={this.exibeMensagemAlert} recarregarLista={this.recarregarLista} />
                            }
                            {this.state.operacaoEmtela === 'listar' &&
                                <>
                                    <Grid container>
                                        <Grid item xs={12} sm={6} style={{padding: '15px 0'}}>
                                            <InputPesquisarPadrao
                                                exibirBotao={false}
                                                placeholder="Pesquisar Itinerários"
                                                onChange={this.handleChangeCampoPesquisar}
                                                value={this.state.campoPesquisar || ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Button variant="outlined" className={classes.buttonCadastro} onClick={this.handleClickCadastrarItinerario}>
                                                <span>Cadastrar Itinerário</span>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} id="tabela-listar-intinerario" name="tabela-sem-borda">
                                        <TabelaPaginada
                                            arrCabecalho={['Caminhão', 'Dias de Coleta', 'Ações']}
                                            arrTamanhoColuna={['50%', '40%', '10%']}
                                            isLoading={this.state.isLoading}
                                            arrDados={this.state.arrItinerarios}
                                            arrAcoes={
                                                {
                                                    acao: [
                                                        {
                                                            chave: 'cod_itinerario_coleta',
                                                            funcao: this.onClickVisualizar,
                                                            icone: SearchIcon
                                                        },
                                                        {
                                                            chave: 'cod_itinerario_coleta',
                                                            funcao: this.onClickEditar,
                                                            icone: EditIcon
                                                        },
                                                        {
                                                            chave: 'cod_itinerario_coleta',
                                                            funcao: this.onClickExcluir,
                                                            icone: CloseIcon
                                                        }
                                                    ]

                                                }
                                            }
                                        />
                                    </Grid>
                                </>
                            }
                        </>
                    }
                </Grid>
                <ModalPadrao open={this.state.modalExclusao} handleCloseModal={this.handleCloseModal} >
                    <h5>Aviso!</h5>
                    <hr></hr>
                    <p>Deseja Excluir este itinerário?</p>
                    <hr></hr>
                    {this.state.isSavingExclusao === false &&
                        <>
                            <Button variant="outlined" onClick={() => this.confirmarExcluirItinerario()} style={{ width: 80 }} >Sim</Button> &nbsp;
                            <Button variant="text" onClick={() => this.setState({ modalExclusao: false })}>Não</Button>
                        </>
                    }
                    {this.state.isSavingExclusao !== false &&
                        <>
                            <Button variant="outlined" disabled style={{ width: 80 }} ><CircularProgress style={{ color: '#FFF', width: 20, height: 20 }} /></Button> &nbsp;
                            <Button variant="text" disabled >Não</Button>
                        </>
                    }
                </ModalPadrao>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(GerenciarItinerario));
