import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, FormControl, Grid, Select} from '@material-ui/core';
import Form from 'react-validation/build/form';
import {Titulo, SubTitulo} from '../../../views/style/styles';
import {connect} from 'react-redux';
import {Alert} from '@material-ui/lab';
import {FaUsers} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import Service from '../../../services/dpo.service';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import {validaInputDataComponente} from '../../util';
import {salvarResposta} from '../../../actions/DpoAction';
import userPerfilDefault from '../../../assets/images/user-perfil-default.png';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,
  formButton: theme.pagina.formButton,
  formCadastro: {
    margin: '15px',
  },
  cardEquipe: {
    //maxWidth: 336,
    flex: '1 1 auto',
    padding: '5px 5px 5px',
    //margin: '5px 0 25px 0',
    borderRadius: '10px',
    backgroundColor: 'white',
    //'rgba(242, 242, 242, 1)',
    border: 'solid 1px rgba(228, 228, 228, 1)',
    color: '#000',
    boxShadow: '5px 5px 5px rgba(0,0,0,0.5)',
  },
  imgPerfil: {
    width: '70px !important',
    height: '70px',
    borderRadius: '100px',
    margin: '12px 0 0 50px',
  },
  buttonNegar: {
    color: '#FFF',
    backgroundColor: '#C80A21',
    border: 'solid 1px #C80A21',
    '&:hover': {
      color: '#C80A21',
      backgroundColor: '#FFF',
      border: 'solid 1px #C80A21',
    },
  },
  buttonCancelar: {
    color: '#FFF',
    backgroundColor: '#7E84A3',
    border: 'solid 1px #7E84A3',
    '&:hover': {
      color: '#7E84A3',
      backgroundColor: '#FFF',
      border: 'solid 1px #7E84A3',
    },
  },

  buttonSalvar: {
    color: '#FFF',
    backgroundColor: '#28A745',
    border: 'solid 1px #28A745',
    '&:hover': {
      color: '#28A745',
      backgroundColor: '#FFF',
      border: 'solid 1px #28A745',
    },
  },
  classErro: {
    margin: '-15px 14px 0 8px', color: '#f44336', fontSize: '0.75rem',
  },
  imgPerfil: {
    width: '100px !important',
    height: '100px',
    borderRadius: '100px',
    // margin: '12px 0 0 50px',
  },
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );

const loadingPadraoBotao =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/>
        </span>
  );

class SolicitacaoResposta extends Component {
  constructor(props) {
    super(props);
    this.salvar = this.salvar.bind(this);

    this.state = {
      errosCampo: [],

      cod_solic_anonimizacao: props.codSolicAnonimizacao,
      str_nome_solicitante: null,
      str_email_solicitante: null,
      contador: null,
      dat_requerimento_fmt: null,
      str_observacao: null,
      str_imagem_perfil: null,
      tp_solicitacao_fmt: null,

      str_observacao_resposta: null,
      dat_devolutiva_resposta: null,
      dat_limite_exclusao: null,
      str_nome_arquivo: null,
      file_base64: null,
      flg_situacao: null,

      isLoadingEdit: true,
      isLoading: false,
      isLiadingA: false,
      isLiadingN: false,
      isLiadingC: false,
      loading: false,
      successful: '',
      redirect: false,
    };
  }

  componentDidMount() {
    this.carregaDados();
  }

  carregaDados = () => {
    if (this.state.cod_solic_anonimizacao) {
      const data = Promise.resolve(Service.getList({cod_solic_anonimizacao: this.state.cod_solic_anonimizacao}));
      data.then((v) => {
        this.populate(v[0]);
      });
    }
  };

  populate = (dado) => {
    this.setState(dado);
    this.setState({isLoadingEdit: false});
  };

  salvar(e) {
    e.preventDefault();

    this.setState({
      successful: '',
      isLoading: true,
    });

    if (!this.onValidateForm()) {
      window.scrollTo(0, 0);
      this.setState({
        isLoading: false,
        isLiadingA: false,
        isLiadingC: false,
        isLiadingN: false,
      });
      return;
    }

    this.form.validateAll();
    this.props
      .dispatch(
        salvarResposta(
          this.state.cod_solic_anonimizacao,
          this.state.str_observacao_resposta,
          new Date(this.state.dat_devolutiva_resposta).toLocaleDateString(),
          new Date(this.state.dat_limite_exclusao).toLocaleDateString(),
          this.state.flg_situacao,
          this.state.file_base64,
          this.state.str_nome_arquivo,
          this.state.str_nome_solicitante,
        ),
      )
      .then(() => {
        this.setState({
          successful: true,
          isLoading: false,
          isLiadingA: false,
          isLiadingC: false,
          isLiadingN: false,
        });
        this.timeout = setTimeout(() => {
          this.props.onVoltar();
          this.setState({
            redirect: false,
          });
        }, 3000);
      })
      .catch(() => {
        this.setState({
          successful: false,
          redirect: false,
          isLoading: false,
          isLiadingA: false,
          isLiadingC: false,
          isLiadingN: false,
        });
      });
  }

  onValidateForm = () => {
    let arrErro = [];
    let erro = false;

    let datDevolutiva = validaInputDataComponente(this.state.dat_devolutiva_resposta, true, true, new Date(), false, null, 'Data da devolutiva não pode ser menor que a data atual');
    if (!datDevolutiva.status) {
      arrErro['datDevolutiva'] = datDevolutiva.message;
      erro = true;
    }

    let datLimite = validaInputDataComponente(this.state.dat_limite_exclusao, true, true, new Date(), false, null, 'Data limite da exclusão não pode ser menor que a data atual');
    if (!datLimite.status) {
      arrErro['datLimite'] = datLimite.message;
      erro = true;
    }

    if (this.state.flg_situacao !== 'A' && this.state.file_base64 === null) {
      arrErro['arquivo'] = 'Campo obrigatório para Negar ou Cancelar a solicitação!';
      erro = true;
    }

    this.setState({
      errosCampo: arrErro,
    });
    return !erro;
  };

  onFileChange = (event) => {
    const files = event.target.files;
    let arFile = files[0];

    let typeImage = arFile.type.split('/').pop();
    let size = arFile.size;
    let maxSize = 10242880;

    let isValidSize = true;
    let arrType = ['pdf', 'PDF'];
    let isValide = arrType.indexOf(typeImage);

    if (maxSize <= size) {
      isValidSize = false;
    }

    if (isValide < 0 || !isValidSize) {
      this.setState({
        file_base64: null,
        str_nome_arquivo: null,
        errosCampo: {arquivo: 'Arquivo inválido. Apenas arquivos do tipo .pdf e com tamanho máximo de 10MB são aceitos'},
      });
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        this.setState({
          str_nome_arquivo: arFile.name,
          file_base64: e.target.result,
          errosCampo: {},
        });
      };
    }
  };

  dowloadBase64 = () => {
    if (this.state.file_base64 !== null && this.state.file_base64 !== undefined) {
      const linkSource = this.state.file_base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = this.state.str_nome_arquivo;
      downloadLink.click();
    }
  };

  render() {
    const {successful, isLoggedIn, message, classes} = this.props;

    if (this.state.redirect) {
      this.onClickVoltar();
    }

    const titulo = 'Responder Solicitação';
    const labelButon = this.state.cod_solic_anonimizacao == null ? 'Cadastrar' : 'Salvar';

    return (
      <>
        {this.state.isLoadingEdit === true &&
          <Grid container spacing={2} style={{marginTop: 25}}>
            <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
              {mensagemPadraoCarregando}
            </Grid>
          </Grid>
        }
        {this.state.isLoadingEdit === false && <div className={classes.root}>
          <Grid container className={classes.conteudoFundoBranco}
                style={{minWidth: 613}}>
            <Grid item xs={12} className={classes.formCadastroTitulo}>
              <FaUsers className={classes.iconeTitulo}/>
              <span className={classes.tituloPadrao}> {titulo}</span>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.content}>
                <Form onSubmit={this.salvar} ref={(c) => {
                  this.form = c;
                }} className={classes.formCadastro}>
                  {this.state.successful == false && this.state.successful !== '' && (
                    <div className="row">
                      <div className="col mb-2">
                        <Alert severity={'error'}>{message}</Alert>
                      </div>
                    </div>
                  )}
                  {this.state.successful == true && this.state.successful !== '' && (
                    <div className="row">
                      <div className="col mb-2">
                        <Alert severity={'success'}>{message}</Alert>
                      </div>
                    </div>
                  )}

                  <SubTitulo>Dados da Solicitação</SubTitulo>
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={12}
                          style={{textAlign: 'center', marginBottom: 6}}>
                      <img
                        src={this.state.str_imagem_perfil ? this.state.str_imagem_perfil : userPerfilDefault}
                        className={classes.imgPerfil}
                        style={{objectFit: 'cover'}}/>
                    </Grid>

                    <Grid item xs={12} sm={2}><strong>Nome</strong>:</Grid>
                    <Grid item xs={12}
                          sm={10}>{this.state.str_nome_solicitante}</Grid>
                    <Grid item xs={12} sm={2}><strong>Email</strong>:</Grid>
                    <Grid item xs={12}
                          sm={10}>{this.state.str_email_solicitante}</Grid>
                    <Grid item xs={12} sm={2}><strong>Tipo da
                      Solicitação</strong>:</Grid>
                    <Grid item xs={12}
                          sm={10}>{this.state.tp_solicitacao_fmt}</Grid>
                    <Grid item xs={12} sm={2}><strong>Data de requerimento da
                      exclusão</strong>:</Grid>
                    <Grid item xs={12}
                          sm={10}>{this.state.dat_requerimento_fmt}</Grid>
                    <Grid item xs={12} sm={10}>
                      <p style={{fontSize: 15}}>{this.state.contador}</p>
                    </Grid>

                  </Grid>


                  <SubTitulo>Resposta</SubTitulo>
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}
                                               locale={ptBrLocale}>
                        <KeyboardDatePicker
                          error={this.state.errosCampo.datDevolutiva != undefined && this.state.errosCampo.datDevolutiva ? true : false}
                          helperText={this.state.errosCampo.datDevolutiva != undefined && this.state.errosCampo.datDevolutiva ? this.state.errosCampo.datDevolutiva : null}
                          required
                          autoOk={true}
                          label="Data da devolutiva"
                          name="dat_devolutiva_resposta"
                          id="dat_devolutiva_resposta"
                          fullWidth
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          InputAdornmentProps={{position: 'end'}}
                          value={this.state.dat_devolutiva_resposta}
                          onChange={(e) => {
                            this.setState({errosCampo: {datDevolutiva: null}});

                            if (e == null) {
                              this.setState({dat_devolutiva_resposta: null});
                              return;
                            }

                            this.setState({dat_devolutiva_resposta: e});
                          }}
                          minDate={new Date()}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}
                                               locale={ptBrLocale}>
                        <KeyboardDatePicker
                          error={this.state.errosCampo.datLimite != undefined && this.state.errosCampo.datLimite ? true : false}
                          helperText={this.state.errosCampo.datLimite != undefined && this.state.errosCampo.datLimite ? this.state.errosCampo.datLimite : null}
                          required
                          autoOk={true}
                          label="Data limite da exclusão"
                          name="dat_limite_exclusao"
                          id="dat_limite_exclusao"
                          fullWidth
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          InputAdornmentProps={{position: 'end'}}
                          value={this.state.dat_limite_exclusao}
                          onChange={(e) => {
                            this.setState({errosCampo: {datLimite: null}});

                            if (e == null) {
                              this.setState({dat_limite_exclusao: null});
                              return;
                            }

                            this.setState({dat_limite_exclusao: e});
                          }}
                          minDate={new Date()}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <TextField
                        required
                        label="Observação"
                        inputProps={{maxLength: 1000}}
                        id="str_observacao_resposta"
                        multiline
                        rows={5}
                        variant="outlined"
                        fullWidth
                        value={this.state.str_observacao_resposta}
                        onChange={(e) => {
                          this.setState({str_observacao_resposta: e.target.value});
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{marginTop: 10}}>
                    <Grid item xs={12} sm={2} style={{minWidth: 109}}>
                      <label htmlFor="file_base64"
                             style={{marginTop: 5}}>Upload
                        de Documentos</label>
                    </Grid>
                    <Grid item xs={12} sm={10} style={{marginLeft: '-10px'}}>
                      <Grid container>
                        <Grid item xs={12} sm={12} style={{minWidth: 87}}>
                          <input
                            accept="application/pdf"
                            id="file_base64"
                            onChange={this.onFileChange}
                            type="file"
                          />
                          <div></div>
                          <label htmlFor="file_base64">
                            <Button component="span">Selecione o arquivo
                              desejado</Button>
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={6}
                              style={{marginTop: 7}}> {this.state.str_nome_arquivo ?
                          <div style={{
                            color: 'blue',
                            cursor: 'pointer',
                            textDecoration: 'underline blue',
                          }} onClick={() => {
                            this.dowloadBase64();
                          }}>{this.state.str_nome_arquivo}</div> : null}
                        < /Grid>
                      </Grid>
                    </Grid>
                    <span className={classes.classErro}>
                       {this.state.errosCampo.arquivo != undefined && this.state.errosCampo.arquivo ? this.state.errosCampo.arquivo : null}
                    </span>
                  </Grid>

                  <Grid item xs={12} style={{marginTop: 30}}>
                    <div style={{textAlign: 'center'}}>
                      <Button variant="contained"
                              type="submit"
                              className={classes.buttonSalvar}
                              onClick={() => {
                                this.setState({
                                  flg_situacao: 'A',
                                  isLiadingA: false,
                                });
                              }}
                      >
                        {this.state.isLiadingA ? loadingPadraoBotao : 'Aprovar Solicitação'}
                      </Button> &nbsp;

                      <Button variant="contained"
                              type="submit"
                              className={classes.buttonNegar}
                              onClick={() => {
                                this.setState({
                                  flg_situacao: 'N',
                                  isLiadingN: false,
                                });
                              }}
                      >
                        {this.state.isLiadingButton ? loadingPadraoBotao : 'Negar Solicitação'}
                      </Button> &nbsp;

                      <Button variant="contained"
                              type="submit"
                              className={classes.buttonCancelar}
                              onClick={() => {
                                this.setState({
                                  flg_situacao: 'C',
                                  isLiadingC: false,
                                });
                              }}
                      >
                        {this.state.isLiadingButton ? loadingPadraoBotao : 'Cancelar Solicitação'}
                      </Button> &nbsp;

                      <Button
                        className={classes.formButton}
                        onClick={() => {
                          this.props.onVoltar();
                        }}>Voltar </Button>
                    </div>
                  </Grid>
                </Form>
              </Grid>
            </Grid>
          </Grid>
        </div>}
      </>
    );
  }
}

function mapStateToProps(state) {
  const {isLoggedIn} = state.auth;
  const {message} = state.message;
  return {
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(SolicitacaoResposta));
