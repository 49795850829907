import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SubTitulo } from '../../../views/style/styles';
import CaminhaoService from "../../../services/caminhoes.service";
import { timestampFormatter } from '../../util';

const mensagemPadraoCarregando =
  (
    <span>
      <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
    </span>
  )

const VisualizarCaminhao = (props) => {
  const [isLoadingVisualizar, setIsLoadingVisualizar] = React.useState(false);
  const [arrDadosCaminhao, setArrDadosCaminhao] = React.useState([]);
  const [arrLastDataCaminhao, setArrLastDataCaminhao] = React.useState([]);

  useEffect(() => {
    carregarDadosCaminhao();
  }, [props.codCaminhao]);

  const carregarDadosCaminhao = async () => {
    if (props.codCaminhao === null || props.codCaminhao == undefined) {
      return false;
    }
    setIsLoadingVisualizar(true);
    const arrDadosCaminhao = await CaminhaoService.carregarDadosCaminhao(props.codCaminhao);

    setArrDadosCaminhao(arrDadosCaminhao);
    if (arrDadosCaminhao.str_mac_addr) {
      const arrLastDataCaminhao = await CaminhaoService.getApiCaminhoesLastData({ mac_addr: arrDadosCaminhao.str_mac_addr });
      setArrLastDataCaminhao(arrLastDataCaminhao);
    }
    setIsLoadingVisualizar(false);
  };

  return (
    <>
      {isLoadingVisualizar === true &&
        <Grid container spacing={2} style={{ marginTop: 25 }} >
          <Grid item xs={10} sm={10} style={{ textAlign: 'center' }} >
            {mensagemPadraoCarregando}
          </Grid>
        </Grid>
      }
      {isLoadingVisualizar === false &&
        <Grid container spacing={2} style={{ marginTop: 25 }} >
          <SubTitulo>Dados da Notificação</SubTitulo>
          <Grid item xs={2} sm={2} ></Grid>
          <Grid item xs={1} sm={1} >
            <b>Caminhão</b>
          </Grid>
          <Grid item xs={3} sm={3} >
            {arrDadosCaminhao.str_identificador}
          </Grid>
          <Grid item xs={1} sm={1} >
            <b>Placa</b>
          </Grid>
          <Grid item xs={3} sm={3} >
            {arrDadosCaminhao.str_placa}
          </Grid>
          <Grid item xs={2} sm={2} ></Grid>
          <Grid item xs={2} sm={2} ></Grid>
          <Grid item xs={1} sm={1} >
            <b>Modelo</b>
          </Grid>
          <Grid item xs={3} sm={3} >
            {arrDadosCaminhao.str_modelo}
          </Grid>
          <Grid item xs={1} sm={1} >
            <b>Status</b>
          </Grid>
          <Grid item xs={3} sm={3} >
            {arrDadosCaminhao.flg_inativo !== 'N' ? 'Inativo' : 'Ativo'}
          </Grid>
          <Grid item xs={2} sm={2} ></Grid>
          <Grid container spacing={2} style={{ marginTop: 25 }}>
            <SubTitulo>Dados para Visualização no Mapa</SubTitulo>
            {arrLastDataCaminhao.mac_addr !== undefined &&
              <>
                <Grid item xs={2} sm={2} ></Grid>
                <Grid item xs={1} sm={1}><strong>mac_addr</strong></Grid>
                <Grid item xs={3} sm={3}>{arrLastDataCaminhao.mac_addr !== undefined ? arrLastDataCaminhao.mac_addr : 'Não informado'}</Grid>
                <Grid item xs={1} sm={1}><strong>Latitude</strong></Grid>
                <Grid item xs={3} sm={3}>{arrLastDataCaminhao.location.latitude !== undefined ? arrLastDataCaminhao.location.latitude : 'Não informado'}</Grid>
                <Grid item xs={2} sm={2} ></Grid>
                <Grid item xs={2} sm={2} ></Grid>
                <Grid item xs={1} sm={1}><strong>Último Update</strong></Grid>
                <Grid item xs={3} sm={3}>{arrLastDataCaminhao.last_update !== undefined ? timestampFormatter(arrLastDataCaminhao.last_update) : 'Não informado'}</Grid>
                <Grid item xs={1} sm={1}><strong>Longitude</strong></Grid>
                <Grid item xs={3} sm={3}>{arrLastDataCaminhao.location.longitude !== undefined ? arrLastDataCaminhao.location.longitude : 'Não informado'}</Grid>
              </>
            }
            {arrLastDataCaminhao.mac_addr === undefined &&
              <Grid item xs={12} ms={12}>Nenhum identificador selecionado</Grid>
            }
          </Grid>
          <Grid item xs={12} sm={12} style={{ textAlign: 'center' }} >
            <Button variant="text" onClick={props.onVoltar} >Voltar</Button>
          </Grid>
        </Grid>
      }
    </>
  );
};


export default VisualizarCaminhao;
