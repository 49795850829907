import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {FaExclamation} from 'react-icons/fa';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import BotaoPanicoService from '../../services/botao-panico.service';
import TabelaPaginada from '../partials/DataTablePaginadapadrao';
import DescriptionIcon from '@material-ui/icons/Description';
import Info from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import {timestampFormatter} from '../util';
import Observacoes from '../partials/chamados/Observacoes';
import Detalhar from '../partials/chamados/Detalhar';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formCadastroTitulo: {
    margin: '15px 0 15px 0',
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  tabIndicator: theme.pagina.tabIndicator,
  formCadastro: {
    margin: '15px',
  },
  modalExclusao: {
    boxShadow: theme.shadows[5],
  },
  titleModal: {
    borderBottom: '1px solid #ccc',
  },
  actionsModal: {
    borderTop: '1px solid #ccc',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '800px',
    minHeight: '400px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
    fontFamily: theme.typography.fontFamily,
  },
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
    </span>
  );

const arrData = [{
  numero: '0000000001',
  nome: 'Skaylle Barreto',
  data: '2021-07-22 00:36:11.000000',
  str_observacao: 'Lorem Ipsum is simply dummy text of the printing and typesetting ' +
    'industry. Lorem Ipsum has been the industrys standa',
  cod_chamado: 1,
  json_localizacao: 'aaaaaaa',
}];

class TermoPolitica extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDisabled: true,
      isLoadingEdit: true,
      arrChamadoAberto: [],
      arrChamadoAndamento: [],
      alert: '',
      loading: true,
      successful: '',
      redirect: false,
      showForm: 'aberto',
      showModal: null,
      value: 0,
      open: false,
      num_abertos: 0,
      num_andamento: 0,
      dataChamado: [],
      numLoad: 0,
    };
  }

  componentDidMount() {
   this.setChamados();
   this.onLoadChamado();
  }

  componentDidUpdate() {
    // on update component
  }

  onLoadChamado = () => {
    setInterval(() => {
      this.setChamados();
    }, 3000);
  }

  onChangeAbertos = () => {
    this.setState({
      showForm: 'aberto',
      isDisabled: true,
      value: 0,
    });
    this.setChamados();
  };

  onChangeAndamento = () => {
    this.setState({
      showForm: 'andamento',
      isDisabled: true,
      value: 1,
    });
    this.setChamados();
  };

  setChamados = async () => {
    let arrChamadoAberto = await BotaoPanicoService.getChamados({
      flgSituacao: 'A',
      flgExcluido: 'N',
    });
    let arrChamadoAndamento = await BotaoPanicoService.getChamados({
      flgSituacao: 'E',
      flgExcluido: 'N',
    });

    this.setState({
      num_abertos: arrChamadoAberto.length ?? 0,
      num_andamento: arrChamadoAndamento.length ?? 0,
      arrChamadoAberto: arrChamadoAberto,
      arrChamadoAndamento: arrChamadoAndamento,
      loading: false,
    });

    this.setState({isLoadingEdit: false});

    let load = this.state.numLoad + 1;
    this.setState({numLoad: load});

    // da um reload na tela para evitar o acesso de requisição no navegador
    if(load >= 200){
      document.location.reload(true);
    }
  };

  getChamado = (codChamado) => {
    let arrayChamado = this.state.showForm == 'aberto' ? this.state.arrChamadoAberto : this.state.arrChamadoAndamento;

    let chamado = arrayChamado.filter(function(arr) {
      return arr.codChamado == codChamado;
    });

    this.setState({dataChamado: chamado[0]});
  };

  exibeFormtObs = (event) => {
    let string = event.length > 80 ? ' ...' : '';
    let title = event.length > 80 ? event : '';

    return (
      <span title={title}
            style={{cursor: 'pointer'}}>{event.substring(0, 80)} {string}</span>
    );
  };

  formtDataHota = (event) => {
    return (
      <>
        {timestampFormatter(event)}
      </>
    );
  };

  onClickObservacao = (event) => {
    this.setState({
      showModal: 'observacao',
      open: true,
    });
    this.getChamado(event);
  };

  onClickDedatlhar = (event) => {
    this.setState({
      showModal: 'detatlhar',
      open: true,
    });
    this.getChamado(event);
  };

  onClickVisualizar = (event) => {
    let url = window.location.origin;
    window.location = url + '/botao-panico/visualizar-chamado/' + event;
  };

  handleClose = () => {
    this.setState({
      isLoading: true,
      open: false,
      loading: true,
    });

    this.setChamados();
  };

  render() {
    const {classes} = this.props;

    const titulo = this.state.showModal == 'detatlhar' ? 'Detalhes do Chamado' : 'Observações do Chamado';

    return (
      <>
        {this.state.isLoadingEdit === true &&
        <Grid container spacing={2} style={{marginTop: 25}}>
          <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
            {mensagemPadraoCarregando}
          </Grid>
        </Grid>
        }
        {this.state.isLoadingEdit === false && <div className={classes.root}>
          <Grid container className={classes.conteudoFundoBranco} style={{minWidth: '588px'}}>
            <Grid item xs={12} className={classes.formCadastroTitulo}>
              <FaExclamation className={classes.iconeTitulo}/>
              <span className={classes.tituloPadrao}> Chamados</span>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.content}>
                <Grid className={classes.formCadastro}>
                  <Tabs
                    classes={{indicator: classes.tabIndicator}}
                    value={this.state.value}>
                    <Tab
                      onClick={
                        () => {
                          this.setState({successful: ''}, this.onChangeAbertos());
                        }
                      }
                      label={'Abertos (' + this.state.num_abertos + ')'}/>

                    <Tab
                      onClick={
                        () => {
                          this.setState({successful: ''}, this.onChangeAndamento());
                        }
                      }
                      label={'Em andamento (' + this.state.num_andamento + ')'}/>
                  </Tabs>
                  {
                    this.state.showForm === 'aberto' &&
                    <>
                      <div className={classes.root}>
                        <Grid container className={classes.formCadastroTitulo}>
                          <Grid item xs={12}>
                            <span
                              className={classes.tituloPadrao}>{'Chamados Recebidos'}</span>
                          </Grid>
                          <Grid container>
                            <Grid container spacing={3}>
                              <Grid item xs={12} id="tabela-listar-usuario"
                                    name="tabela-sem-borda">
                                <TabelaPaginada
                                  arrCabecalho={['Nº Chamado', 'Nome', 'Data/Hora', 'Observações', 'Ações']}
                                  arrTamanhoColuna={['12%', '12%', '12%', '35%', '10%']}
                                  isLoading={this.state.loading}
                                  style={{boxShadow: 'none!important'}}
                                  mensagemSemRegistros="Nenhum Chamado Aberto"
                                  arrDados={
                                    this.state.arrChamadoAberto
                                  }
                                  arrFuncoes={
                                    {
                                      strObservacao: {
                                        chave: 'strObservacao',
                                        funcao: this.exibeFormtObs,
                                      },
                                      tmsCriacao: {
                                        chave: 'tmsCriacao',
                                        funcao: this.formtDataHota,
                                      },
                                    }
                                  }
                                  arrAcoes={
                                    {
                                      codChamado: [
                                        {
                                          chave: 'codChamado',
                                          funcao: this.onClickObservacao,
                                          icone: DescriptionIcon,
                                        },
                                        {
                                          chave: 'codChamado',
                                          funcao: this.onClickDedatlhar,
                                          icone: Info,
                                        },
                                        {
                                          chave: 'codChamado',
                                          funcao: this.onClickVisualizar,
                                          icone: SearchIcon,
                                        },
                                      ],
                                    }
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  }
                  {
                    this.state.showForm === 'andamento' &&
                    <>
                      <div className={classes.root}>
                        <Grid container className={classes.formCadastroTitulo}>
                          <Grid item xs={12}>
                            <span
                              className={classes.tituloPadrao}>{'Chamados Recebidos'}</span>
                          </Grid>
                          <Grid container>
                            <Grid container spacing={3}>
                              <Grid item xs={12} id="tabela-listar-usuario"
                                    name="tabela-sem-borda">
                                <TabelaPaginada
                                  arrCabecalho={['Nº Chamado', 'Nome', 'Data/Hora', 'Responsável', 'Observações', 'Ações']}
                                  arrTamanhoColuna={['12%', '12%', '12%', '12%' , '35%', '10%']}
                                  isLoading={this.state.loading}
                                  style={{boxShadow: 'none!important'}}
                                  mensagemSemRegistros="Nenhum Chamado Aberto"
                                  arrDados={
                                    this.state.arrChamadoAndamento
                                  }
                                  arrFuncoes={
                                    {
                                      strObservacao: {
                                        chave: 'strObservacao',
                                        funcao: this.exibeFormtObs,
                                      },
                                      tmsCriacao: {
                                        chave: 'tmsCriacao',
                                        funcao: this.formtDataHota,
                                      },
                                    }
                                  }
                                  arrAcoes={
                                    {
                                      codChamado: [
                                        {
                                          chave: 'codChamado',
                                          funcao: this.onClickObservacao,
                                          icone: DescriptionIcon,
                                        },
                                        {
                                          chave: 'codChamado',
                                          funcao: this.onClickDedatlhar,
                                          icone: Info,
                                        },
                                        {
                                          chave: 'codChamado',
                                          funcao: this.onClickVisualizar,
                                          icone: SearchIcon,
                                        },
                                      ],
                                    }
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  }
                </Grid>
              </Grid>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={this.state.open}
                onClose={this.handleClose}
                closeAfterTransition
                disableScrollLock
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={this.state.open}>
                  <Grid item xs={5}>
                    <Grid item xs={12}>
                      <Paper className={classes.paper}>
                        <Grid container alignItems="center">
                          <Grid item xs={10}>
                            <h5>{titulo}</h5>
                          </Grid>
                          <Grid item xs={2} style={{textAlign: 'right'}}>
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={this.handleClose}
                            >
                              <CloseIcon fontSize="inherit"/>
                            </IconButton>
                          </Grid>
                        </Grid>
                        <hr></hr>
                        {this.state.showModal === 'detatlhar' &&
                        <Detalhar isLoading={false}
                                  arrDetalhar={this.state.dataChamado}
                                  onVoltar={this.handleClose}></Detalhar>
                        }
                        {this.state.showModal === 'observacao' &&
                        <Observacoes isLoading={false}
                                     arrObservacoes={this.state.dataChamado}
                                     onVoltar={this.handleClose}></Observacoes>
                        }
                      </Paper>
                    </Grid>
                  </Grid>
                </Fade>
              </Modal>
            </Grid>
          </Grid>
        </div>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(TermoPolitica));
