import axios from 'axios';
import authHeader from './auth-header';
import trataErroApi from './trata-erro-api';
import {anomizarDados} from '../actions/DpoAction';

const API_URL = process.env.REACT_APP_API_URL;
const authenticateTimeout = '';

class DpoService {

  salvar(
    cod_solic_anonimizacao,
    cod_usuario_anonimizacao,
    str_observacao,
    dat_requerimento,
  ) {
    return axios.post(API_URL + 'soliciataoAnonimizacao/formulario', {
        cod_solic_anonimizacao,
        cod_usuario_anonimizacao,
        str_observacao,
        dat_requerimento,
      },
      {headers: authHeader()},
    );
  }

  salvarResposta(
    cod_solic_anonimizacao,
    str_observacao_resposta,
    dat_devolutiva_resposta,
    dat_limite_exclusao,
    flg_situacao,
    file_base64,
    str_nome_arquivo,
    str_nome_solicitante,
  ) {
    return axios.post(API_URL + 'soliciataoAnonimizacao/formulario', {
        cod_solic_anonimizacao,
        str_observacao_resposta,
        dat_devolutiva_resposta,
        dat_limite_exclusao,
        flg_situacao,
        file_base64,
        str_nome_arquivo,
        str_nome_solicitante,
      },
      {headers: authHeader()},
    );
  }

  async getList(arrFiltros) {
    return axios
      .post(API_URL + "soliciataoAnonimizacao/getList", arrFiltros, { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return []
        }
      });
  }

  async getUserAutoComplete(arrFiltros) {
    return axios
      .post(API_URL + "soliciataoAnonimizacao/getUserAutoComplete", arrFiltros, { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return []
        }
      });
  }

  async getPerilUsuario(arrFiltros) {
    return axios
      .post(API_URL + "soliciataoAnonimizacao/getPerilUsuario", arrFiltros, { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return []
        }
      });
  }

  anonimizarDados(postParams) {
    return axios.post(API_URL + 'soliciataoAnonimizacao/anonimizarDados', postParams,
      {headers: authHeader()},
    );
  }
}

export default new DpoService();
