import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {FormControl, Grid, Select} from '@material-ui/core';
import {SubTitulo} from '../../../views/style/styles';
import {timestampFormatter} from '../../util';
import Paper from '@material-ui/core/Paper';
import {Link} from 'react-router-dom';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import Exportar from '../../../services/rede-social';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyle = makeStyles((theme) => ({
  formButton: theme.pagina.formButton,
  buttonCadastro: theme.pagina.buttonCadastro,

  button: {
    color: '#000',
    backgroundColor: '#FFF',
    border: 'solid 1px #005b96',
    maxWidth: 100,
    height: '42px',
    marginTop: '25px',
    '&:hover': {
      color: '#005b96',
      backgroundColor: '#FFF',
      border: 'solid 1px #005b96',
    },
  },
  span: {
    '&. MuiFormControlLabel-label': {
      color: '#000',
    },
  },

  cardEquipe: {
    width: '100%',
    flex: '1 1 auto',
    margin: '5px 25px 25px 0',
    borderRadius: '10px',
    backgroundColor: 'rgba(242, 242, 242, 1)',
    border: 'solid 1px rgba(228, 228, 228, 1)',
    color: '#000',
  },
  formCadastro: {
    margin: '10px',
    textAlign: 'center'
  },
  mensagemInformativa: {
    color: '#FFF',
    backgroundColor: '#1976d2',
    padding: '10px 10px 10px',
    textAlign: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '5px',
    fontFamily: theme.typography.fontFamily,
  },
}));

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );

export default function Conteudo(props) {
  const classes = useStyle();

  const [tpExportacao, setTpExportacao] = React.useState(null);
  const [isLoadingExpost, setIsLoadingExpost] = React.useState(false);

  let arrConteudo = [];

  if (props.acao == 'conteudo' && props.arrConteudo.length > 0) {
    arrConteudo = props.arrConteudo;
  }

  if (props.acao == 'metricas' && props.arrConteudo.length > 0) {
    let count = 10;
    let arrNumeros = [];

    for (let i = 0; i < count; i = i + 1) {
      let index = Math.floor(Math.random() * props.arrConteudo.length);
      arrNumeros.push(props.arrConteudo[index]);
    }
    arrConteudo = arrNumeros;
  }

  const arrExport = [
    {
      'id': 'xls',
      name: 'Exportar XLS',
    },
    {
      'id': 'txt',
      name: 'Exportar TXT',
    },
  ];

  const onChangeTpExprtacao = async (e) => {
    setTpExportacao(e.target.value);
    setIsLoadingExpost(true);

    await Exportar.exportDados({
      tpExport: e.target.value,
      cod_pesquisa: props.codPesquisa,
      data: JSON.stringify(props.arrConteudo),

      // cod_hashtag: props.codTag,
      // disabledInsta: props.disabledInsta,
      // disabledTwiter: props.disabledTwiter,
    });

    setTpExportacao(null);
    setIsLoadingExpost(false);
  };

  return (
    <>
      <SubTitulo></SubTitulo>

      <Grid item xs={12} sm={6}>
        <p style={{margin: '15px 0 0 0'}}><h4>CONTEÚDO</h4></p>
      </Grid>


      {props.acao === 'metricas' &&
        <Grid item xs={12} sm={6}>
          <Button
            component={Link}
            to={'/metricas-conteudo'}
            variant="outlined"
            className={classes.buttonCadastro}
          >VER TODO O CONTEÚDO
          </Button>
        </Grid>
      }

      <Grid item xs={12} sm={4}></Grid>

      {props.acao === 'conteudo' &&
        <Grid item xs={12} sm={2}>
          <>
            {isLoadingExpost == true &&
              <CircularProgress style={{
                width: 20,
                height: 20,
                marginRight: 15,
              }}/>
            }

            {isLoadingExpost == false &&
              <FormControl variant="outlined"
                           className={classes.formControl} fullWidth>
                <InputLabel
                  id="demo-simple-select-outlined-label">Exportar</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="tp_exportacao"
                  label="Exportar"
                  fullWidth
                  name="cod_unidade_federacao"
                  value={tpExportacao}
                  onChange={onChangeTpExprtacao}
                >
                  {tpExportacao == null &&
                    <MenuItem value="">
                      <em> Selecione o tipo </em>
                    </MenuItem>
                  }

                  {
                    arrExport.map(
                      (row, index) =>
                        <MenuItem value={row.id}
                                  key={row.id}>{row.name}</MenuItem>,
                    )
                  }
                </Select>
              </FormControl>
            }
          </>
        </Grid>
      }

      <Grid item xs={12} sm={12}>
        {arrConteudo.length > 0 && <>
          {arrConteudo.map(function(item, index) {
            return (
              <Card className={classes.cardEquipe} key={index}>
                <CardContent>
                  <Typography style={{textAlign: 'justify'}}>
                    {props.acao === 'conteudo' &&
                      <p>{JSON.parse(item.text)}</p>
                    }

                    {props.acao === 'metricas' &&
                      <p>{item.text}</p>
                    }
                  </Typography>
                  <Typography style={{display: 'block', float: 'right'}}>

                    {props.acao === 'conteudo' &&
                      <p>
                        {timestampFormatter(item.created_at) + ' - '}
                        {item.link !== undefined &&
                          <a style={{color: '#1976d2'}}
                             href={JSON.parse(item.link)}
                             target="_blank">{item.rede_social}</a>}
                        {item.link === undefined && <>{item.rede_social}</>}
                      </p>
                    }

                    {props.acao === 'metricas' &&
                      <p>
                        {timestampFormatter(item.created_at) + ' - '}
                        {item.link !== undefined &&
                          <a style={{color: '#1976d2'}} href={item.link}
                             target="_blank">{item.rede_social}</a>}
                        {item.link === undefined && <>{item.rede_social}</>}
                      </p>
                    }
                  </Typography>
                </CardContent>
              </Card>);
          })}
          {props.acao === 'conteudo' &&
            <Grid item xs={12} className={classes.formCadastro}>
              {props.isLoadConteudo &&
                <CircularProgress style={{
                  width: 20,
                  height: 20,
                  marginRight: 15,
                }}/>
              }

              {!props.isLoadConteudo &&
                <Paper className={classes.mensagemInformativa}>Fim dos Resultados.</Paper>
              }
            </Grid>
          }
        </>}
      </Grid>
    </>
  );
}

function openLink(link) {
  window.open(link, '_blank');
}

