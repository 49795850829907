import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArquivoService from "../../../services/arquivo.service";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Titulo, SubTitulo } from '../../../views/style/styles';
import HistoricoNotificacao from './HistoricoAlteracao';
import NotificacaoService from "../../../services/notificacoes.service";

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
    )

const VisualizarNotificacao = (props) => {

    const [arrDadosNotificacao, setArrDadosNotificacao] = React.useState([]);
    const [isLoadingFile, setIsLoadingFile] = React.useState(false);
    const [isLoadingVisualizar, setIsLoadingVisualizar] = React.useState(true);
    const [horarioFimExibicao, setHorarioFimExibicao] = React.useState('');
    const [horarioInicioExibicao, setHorarioInicioExibicao] = React.useState('');
    const [arquivoDocumento, setArquivoDocumento] = React.useState('-');


    useEffect(() => {
        carregarDadosNotificacao();
    }, [props.codNotificacao]);

    const carregarDadosNotificacao = async () => {
        setIsLoadingVisualizar(true);
        if (props.codNotificacao === null || props.codNotificacao == undefined) {
            return false;
        }
        const arrDadosNotificacao = await NotificacaoService.carregarDadosNotificacao(props.codNotificacao);
        setArrDadosNotificacao(arrDadosNotificacao);

        if (arrDadosNotificacao.hora_inicio) {
            const dataHoje = new Date();
            dataHoje.setHours(parseInt(arrDadosNotificacao.hora_inicio.split(":")[0]));
            dataHoje.setMinutes(parseInt(arrDadosNotificacao.hora_inicio.split(":")[1]));
            setHorarioInicioExibicao(addZero(dataHoje.getHours()) + ':' + addZero(dataHoje.getMinutes()));
        }
        if (arrDadosNotificacao.hora_fim) {
            const dataHoje = new Date();
            dataHoje.setHours(parseInt(arrDadosNotificacao.hora_fim.split(":")[0]));
            dataHoje.setMinutes(parseInt(arrDadosNotificacao.hora_fim.split(":")[1]));
            setHorarioFimExibicao(addZero(dataHoje.getHours()) + ':' + addZero(dataHoje.getMinutes()));
        }
        if (arrDadosNotificacao.cod_arquivo) {
            const nomeArquivo = <a style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={() => downloadArquivo(arrDadosNotificacao.cod_arquivo)} >{arrDadosNotificacao.str_descricao_arq}</a>;
            setArquivoDocumento(nomeArquivo);
        }

        setIsLoadingVisualizar(false);
    }

    const downloadArquivo = async (codArquivo, setLoading = 'upload') => {
        setIsLoadingFile(true);
        await ArquivoService.download(codArquivo);
        setIsLoadingFile(false);
    }

    const addZero = (i) => {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    return (
        <>
            {isLoadingVisualizar === true &&
                <Grid container spacing={2} style={{ marginTop: 25 }} >
                    <Grid item xs={10} sm={10} style={{ textAlign: 'center' }} >
                        {mensagemPadraoCarregando}
                    </Grid>
                </Grid>
            }
            {isLoadingVisualizar === false &&
                <Grid container spacing={2} style={{ marginTop: 25 }} >
                    <SubTitulo>Dados da Notificação</SubTitulo>
                    <Grid item xs={3} sm={3} >
                        <b>Título</b>
                    </Grid>
                    <Grid item xs={9} sm={9} style={{wordWrap: 'break-word', minWidth: 200 }}>
                        {arrDadosNotificacao.str_titulo}
                    </Grid>
                    <Grid item xs={3} sm={3} >
                        <b>Descrição</b>
                    </Grid>
                    <Grid item xs={9} sm={9} style={{wordWrap: 'break-word', minWidth: 200 }}>
                        {arrDadosNotificacao.str_descricao}
                    </Grid>
                    <Grid item xs={3} sm={3} >
                        <b>Período de Exibição</b>
                    </Grid>
                    <Grid item xs={9} sm={9} >
                        {new Date(arrDadosNotificacao.data_inicial).toLocaleDateString()} {horarioInicioExibicao} a {new Date(arrDadosNotificacao.data_final).toLocaleDateString()} {horarioFimExibicao}
                    </Grid>
                    <Grid item xs={3} sm={3} >
                        <b>Link de Vídeo</b>
                    </Grid>
                    <Grid item xs={9} sm={9} >
                        {arrDadosNotificacao.str_link &&
                            <a href={arrDadosNotificacao.str_link} target="_blank">{arrDadosNotificacao.str_link}</a>
                        }
                        {!arrDadosNotificacao.str_link && '-'}
                    </Grid>
                    <Grid item xs={3} sm={3} >
                        <b>Upload de Documentos</b>
                    </Grid>
                    <Grid item xs={9} sm={9} >
                        {isLoadingFile ? mensagemPadraoCarregando : arquivoDocumento}
                    </Grid>
                    {props.codNotificacao && arrDadosNotificacao.historico != undefined && arrDadosNotificacao.historico != null &&
                        <HistoricoNotificacao arrDadosNotificacao={arrDadosNotificacao} />
                    }
                    <Grid item xs={12} sm={12} style={{ textAlign: 'center' }} >
                        <Button variant="text" onClick={props.onVoltar} >Voltar</Button>
                    </Grid>
                </Grid>
            }
        </>
    );
};


export default VisualizarNotificacao;