export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.accessToken) {
    return {
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization, Rota',
      'Authorization': 'Bearer ' + user.accessToken,
      'X-Rota' : window.location.pathname,
    };
  } else {
    return {};
  }
}