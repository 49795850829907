import axios from 'axios';
import authHeader from './auth-header';
import trataErroApi from './trata-erro-api';

const API_URL = process.env.REACT_APP_API_URL;

class UsuarioService {

  async getAll() {
    return axios
      .get(API_URL + 'usuario/listar', {headers: authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  async getAllPost(valor) {
    return axios
      .get(API_URL + 'usuario/listar',
        {
          headers: authHeader(),
          body: JSON.stringify({
            Busca: valor,
          }),
        })
      .then((response) => {
        return response.data;
      });
  }

  async getById(id) {
    const postParams = {id: id};
    return axios
      .post(API_URL + 'usuario/getById', postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  async getPerfilPorEquipe(cod_usuario) {
    const postParams = {codUsuario: cod_usuario};
    return axios
      .post(API_URL + 'usuario/getPerfilPorEquipe', postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      });
  }

  async removerUsuario(cod_usuario) {
    const postParams = {cod_usuario: cod_usuario};
    return axios
      .post(API_URL + 'usuario/excluir', postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async carregarDadosUsuario(codUsuario) {
    return axios
      .post(API_URL + 'usuario/visualizar', {cod_usuario: codUsuario}, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getDadosUsuarioLogado() {
    const user = JSON.parse(localStorage.getItem('user'));
    const postParams = {token: user.token, str_login: user.login};
    return axios
      .post(API_URL + 'usuario/dados-usuario-logado', postParams, {headers: authHeader()})
      .then((response) => {
        return response.data.data;
      });
  }

  async validarSenha(parans = {}) {
    return axios
        .post(API_URL + 'usuario/valida-senha', parans, {headers: authHeader()})
        .then((response) => {
          return response.data;
        }).catch(error => {
          if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href = '/login';
          } else if (error.response.data.code === 403) {
            trataErroApi(error.response.data);
          } else {
            return error.response.data
          }
        });
  }

}

export default new UsuarioService();
