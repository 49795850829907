import {
  REGISTER_SUCCESS,
  REGISTER_CONFIRM_SUCCESS,
  REGISTER_CONFIRM_FAIL,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAIL,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "./TypesAction";

import AuthService from "../services/auth.service";
import {history} from "../helpers/history";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL

export const register = (str_email, emailConfirmation,
                        str_nome_pessoa, str_sobrenome, dat_nascimento, flg_genero, password, passwordConfirmation, strongPassword) => (dispatch) => {
  return AuthService.register(str_email, emailConfirmation, str_nome_pessoa, str_sobrenome, dat_nascimento, flg_genero, password, passwordConfirmation, strongPassword).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password, manter_conectado) => (dispatch) => {
  return AuthService.login(username, password, manter_conectado).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {user: data},
      });

      return Promise.resolve();
    },
    (error) => {
      let detail = error.response.data.detail;
      let message = '';

      if (detail === "Call to a member function getStrNomePessoa() on null") {
          message = "Parece que você ainda não confirmou seu cadastro. Acesse o seu e-mail e confirme o cadastro antes de acessar o sistema.";
      } else {
        message = "Usuário ou senha incorretos."
      }

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const sendEmail = (str_email, str_nome_pessoa, acao = 'recuperar-senha') => (dispatch) => {
  return AuthService.sendEmail(str_email, str_nome_pessoa, acao).then(
    (response) => {
      dispatch({
        type: SEND_EMAIL_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: SEND_EMAIL_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const registerConfirmation = (token) => (dispatch) => {
  return AuthService.registerConfirmation(token).then(
    (response) => {
      dispatch({
        type: REGISTER_CONFIRM_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
         error.response.data &&
         error.response.data.message) ||
         error.message ||
         error.toString();

      dispatch({
        type: REGISTER_CONFIRM_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const facebookLogin = (authResponse) => (dispatch) => {
    return AuthService.facebookAuthenticate(authResponse).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {user: data},
            });
            window.location.href = '/home';
          return Promise.resolve();
        }, (error) => {
            const message =
                (error.response &&
                 error.response.data &&
                 error.response.data.message) ||
                 error.message ||
                 error.toString();

            dispatch({
                type: REGISTER_CONFIRM_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });
          return Promise.reject();
        });
};

export const googleLogin = (authResponse) => (dispatch) => {
    return AuthService.googleAuthenticate(authResponse).then(
        (data) => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {user: data},
            });
            window.location.href = '/home';
            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                 error.response.data &&
                 error.response.data.message) ||
                 error.message ||
                 error.toString();

            dispatch({
                type: REGISTER_CONFIRM_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        });
};


export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const registerConfirmationApp = (token) => (dispatch) => {
  return AuthService.registerConfirmationApp(token).then(
    (response) => {
      dispatch({
        type: REGISTER_CONFIRM_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_CONFIRM_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
