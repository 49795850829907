import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  mainBox: {
    overflowY: 'auto',
    width: '100%',
    position: 'fixed',
    margin: 0,
    padding: 0,
    top: 0,
    left: 0,
    height: '100%',
  },
  headerPanel: {
    backgroundColor: '#185b8d',
    width: '100%',
    height: 176,
  },
  logo: {
    width: 350,
    lineHeight: 94,
  },
  logoDiv: {
    width: '100%',
    textAlign: 'center',
  },
  button: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    background: theme.palette.background.button,
    padding: '10px 10px',
    lineHeight: 1.4,
    borderRadius: 30,
    width: '100%',
  },
  content: {
    margin: '2% 20% 0 20%',
  },
  buttonDiv: {
    textAlign: '-webkit-center',
  },
  buttonContainer: {
    marginTop: 20,
    width: 300,
    marginBottom: 20,
  },
  link: {
    color: '#979797',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  storeButton: {
    width: 150,
    lineHeight: 94,
  },
  contentFooterDiv: {
    marginTop: '1%',
    marginBottom: '1%'
  },
  sMediaDiv: {
    width: '10%'
  },
  sMediaButton: {
    width: 50,
    marginRight: 10
  },
  footerDiv: {
    textAlign: 'center',
    marginTop: '1%',
  }
}));

export default function ListarEquipes() {
  const classes = useStyle();

  const [data, setData] = useState([]);

  const getListaUsuarios = async () => {
    fetch("http://localhost/api/lista-usuarios")
    .then((response) => response.json())
    .then((responseJson) => (
      //console.log(responseJson),
      setData(responseJson)
    ))
  }

  useEffect(() => {
    getListaUsuarios();
  }, [])

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.headerPanel}>
        <div className={classes.logoDiv}>
          <img className={classes.logo} src={'images/logo_white.png'}
               alt="Logo"/>
        </div>
      </Box>
      <Box className={classes.content}>
        <table>
          <thead>
            <tr>
              <th>Foto</th>
              <th>Nome</th>
              <th>Email</th>
              <th>Ações</th>
            </tr>
          </thead>
        </table>
        {/* Olá, usuario!<br/>
        <br/>
        Falta pouco para finalizarmos o seu cadastro. Você só conseguirá realizar seu login,
        após a confirmação da sua conta, clique no botão abaixo:
        <Box className={classes.buttonDiv}>
          <Grid className={classes.buttonContainer} container justify='center'>
            <Button className={classes.button} onClick={routeChange}>
              Confirmar Conta
            </Button>
          </Grid>
        </Box>
        Caso o  botão acima não funcione copie e cole a seguinte URL no seu navegador:<br/>
        <strong>URL</strong>
        <br/>
        <br/>
        <em>Obs.: A confirmação de conta somente poderá ser usada uma vez.</em>
        <Box className={classes.footerDiv}>
          Baixe nosso aplicativo<br/>
          <div className={classes.contentFooterDiv}>
            <img className={classes.storeButton} style={{ marginRight: '2%' }}
                 src={'images/disp_gplay.png'}
                 alt="Disponível no Google Play"/>
            <img className={classes.storeButton} src={'images/disp_apstore.png'}
                 alt="Disponível na Apple Store"/>
          </div>
          <Box>
            Siga a Vila A nas redes sociais<br/>
            <div className={classes.contentFooterDiv}>
              <img className={classes.sMediaButton} src={'images/logo_facebook.png'}
                   alt="Logo Facebook"/>
              <img className={classes.sMediaButton} src={'images/logo_twitter.png'}
                   alt="Logo Twitter"/>
              <img className={classes.sMediaButton} src={'images/logo_youtube.png'}
                   alt="Logo Youtube"/>
            </div>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
}
