import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Button, Grid} from '@material-ui/core';
import {telefoneMask} from '../../util';
import {capturarChamado, salvarObservacao,} from '../../../actions/BotaoPanicoAction';
import {connect} from 'react-redux';
import Form from 'react-validation/build/form';
import {Alert} from '@material-ui/lab';
import GoogleMapReact from 'google-map-react';
import PlaceIcon from '@material-ui/icons/Place';


const REACT_APP_KEY_GOOGLE_MAPS_API = process.env.REACT_APP_KEY_GOOGLE_MAPS_API

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  gridLabel: {
    fontWeight: 'bold',
  },
  gridText: {
    paddingLeft: '20px',
  },
  formCadastro: {
    margin: '15px',
  },
});

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const mensagemPadraoCarregando =
  (
    <div style={{textAlign: 'center', padding: 15, margin: '0 auto'}}>
      <CircularProgress
        style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
    </div>
  );

class Detalhar extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeCodChamado = this.onChangeCodChamado.bind(this);
    this.onChangCapturarChamado = this.onChangCapturarChamado.bind(this);
    this.onClickVoltar = props.onVoltar;

    this.state = {
      cod_chamado: props.arrDetalhar.codChamado,
      str_nome: props.arrDetalhar.strNomePessoaChamadoCompleto,
      str_telefone: props.arrDetalhar.strTelefone ? telefoneMask(props.arrDetalhar.strTelefone) : ' - ',
      num_latitude_chamado: props.arrDetalhar.numLatitudeChamado ?? null,
      num_longitude_chamado: props.arrDetalhar.numLongitudeChamado ?? null,
      geo_trajeto_percorrido: props.arrDetalhar.geoTrajetoPercorrido ?? null,
      str_nome_pessoa_responsavel: props.arrDetalhar.strNomePessoaResposavelChamadoCompleto ?? null,
      flg_situacao: 'A',
      isLoadingEdit: true,
      isLoading: false,
      alert: '',
      loading: false,
      successful: '',
      redirect: false,
    };
  }

  static defaultProps = {
    center: {
      lat: -25.5003678,
      lng: -54.5684169
    },
    zoom: 10
  };

  componentDidMount() {
    // on mount component
  }

  componentDidUpdate() {
    // on update component
  }

  onChangeCodChamado(e) {
    this.setState({
      cod_chamado: e.target.value,
    });
  }

  handleApiLoaded = (map, maps) => {
    console.log(maps);
  };

  createMapOptions = (maps) => {
    return {
      panControl: true,
      mapTypeControl: true,
      scrollwheel: true,
      rotateControl: true,
      fullscreenControl: true,
    }
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      successful: '',
      isLoading: true,
    });
    this.form.validateAll();
    this.props
      .dispatch(
        capturarChamado(
          this.state.cod_chamado,
          'E',
        ),
      )
      .then(() => {
        this.setState({
          successful: true,
          redirect: false,
          isLoading: false,
        });
        this.timeout = setTimeout(() => {
          this.setState({
            successful: '',
          }, this.onChangCapturarChamado());
        }, 3000);
      })
      .catch(() => {
        this.setState({
          successful: false,
          redirect: false,
          isLoading: false,
        });
      });
  }

  onChangCapturarChamado = () => {
    if(this.state.str_nome_pessoa_responsavel === null){
      this.setState({str_nome_pessoa_responsavel: JSON.parse(localStorage.getItem("user")).nome});
    }else{
      this.setState({str_nome_pessoa_responsavel: null});
    }
  }

  render() {
    const {successful, isLoggedIn, message, classes} = this.props;

    return (
      <>
        {this.state.isLoading === true &&
        <Grid container spacing={2} style={{marginTop: 25}}>
          <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
            {mensagemPadraoCarregando}
          </Grid>
        </Grid>
        }
        {
          this.state.isLoading === false &&
          <Form onSubmit={this.handleRegister} ref={(c) => {
            this.form = c;
          }} className={classes.formCadastro}>
            {this.state.successful == false && this.state.successful !== '' && (
              <div className="row">
                <div className="col mb-2">
                  <Alert severity={'error'}>{message}</Alert>
                </div>
              </div>
            )}
            {this.state.successful == true && this.state.successful !== '' && (
              <div className="row">
                <div className="col mb-2">
                  <Alert severity={'success'}>{message}</Alert>
                </div>
              </div>
            )}
            <Grid className={classes.formCadastro}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2} className={classes.gridLabel}>Nome:</Grid>
                <Grid item xs={12} sm={4} className={classes.gridText}>{this.state.str_nome}</Grid>
                <Grid item xs={12} sm={2} className={classes.gridLabel}>Telefone:</Grid>
                <Grid item xs={12} sm={4} className={classes.gridText}>{this.state.str_telefone}</Grid>

                <Grid item xs={12} sm={2} className={classes.gridLabel}>Localização:</Grid>
                <Grid item xs={12} sm={4} className={classes.gridText}> {this.state.num_latitude_chamado} {','} {this.state.num_longitude_chamado}</Grid>
                {this.state.str_nome_pessoa_responsavel !== null &&
                <>
                  <Grid item xs={12} sm={2}
                        className={classes.gridLabel}>Responsável:</Grid>
                  <Grid item xs={12} sm={4}
                        className={classes.gridText}> {this.state.str_nome_pessoa_responsavel} </Grid>
                </>
                }
                {this.state.str_nome_pessoa_responsavel === null &&
                <Grid item xs={12} sm={12}><Button type='submit' >Capturar Chamado</Button></Grid>
                }
              </Grid>
              <hr></hr>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <div style={{border: '0', height: '300px', width: '100%'}}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: REACT_APP_KEY_GOOGLE_MAPS_API }}
                      defaultCenter={this.props.center}
                      defaultZoom={this.props.zoom}
                      options={this.createMapOptions}
                      yesIWantToUseGoogleMapApiInternals
                      onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                    >
                      <AnyReactComponent
                        lat={this.state.num_latitude_chamado}
                        lng={this.state.num_longitude_chamado}
                        text= <PlaceIcon className={classes.iconeTitulo}/>
                      />
                    </GoogleMapReact>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <hr></hr>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{textAlign: 'right'}}>
                  {this.state.isLoading === false &&
                  <>
                    <Button onClick={this.onClickVoltar}>Cancelar </Button>
                  </>
                  }
                </div>
              </Grid>
            </Grid>
          </Form>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  const {message} = state.message;

  return {user, message};
}

export default connect(mapStateToProps)(withStyles(styles)(Detalhar));
