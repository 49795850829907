import axios from "axios";
import authHeader from './auth-header';
import trataErroApi from './trata-erro-api';

const API_URL = process.env.REACT_APP_API_URL

class ArquivoService {

  async download(codArquivo) {
    return axios
      .post(API_URL + "arquivo/download", { codArquivo: codArquivo }, { headers: authHeader() })
      .then((response) => {
        if (response.data.base64) {
          const linkSource = response.data.base64;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = response.data.fileName;
          downloadLink.click();
        } else {
          alert('Erro ao recuperar o arquivo');
        }
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getImage(path) {
    return axios
      .post(API_URL + "arquivo/getImage", { path: path }, { headers: authHeader() })
      .then((response) => {
        if (response.data.path) {
          return response.data.path;
        } else {
          alert('Erro ao recuperar o arquivo');
        }
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

} export default new ArquivoService();
