import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid} from '@material-ui/core';
import {Chart} from 'react-google-charts';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  Area,
  Bar,
} from 'recharts';
import CircularProgress from '@material-ui/core/CircularProgress';

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
  );

export default function Graficos(props) {

  const tagTempo = [];
  if (props.arrDadosGraficos.tagTempo !== undefined) {
    Object.values(props.arrDadosGraficos.tagTempo).map((row, index) =>
      tagTempo.push(row),
    );
  }

  const tagRedeSocial = [];
  if (props.arrDadosGraficos.tagRedeSocial !== undefined) {
    Object.values(props.arrDadosGraficos.tagRedeSocial).map((row, index) =>
      tagRedeSocial.push(row),
    );
  }

  let isLoadGraficos = true;
  if(props.arrDadosGraficos.tagRedeSocial !== undefined && props.arrDadosGraficos.tagTempo !== undefined){
    isLoadGraficos = false;
  }

  return (
    <>
      {isLoadGraficos === true &&
        <Grid container spacing={2} style={{ marginTop: 25 }}>
          <Grid item xs={10} sm={10} style={{ textAlign: 'center' }}>
            {mensagemPadraoCarregando}
          </Grid>
        </Grid>
      }
      {isLoadGraficos === false &&
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h3>HASHTAG X TEMPO:</h3>
            <LineChart
              width={1000}
              height={400}
              data={tagTempo}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 3"/>
              <XAxis dataKey="name"/>
              <YAxis/>
              <Tooltip/>
              {props.arrHashtag.map((hash, index) => (
                <Line dataKey={hash.str_hashtag}
                      stroke={props.arrCoresHashtag[index]} activeDot={{r: 8}}/>
              ))}
            </LineChart>
          </Grid>

          <Grid item xs={12}>
            <h3>HASHTAG X REDES SOCIAIS:</h3>
            <ComposedChart
              layout="vertical"
              width={1000}
              height={400}
              data={tagRedeSocial}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 50,
              }}
            >
              <CartesianGrid stroke="#f5f5f5"/>
              <XAxis type="number"/>
              <YAxis dataKey="name" type="category" scale="band"/>
              <Tooltip/>
              {props.arrHashtag.map((hash, index) => (
                <Bar dataKey={hash.str_hashtag} barSize={20}
                     fill={props.arrCoresHashtag[index]}/>
              ))}
            </ComposedChart>
          </Grid>
        </Grid>
      }
    </>
  );
}
