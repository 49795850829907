import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { clearMessage } from "../../actions/MessageAction";
import { history } from "../../helpers/history";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid } from '@material-ui/core';
import Storage from '@material-ui/icons/Storage';
import SelectPadrao from '../partials/SelectPadrao';
import FormControl from '@material-ui/core/FormControl';
import InputPesquisarPadrao from '../partials/InputPesquisarPadrao';
import DatePickerPtBrPadrao from '../partials/DatePickerPtBrPadrao';
import TabelaPaginada from '../partials/DataTablePaginadapadrao';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import AuditoriaService from "../../services/auditoria.service";
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import VisualizarLog from '../partials/auditoria/Visualizar';

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
    },
    formCadastro: {
        margin: '15px 0 15px 0',
    },
    conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
    tituloPadrao: theme.pagina.tituloPadrao,
    iconeTitulo: theme.pagina.iconeTitulo,
    formCadastroTitulo: theme.pagina.formCadastroTitulo,
    buttonCadastro: theme.pagina.buttonCadastro,
    buttonPesquisar: theme.pagina.buttonPesquisar,
    formControl: {
        margin: theme.spacing(1),
        minWidth: 350,
        maxWidth: 400,
    },
    button: {
        color: '#000',
        backgroundColor: '#FFF',
        border: 'solid 1px #005b96',
        maxWidth: 100,
        height: '42px',
        marginTop: '25px',
        '&:hover': {
            color: '#005b96',
            backgroundColor: '#FFF',
            border: 'solid 1px #005b96',
        },
    },
});

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
    )

const arrTipoFuncionalidades = [
    {
        cod_funcionalidade: 'todas',
        str_nome: 'Todas',
    },
]

const arrTipoAlteracao = [
    {
        id: 'todas',
        nome: 'Todas',
    },
    {
        id: 'I',
        nome: 'Cadastro',
    },
    {
        id: 'U',
        nome: 'Edição',
    },
    {
        id: 'D',
        nome: 'Exclusão'
    },
]
class Auditoria extends Component {

    constructor(props) {
        super(props);
        history.listen((location) => {
            props.dispatch(clearMessage());
        });

        this.state = {
            user: localStorage.getItem("user"),
            campoPesquisar: null,
            tipoFuncionalidade: 'todas',
            tipoAlteracao: 'todas',
            tipoAlteracaoIsLoaded: false,
            dataInicial: null,
            dataFinal: null,
            mensagemAlert: { mensagem: null, severity: 'success', closeButton: true },
            isLoading: false,
            isLoaded: false,
            arrDados: true,
            arrTipoFuncionalidades: [],
            isLoadingVisualizarLog: false,
            codAuditoria: null
        };

    }

    componentDidMount() {
        // on mount component
        this.carregarFuncionalidades()
        // this.pesquisarLogs();
    }

    componentDidUpdate() {
        // on update component
    }

    carregarFuncionalidades = async () => {
        const arrListaFuncionalidades = await AuditoriaService.getListaFuncionalidades();
        this.setState({ arrTipoFuncionalidades: [...arrTipoFuncionalidades, ...arrListaFuncionalidades], tipoAlteracaoIsLoaded: true });
    }

    handleChangeCampoPesquisar = (valor) => {
        this.setState({ campoPesquisar: valor });
    }

    handleChangeTipoFuncionalidade = (event) => {
        this.setState({ tipoFuncionalidade: event.target.value });
    }

    handleChangeTipoAlteracao = (event) => {
        this.setState({ tipoAlteracao: event.target.value });
    }

    onChangeDataInicial = (data) => {
        this.setState({ dataInicial: data });
    }

    onChangeDataFinal = (data) => {
        this.setState({ dataFinal: data });
    }

    pesquisarLogs = async () => {
        this.setState({ isLoaded: false });
        this.setState({ isLoading: true });
        const arrFiltros =
        {
            busca: this.state.campoPesquisar,
            cod_funcionalidade: this.state.tipoFuncionalidade,
            tipo_alteracao: this.state.tipoAlteracao,
            data_inicial: this.state.dataInicial,
            data_final: this.state.dataFinal,
        }

        const arrDados = await AuditoriaService.getDadosListaAuditoria(arrFiltros);
        //this.setState({ mensagemAlert: { mensagem: mensagemPadraoCarregando , severity: 'info', closeButton: false } });
        this.setState({ arrDados: arrDados });
        this.setState({ isLoaded: true });
        this.setState({ isLoading: false });
    }

    onClickVisualizar = (codAuditoria) => {
        this.setState({ isLoadingVisualizarLog: true });
        this.setState({ codAuditoria: codAuditoria });
    }

    onClickVisualizarUsuario = (valor) => {
        console.log('usuario', valor);
    }

    onVoltar = () => {
        this.setState({ isLoadingVisualizarLog: false });
        this.setState({ codAuditoria: null });
    }

    trataDescricaoHMTL = (str_descricao) => {
        return <span dangerouslySetInnerHTML={{ __html: str_descricao }} ></span>
    }

    render() {
        const {classes} = this.props;

        return (
          <div className={classes.root}>
              <Grid container className={classes.conteudoFundoBranco} style={{minWidth: '405px'}}>
                  <Grid item xs={12} className={classes.formCadastroTitulo}>
                      <Storage className={classes.iconeTitulo}/>
                      <span className={classes.tituloPadrao}>
                          {this.state.codAuditoria !== null ? 'Visualizar Log' : 'Auditoria'}
                      </span>
                  </Grid>
                  {this.state.isLoadingVisualizarLog === true && this.state.codAuditoria !== null &&
                  <Grid item xs={12}>
                      <VisualizarLog codAuditoria={this.state.codAuditoria}
                                     onVoltar={this.onVoltar}/>
                  </Grid>
                  }
                  {this.state.codAuditoria == null &&
                  <>
                      <Grid item xs={6} style={{minWidth: 355, marginTop: 10, marginBottom: 10}}>
                          <InputPesquisarPadrao stylePaper={{height: 52}}
                                                exibirBotao={false}
                                                onChange={this.handleChangeCampoPesquisar}
                                                value={this.state.campoPesquisar || ''}
                          />
                      </Grid>
                      <Grid item xs={3} style={{minWidth: 260, paddingLeft: 5}}>
                          <SelectPadrao
                            label="Selecione a Funcionalidade"
                            id="id-tipo-funcionalidade"
                            value={this.state.tipoFuncionalidade}
                            onChange={this.handleChangeTipoFuncionalidade}
                            arrDados={this.state.arrTipoFuncionalidades}
                            isLoaded={this.state.tipoAlteracaoIsLoaded}
                          />
                      </Grid>
                      <Grid item xs={3} style={{minWidth: 260, paddingLeft: 5}}>
                          <SelectPadrao
                            label="Selecione o Tipo de Alteração"
                            id="id-tipo-alteracao"
                            value={this.state.tipoAlteracao}
                            onChange={this.handleChangeTipoAlteracao}
                            arrDados={arrTipoAlteracao}
                          />
                      </Grid>
                      <Grid item xs={12}>
                          <div>
                              <FormControl
                                >
                                  <Grid container>
                                      <Grid item xs={6} style={{minWidth: 268, paddingLeft: 10}}>
                                          <DatePickerPtBrPadrao
                                            id="campo-data-inicial"
                                            label="Data Inicial"
                                            value={this.state.dataInicial}
                                            onChange={this.onChangeDataInicial}
                                          />
                                      </Grid>
                                      <Grid item xs={6}
                                            style={{minWidth: 268, paddingLeft: 10}}>
                                          <DatePickerPtBrPadrao
                                            id="campo-data-final"
                                            label="Data Final"
                                            value={this.state.dataFinal}
                                            onChange={this.onChangeDataFinal}
                                            dataMinima={this.state.dataInicial}
                                            erroDataMinima="A data final não pode ser anterior à data inicial."
                                          />
                                      </Grid>
                                  </Grid>
                              </FormControl>
                              {this.state.isLoading === true &&
                              <Button style={{
                                  width: 100,
                                  height: 51,
                                  margin: '17px 10px',
                                  textAlign: 'center',
                              }}>
                                  <CircularProgress style={{
                                      width: 20,
                                      height: 20,
                                      marginRight: 15,
                                  }}/>
                              </Button>
                              }
                              {this.state.isLoading === false &&
                              <Button style={{
                                  width: 100,
                                  height: 51,
                                  margin: '17px 10px',
                                  textAlign: 'center',
                              }} variant="contained"
                                      onClick={this.pesquisarLogs}>
                                  Pesquisar
                              </Button>
                              }
                          </div>
                      </Grid>
                      {this.state.mensagemAlert.mensagem !== null &&
                      <Grid item xs={12}
                            style={{marginBottom: 15, marginTop: 15}}>
                          <Alert
                            severity={this.state.mensagemAlert.severity}
                            action={
                                this.state.mensagemAlert.closeButton === true &&
                                <IconButton
                                  aria-label="close"
                                  color="inherit"
                                  size="small"
                                  onClick={() => {
                                      this.setState({
                                          mensagemAlert: {
                                              mensagem: null,
                                              severity: 'success',
                                              closeButton: true,
                                          },
                                      });
                                  }}
                                >
                                    <CloseIcon fontSize="inherit"/>
                                </IconButton>
                            }
                          >
                              {this.state.mensagemAlert.mensagem}
                          </Alert>
                      </Grid>
                      }
                      {this.state.isLoaded === true &&
                      <TabelaPaginada
                        name="tabela-sem-borda"
                        arrCabecalho={['ID', 'Usuário', 'Data', 'Funcionalidade', 'Descrição', 'Ações']}
                        arrDados={this.state.arrDados}
                        arrFuncoes={
                            {
                                str_descricao: {
                                    chave: 'str_descricao',
                                    funcao: this.trataDescricaoHMTL,
                                },
                            }
                        }
                        arrAcoes={
                            {
                                acao: [
                                    {
                                        chave: 'cod_auditoria',
                                        funcao: this.onClickVisualizar,
                                        icone: SearchIcon,
                                    },
                                ],

                            }
                        }
                      />
                      }
                  </>
                  }
              </Grid>
          </div>
        );
    }
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Auditoria));
