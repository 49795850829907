        import React, {Component} from 'react';
        import {withStyles} from '@material-ui/core/styles';
        import {Grid} from '@material-ui/core';
        import {connect} from 'react-redux';
        import TextoRicoPadrao from '../components/partials/TextoRicoPadrao';
        import draftToHtml from 'draftjs-to-html';
        import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
        import '../views/style/termos-publicos.css';
        import CircularProgress from '@material-ui/core/CircularProgress';
        import TermoPoliticaService from '../services/termo-politica.service';

        const styles = (theme) => ({
        root: {
            flexGrow: 1,
        },

        userCicle: {
            width: '150px',
          },

        contentFooterDiv: {
            marginTop: '1%',
            marginBottom: '1%',
        },

        footerDiv: {
            textAlign: 'center',
            marginTop: '1%',
        },

          editorClassDisabled: {
            backgroundColor: 'white',
            padding: '1rem;',
            border: '0',
          },
        });

        const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
    )

        class TermoPolitica extends Component {
        constructor(props) {
            super(props);
            this.onEditorTermoStateChange = this.onEditorTermoStateChange.bind(this);
        
            this.state = {
            redirect: false,
            editorStateTermo: '',
            isLoadingEdit: true,
            cod_politica_privacidade: '',
            str_politica_privacidade: '',
            str_politica_privacid_tag: '',
            is_null_politica: '',

            };
        }

        componentDidMount() {
            
            this.getDados();
        }

        
        getDados = () => {
            const data = Promise.resolve(TermoPoliticaService.getDadosTermo());
        
            if (data) {
              data.then((v) => {
                this.populatePoliticaForm(v.politica);
              });
            }
          };
          
        
          populatePoliticaForm = (dados) => {
            if (dados != undefined && dados.str_politica_privacidade != '' && dados.str_politica_privacidade != undefined) {
              let row = convertFromRaw(dados.str_politica_privacidade);
              this.setState({
                editorStateTermo: EditorState.createWithContent(row),
                str_politica_privacidade_tag: dados.str_politica_privacidade_tag,
                cod_politica_privacidade: dados.cod_politica_privacidade,
              });
            }else{
              this.setState({editorStateTermo: ''});
            }
        
            this.setState({isLoadingEdit: false});
          };

            onEditorTermoStateChange = (editorStateTermo) => {
                let element = convertToRaw(editorStateTermo.getCurrentContent());
                let text = element.blocks[0].text;

                this.setState({
                    editorStateTermo,
                    str_desc_instrucao: JSON.stringify(convertToRaw(editorStateTermo.getCurrentContent())),
                    str_desc_instrucao_fmt: draftToHtml(convertToRaw(editorStateTermo.getCurrentContent())),
                    is_null_termo: text === '' ? true : false,
                });
            };
                
            render() {
                const {classes} = this.props;                
                const {editorStateTermo} = this.state;

                
            return (
                <>
                    {this.state.isLoadingEdit === true &&
                        <Grid container spacing={2} style={{marginTop: 25}}>
                        <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
                            {mensagemPadraoCarregando}
                        </Grid>
                        </Grid>
                    }
                    {this.state.isLoadingEdit === false &&
                    <div>
                    <Grid container style={{ minWidth: 330}}>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                    <TextoRicoPadrao 
                                    editorState={editorStateTermo}
                                    editorClassName={classes.editorClassDisabled}
                                    />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                    }
               
               </> 
            );
        }
        }



        function mapStateToProps(state) {
        const {isLoggedIn} = state.auth;
        return {
            isLoggedIn,
        };
        }

        export default connect(mapStateToProps)(withStyles(styles)(TermoPolitica));
