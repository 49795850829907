/**
 * Formata Data no padrão brasiliero
 * @param  value formato DD/MM/YYYY
 * @returns {string|string|*}
 */
export const dateFormatter = (value) => {
  if (value) {
    // Caso o valor já esteja formatado
    if (value.split('/').length == 3) {
      return value;
    }

    var data = value.substr(0, 10).split('-');
    return Array.isArray(data) && data.length > 0 ? data[2] + '/' + data[1] + '/' + data[0] : '';
  }

  return '';
};

/**
 * Formata a hora no padrão HH:MM
 * @param value hora no formato HH:MM:SS
 * @returns {*}
 */
export const timeFormatter = (value) => {

  if (value) {
    var tempo = value.split(':');
    return tempo[0] + ':' + tempo[1];
  } else {
    return '-';
  }

};

/**
 * Add Marcara de CPF
 * Criado por: Lucas Silva
 * @param value forma 999.999.999-99
 * @returns {*}
 */
export const cpfMask = (value) => {
  return value
    // substitui qualquer caracter que nao seja numero por nada
    .replace(/\D/g, '')
    // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    .replace(/(-\d{2})\d+?$/, '$1');
};

/**
 * Add Marcara de CNPF
 * @param value forma 00.000.000/0000-00
 * @returns {*}
 */
export const cnpjMask = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
};


/**
 * Add Mascara CEP
 * @param value formato 73.150-150
 * @returns {*}
 */
export const cepMaks = (value) => {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2');
  }
};

/**
 *
 * @param value formato (61) 99999-9999 / (61) 9999-9999
 * @returns {string|*}
 */
export const telefoneMask = (value) => {

  if (value == '' || value == null || value == undefined) {
    return '';
  }

  //Trava o telefone em 15 digitos com (), e -
  if (value.length > 15) {
    return value.substr(0, 15);
  }

  // Remove tudo o que não é dígito
  value = value.replace(/\D/g, '');
  // Coloca parênteses em volta dos dois primeiros dígitos
  value = value.replace(/^(\d\d)(\d)/g, '($1) $2');
  // Número com 8 dígitos. Formato: (99) 9999-9999
  if (value.length < 14) {
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
  }// Número com 9 dígitos. Formato: (99) 99999-9999
  else {
    value = value.replace(/(\d{5})(\d)/, '$1-$2');
  }
  return value;
};

/**
 * Valida se email é valido
 * @param value
 * @returns {boolean}
 */
export const validateEmail = (value) => {
  var re = /\S+@\S+\.\S+/;

  return re.test(value);
};

/**
 * Formata a data para ser exbido no compomente KeyboardDatePicker
 * Criado por Skaylle
 * @param value YYYY-MM-DD
 * @returns {boolean}
 */
export const formatDataComponent = (value) => {
  if (value == '' || value == undefined) {
    return;
  }

  let partes = value.split('-');
  let ano = partes[0];
  let mes = partes[1] - 1;
  let dia = partes[2];

  let datainicial = new Date(ano, mes, dia);
  let datafinal = new Date(datainicial);
  datafinal.setDate(datafinal.getDate() + 1);

  let dd = ('0' + datafinal.getDate()).slice(-2);
  let mm = ('0' + (datafinal.getMonth() + 1)).slice(-2);
  let y = datafinal.getFullYear();

  return y + '-' + mm + '-' + dd;
};

/**
 * Formata timestamp
 * @param value
 * @returns {string}
 */

export const timestampFormatter = (value) => {
  if (value) {
    var data = dateFormatter(value);
    var hora = value.substr(11, 8);
    return data + ' ' + hora;
  }

  return '';

  // A formatação abaixo está apresentando problemas no IE e no Firefox
  //return $.datepicker.formatDate('dd/mm/yy ', new Date(value));
};

/**
 * Formata Data no padrão americadno
 * @param  value formato DD/MM/YYYY
 * @returns {string|string|*}
 */
export const dateFormatterAmericano = (value) => {
  if (value) {
    let data = value;

    if (data[0] == 1) {
      data = data[0] + 1 + '/' + data[1] + '/' + data[2];
    }
    return data.split('/').reverse().join('-');
  }

  return '';
};

/**
 * Formata para receber apenas numeros
 * @param value apenas numeros
 * @returns {*}
 */
export const limpaCaracter = (value) => {
  return value
    .replace(/\D/g, '');
};

/**
 *
 * @param data -> Data a ser validade -> Sun May 01 2022 21:51:00 GMT-0300 (Horário Padrão de Brasília)
 * @param required -> Valida campo obrigatorio
 * @param minDate -> Validar data menor
 * @param dateMin -> Data menor a ser validada -> Sun May 01 2022 21:51:00 GMT-0300 (Horário Padrão de Brasília)
 * @param maxDat -> Validar data maior
 * @param datMax -> Data menor a ser validada -> Sun May 01 2022 21:51:00 GMT-0300 (Horário Padrão de Brasília)
 * @param message -> Mensagem de retorno
 * @returns {{message: string, status: boolean}|{message: null, status: boolean}}
 */
export const validaInputDataComponente = (data, required = true, minDate = false, dateMin = null, maxDat = false, datMax = null, message = null) => {
  if (data == null && !required) {
    return {'status': true, 'message': null};
  }

  if (data == null && required) {
    return {'status': false, 'message': 'Campo Obrigatório.'};
  }

  if (data == 'Invalid Date') {
    return {'status': false, 'message': 'Data Inválida.'};
  }

  if (minDate && data != null && dateMin != null && dateMin != 'Invalid Date' && data != 'Invalid Date') {
    if (mudaData(new Date(data).toLocaleDateString()) < mudaData(new Date(dateMin).toLocaleDateString())) {
      return {
        'status': false,
        'message': message ?? 'A Data fim não pode ser menor que a Data início.',
      };
    }
  }

  if (maxDat && data != null && datMax != null && datMax != 'Invalid Date' && data != 'Invalid Date') {
    if (mudaData(new Date(data).toLocaleDateString()) > mudaData(new Date(datMax).toLocaleDateString())) {
      return {
        'status': false,
        'message': message ?? 'A Data não pode ser superior.',
      };
    }
  }

  return {'status': true, 'message': message ?? null};
};

/**
 * Valida data
 * @param data -> MM/DD/YYYY -> data obrigatória
 * @param formato -> MM/DD/YYYY
 * @returns {boolean}
 */
export const validaData = (value) => {
  if (value == null || value == '' || value == undefined) return 'Data obrigatória';
  let data = value.split('/');
  let erro = false;

  var dia = data[0];
  var mes = data[1];
  var ano = data[2];

  if (['01', '03', '05', '07', '08', '10', '12'].includes(mes)) {
    if (dia <= 31) {
      erro = true;
    }
  }

  if (['04', '06', '09', '11'].includes(mes)) {
    if (dia <= 30) {
      erro = true;
    }
  }

  if (['02'].includes(mes)) {
    if ((ano % 400 == 0) || (ano % 4 == 0 && ano % 100 != 0)) {
      if (dia <= 29) {
        erro = true;
      }
    } else if (dia <= 28) {
      erro = true;
    }
  }

  let dataAtual = new Date();
  let calc = dataAtual.getFullYear() - ano == 'NaN' ? 0 : dataAtual.getFullYear() - ano;

  if (calc > 122) {
    erro = false;
  }

  return erro;
};

export const mudaData = (value) => {
  value = value.split('/');

  if (value[2].lenght === 4) {
    return value[0] + '/' + value[1] + '/' + value[2];
  } else {
    return value[2] + '/' + value[1] + '/' + value[0];
  }
};


