import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import {logout} from '../../actions/AuthAction';
import {connect} from 'react-redux';
import {clearMessage} from '../../actions/MessageAction';
import {history} from '../../helpers/history';
import {FaBook} from 'react-icons/fa';
import {SubTitulo} from '../../views/style/styles';
import {Alert} from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Equipe from '../../services/equipe.service';
import CircularProgress from '@material-ui/core/CircularProgress';
// import UserForm from "./UserForm";

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import ModalPadrao from "../partials/ModalPadrao";
import TermoPoliticaService from "../../services/termo-politica.service";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },

    conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
    tituloPadrao: theme.pagina.tituloPadrao,
    iconeTitulo: theme.pagina.iconeTitulo,
    formCadastroTitulo: theme.pagina.formCadastroTitulo,
    formButton: theme.pagina.formButton,

    logoutButton: {
        height: 20,
    },

    formCadastro: {
        margin: '15px',
    },

    cardEquipe: {
        flex: '1 1 auto',
        padding: '5px 5px 5px',
        //margin: '5px 25px 25px 0',
        borderRadius: '5px',
    },
});

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
    );

class Home extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.loadMenu = this.loadMenu.bind(this);

        this.state = {
            arrMenu: [],
            isLoading: true,
            notPerfil: false,
            count: 0,
            mensagemAlert: {mensagem: null, severity: 'success', closeButton: true},
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null ,
            openModal: false,
            messageSuccess: "",
        };

        history.listen((location) => {
            props.dispatch(clearMessage());
        });
    }

    logOut() {
        this.props.dispatch(logout());
    }

    componentDidMount() {
        this.loadMenu();
        this.verificaMensagem();

        let data = this.state.user
        this.setState({openModal: !data.flg_aceite_politica_privacidade && !data.flg_aceite_termo_uso})
    }

    verificaMensagem = () => {
        const url = window.location.href;
        if (url.includes('?m=')) {
            const arrMessage = url.split('?m=');
            if (arrMessage.length === 2) {
                const httpCode = arrMessage[1];
                if (httpCode === '401') {
                    this.setState({
                        mensagemAlert: {
                            mensagem: 'Você não tem permissão para acessar essa página.',
                            severity: 'error',
                            closeButton: true
                        }
                    });
                } else if (httpCode === '403') {
                    this.setState({
                        mensagemAlert: {
                            mensagem: 'Você não tem permissão para acessar essa página.',
                            severity: 'error',
                            closeButton: true
                        }
                    });
                }

            }
        }
    }

    loadMenu = async () => {
        const menu = await Equipe.getMenuHome();

        if (menu == '' || menu == null) {
            this.setState({notPerfil: true},
            );
        }

        this.setState({
            arrMenu: menu,
            isLoading: false,
        });
    };

    saveTerms = async (e) => {
        e.preventDefault();
        await TermoPoliticaService.saveAcceptTerms().then((response) => {
            this.setState({messageSuccess: response.message})

            const userData = this.state.user;
            userData.flg_aceite_politica_privacidade = true;
            userData.flg_aceite_termo_uso = true;
            localStorage.setItem("user", JSON.stringify(userData));

            setTimeout(() => {
                this.setState({
                    messageSuccess: null,
                    openModal: false,
                })
            }, 2000);
        }).catch((error) => {
            this.setState({messageSuccess: error.message})
        });
    }

    render() {
        const {classes} = this.props;

        return (
            <>
                {this.state.isLoading === true &&
                    <Grid container spacing={2} style={{marginTop: 25}}>
                        <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
                            {mensagemPadraoCarregando}
                        </Grid>
                    </Grid>
                }
                {!this.state.isLoading && <div className={classes.root}>
                    <Grid container className={classes.conteudoFundoBranco}>
                        <Grid item xs={12} className={classes.formCadastroTitulo}>
                            <span className={classes.tituloPadrao}> Bem Vindo ao Multifinalitário.</span>
                        </Grid>
                        <Grid container className={classes.formCadastro}>
                            <Grid item xs={12} className={classes.content}>
                                <SubTitulo></SubTitulo>
                                {this.state.mensagemAlert.mensagem !== null &&
                                    <Grid item xs={12} style={{marginBottom: 15, marginTop: 15}}>
                                        <Alert
                                            severity={this.state.mensagemAlert.severity}
                                            action={
                                                this.state.mensagemAlert.closeButton === true &&
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        this.setState({
                                                            mensagemAlert: {
                                                                mensagem: null,
                                                                severity: 'success',
                                                                closeButton: true
                                                            }
                                                        });
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit"/>
                                                </IconButton>
                                            }
                                        >
                                            {this.state.mensagemAlert.mensagem}
                                        </Alert>
                                    </Grid>
                                }
                                <Grid container spacing={3}>
                                    {!this.state.notPerfil &&
                                        this.state.arrMenu.map(function (item, index) {
                                            let image = '/images/home/' + item.str_permicao + '.jpg';
                                            return (
                                                <>
                                                    <br/><br/>
                                                    <Grid item xs={12} sm={4} md={4}>
                                                        <Card className={classes.root} style={{boxShadow: 'none'}}>
                                                            <CardContent>
                                                                <img style={{maxWidth: '100%'}} src={image} alt=""/>
                                                            </CardContent>
                                                            <CardContent>
                                                                {
                                                                    item.submenu.map(function (v, i) {
                                                                        return (
                                                                            <ul style={{listStyleType: 'none'}}>
                                                                                <li>
                                                                                    <a style={{color: '#1976D2'}}
                                                                                       href={v.str_rota}>{formaterLink(v.str_nome)}</a>
                                                                                </li>
                                                                            </ul>
                                                                        );
                                                                    })
                                                                }
                                                            </CardContent>
                                                        </Card>
                                                    </Grid>
                                                </>
                                            );
                                        })
                                    }
                                    {this.state.notPerfil &&
                                        <p style={{margin: '8px 15px'}}>
                                            <h4> Parece que você ainda não recebeu nenhuma permissão para acessar o
                                                sistema. Entre em contato com um dos administradores para solicitar o
                                                seu acesso.</h4>
                                        </p>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <ModalPadrao open={this.state.openModal} handleCloseModal={() => this.setState({openModal: false})}>
                        <h5>Aviso!</h5>
                        <hr></hr>
                        {this.state.messageSuccess &&
                            <p>
                                <div className="row">
                                    <div className="col mb-2">
                                        <Alert severity={'success'}>{this.state.messageSuccess}</Alert>
                                    </div>
                                </div>
                            </p>
                        }
                        <p> Ouve alteração nos Termos de Uso e Política de Privacidade do Aplicativo!</p>
                        <p><a href="/termo-uso-public" target="_blank">Termos de Uso</a></p>
                        <p><a href="/termo-politica-public" target="_blank">Política de Privacidade do Aplicativo</a>
                        </p>
                        <hr></hr>
                        <div style={{textAlign: 'right'}}>
                            <Button
                                onClick={this.saveTerms}
                                className={classes.formButton}
                                variant="outlined">Aceitar
                            </Button> &nbsp;
                            <Button
                                href={'/login'}
                                variant="text"
                                onClick={logout()}>
                                SAIR
                            </Button>
                        </div>
                    </ModalPadrao>
                </div>
                }
            </>
        );
    }
}

function formaterLink(str_nome) {
    let label = str_nome;

    switch (label) {

        //  ADMINSTRAÇÃO

        case 'Gerenciar Auditoria' :
            label = label.replace('Gerenciar', '')
            break;

        case 'Gerenciar Termos e Politica' :
            label = label.replace('Gerenciar Termos e Politica', 'Termos e Política')
            break;

        //   Botão do pânico

        case 'Gerenciar Mapa de Chamados' :
            label = label.replace('Gerenciar', '')
            break;

        case 'Gerenciar Chamados' :
            label = label.replace('Gerenciar', '')
            break;

        case 'Gerenciar Dashboard' :
            label = label.replace('Gerenciar', '')
            break;

        case 'Gerenciar Configurações' :
            label = label.replace('Gerenciar', '')
            break;

        case 'Gerenciar Instruções Botão do Pânico':
            label = label.replace('Botão do Pânico', '')
            break;

        case 'Funcionalidade de Visualizar Chamado':
            label = label.replace('Funcionalidade de', '')
            break;

        //   Coleta Seletiva

        case 'Gerenciar Notificações de Usuário':
            label = label.replace('Gerenciar Notificações de Usuário', 'Notificar Usuários')
            break;

        case 'Gerenciar Instruções Pontos de Coleta':
            label = label.replace('Pontos de Coleta', '')
            break;

        case 'Gerenciar Pontos de Coleta':
            label = label.replace('Gerenciar', '')
            break;

        //  Métricas

        case 'Métricas Vila A':
            label = label.replace('Vila A', '')
            break;

        case 'Métricas Conteudo':
            label = label.replace('Conteudo', 'Conteúdo')
            break;

        default:
            break;
    }

    return label.toUpperCase();
}

function mapStateToProps(state) {
    const {user} = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Home));
