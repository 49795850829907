import React from 'react';
import {TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";

const useStyle = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
}));

export default function AutoCompletePadrao(props) {

  const classes = useStyle();

  const onChangePai = (event, value) => {
    if (typeof props.onChange === 'function') {
      props.onChange(event, value);
    }
  };

  const onInputChangePai = (event, value) => {
    if (typeof props.onInputChange === 'function') {
      props.onInputChange(event, value);
    }
  };

  return (
    <Autocomplete
      value={props.value}
      id={props.id??null}
      noOptionsText={props.noOptionsText}
      loadingText="Carregando..."
      onChange={onChangePai}
      onInputChange={onInputChangePai}
      variant="outlined" fullWidth
      options={props.arrDados}
      getOptionLabel={(option) => option.value}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined" fullWidth
            label={option.value}
            size="small"
            {...getTagProps({index})}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params}
                   error={props.erro != undefined  ? props.erro :false}
                   helperText={props.message??null}
                   variant="outlined" fullWidth
                   label={props.label}
                   required={props.required??false}
        />
      )}
    />
  )
}
