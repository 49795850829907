import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import {SubTitulo} from '../../../views/style/styles';
import {connect} from 'react-redux';
import {FaUsers} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import userPerfilDefault from '../../../assets/images/user-perfil-default.png';
import Service from '../../../services/dpo.service';
import {cpfMask} from '../../util';
import ModalPadrao from '../ModalPadrao';
import DpoService from '../../../services/dpo.service';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {anonimizarDados} from '../../../actions/DpoAction';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,
  formCadastro: {
    margin: '15px',
  },
  button: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    padding: '10px 10px',
    lineHeight: 1.4,
    borderRadius: 30,
    width: '100%',
  },
  content: {
    backgroundColor: '#FFF',
    padding: '5px 5px 5px',
    borderRadius: '5px',
  },
  imgPerfil: {
    width: '100px !important',
    height: '100px',
    borderRadius: '100px',
    // margin: '12px 0 0 50px',
  },
  buttonNegar: {
    color: '#FFF',
    backgroundColor: '#C80A21',
    border: 'solid 1px #C80A21',
    '&:hover': {
      color: '#C80A21',
      backgroundColor: '#FFF',
      border: 'solid 1px #C80A21',
    },
  },
  divImagem: {
    marginRight: 0
  }
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );

const loadingPadraoBotao =
  (
    <span>
            <CircularProgress style={{width: 18, height: 18, marginRight: 15}}/>
        </span>
  );

class Visualizar extends Component {
  constructor(props) {
    super(props);

    this.onClickVoltar = props.onVoltar;

    this.state = {
      mensagemAlert: {mensagem: null, severity: 'success', closeButton: true},
      cod_solic_anonimizacao: props.codSolicAnonimizacao,
      cod_usuario_anonimizacao: null,
      str_nome_solicitante: null,
      str_email_solicitante: null,
      contador: null,
      dat_requerimento_fmt: null,
      str_observacao: null,
      str_imagem_perfil: null,
      str_tipo_usuario: null,
      genero: null,
      dat_nascimento_fmt: null,
      num_cpf: null,
      dat_devolutiva_resposta_fmt: null,
      dat_limite_exclusao_fmt: null,
      str_observacao_resposta: null,
      tp_solicitacao_fmt: null,
      str_nome_arq: null,
      cod_arquivo: null,
      base64: null,
      contador_resposta: null,
      flg_situacao_fmt: null,
      str_email_resp: null,
      str_imagem_perfil_resp: null,
      str_nome_resp: null,
      str_email_atual: null,
      tms_atualizacao_fmt: null,
      open: false,
      cod_modal: null,
      message: '',
      isLoading: true,
      successful: '',
      redirect: false,
      isLoadingButtonAninimizar: false,
    };
  }

  componentDidMount() {
    if (this.state.cod_solic_anonimizacao) {
      const data = Promise.resolve(Service.getList({cod_solic_anonimizacao: this.state.cod_solic_anonimizacao}));
      data.then((v) => {
        this.populateForm(v[0]);
      });
    }
  }

  populateForm(dados) {
    this.setState(dados);
    this.getPerilUsuario({cod_usuario: dados.cod_usuario_anonimizacao});
  }

  getPerilUsuario = async (value) => {
    const tipo_usuario = await Service.getPerilUsuario(value);

    let html = <div className="content"
                    dangerouslySetInnerHTML={{__html: tipo_usuario}}></div>;
    this.setState({str_tipo_usuario: html, isLoading: false});
  };

  setAnexo = () => {
    if (this.state.cod_arquivo) {

      return <div style={{
        color: 'blue',
        cursor: 'pointer',
        textDecoration: 'underline blue',
      }} onClick={() => {
        this.dowloadBase64();
      }}>{this.state.str_nome_arq}</div>;
    }

    return <span>-</span>;
  };

  dowloadBase64 = () => {
    if (this.state.base64 !== null && this.state.base64 !== undefined) {
      const linkSource = this.state.base64;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = this.state.str_nome_arq;
      downloadLink.click();
    }
  };

  onClickConfirma = () => {
    this.setState({
      open: false,
      isLoadingButtonAninimizar: true,
      successful: false,
    });

    this.anomizaDados();
  }

  handleClose = () => {
    this.setState({
      open: false,
    });
  }

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  anomizaDados = async () => {
    let params = {
      cod_solic_anonimizacao: this.state.cod_solic_anonimizacao,
      flg_situacao: 'F',
      cod_usuario: this.state.cod_usuario_anonimizacao,
      str_nome_solicitante: this.state.str_nome_solicitante,
    }

    this.setState({
      successful: '',
      isLoadingButtonAninimizar: true,
    });

    this.props
      .dispatch(
        anonimizarDados(params),
      )
      .then(() => {
        window.scrollTo(0, 0);

        this.setState({
          successful: true,
        });
        this.timeout = setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 3000);
      })
      .catch(() => {
        window.scrollTo(0, 0);

        this.setState({
          successful: false,
          redirect: false,
          isLoadingButtonAninimizar: false,
        });
      });
  };

  render() {
    const {successful, isLoggedIn, message, classes, acao} = this.props;

    if (this.state.redirect) {
      let url = window.location.origin;
      window.location = url + '/anonimizar-usuario';
    }

    const titulo = this.state.flg_situacao == 'A' && acao == 'A' ? 'Anonimizar usuário' : 'Vizualizar Anonimização' ;

    return (

      <>
        {this.state.isLoading === true &&
          <Grid container spacing={2} style={{marginTop: 25}}>
            <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
              {mensagemPadraoCarregando}
            </Grid>
          </Grid>
        }
        {this.state.isLoading === false && <div className={classes.root}>
          <Grid container className={classes.conteudoFundoBranco}
                style={{minWidth: 692}}>
            <Grid item xs={12} className={classes.formCadastroTitulo}>
              <FaUsers className={classes.iconeTitulo}/>
              <span className={classes.tituloPadrao}> {titulo}</span>
            </Grid>
            <Grid container>
              <Grid item xs={12} className={classes.content}>

                {this.state.successful == false && this.state.successful !== '' && (
                  <div className="row">
                    <div className="col mb-2">
                      <Alert severity={'error'}>{message}</Alert>
                    </div>
                  </div>
                )}
                {this.state.successful == true && this.state.successful !== '' && (
                  <div className="row">
                    <div className="col mb-2">
                      <Alert severity={'success'}>{message}</Alert>
                    </div>
                  </div>
                )}

                <Grid className={classes.formCadastro}>
                  <SubTitulo>Dados Pessoais</SubTitulo>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2} style={{marginTop: 15}}>
                      <img
                        src={this.state.str_imagem_perfil ? this.state.str_imagem_perfil : userPerfilDefault}
                        className={classes.imgPerfil}
                        style={{objectFit: 'cover'}}/>
                    </Grid>

                    <Grid item xs={12} sm={10} className={classes.divImagem}>
                      <Grid container spacing={2}>

                        <Grid item xs={12} sm={2}>
                          <strong>Nome:</strong>
                        </Grid>
                        <Grid item xs={10}
                              sm={10}>{this.state.str_nome_solicitante}</Grid>

                        <Grid item xs={12} sm={2}>
                          <strong>Data de Nascimento:</strong>
                        </Grid>
                        <Grid item xs={12}
                              sm={4}>{this.state.dat_nascimento_fmt}</Grid>

                        <Grid item xs={12} sm={2}>
                          <strong>Gênero:</strong>
                        </Grid>
                        <Grid item xs={12} sm={4}>{this.state.genero}</Grid>

                        <Grid item xs={12} sm={2}>
                          <strong>CPF:</strong>
                        </Grid>

                        <Grid item xs={12}
                              sm={4}>{this.state.num_cpf ? cpfMask(this.state.num_cpf) : '-'}</Grid>

                        <Grid item xs={12} sm={2}>
                          <strong>E-mail</strong>:
                        </Grid>
                        <Grid item xs={12}
                              sm={4}>{this.state.str_email_solicitante}</Grid>

                        <Grid item xs={12} sm={2}>
                          <strong>Tipo de acesso</strong>:
                        </Grid>
                        <Grid item xs={12}
                              sm={3}>{this.state.str_tipo_usuario}</Grid>
                      </Grid>

                      </Grid>
                    </Grid>

                  <SubTitulo>Dados da Solicitação</SubTitulo>
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={2}>
                      <strong>Data da devolutiva</strong>:
                    </Grid>
                    <Grid item xs={12}
                          sm={4}>{this.state.dat_devolutiva_resposta_fmt}</Grid>

                    <Grid item xs={12} sm={2}>
                      <strong>Data limite da exclusão</strong>:
                    </Grid>
                    <Grid item xs={12}
                          sm={4}>{this.state.dat_limite_exclusao_fmt}</Grid>

                    <Grid item xs={12} sm={2}>
                      <strong>Tipo de solicitação</strong>:
                    </Grid>
                    <Grid item xs={12}
                          sm={4}>{this.state.tp_solicitacao_fmt}</Grid>

                    <Grid item xs={12} sm={2}>
                      <strong>Status</strong>:
                    </Grid>
                    <Grid item xs={12}
                          sm={4}>{this.state.flg_situacao_fmt}</Grid>

                    <Grid item xs={12} sm={2}>
                      <strong>Data de requerimento exclusão</strong>:
                    </Grid>
                    <Grid item xs={12}
                          sm={10}>{this.state.dat_requerimento_fmt}</Grid>

                    <Grid item xs={12} sm={2}>
                      <strong>Observação</strong>:
                    </Grid>

                    <Grid item xs={12}
                          sm={10}>{this.state.str_observacao_resposta}</Grid>

                    <Grid item xs={12} sm={2}>
                      <strong>Anexo</strong>:
                    </Grid>
                    <Grid item xs={12} sm={10}>{this.setAnexo()}</Grid>

                    <Grid item xs={12} sm={12}>
                      <p
                        style={{fontSize: 15}}>{this.state.contador_resposta}</p>
                    </Grid>

                    {this.state.flg_situacao !== null && this.state.flg_situacao !== 'A' &&
                      <>
                        <Grid item xs={12} sm={2}>
                          <strong>Atendido por</strong>:
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          {this.state.str_nome_resp}
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <strong>Atendido em</strong>:
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          {this.state.tms_atualizacao_fmt}
                        </Grid>
                      </>
                    }

                  </Grid>

                  <SubTitulo>Responsável pela Análise</SubTitulo>
                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={2} >
                      <img
                        src={this.state.str_imagem_perfil_resp ? this.state.str_imagem_perfil_resp : userPerfilDefault}
                        className={classes.imgPerfil}
                        style={{objectFit: 'cover'}}/>
                    </Grid>

                    <Grid item xs={12} sm={10} style={{marginTop: 8}}>
                      <Grid container spacing={2}>

                        <Grid item xs={12} sm={2}>
                          <strong>Nome:</strong>
                        </Grid>
                        <Grid item xs={12}
                              sm={10}>{this.state.str_nome_resp}</Grid>

                        <Grid item xs={12} sm={2}>
                          <strong>E-mail</strong>:
                        </Grid>
                        <Grid item xs={12}
                              sm={10}>{this.state.str_email_resp}</Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} style={{marginTop: 30}}>
                    <Grid item xs={12}>
                      <div style={{textAlign: 'center'}}>
                        {this.state.flg_situacao == 'A' && acao == 'A' &&
                          <>
                            <Button variant="contained"
                                    className={classes.buttonNegar}
                                    onClick={() => {
                                      this.handleOpen();
                                    }}
                            >
                              {this.state.isLoadingButtonAninimizar ? loadingPadraoBotao : 'Anonimizar Usuário'}
                            </Button> &nbsp;
                          </>
                        }
                        <Button onClick={() => {
                          this.props.onVoltar();
                        }}>Voltar </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <ModalPadrao open={this.state.open} handleCloseModal={this.handleClose} >
            <h5>Aviso!</h5>
            <hr></hr>
            <p>
              Após realizar a anonimização do usuário <b>NÃO SERÁ POSSÍVEL
              DESFAZER</b> essa ação. Você confirma a anonimização?
            </p>
            <hr></hr>
            <Button onClick={this.onClickConfirma}
                    variant="outlined">
              Sim
            </Button> &nbsp;
            <Button onClick={this.handleClose}>
              Não
            </Button>
          </ModalPadrao>
        </div>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {isLoggedIn}  = state.auth;
  const {message}     = state.message;

  return {isLoggedIn, message};
}

export default connect(mapStateToProps)(withStyles(styles)(Visualizar));
