import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import {SubTitulo} from '../../../views/style/styles';
import {connect} from 'react-redux';
import {FaUsers} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import userPerfilDefault from '../../../assets/images/user-perfil-default.png';
import Service from '../../../services/dpo.service';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,
  formCadastro: {
    margin: '15px',
  },
  button: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    padding: '10px 10px',
    lineHeight: 1.4,
    borderRadius: 30,
    width: '100%',
  },
  content: {
    backgroundColor: '#FFF',
    padding: '5px 5px 5px',
    borderRadius: '5px',
  },
  imgPerfil: {
    width: '100px !important',
    height: '100px',
    borderRadius: '100px',
    // margin: '12px 0 0 50px',
  },
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );

class Visualizar extends Component {
  constructor(props) {
    super(props);

    this.onClickVoltar = props.onVoltar;

    this.state = {
      cod_solic_anonimizacao: props.codSolicAnonimizacao,
      str_nome_solicitante: null,
      str_email_solicitante: null,
      contador: null,
      dat_requerimento_fmt: null,
      str_observacao: null,
      str_imagem_perfil: null,
      str_tipo_usuario: null,
      message: '',
      isLoading: true,
      successful: false,
      redirect: false,
    };
  }

  componentDidMount() {
    if (this.state.cod_solic_anonimizacao) {
      const data = Promise.resolve(Service.getList({cod_solic_anonimizacao: this.state.cod_solic_anonimizacao}));
      data.then((v) => {
        this.populateForm(v[0]);
      });
    }
  }

  populateForm(dados) {
    this.setState(dados);
    this.getPerilUsuario({cod_usuario: dados.cod_usuario_anonimizacao});
  }

  getPerilUsuario = async (value) => {
    const tipo_usuario = await Service.getPerilUsuario(value);

    let html = <div className="content"
                    dangerouslySetInnerHTML={{__html: tipo_usuario}}></div>;
    this.setState({str_tipo_usuario: html, isLoading: false});
  };

  render() {
    const {successful, isLoggedIn, message, classes} = this.props;

    const {editorState} = this.state;
    const titulo = 'Visualizar Solicitação';

    return (

      <>
        {this.state.isLoading === true &&
          <Grid container spacing={2} style={{marginTop: 25}}>
            <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
              {mensagemPadraoCarregando}
            </Grid>
          </Grid>
        }
        {this.state.isLoading === false && <div className={classes.root}>
          <Grid container className={classes.conteudoFundoBranco}
                style={{minWidth: 692}}>
            <Grid item xs={12} className={classes.formCadastroTitulo}>
              <FaUsers className={classes.iconeTitulo}/>
              <span className={classes.tituloPadrao}> {titulo}</span>
            </Grid>

            <Grid container>
              <Grid item xs={12} className={classes.content}>
                <Grid className={classes.formCadastro}>

                  <SubTitulo>Dados do Solicitante</SubTitulo>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}
                          style={{textAlign: 'center', marginBottom: 6}}>
                      <img
                        src={this.state.str_imagem_perfil ? this.state.str_imagem_perfil : userPerfilDefault}
                        className={classes.imgPerfil}
                        style={{objectFit: 'cover'}}/>
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <strong>Nome:</strong>
                    </Grid>
                    <Grid item xs={12}
                          sm={10}>{this.state.str_nome_solicitante}</Grid>

                    <Grid item xs={12} sm={2}>
                      <strong>Email</strong>:
                    </Grid>
                    <Grid item xs={12}
                          sm={10}>{this.state.str_email_solicitante}</Grid>

                    <Grid item xs={12} sm={2}>
                      <strong>Tipo de acesso do usuário</strong>:
                    </Grid>
                    <Grid item xs={12}
                          sm={3}>{this.state.str_tipo_usuario}</Grid>
                  </Grid>

                  <SubTitulo>Dados da Solicitação</SubTitulo>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={2}>
                      <strong>Data de requerimento exclusão</strong>:
                    </Grid>
                    <Grid item xs={12}
                          sm={10}>{this.state.dat_requerimento_fmt}</Grid>

                    <Grid item xs={12} sm={2}>
                      <strong>Observação</strong>:
                    </Grid>

                    <Grid item xs={12}
                          sm={10}>{this.state.str_observacao}</Grid>

                    <Grid item xs={12} sm={10}>
                      <p style={{fontSize: 15}}>{this.state.contador}</p>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} style={{marginTop: 30}}>
                    <Grid item xs={12}>
                      <div style={{textAlign: 'center'}}>
                        <Button onClick={this.onClickVoltar}>Voltar </Button>
                      </div>
                    </Grid>
                  </Grid>


                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    isLoggedIn,
  }

    = state.auth;
  const {message} = state.message;

  return {isLoggedIn, message};
}

export default connect(mapStateToProps)(withStyles(styles)(Visualizar));
