import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from './TypesAction';


import RedeSocialService from '../services/rede-social';
import AlterarSenhaService from '../services/alterar-senha.service';

const API_URL = process.env.REACT_APP_API_URL;

export const salvarPesquisa = (
  cod_pesquisa_rede_social,
  str_nome_pesquisa,
  disabledInsta,
  disabledTwiter,
  arrHashtag,
  arrPalavras,
  salvar_json,
) => (dispatch) => {
  return RedeSocialService.salvarPesquisa(
    cod_pesquisa_rede_social,
    str_nome_pesquisa,
    disabledInsta,
    disabledTwiter,
    arrHashtag,
    arrPalavras,
    salvar_json,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};
