import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Titulo, SubTitulo } from '../../../views/style/styles';
import BlockIcon from '@material-ui/icons/Block';
import InputPesquisarPadrao from '../InputPesquisarPadrao';
import TabelaPaginada from '../DataTablePaginadapadrao';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import ModalPadrao from '../ModalPadrao';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import UsuarioBloqueadoService from '../../../services/usuario-bloqueado.service';
import DatePickerPtBrPadrao from '../DatePickerPtBrPadrao';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import {timestampFormatter} from '../../util';

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
  )

const loadingPadraoBotao =
  (
    <CircularProgress style={{ width: 20, height: 20 }} />
  )


const useStyles = makeStyles((theme) => ({
    mensagemErro: {
        color: '#f44336',
        fontSize: '0.75rem'
    },
    formButton : theme.pagina.formButton,
    formCadastroTitulo: {
        margin: '15px 0 15px 0',
    },
}));

const UsuarioBloqueado = (props) => {

    const css = useStyles();
    const [arrUsuariosBloqueados, setArrUsuariosBloqueados] = React.useState(props.arrUsuariosBloqueados);
    const [arrUsuariosBloqueadosPesquisa, setArrUsuariosBloqueadosPesquisa] = React.useState(props.arrUsuariosBloqueados);
    const [arrUsariosNaBusca, setArrUsariosNaBusca] = React.useState([]);
    const [arrDadosUsuarioEdicao, setArrDadosUsuarioEdicao] = React.useState(null);
    const [campoNomeUsuarioEdicao, setCampoNomeUsuarioEdicao] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isSaving, setIsSaving] = React.useState(false);
    const [isLoadingModal, setIsLoadingModal] = React.useState(null);
    const [mensagemErro, setMensagemErro] = React.useState(null);
    const [errosCampo, setErrosCampo] = React.useState({ campoObservacao: null, campoDataFinal: null });
    const [campoObservacao, setCampoObservacao] = React.useState('');
    const [campoUsuario, setCampoUsuario] = React.useState(null);
    const [modalBloquearUsuario, setModalBloquearUsuario] = React.useState(false);
    const [modalDesbloquearUsuario, setModalDesbloquearUsuario] = React.useState(false);
    const [codUsuarioBloqueado, setCodUsuarioBloqueado] = React.useState(null);
    const [campoPesquisar, setCampoPesquisar] = React.useState('');
    const [dataInicial, setDataInicial] = React.useState(new Date());
    const [dataFinal, setDataFinal] = React.useState(null);
    const [mensagemAlert, setMensagemAlert] = React.useState({ mensagem: null, severity: 'success', closeButton: true });

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const handleChangeCampoPesquisar = (valor) => {
        setCampoPesquisar(valor);
        if (valor.length === 0) {
            setArrUsuariosBloqueadosPesquisa(arrUsuariosBloqueados);
            return true;
        }
        let result = [];
        if (arrUsuariosBloqueadosPesquisa === false) {
            setArrUsuariosBloqueadosPesquisa(arrUsuariosBloqueados);
        }
        let busca = valor.toString().toLowerCase();

        if (arrUsuariosBloqueadosPesquisa.length == 0) {
            result = arrUsuariosBloqueados.filter((data) => {
                return (
                  (data && data.nome_usuario && data.nome_usuario.toLowerCase().search(busca) != -1)
                  || (data && data.dat_inicio_bloqueio && data.dat_inicio_bloqueio.toLowerCase().search(busca) != -1)
                  || (data && data.dat_fim_bloqueio && data.dat_fim_bloqueio.toLowerCase().search(busca) != -1)
                  || (data && data.str_email && data.str_email.toLowerCase().search(busca) != -1)
                  || (data && data.str_observacao && data.str_observacao.toLowerCase().search(busca) != -1)
                );
            });
        } else {
            result = arrUsuariosBloqueadosPesquisa.filter((data) => {
                return (
                  (data && data.nome_usuario && data.nome_usuario.toLowerCase().search(busca) != -1)
                  || (data && data.dat_inicio_bloqueio && data.dat_inicio_bloqueio.toLowerCase().search(busca) != -1)
                  || (data && data.dat_fim_bloqueio && data.dat_fim_bloqueio.toLowerCase().search(busca) != -1)
                  || (data && data.str_email && data.str_email.toLowerCase().search(busca) != -1)
                  || (data && data.str_observacao && data.str_observacao.toLowerCase().search(busca) != -1)
                );
            });
        }
        setArrUsuariosBloqueadosPesquisa(result);

    }

    const recarregarUsuariosBloqueados = async () => {
        const arrUsuariosBloqueadosRetorno = await UsuarioBloqueadoService.carregarLista();
        props.atualizarTotalBloqueados(arrUsuariosBloqueadosRetorno.length);
        setArrUsuariosBloqueados(arrUsuariosBloqueadosRetorno);
        setArrUsuariosBloqueadosPesquisa(arrUsuariosBloqueadosRetorno);
    }

    const onClickBloquearUsuario = async () => {
        setCodUsuarioBloqueado(null);
        setCampoNomeUsuarioEdicao(null);
        setCampoObservacao(null);
        setIsLoadingModal('bloquear');
        const arrUsariosNaBusca = await UsuarioBloqueadoService.getUsuariosParaBloquear();
        setArrUsariosNaBusca(arrUsariosNaBusca);
        setModalBloquearUsuario(true);
        setIsLoadingModal(null);
        setCampoObservacao('');
        setDataFinal(null);
        setMensagemAlert({ mensagem: null, severity: 'success', closeButton: true });
    }

    const onClickEditar = (arrDados) => {
        setCodUsuarioBloqueado(arrDados.cod_usuario_bloqueado);
        setModalBloquearUsuario(true);
        setCampoNomeUsuarioEdicao(arrDados.nome_usuario);
        setCampoObservacao(arrDados.str_observacao);
        setDataFinal(new Date(arrDados.dat_fim_bloqueio_edicao));
        setIsLoadingModal(null);
        setMensagemAlert({ mensagem: null, severity: 'success', closeButton: true });
    }

    const onClickExcluir = (cod_usuario_bloqueado) => {
        setMensagemAlert({ mensagem: null, severity: 'success', closeButton: true });
        setModalDesbloquearUsuario(true);
        setCodUsuarioBloqueado(cod_usuario_bloqueado);
    }

    const handleOnChangeUsuarios = (event, valor) => {
        if (valor) {
            setCampoUsuario(valor);
        }
    };

    const onChangeDataFinal = (data) => {
        setDataFinal(data);
    };

    const onChangeObservacao = (event) => {
        setCampoObservacao(event.target.value);
    };

    const salvarBloqueioUsuario = async (evt) => {
        evt.preventDefault();

        if (verificaCamposObrigatorios() !== true) {
            return false;
        }

        setIsSaving(true);
        const arrCampos = { codUsuarioBloqueado: codUsuarioBloqueado, codUsuario: (campoUsuario ? campoUsuario.cod_usuario : null), campoObservacao: campoObservacao, dataFinal: dataFinal }
        const retorno = await UsuarioBloqueadoService.bloquearUsuario(arrCampos);
        if (retorno == 'ok') {
            let nomeUsuario = await UsuarioBloqueadoService.buscarUsuarioBanco(campoUsuario.cod_usuario);
            await recarregarUsuariosBloqueados();
            setModalBloquearUsuario(false);
            if (codUsuarioBloqueado == null) {
               setMensagemAlert({ mensagem:  'Usuário ' + nomeUsuario[0].str_nome_pessoa  + ' bloqueado até ' + timestampFormatter(dataFinal.toLocaleDateString()) , severity: 'success', closeButton: true });
            } else {
                setMensagemAlert({ mensagem: 'Bloqueio de usuário alterado com sucesso.', severity: 'success', closeButton: true });
            }

        } else {
            setModalBloquearUsuario(false);
            if (codUsuarioBloqueado == null) {
                setMensagemAlert({ mensagem: 'Não foi possível bloquear o usuário.', severity: 'error', closeButton: true });
            } else {
                setMensagemAlert({ mensagem: 'Não foi possível alterar o bloqueio do usuário.', severity: 'error', closeButton: true });
            }
        }
        setIsSaving(false);
        setCodUsuarioBloqueado(null);
    };

    const salvarDesbloquearUsuario = async () => {
        setIsSaving(true);
        if (codUsuarioBloqueado === null) {
            setMensagemAlert({ mensagem: 'Não foi possível desbloquear o usuário.', severity: 'error', closeButton: true });
        }
        const retorno = await UsuarioBloqueadoService.desbloquearUsuario(codUsuarioBloqueado);
        if (retorno == 'ok') {
            await recarregarUsuariosBloqueados();
            setMensagemAlert({ mensagem: 'Usuário desbloqueado com sucesso.', severity: 'success', closeButton: true });
        } else {
            setMensagemAlert({ mensagem: 'Não foi possível desbloquear o usuário.', severity: 'error', closeButton: true });
        }
        setModalDesbloquearUsuario(false);
        setCodUsuarioBloqueado(null);
        setIsSaving(false);
    };

    const verificaCamposObrigatorios = () => {
        const arrErros = [];
        let erro = false;
        if (codUsuarioBloqueado == null && (campoUsuario === null || campoUsuario === undefined || campoUsuario === '')) {
            arrErros['campoUsuario'] = 'Campo obrigatório.';
            erro = true;
        }
        if (campoObservacao === null || campoObservacao === undefined || campoObservacao === '') {
            arrErros['campoObservacao'] = 'Campo obrigatório.';
            erro = true;
        }
        if (dataFinal === null || dataFinal === undefined || dataFinal === '') {
            arrErros['campoDataFinal'] = 'Campo obrigatório.';
            erro = true;
        }

        setErrosCampo(arrErros);
        return !erro;
    };

    return (
      <>
          {isLoading === true &&
            <Grid item xs={12} sm={12} style={{ textAlign: 'center' }} >
                {mensagemPadraoCarregando}
            </Grid>
          }
          {isLoading === false &&
            <>
                <Grid item xs={12} sm={10} className={props.classes.formCadastroTitulo}>
                    <BlockIcon className={props.classes.iconeTitulo} style={{ marginTop: -10 }} />
                    <span className={props.classes.tituloPadrao}> Bloqueio de Usuários do Botão do Pânico</span>
                </Grid>
                <Grid item xs={12} sm={2} style={{ textAlign: 'right' }} >
                    <Button variant="text" onClick={props.onVoltar} >Voltar</Button>
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'left', marginTop: '5px', minWidth: 336 }} >
                    <InputPesquisarPadrao
                      exibirBotao={false}
                      placeholder='Pesquisar Usuários'
                      onChange={handleChangeCampoPesquisar}
                      value={campoPesquisar || ''}
                    />
                </Grid>
                <Grid item xs={12} sm={6} style={{ display: 'block', textAlign: 'right', marginTop: '5px' }} >
                    {/*<Button style={{ textAlign: 'center', width: '150px' }} disabled={isLoadingModal == 'bloquear' ? true : false} variant="text" onClick={onClickBloquearUsuario} >{isLoadingModal == 'bloquear' ? loadingPadraoBotao : 'Bloquear Usuário'}</Button>*/}
                    <button
                      style={{ textAlign: 'center', width: '160px', fontSize: '0.875rem'}}
                      type="button"
                      disabled={isLoadingModal == 'bloquear' ? true : false} variant="text" onClick={onClickBloquearUsuario}
                      className="btn btn-success mr-4">
                        {isLoadingModal == 'bloquear' ? loadingPadraoBotao : 'BLOQUEAR USUÁRIO'}
                    </button>
                </Grid>
                {mensagemAlert.mensagem !== null &&
                  <Grid item xs={12} style={{ marginBottom: 15, marginTop: 15 }} >
                      <Alert
                        severity={mensagemAlert.severity}
                        action={
                          mensagemAlert.closeButton === true &&
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setMensagemAlert({ mensagem: null, severity: 'success', closeButton: true });
                            }}
                          >
                              <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                      >
                          {mensagemAlert.mensagem}
                      </Alert>
                  </Grid>
                }
                <Grid item xs={12} style={{ marginTop: '5px' }} name="tabela-sem-borda">
                    <TabelaPaginada
                      arrCabecalho={['Usuário', 'E-mail', 'Início do bloqueio', 'Fim do bloqueio', 'Observações', 'Ações']}
                      arrDados={arrUsuariosBloqueadosPesquisa}
                      arrAcoes={
                          {
                              acao: [
                                  {
                                      funcao: onClickEditar,
                                      icone: EditIcon
                                  },
                                  {
                                      chave: 'cod_usuario_bloqueado',
                                      funcao: onClickExcluir,
                                      icone: CloseIcon
                                  }
                              ]

                          }
                      }
                    />
                </Grid>
            </>
          }
          <ModalPadrao open={modalBloquearUsuario} handleCloseModal={() => setModalBloquearUsuario(false)} >
              <h5>{codUsuarioBloqueado ? 'Editar bloqueio de usuário' : 'Bloquear usuário'}</h5>
              <hr></hr>

              <form onSubmit={salvarBloqueioUsuario} autoComplete="off">

                  {codUsuarioBloqueado == null &&
                    <>
                        <span>Selecione um usuário para o bloqueio *</span>
                        <div>
                            <Autocomplete
                              noOptionsText="Nenhum usuário encontrado."
                              id="size-small-filled-multi"
                              size="small"
                              onChange={handleOnChangeUsuarios}
                              options={arrUsariosNaBusca}
                              getOptionLabel={(option) => option.str_nome_pessoa + ' ' + option.str_sobrenome + ' (' + option.str_email + ')'}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={option.str_nome_pessoa + ' ' + option.str_sobrenome}
                                    size="small"
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField required={true} {...params} variant="filled" placeholder="Usuários" />
                              )}
                            />
                            {errosCampo.campoUsuario !== null &&
                              <span className={css.mensagemErro}>{errosCampo.campoUsuario}</span>
                            }
                        </div>
                    </>
                  }
                  <Grid container  style={{ minWidth: 550}}>
                      {codUsuarioBloqueado &&
                        <>
                            <Grid item xs={2} style={{ marginTop: '40px' }} >
                                <span>Usuário</span>
                            </Grid>
                            <Grid item xs={10} style={{ marginTop: '40px' }} >
                                {campoNomeUsuarioEdicao}
                            </Grid>
                        </>
                      }
                      <Grid item xs={2} style={{ marginTop: '40px' }} >
                          <span>Bloquear até *</span>
                      </Grid>
                      <Grid item xs={4} >
                          <DatePickerPtBrPadrao
                            id="campo-data-final"
                            label="Data Final"
                            value={dataFinal}
                            onChange={onChangeDataFinal}
                            dataMinima={dataInicial}
                            erroDataMinima="A data final não pode ser anterior à data inicial."
                            required={true}
                          />
                          {errosCampo.campoDataFinal !== null &&
                            <span className={css.mensagemErro}>{errosCampo.campoDataFinal}</span>
                          }
                      </Grid>
                      <Grid item xs={5} style={{ marginTop: '40px' }} ></Grid>
                      <Grid item xs={2} style={{ marginTop: '40px' }} >
                          <span>Motivo *</span>
                      </Grid>
                      <Grid item xs={10} >
                          <TextField
                            inputProps={{ maxLength: 500, minWidth: 294 }}
                            error={errosCampo.campoObservacao ? true : false}
                            helperText={errosCampo.campoObservacao ?? null}
                            id="campoDescricao" multiline rows={5} variant="outlined"
                            fullWidth
                            required value={campoObservacao} onChange={onChangeObservacao}
                          />
                      </Grid>
                  </Grid>
                  <hr></hr>
                  <div style={{ textAlign: 'right' }}>
                      {isSaving === false &&
                        <>
                            <Button variant="outlined" type="submit" style={{ height: 35}} >Salvar</Button> &nbsp;
                            <Button variant="text" onClick={() => setModalBloquearUsuario(false)} style={{ height: 35}}>Cancelar</Button>
                        </>
                      }
                      {isSaving === true &&
                        <>
                            <Button variant="outlined" disabled style={{ width: 80 }} >
                                <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} />
                            </Button> &nbsp;
                            <Button variant="text" disabled >Cancelar</Button>
                        </>
                      }
                  </div>


              </form>
          </ModalPadrao>
          <ModalPadrao open={modalDesbloquearUsuario} handleCloseModal={() => setModalDesbloquearUsuario(false)} >
              <h5>Aviso!</h5>
              <hr></hr>
              <p>Deseja desbloquear esse usuário?</p>
              <hr></hr>
              <div style={{ textAlign: 'left' }}>
                  {isSaving === false &&
                    <>
                        <Button variant="outlined" onClick={salvarDesbloquearUsuario} style={{ width: 80 }} >Sim</Button> &nbsp;
                        <Button variant="text" onClick={() => setModalDesbloquearUsuario(false)} >Não</Button>
                    </>
                  }
                  {isSaving === true &&
                    <>
                        <Button variant="outlined" disabled style={{ width: 80 }} >
                            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} />
                        </Button> &nbsp;
                        <Button variant="text" disabled >Não</Button>
                    </>
                  }
              </div>
          </ModalPadrao>
      </>
    );
};

export default UsuarioBloqueado;
