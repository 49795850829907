import React, {useEffect, useRef} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as Icon from '@material-ui/icons';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import {Collapse} from '@material-ui/core';
import {BrowserRouter, matchPath, Route, Switch} from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import AuthService from '../../services/auth.service';
import {Link} from 'react-router-dom';
import {history} from '../../helpers/history';
import userPerfilDefault from '../../assets/images/user-perfil-default.png';
import Equipe from '../../services/equipe.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {},
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fotoPerfil: {
    borderRadius: 30,
    width: 25,
    height: 25,
    objectFit: 'cover',
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },

  itemMenu: {
    paddingTop: 70,
  },

  menuItem: {
    color: theme.pagina.menu.color,
    textDecoration: 'none',
    '&:hover': {
      background: theme.pagina.menu.backgroud,
      color: '#FFFFFF',
    },
    '&:focus': {
      background: theme.pagina.menu.backgroud,
      color: '#FFFFFF',
    },
    '&:active': {
      background: theme.pagina.menu.backgroud,
      color: '#FFFFFF',
    },
    '& .MuiListItemIcon-root': {
      paddingLeft: '10px',
    },
    '& .MuiSvgIcon-root': {
      color: theme.pagina.menu.color,
    },
    '& .MuiTypography-body1': {
      fontWeight: 'normal',
    },
  },

  menuDivSubItem: {
    background: 'rgba(25, 118, 210, 0.04)',
    '& .menuSubItem': {
      color: '#000',
      float: 'left',
    },
    '& .MuiListItemText-inset': {
      paddingLeft: '5px !important',
    },
  },

  menuSubItem: {
    color: theme.pagina.menu.color,
    textDecoration: 'none',
    display: 'flex;',
    '&:hover': {
      background: theme.pagina.menu.backgroud,
      color: '#FFFFFF',
    },
    '&:focus': {
      background: theme.pagina.menu.backgroud,
      color: '#FFFFFF',
    },
    '& .MuiListItemIcon-root': {
      color: '#000',
      float: 'left',
    },
    '& .MuiSvgIcon-root': {
      color: theme.pagina.menu.color,
      marginLeft: '50px',
    },
    '& .MuiTypography-body1': {
      fontWeight: '100',
    },
  },

  logoimg: {
    width: 125,
  },

  header: {
    width: '100%',
    height: 68,
    zIndex: 999999,
    backgroundColor: theme.palette.background.login,
    boxShadow: 'unset',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );

export default function Navigation(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);

    handleCollapese(0);
  };

  //const userLogin = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
  const userLogin = JSON.parse(localStorage.getItem('user'));

  const [collapseOpened, setCollapseOpened] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingMenu, setIsLoadingMenu] = React.useState(true);

  //Ao criar um novo modulo adiconar ele a constante e switch das permições
  const [administracao, setAdmibistracao] = React.useState(false);
  const [coletaSeletiva, setColetaSeletiva] = React.useState(false);
  const [botaoPanico, setBotaoPanico] = React.useState(false);
  const [metricasApp, setMetricasApp] = React.useState(false);
  const [dpo, setDpo] = React.useState(false);

  const innerRef = useOuterClick(e => {
    if(anchorEl === true){
      handleClose();
    }
  });

  function handleClick(option) {
    setAnchorEl(!anchorEl);
  }

  function useOuterClick(callback) {
    const innerRef = useRef();
    const callbackRef = useRef();

    // set current callback in ref, before second useEffect uses it
    useEffect(() => { // useEffect wrapper to be safe for concurrent mode
      callbackRef.current = callback;
    });

    useEffect(() => {
      window.matchMedia('(max-width: 769px)').addEventListener('change', matchOpenCloseMenu);
      document.addEventListener("click", handleClickMenuLateral);
      return () => document.removeEventListener("click", handleClickMenuLateral);

      // read most recent callback and innerRef dom node from refs
      function handleClickMenuLateral(e) {
        if (
          innerRef.current &&
          callbackRef.current &&
          !innerRef.current.contains(e.target)
        ) {
          callbackRef.current(e);
        }else{
          handleClick();
        }
      }
    }, []); // no need for callback + innerRef dep

    return innerRef; // return ref; client can omit `useRef`
  }

  const matchOpenCloseMenu = (e) => {
    if (e.matches) {
       handleDrawerClose ();
    } else {
       handleDrawerOpen ();
    }
 };

  function handleClose() {
    setAnchorEl(false);
  }

  function routeChange(path) {
    console.log(history);
    const {from} = {from: {pathname: '/recuperar-senha'}};
    //history.push(from);
    history.push('/home', {from: 'Home'});
  }

  function handleCollapese(id) {
    if (id !== undefined) {
      setCollapseOpened(collapseOpened === id ? 0 : id);
    }

    if (id !== 0) {
      handleDrawerOpen();
    }
  }

  function logOut() {
    AuthService.logout();
    setAnchorEl(null);
  }

  // Carrega apenas uma vez no começo
  useEffect(() => {
    //setIsLoaded(true);
    // redireciona o usuário para home se não tiver logado
    verificaUsuarioLogado();

    abreMenuPorPagina()

  }, []);

  const getMenu = async () => {
    const arrMenu = await Equipe.getMenu();

    if (arrMenu != undefined && arrMenu != '') {
      arrMenu.map(function(item, indice) {
        setPemicoes(item.str_permicao);
      });
    }

    setIsLoadingMenu(false);
  };

  const abreMenuPorPagina = () => {
    let path = window.location.pathname;
    if([ '/usuarios/listar', '/equipe', '/auditoria', '/termopolitica' ].includes(path)){
      handleCollapese(1);
    }
    if([ '/dashboard-coleta', '/caminhaocoleta', '/gerenciar-itinerario', '/instrucaocoleta', '/notificarusuario', '/pontocoleta' ].includes(path)){
      handleCollapese(2);
    }
    if([ '/botao-panico/mapa-chamados', '/chamados', '/botao-panico/dashboard', '/instrucaobotaopanico', '/botao-panico/config' ].includes(path)){
      handleCollapese(3);
    }
    if([ '/metricas' ].includes(path)){
      handleCollapese(4);
    }
    if([ '/anonimizar-usuario', '/solicitacao-exclusao-usuario'].includes(path)){
      handleCollapese(5);
    }
  }

  //switch de permições
  const setPemicoes = (strPermisao) => {
    switch (strPermisao) {
      case 'administracao':
        setAdmibistracao(true);
        break;
      case 'coleta_seletiva':
        setColetaSeletiva(true);
        break;
      case 'botao_do_panico':
        setBotaoPanico(true);
        break;
      case 'metricas_vila_a':
        setMetricasApp(true);
        break;
    case 'dpo':
      setDpo(true);
      break;
    }
  };


  const verificaUsuarioLogado = async () => {
    if (userLogin === null || userLogin.accessToken) {
      let path = window.location.pathname;
      const permiteAcesso = await props.routes.filter((route) => {
        return (route.showMenu === true || route.showMenu === undefined) && route.path === path;
      });

      //Carrega o menu caso usuário logado
      if (userLogin !== null && path !== '/login') {
        getMenu();
        setIsLoading(false);
      }

      if (permiteAcesso.length > 0) {
        if (userLogin && userLogin.expiration && userLogin.expiration.date) {
          let dataAual = new Date();
          let dataExpiracao = new Date(userLogin.expiration.date);
          if (dataAual > dataExpiracao) {
            window.location.href = '/';
            return true;
          }
        } else {
          window.location.href = '/';
          return true;
        }
      }
    }
    setIsLoading(false);
  };

  const itensMenu = (
    <div id="menu-principal">
      <ListItem button className={classes.menuItem} component={Link} to="/home">
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon.Home/>
        </ListItemIcon>
        <ListItemText primary="Home"/>
      </ListItem>

      {isLoadingMenu &&
      <Grid container spacing={2} style={{marginTop: 25}}>
        <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
          {mensagemPadraoCarregando}
        </Grid>
      </Grid>
      }

      {!isLoadingMenu && <>
        {administracao && <>
          <ListItem button onClick={() => handleCollapese(1)}
                    className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <Icon.Build/>
            </ListItemIcon>
            <ListItemText primary="Administração"/>
            {collapseOpened === 1 ? <IconExpandLess/> : <IconExpandMore/>}
          </ListItem>
          <Collapse in={collapseOpened === 1} timeout="auto" unmountOnExit>
            <Divider/>
            <List component="div" disablePadding
                  className={classes.menuDivSubItem}>
              <ListItem button className={classes.menuSubItem} component={Link}
                        to="/usuarios/listar">
                <ListItemIcon className={classes.menuItemIcon}>
                  <Icon.Person/>
                </ListItemIcon>
                <ListItemText inset primary="Gerenciar Usuários"/>
              </ListItem>
              <ListItem button className={classes.menuSubItem} component={Link}
                        to="/equipe">
                <ListItemIcon className={classes.menuItemIcon}>
                  <Icon.PeopleAlt/>
                </ListItemIcon>
                <ListItemText inset primary="Gerenciar Equipes"/>
              </ListItem>
              <ListItem button className={classes.menuSubItem} component={Link}
                        to="/auditoria">
                <ListItemIcon className={classes.menuItemIcon}>
                  <Icon.Storage/>
                </ListItemIcon>
                <ListItemText inset primary="Auditoria"/>
              </ListItem>
              <ListItem button className={classes.menuSubItem} component={Link}
                        to="/termopolitica">
                <ListItemIcon className={classes.menuItemIcon}>
                  <Icon.Description/>
                </ListItemIcon>
                <ListItemText inset primary="Termos e Política"/>
              </ListItem>
            </List>
          </Collapse>
        </>}

        {dpo &&<>
          <ListItem button onClick={() => handleCollapese(5)}
                    className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <Icon.Security/>
            </ListItemIcon>
            <ListItemText primary="DPO"/>
            {collapseOpened === 5 ? <IconExpandLess/> : <IconExpandMore/>}
          </ListItem>
          <Collapse in={collapseOpened === 5} timeout="auto" unmountOnExit>
            <Divider/>
            <List component="div" disablePadding className={classes.menuDivSubItem}>
              <ListItem button className={classes.menuSubItem}
                        component={Link} to="/solicitacao-exclusao-usuario">
                <ListItemIcon className={classes.menuItemIcon}>
                  <Icon.GroupAdd/>
                </ListItemIcon>
                <ListItemText inset primary="Solicitações"/>
              </ListItem>

              <ListItem button className={classes.menuSubItem}
                        component={Link} to="/anonimizar-usuario">
                <ListItemIcon className={classes.menuItemIcon}>
                  <Icon.VerifiedUser/>
                </ListItemIcon>
                <ListItemText inset primary="Anonimizar Dados"/>
              </ListItem>
            </List>
          </Collapse>
        </>
        }

        {
          coletaSeletiva && <>
            <ListItem button onClick={() => handleCollapese(2)}
                      className={classes.menuItem}>
              <ListItemIcon className={classes.menuItemIcon}>
                <Icon.LocalShipping/>
              </ListItemIcon>
              <ListItemText primary="Coleta Seletiva"/>
              {collapseOpened === 2 ? <IconExpandLess/> : <IconExpandMore/>}
            </ListItem>
            <Collapse in={collapseOpened === 2} timeout="auto" unmountOnExit>
              <Divider/>
              <List component="div" disablePadding
                    className={classes.menuDivSubItem}>
                <ListItem button className={classes.menuSubItem}
                          component={Link} to="/dashboard-coleta">
                  <ListItemIcon className={classes.menuItemIcon}>
                    <Icon.BarChart/>
                  </ListItemIcon>
                  <ListItemText inset primary="Dashboard"/>
                </ListItem>


                <ListItem button className={classes.menuSubItem}
                          component={Link} to="/caminhaocoleta">
                  <ListItemIcon className={classes.menuItemIcon}>
                    <Icon.LocalShipping/>
                  </ListItemIcon>
                  <ListItemText inset primary="Gerenciar Caminhões"/>
                </ListItem>
                <ListItem button className={classes.menuSubItem}
                          component={Link} to="/gerenciar-itinerario">
                  <ListItemIcon className={classes.menuItemIcon}>
                    <Icon.AccessTime/>
                  </ListItemIcon>
                  <ListItemText inset primary="Gerenciar Itinerário"/>
                </ListItem>
                <ListItem button className={classes.menuSubItem}
                          component={Link} to="/instrucaocoleta">
                  <ListItemIcon className={classes.menuItemIcon}>
                    <Icon.LibraryBooks/>
                  </ListItemIcon>
                  <ListItemText inset primary="Gerenciar Instruções"/>
                </ListItem>
                <ListItem button className={classes.menuSubItem}
                          component={Link} to="/notificarusuario">
                  <ListItemIcon className={classes.menuItemIcon}>
                    <Icon.Notifications/>
                  </ListItemIcon>
                  <ListItemText inset primary="Notificar Usuários"/>
                </ListItem>
                <ListItem button className={classes.menuSubItem}
                          component={Link} to="/pontocoleta">
                  <ListItemIcon className={classes.menuItemIcon}>
                    <Icon.Nature/>
                  </ListItemIcon>
                  <ListItemText inset primary="Pontos de Coleta"/>
                </ListItem>
              </List>
            </Collapse>
          </>
        }

        {botaoPanico && <>
          <ListItem button onClick={() => handleCollapese(3)}
                    className={classes.menuItem}>
            <ListItemIcon className={classes.menuItemIcon}>
              <Icon.ReportProblem/>
            </ListItemIcon>
            <ListItemText primary="Botão de Pânico"/>
            {collapseOpened === 3 ? <IconExpandLess/> : <IconExpandMore/>}
          </ListItem>
          <Collapse in={collapseOpened === 3} timeout="auto" unmountOnExit>
            <Divider/>
            <List component="div" disablePadding
                  className={classes.menuDivSubItem}>
              <ListItem button className={classes.menuSubItem} component={Link}
                        to="/botao-panico/mapa-chamados">
                <ListItemIcon className={classes.menuItemIcon}>
                  <Icon.Map/>
                </ListItemIcon>
                <ListItemText inset primary="Mapa de Chamados"/>
              </ListItem>
            </List>
            <List component="div" disablePadding
                  className={classes.menuDivSubItem}>
              <ListItem button className={classes.menuSubItem} component={Link}
                        to="/chamados">
                <ListItemIcon className={classes.menuItemIcon}>
                  <Icon.PriorityHigh/>
                </ListItemIcon>
                <ListItemText inset primary="Chamados"/>
              </ListItem>
            </List>
            <List component="div" disablePadding
                  className={classes.menuDivSubItem}>
              <ListItem button className={classes.menuSubItem} component={Link}
                        to="/botao-panico/dashboard">
                <ListItemIcon className={classes.menuItemIcon}>
                  <Icon.BarChart/>
                </ListItemIcon>
                <ListItemText inset primary="Dashboard"/>
              </ListItem>
            </List>
            <List component="div" disablePadding
                  className={classes.menuDivSubItem}>
              <ListItem button className={classes.menuSubItem} component={Link}
                        to="/instrucaobotaopanico">
                <ListItemIcon className={classes.menuItemIcon}>
                  <Icon.LibraryBooks/>
                </ListItemIcon>
                <ListItemText inset primary="Gerenciar Instruções"/>
              </ListItem>
            </List>
            <List component="div" disablePadding
                  className={classes.menuDivSubItem}>
              <ListItem button className={classes.menuSubItem} component={Link}
                        to="/botao-panico/config">
                <ListItemIcon className={classes.menuItemIcon}>
                  <Icon.Settings/>
                </ListItemIcon>
                <ListItemText inset primary="Configurações"/>
              </ListItem>
            </List>
          </Collapse>
        </>}
      </>}

      {metricasApp && <>
        <ListItem button onClick={() => handleCollapese(4)}
                  className={classes.menuItem}>
          <ListItemIcon className={classes.menuItemIcon}>
            <TrendingUpIcon/>
          </ListItemIcon>
          <ListItemText primary="Métricas Vila A"/>
          {collapseOpened === 4 ? <IconExpandLess/> : <IconExpandMore/>}
        </ListItem>
        <Collapse in={collapseOpened === 4} timeout="auto" unmountOnExit>
          <Divider/>
          <List component="div" disablePadding
                className={classes.menuDivSubItem}>
            <ListItem button className={classes.menuSubItem} component={Link}
                      to="/metricas">
              <ListItemIcon className={classes.menuItemIcon}>
                <TrendingUpIcon/>
              </ListItemIcon>
              <ListItemText inset primary="Métricas"/>
            </ListItem>
            <ListItem button className={classes.menuSubItem} component={Link}
                      to="/metricas-conteudo">
              <ListItemIcon className={classes.menuItemIcon}>
                <Icon.Description/>
              </ListItemIcon>
              <ListItemText inset primary="Métricas Conteúdo"/>
            </ListItem>
          </List>
        </Collapse>
      </>
      }

    </div>
  );

  props.routes.sort((a, b) => {
    return a.path < b.path ? 1 : a.path > b.path ? -1 : 0;
  });

  const showNav = () => {
    return props.routes.some(({path, showMenu = true}) => {
      const match = matchPath(window.location.pathname, {
        path,
        exact: true,
      });
      return match && match.isExact && showMenu;
    });
  };

  return (
    <BrowserRouter forceRefresh={true} >
      {/*MENU SUPERIOR*/}
      {showNav() && isLoading == false &&
      <AppBar position="absolute"
              className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar} style={{ minWidth: '414px'}}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon/>
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap
                      className={classes.title}
                      component={Link} to="/home">
            <img id="dentro" className={classes.logoimg}
                 src="/images/logo_blue.png" alt="logo"/>
          </Typography>

          <Grid style={{marginRight: 5}}>
            <img
              src={localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).fotoPerfil !== null && JSON.parse(localStorage.getItem('user')).fotoPerfil.length > 0 ? JSON.parse(localStorage.getItem('user')).fotoPerfil : userPerfilDefault}
              className={classes.fotoPerfil}/>
          </Grid>

          <Grid style={{marginRight: 5}}>
            {localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).nome : ''}
          </Grid>

          <Grid item  ref={innerRef}
                style={{cursor: 'pointer', marginTop: -5}}>
            <IconExpandMore/>
          </Grid>
          <Box>
            {anchorEl &&
            <div id="menu-secundario"
                 style={{position: 'fixed', top: '65px', right: '5px'}}>
              <Paper className={classes.root}>
                <MenuList>
                  <MenuItem key={1}>
                    <ListItem key={1} style={{
                      textDecoration: 'none',
                      color: '#000',
                      padding: '0px 0px 0px',
                    }} component={Link} to="/alterar-meus-dados"
                              onClick={handleClose}>
                      <ListItemText primary="Meus Dados"/>
                    </ListItem>
                  </MenuItem>

                  <MenuItem key={4}>
                      <ListItem
                          style={{
                              textDecoration: 'none',
                              color: '#000',
                              padding: '0px 0px 0px',
                          }}
                          component={Link}
                          to={{pathname:"/termo-uso-public"}}
                          target="_blank"
                          onClick={handleClose}>
                          <ListItemText primary="Termos de Uso"/>
                      </ListItem>
                  </MenuItem>

                    <MenuItem key={5}>
                        <ListItem
                            style={{
                                textDecoration: 'none',
                                color: '#000',
                                padding: '0px 0px 0px',
                            }}
                            component={Link}
                            to={{ pathname: "/termo-politica-public" }}
                            target="_blank"
                            onClick={handleClose}>
                            <ListItemText primary="Política de Privacidade"/>
                        </ListItem>
                    </MenuItem>


                  <MenuItem key={2}
                            hidden={localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).loginSocial : false}>
                    <ListItem
                      style={{
                        textDecoration: 'none',
                        color: '#000',
                        padding: '0px 0px 0px',
                      }}
                      component={Link} to="/recuperar-senha"
                      onClick={handleClose}>
                      <ListItemText primary="Alterar Senha"/>
                    </ListItem>
                  </MenuItem>
                  <a href="/login" onClick={logOut}
                     style={{textDecoration: 'none', color: '#000'}}>
                    <MenuItem key={3}>
                      <Typography variant="inherit">Sair</Typography>
                    </MenuItem>
                  </a>
                </MenuList>
              </Paper>
            </div>
            }
          </Box>
        </Toolbar>
      </AppBar>}
      {/*MENU SUPERIOR*/}


      {/*MENU LATERAL*/}
      {showNav() && isLoading == false && <Drawer variant="permanent"
                                                  open={open}
                                                  classes={{paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)}}
      >
        <div className={classes.toolbarIcon}>
          <Typography component="h1" variant="h6" color="inherit" noWrap
                      className={classes.title}
                      component={Link} to="/home">
            <img id="fora" className={classes.logoimg}
                 src="/images/logo_blue.png" alt="logo"/>
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon/>
          </IconButton>
        </div>
        <Divider/>
        <List>{itensMenu}</List>
      </Drawer>
      }
      {/*MENU LATERAL*/}

      {/*VIEWS*/}
      <Switch>
        {isLoading == false && props.routes.map((route, index) => {
          return <Route key={index} path={route.path}>
            {/*não remover a virgula, estamos procurando o motivo do bug, porém ela é importante no codigo*/}
            <Box className={classes.container}
                 className={clsx(classes.appBar, 'open-conteudo', open && classes.appBarShift)}
                 style={{
                   width: '80%',
                   paddingTop: 100,
                   paddingLeft: 30,
                 }}>
              <route.component/>
            </Box>
          </Route>;
        })}
      </Switch>
      {/*VIEWS*/}
    </BrowserRouter>
  );
}
