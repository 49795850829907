import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Grid, Button, FormControl, Select} from '@material-ui/core';
import Description from '@material-ui/icons/Description';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TabelaPaginada from '../partials/DataTablePaginadapadrao';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {FaArrowDown, FaArrowUp} from 'react-icons/fa';
import CircularProgress from '@material-ui/core/CircularProgress';
import BotaoPanicoService from '../../services/botao-panico.service';
import {
  formatDataComponent,
  dateFormatterAmericano, timestampFormatter,
} from '../../components/util';
import {Alert} from '@material-ui/lab';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,

  formCadastro: {
    margin: '15px 0 15px 0',
  },

  content: {
    backgroundColor: '#FFF',
    padding: '5px 5px 5px',
    borderRadius: '5px',
  },

  cardEquipe: {
    maxWidth: 336,
    flex: '1 1 auto',
    padding: '5px 5px 5px',
    margin: '5px 0 25px 0',
    borderRadius: '10px',
    backgroundColor: 'white',
    //'rgba(242, 242, 242, 1)',
    border: 'solid 1px rgba(228, 228, 228, 1)',
    color: '#000',
    boxShadow: '5px 5px 5px rgba(0,0,0,0.5)',
  },
});

const arrExport = [
  {
    'id': 'xls',
    name: 'Exportar XLS',
  },
  {
    'id': 'pdf',
    name: 'Exportar PDF',
  },
];

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );


class DashboardBotaoPanico extends Component {
  constructor(props) {
    super(props);

    this.onClickPeriodo = this.onClickPeriodo.bind(this);
    this.onChangeTpExprtacao = this.onChangeTpExprtacao.bind(this);
    this.onChangeDatIncio = this.onChangeDatIncio.bind(this);
    this.onChangeDatFim = this.onChangeDatFim.bind(this);
    this.onClickPesquisa = this.onClickPesquisa.bind(this);
    this.onClickVisualizar = this.onClickVisualizar.bind(this);
    this.onClickExport = this.onClickExport.bind(this);

    this.state = {
      dat_inicio: null,
      dat_fim: null,
      arrListChamados: [],
      tp_pesquisa: 'M',
      tp_exportacao: '',

      qtd_chamado_registrado: 0,
      qtd_chamado_finalizado: 0,
      qtd_chamado_em_analise: 0,
      qtd_chamado_aberto: 0,

      perc_comparacao_chamado_registrado: null,
      perc_comparacao_chamado_finalizado: null,
      perc_comparacao_chamado_em_analise: null,
      perc_comparacao_chamado_aberto: null,

      isShowPercentual: true,
      labelPecenteual: 'ao Mês',
      dt_max: null,
      isLoadingEdit: true,
      isLoading: true,
      isLoadingPesquisar: false,
      alert: '',
      successful: '',
      redirect: false,
      mensagem_erro_data: null,
    };
  }

  componentDidMount() {
    this.getDados();
    // on mount component
  }

  componentDidUpdate() {
    // on update component
  }

  getDados = async (tipo = 'M', incio = null, fim = null) => {
    let arrJson = await BotaoPanicoService.getDadosDashboard({
      tpPesquisa: tipo,
      datInicio: incio,
      datFim: fim,
    });
    let arrlistChamados = arrJson.json_lista_chamado ? JSON.parse(arrJson.json_lista_chamado) : [];
    let arrComparacao = arrJson.json_percentual_periodo_anterior ? JSON.parse(arrJson.json_percentual_periodo_anterior)[0] : [];
    let arrQuantidade = arrJson.json_quantitativo_chamado ? JSON.parse(arrJson.json_quantitativo_chamado)[0] : [];

    this.setState({arrListChamados: arrlistChamados});

    this.setState({
      perc_comparacao_chamado_registrado: arrComparacao.perc_comparacao_chamado_registrado  ?? 0,
      perc_comparacao_chamado_finalizado: arrComparacao.perc_comparacao_chamado_finalizado  ?? 0,
      perc_comparacao_chamado_em_analise: arrComparacao.perc_comparacao_chamado_em_analise  ?? 0,
      perc_comparacao_chamado_aberto: arrComparacao.perc_comparacao_chamado_aberto ?? 0,
    });


    this.setState({
      qtd_chamado_registrado: arrQuantidade.qtd_chamado_registrado ?? 0,
      qtd_chamado_finalizado: arrQuantidade.qtd_chamado_finalizado ?? 0,
      qtd_chamado_em_analise: arrQuantidade.qtd_chamado_em_analise ?? 0,
      qtd_chamado_aberto: arrQuantidade.qtd_chamado_aberto ?? 0,
    });

    if (incio != null || fim != null) {
      this.setState({isShowPercentual: false});
    } else {
      this.setState({isShowPercentual: true});
    }
    this.setState({isLoadingEdit: false, isLoading: false, isLoadingPesquisar: false});
  };

  onClickPeriodo = (value) => {
    this.setState({isLoading: true});
    let label;
    switch (value) {
    case 'A':
      label = 'ao Ano';
      break;
    case 'S':
      label = 'a Semana';
      break;
    default:
      label = 'ao Mês';
      break;
    }

    this.setState({
      tp_pesquisa: value,
      labelPecenteual: label,
      dat_inicio: null,
      dat_fim: null,
    });

    this.getDados(value);
  };

  onChangeTpExprtacao(e) {
    this.setState({
      tp_exportacao: e.target.value,
    });

    if (e.target.value != '') {
      this.onClickExport(e.target.value);
    }
  }

  onClickExport = async (e) => {
    let exporte = await BotaoPanicoService.getDadosPdf({
      tpExport: e,
      tpPesquisa: this.state.tp_pesquisa,
      tpExprtacao: e,
      datInicio: this.state.dat_inicio,
      datFim: this.state.dat_fim,
    });
    this.setState({tp_exportacao: ''});
  };

  onChangeDatIncio = (value) => {

    this.setState({
      dat_inicio: value,
      dat_fim: null,
      dt_max: value ? formatDataComponent(dateFormatterAmericano(value.toLocaleDateString())) : null,
      mensagem_erro_data: null,
    });
  };

  onChangeDatFim = (value) => {
    this.setState({
      dat_fim: value,
      mensagem_erro_data: null,
    });
  };

  onClickPesquisa = () => {
    if (this.state.dat_fim == null || this.state.dat_inicio == null) {
      this.setState({mensagem_erro_data: 'Data de Início e Fim não podem ficar em branco'});
      return;
      //alert('Data de Início e Fim não podem ficar em branco');
    }

    this.setState({isLoading: true, isLoadingPesquisar: true});
    this.getDados(null, this.state.dat_inicio, this.state.dat_fim);
  };

  onClickVisualizar = (event) => {
    let url = window.location.origin;
    window.location = url + '/botao-panico/visualizar-chamado/' + event;
  };

  formtDataHota = (event) => {
    return (
      <>
        {timestampFormatter(event)}
      </>
    );
  }

  render() {
    const {classes} = this.props;

    return (
      <>
        {this.state.isLoadingEdit === true &&
        <Grid container spacing={2} style={{marginTop: 25}}>
          <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
            {mensagemPadraoCarregando}
          </Grid>
        </Grid>
        }
        {this.state.isLoadingEdit === false &&
        <Grid container className={classes.conteudoFundoBranco} style={{minWidth: '430px'}}>
          <Grid item xs={12} className={classes.formCadastroTitulo}>
            <Description className={classes.iconeTitulo}/>
            <span className={classes.tituloPadrao}> DashBoard</span>
          </Grid>
          <Grid item xs={12} className={classes.content}>
            {this.state.mensagem_erro_data !== null && (
              <div className="row">
                <div className="col mb-2">
                  <Alert
                    severity={'warning'}>{this.state.mensagem_erro_data}</Alert>
                </div>
              </div>
            )}
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.content}>
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5} style= {{minWidth: 283}}>
                    <Button type="button" variant="contained"
                            onClick={
                              () => this.onClickPeriodo('S')
                            }
                            color={this.state.tp_pesquisa === 'S' ? 'primary' : 'default'}
                    >Semanal</Button> {' '}
                    <Button type="button" variant="contained"
                            onClick={
                              () => this.onClickPeriodo('M')
                            }
                            color={this.state.tp_pesquisa === 'M' ? 'primary' : 'default'}
                    >Mensal</Button> {' '}
                    <Button type="button" variant="contained"
                            onClick={
                              () => this.onClickPeriodo('A')
                            }
                            color={this.state.tp_pesquisa === 'A' ? 'primary' : 'default'}
                    >Anual</Button> {' '}
                  </Grid>
                  <Grid item xs={12} sm={3} style= {{minWidth: 165}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}
                                             locale={ptBrLocale}>
                      <KeyboardDatePicker
                        autoOk={true}
                        name="dat_inicio"
                        id="dat_inicio"
                        fullWidth
                        variant="inline"
                        disableFuture="true"
                        invalidDateMessage="Data inválida"
                        inputVariant="outlined"
                        label="Data Início"
                        format="dd/MM/yyyy"
                        maxDateMessage={'Data Início não pode ser superior a data atual'}
                        minDateMessage={'Data inválida'}
                        InputAdornmentProps={{position: 'end'}}
                        value={this.state.dat_inicio}
                        onChange={this.onChangeDatIncio}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={3} style= {{minWidth: 165}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}
                                             locale={ptBrLocale}>
                      <KeyboardDatePicker
                        autoOk={true}
                        name="dat_fim"
                        id="dat_fim"
                        fullWidth
                        variant="inline"
                        disableFuture="true"
                        inputVariant="outlined"
                        label="Data Fim"
                        format="dd/MM/yyyy"
                        maxDateMessage={'Data Fim não pode ser superior a data atual'}
                        minDateMessage={'Data Fim não pode ser inferior a data atual'}
                        minDate={this.state.dt_max}
                        InputAdornmentProps={{position: 'end'}}
                        value={this.state.dat_fim}
                        onChange={this.onChangeDatFim}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={1} style= {{minWidth: 80}}>

                    {this.state.isLoadingPesquisar === true &&
                    <Button style={{
                      marginTop: '9px',
                      marginLeft: 10,
                      width: 100,
                      textAlign: 'center',
                      height: 54,
                    }} variant="contained" className={classes.button} disabled
                            color="primary">
                      <CircularProgress
                        style={{width: 20, height: 20, marginRight: 15}}/>
                    </Button>
                    }
                    {this.state.isLoadingPesquisar === false &&
                    <Button variant="contained"
                            style={{height: 50}}
                            onClick={this.onClickPesquisa}
                    >
                      Pesquisar
                    </Button>
                    }
                  </Grid>
                </Grid>
                {this.state.isLoading === true &&
                <Grid container spacing={2} style={{marginTop: 25}}>
                  <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
                    {mensagemPadraoCarregando}
                  </Grid>
                </Grid>
                }
                {this.state.isLoading === false &&
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Card className={classes.cardEquipe} key={0}>
                        <CardContent>
                          <Grid item xs={12} sm container>
                            <Grid item xs container direction="column"
                                  spacing={2}>
                              <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                  Chamados
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  <strong>{('0000' + this.state.qtd_chamado_registrado).slice(-4)}</strong>
                                </Typography>
                                <Typography variant="body2"
                                            color="textSecondary">
                                  Chamados Registrados
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item>
                              {this.state.isShowPercentual &&
                              <>
                                <Typography variant="subtitle1"
                                            style={{textAlign: 'center'}}><strong>{this.state.perc_comparacao_chamado_registrado}%</strong>
                                  {this.state.perc_comparacao_chamado_registrado < 0 ? <FaArrowDown/> : <FaArrowUp/>}
                                </Typography>
                                <Typography variant="body2">
                                  Comparado <br/> {this.state.labelPecenteual} Passado</Typography>
                              </>
                              }
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Card className={classes.cardEquipe} key={0}>
                        <CardContent>
                          <Grid item xs={12} sm container>
                            <Grid item xs container direction="column"
                                  spacing={2}>
                              <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                  Chamados
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  <strong>{('0000' + this.state.qtd_chamado_finalizado).slice(-4)}</strong>
                                </Typography>
                                <Typography variant="body2"
                                            color="textSecondary">
                                  Chamados Finalizados
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item>
                              {
                                this.state.isShowPercentual &&
                                <>
                                  <Typography variant="subtitle1"
                                              style={{textAlign: 'center'}}><strong>{this.state.perc_comparacao_chamado_finalizado}%</strong>
                                    {this.state.perc_comparacao_chamado_finalizado < 0 ? <FaArrowDown/> : <FaArrowUp/>}
                                  </Typography>
                                  <Typography variant="body2">
                                    Comparado <br/> {this.state.labelPecenteual} Passado</Typography>
                                </>
                              }
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Card className={classes.cardEquipe} key={0}>
                        <CardContent>
                          <Grid item xs={12} sm container>
                            <Grid item xs container direction="column"
                                  spacing={2}>
                              <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                  Chamados
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  <strong>{('0000' + this.state.qtd_chamado_aberto).slice(-4)}</strong>
                                </Typography>
                                <Typography variant="body2"
                                            color="textSecondary">
                                  Chamados em Aberto
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item>
                              {this.state.isShowPercentual &&
                              <>
                                <Typography variant="subtitle1"
                                            style={{textAlign: 'center'}}><strong>{this.state.perc_comparacao_chamado_aberto}%</strong>
                                  {this.state.perc_comparacao_chamado_aberto < 0 ? <FaArrowDown/> : <FaArrowUp/>}
                                </Typography>
                                <Typography variant="body2">
                                  Comparado <br/> {this.state.labelPecenteual} Passado</Typography>
                              </>
                              }
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Card className={classes.cardEquipe} key={0}>
                        <CardContent>
                          <Grid item xs={12} sm container>
                            <Grid item xs container direction="column"
                                  spacing={2}>
                              <Grid item xs>
                                <Typography gutterBottom variant="subtitle1">
                                  Chamados
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                  <strong>{('0000' + this.state.qtd_chamado_em_analise).slice(-4)}</strong>
                                </Typography>
                                <Typography variant="body2"
                                            color="textSecondary">
                                  Chamados em Análise
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item>
                              {this.state.isShowPercentual &&
                              <>
                                <Typography variant="subtitle1"
                                            style={{textAlign: 'center'}}><strong>{this.state.perc_comparacao_chamado_em_analise}%</strong>
                                  {this.state.perc_comparacao_chamado_em_analise < 0 ? <FaArrowDown/> : <FaArrowUp/>}
                                </Typography>
                                <Typography variant="body2">
                                  Comparado <br/> {this.state.labelPecenteual} Passado</Typography>
                              </>
                              }
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </>
                }
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9}></Grid>
                  <Grid item xs={12} sm={3} style= {{minWidth: 124}}>
                    <FormControl variant="outlined"
                                 className={classes.formControl} fullWidth>
                      <InputLabel
                        id="demo-simple-select-outlined-label">Exportar</InputLabel>
                      <Select
                        MenuProps={{ style: { maxWidth: 0, maxHeight: 300, position: 'absolute', }, disableScrollLock: true, }}
                        labelId="demo-simple-select-outlined-label"
                        id="tp_exportacao"
                        label="Exportar"
                        fullWidth
                        name="cod_unidade_federacao"
                        value={this.state.tp_exportacao}
                        onChange={this.onChangeTpExprtacao}
                      >
                        <MenuItem value="">
                          <em> Selecione o tipo </em>
                        </MenuItem>
                        {
                          arrExport.map(
                            (row, index) =>
                              <MenuItem value={row.id}
                                        key={row.id}>{row.name}</MenuItem>,
                          )
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} id="table" name="tabela-sem-borda">
                    <TabelaPaginada
                      arrCabecalho={['Nº Chamado', 'Nome', 'Data/Hora', 'Atendente', 'Situação', 'Ações']}
                      isLoading={this.state.isLoading}
                      style={{boxShadow: 'none!important'}}
                      mensagemSemRegistros="Nenhum chamado Cadastrado"
                      arrDados={this.state.arrListChamados}
                      arrFuncoes={
                        {
                          tms_abertura_chamado: {
                            chave: 'tms_abertura_chamado',
                            funcao: this.formtDataHota,
                          },
                        }
                      }
                      arrAcoes={
                        {
                          cod_chamado: [
                            {
                              chave: 'cod_chamado',
                              funcao: this.onClickVisualizar,
                              icone: SearchIcon,
                            },
                          ],
                        }
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(DashboardBotaoPanico));
