import axios from 'axios';
import authHeader from './auth-header';
import trataErroApi from './trata-erro-api';

const API_URL = process.env.REACT_APP_API_URL;
const authenticateTimeout = '';

class BotaoPanicoService {

  instrucao(
    cod_instrucao_botao_panico,
    str_desc_instrucao,
    str_desc_instrucao_fmt,
    is_null,
  ) {
    return axios.post(API_URL + 'botaopanicoinstrucao/formulario', {
      cod_instrucao_botao_panico,
      str_desc_instrucao,
      str_desc_instrucao_fmt,
      is_null,
    },
      { headers: authHeader() },
    );
  }

  async excluirIntrucao(cod_instrucao_botao_panico) {
    return axios
      .post(API_URL + 'botaopanicoinstrucao/delete', { cod_instrucao_botao_panico: cod_instrucao_botao_panico },
        { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getDaodsIntrucao(cod_instrucao_botao_panico) {
    const postParams = { codInstrucaoBotaoPanico: cod_instrucao_botao_panico };
    return axios
      .post(API_URL + 'botaopanicoinstrucao/populate', postParams, { headers: authHeader() })
      .then((response) => {
        return response.data.data;
      });
  }

  async salvarConfig(arrParams) {
    return axios
      .post(API_URL + 'botaoPanico/salvarConfig', arrParams, { headers: authHeader() })
      .then((response) => {
        return response.data;
      });
  }

  async getConfig() {
    return axios
      .get(API_URL + 'botaoPanico/getConfig', { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }
  async carregarChamados() {
    return axios
      .post(API_URL + 'chamado/getChamadosMapa', { flgExcluido: 'N' }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      })
      .catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  salvarObservacao(
    cod_chamado,
    str_observacao
  ) {
    return axios.post(API_URL + 'chamado/salvar-chamado', {
      cod_chamado,
      str_observacao
    },
      { headers: authHeader() },
    );
  }

  capturarChamado(
    cod_chamado,
    flg_situacao,
  ) {
    return axios.post(API_URL + 'chamado/capturar-chamado', {
      cod_chamado,
      flg_situacao,
    },
      { headers: authHeader() },
    );
  }

  async getChamados(arrFiltros) {
    return axios
      .post(API_URL + "chamado/get-chamados", arrFiltros, { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getUsuariosComChamadosAbertos() {
    return axios
      .get(API_URL + "chamado/getUsuariosComChamadosAbertos", { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async carregarDadosChamado(codChamado) {
    return axios
      .post(API_URL + "chamado/getDadosVisualizarChamado/" + codChamado , {id: codChamado}, { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async finalizarChamado(cod_chamado, str_resposta_chamado) {
    return axios
      .post(API_URL + "chamado/finalizarChamado" , {cod_chamado: cod_chamado, str_resposta_chamado : str_resposta_chamado }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async salvarChamadoInterno(cod_chamado, str_resposta_chamado) {
    return axios
      .post(API_URL + "chamado/salvarChamadoInterno" , {cod_chamado: cod_chamado, str_resposta_chamado : str_resposta_chamado }, { headers: authHeader() })
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getDadosDashboard(arrFiltros) {
    return axios
      .post(API_URL + "chamado/get-dados-dashboard", arrFiltros, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getDadosPdf(arrFiltros) {
    return axios
      .post(API_URL + "chamado/set-export", arrFiltros, {headers: authHeader()})
      .then((response) => {
        if (response.data.base64) {
          const linkSource = response.data.base64;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = response.data.fileName;
          downloadLink.click();
        } else {
          alert('Erro ao recuperar o arquivo');
        }
      }).catch(error => {
        trataErroApi(error.response.data);
      });
  }

  async getAllInstrucao() {
    const postParams = {};
    return axios
      .post(API_URL + 'botaopanicoinstrucao/listar', postParams, { headers: authHeader() })
      .then((response) => {
        return response.data.data;
      })
      .catch(error => {
        trataErroApi(error.response.data);
      });
  }
}

export default new BotaoPanicoService();
