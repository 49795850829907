import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '800px',
        minHeight: '400px'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: '5px',
        fontFamily: theme.typography.fontFamily
    }
}));

export default function ModalPadrao(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(props.open);

    useEffect(() => {
        setOpen(props.open);
      }, [props.open]);

    const handleOpenModal = async (e) => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
        if (typeof props.handleCloseModal === 'function') {
            props.handleCloseModal(false);
        }
    };
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={props.className ?? classes.modal}
                open={open}
                onClose={handleCloseModal}
                closeAfterTransition
                disableScrollLock
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Grid item xs={5}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                {props.children}
                            </Paper>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
        </div >
    );
}