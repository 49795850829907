import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { Button, Grid } from '@material-ui/core';
import { timestampFormatter } from '../../util';
import { LocalShipping } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginLeft: 15, marginTop: 20 }} />
        </span>
    )

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            padding: 8,
            width: 300,
            minHeight: 80,
            position: 'absolute',
            top: -70,
            right: -330,
            zIndex: 1,
            cursor: 'default',
            fontSize: 14,
        },
    }
}));

const timesTampFormatter = (timestamp) => {
    let data = new Date(timestamp).toLocaleDateString();
    let hora = new Date(timestamp).toLocaleTimeString();

    return data + ' ' + hora;
}

const IconeCaminhaoMapa = (props) => {
    const classes = useStyles();
    return (
        <div>
            <LocalShipping style={{ width: 40, height: 40, cursor: 'pointer', color: '#000' }} onClick={() => props.onClickExibeDadosCaminhao(props.caminhao.mac_addr)} />
            {props.caminhao.mac_addr === props.exibeInfoCaminhao &&
                <div className={classes.root} >
                    <Paper elevation={3}>
                        <Grid container>
                            <Grid key={props.caminhao.mac_addr + '_dados'} item xs={12} >
                                <strong>Dados do caminhão</strong><br />
                                {props.dadoCaminhao.strIdentificador}<br />
                                Placa: {props.dadoCaminhao.strPlaca}<br />
                                Identificador: {props.caminhao.mac_addr}<br />
                                {/* Formatação removida para fazer um teste da movimentação do caminhão */}
                                {/* Última Atualização: {timestampFormatter(props.caminhao.location.timestamp)} */}
                                Última Atualização: {timesTampFormatter(props.caminhao.location.timestamp)}
                            </Grid>
                        </Grid>
                        <CloseIcon fontSize="inherit" style={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0 }} onClick={() => props.onClickExibeDadosCaminhao(props.caminhao.mac_addr)} />
                    </Paper>
                </div>
            }
        </div>
    );
};


export default IconeCaminhaoMapa;
