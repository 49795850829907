import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
  table: {
    minWidth: 400,
    color: '#000',
    border: 'unset',
    borderBottom: 'solid 1px #797979',
    '&& .MuiTableCell-root': {
      color: '#000',
    },
    '&& .MuiTableCell-head': {
      fontWeight: 'bold'
    },
    '&& .MuiTableCell-body': {
      color: '#797979'
    }
  }
});

export default function PermissaoEquipe(props) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.listaTabela} >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Permissão</TableCell>
            <TableCell align="right">Descrição</TableCell>
            <TableCell align="right">Funcionalidade</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.isLoaded === false &&
            <TableRow key="loading">
              <TableCell component="th" colSpan={3} scope="row" style={{textAlign: 'center'}} >
                <div><CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...</div>
              </TableCell>
            </TableRow>
          }
          {(props.isLoaded === true || props.isLoaded === undefined) && (props.arrDados.length === 0) &&
            <TableRow key="no-records">
              <TableCell component="th" colSpan={3} scope="row" style={{textAlign: 'center'}} >
                <div>Nenhuma permissão encontrada.</div>
              </TableCell>
            </TableRow>
          }
          {props.isLoaded === true && props.arrDados.map((row) => (
            <TableRow key={row.cod_funcionalidade}>
              <TableCell component="th" scope="row">
                {row.str_permissao}
              </TableCell>
              <TableCell align="right">{row.str_nome}</TableCell>
              <TableCell align="right">{row.str_nome_detalhado}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}