import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import LoadingBar from 'react-top-loading-bar';
import { makeStyles } from '@material-ui/core/styles';
import Login from '../views/Login';
import Navigation from './partials/Navigation';
import Home from './pages/Home';
import Equipe from './pages/Equipe';
import Auditoria from './pages/Auditoria';
import TermoPolitica from './pages/TermoPolitica';
import RecuperarSenha from '../views/RecuperarSenha';
import * as Icon from '@material-ui/icons';
import NovoCadastro from '../views/NovoCadastro';

import CadastrarUsuarios from '../views/CadastrarUsuarios';
import VisualizarUsuario from '../views/VisualizarUsuario';
import ListarUsuarios from '../views/ListarUsuarios';
import ListarEquipes from '../views/ListarEquipes';
import Dashboard from '../views/Dashboard';
import RecuperarSenhaEmail from "../views/RecuperarSenhaEmail";
import AlterarMeusDados from '../views/AlterarMeusDados';
import InstrucaoColeta from './pages/InstrucaoColeta';
import NotificarUsuarios from './pages/NotificarUsuarios';
import InstrucaoBotaoPanico from './pages/InstrucaoBotaoPanico';
import ConfiguracaoBotaoPanico from './pages/ConfiguracaoBotaoPanico';
import VisualizarChamado from './pages/VisualizarChamado';
import MapaChamado from './pages/MapaChamado';
import PontoColeta from './pages/PontoColeta';
import Chamados from './pages/Chamado';
import DashboardBotaoPanico from './pages/DashboardBotaoPanico';
import GerenciarItinerario from './pages/GerenciarItinerario';
import CaminhaoColeta from './pages/CaminhaoColeta';
import CadastroConfirmadoApp from '../views/CadastroConfirmadoApp';
import DashboardColeta from './pages/DashboardColetaSeletiva';
import MetricasApp from './pages/MetricasApp';
import RecuperarSenhaApp from "../views/RecuperarSenhaApp"
import MetricasConteudo from './pages/MetricasConteudo';
import TermoUsoPuclic from '../views/TermosUsoPublic'
import TermoPoliticaPublic from '../views/TermoPoliticaPublic';
import SolicitacaoExclusaoUser from './pages/SolicitacaoExclusaoUser';
import AnonimizarUsuarios from './pages/AnonimizarUsuarios';

const useStyle = makeStyles((theme) => ({
  content: {
    height: '100%',
    fontFamily: theme.typography.fontFamily,
    color: '#000',
    background: theme.palette.background.secondary,
    '& .makeStyles-paper-6': {
      boxShadow: 'unset',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)'
    }

  },
}));

export default function App() {
  const [isInitialized, setInitialized] = useState(false);
  const classes = useStyle();

  // return (
  //     <div className="wrapper">
  //       <h1>Application</h1>
  //       <BrowserRouter>
  //         <Switch>
  //           <Route path="/dashboard">
  //             <Dashboard />
  //           </Route>
  //           <Route path="/preferences">
  //             <Preferences />
  //           </Route>
  //         </Switch>
  //       </BrowserRouter>
  //     </div>
  // );

  return <Box className={classes.content}>
    <LoadingBar
      progress={100}
      height={3}
      color='#1d5e8f'
      onLoaderFinished={() => {
        setInitialized(true);
      }}
    />
    {isInitialized &&
      <Navigation
        routes={[
          {
            showMenu: false,
            onMenu: false,
            path: '/',
            component: Login,
          },
          {
            showMenu: false,
            onMenu: false,
            path: '/recuperar-senha',
            component: RecuperarSenha,
          },
          {
            showMenu: false,
            onMenu: false,
            path: '/cadastroconfirmadoapp',
            component: CadastroConfirmadoApp,
          },
          {
            showMenu: false,
            onMenu: false,
            path: '/dashboard',
            component: Dashboard,
          },
          {
            path: '/home',
            icon: <Icon.Home />,
            linkText: 'Home',
            component: Home,
          },
          // Administração
          {
            path: '/usuarios/listar',
            linkText: 'Gerenciar Usuarios',
            component: ListarUsuarios,
          },
          {
            path: '/usuarios/cadastrar',
            linkText: 'Gerenciar Usuarios',
            component: CadastrarUsuarios,
          },
          {
            path: '/usuarios/visualizar/:id',
            linkText: 'Visualizar Usuário',
            component: VisualizarUsuario,
          },
          {
            showMenu: false,
            onMenu: false,
            path: '/recuperar-senha-email',
            component: RecuperarSenhaEmail,
          },
          {
            path: '/alterar-meus-dados',
            component: AlterarMeusDados,
          },
          {
            path: '/recuperar-senha',
            component: RecuperarSenha,
          },
          {
            showMenu: true,
            onMenu: true,
            path: '/auditoria',
            component: Auditoria,
          },
          {
            showMenu: true,
            onMenu: true,
            path: '/termopolitica',
            component: TermoPolitica,
          },
          {
            showMenu: true,
            onMenu: true,
            path: '/equipe',
            component: Equipe,
          },
          {
            showMenu: true,
            onMenu: true,
            path: '/notificarusuario',
            component: NotificarUsuarios,
          },
          {
            showMenu: false,
            onMenu: false,
            path: '/novo-cadastro',
            component: NovoCadastro,
          },
          //Coleta Seletiva
          {
            path: '/caminhaocoleta',
            linkText: 'Caminhao Coleta',
            component: CaminhaoColeta,
          },
          {
            path: '/instrucaocoleta',
            linkText: 'Gerenciar Instruções',
            component: InstrucaoColeta,
          },
          {
            path: '/gerenciar-itinerario',
            linkText: 'Gerenciar Itinerário',
            component: GerenciarItinerario,
          },
          {
            path: '/pontocoleta',
            component: PontoColeta,
          },
          //Botão de pânico
          {
            path: '/chamados',
            component: Chamados,
          },
          {
            path: '/gerenciar-itinerario',
            linkText: 'Gerenciar Itinerário',
            component: GerenciarItinerario,
          },
          {
            path: '/instrucaobotaopanico',
            linkText: 'Gerenciar Instruções',
            component: InstrucaoBotaoPanico,
          },
          {
            showMenu: true,
            onMenu: true,
            path: '/botao-panico/config',
            component: ConfiguracaoBotaoPanico,
          },
          {
            showMenu: true,
            onMenu: true,
            path: '/botao-panico/mapa-chamados',
            component: MapaChamado,
          },
          {
            path: '/botao-panico/dashboard',
            component: DashboardBotaoPanico,
          },
          {
            showMenu: true,
            onMenu: true,
            path: '/botao-panico/visualizar-chamado/:id',
            component: VisualizarChamado,
          },
          {
            path: '/dashboard-coleta',
            component: DashboardColeta,
            showMenu: true,
            onMenu: true,
          },
          {
            path: '/recuperar-senha-app',
            component: RecuperarSenhaApp,
            showMenu: false,
            onMenu: false,
          },
          {
            path: '/metricas',
            component: MetricasApp,
            showMenu: true,
            onMenu: true,
          },
          {
            path: '/metricas-conteudo',
            component: MetricasConteudo,
            showMenu: true,
            onMenu: true,
          },
          {
            path: '/termo-uso-public',
            component: TermoUsoPuclic,
            showMenu: false,
            onMenu: false,
          },
          {
            path: '/termo-politica-public',
            component: TermoPoliticaPublic,
            showMenu: false,
            onMenu: false,
          },

          {
            path: '/solicitacao-exclusao-usuario',
            component: SolicitacaoExclusaoUser,
            showMenu: true,
            onMenu: true,
          },
          {
            path: '/anonimizar-usuario',
            component: AnonimizarUsuarios,
            showMenu: true,
            onMenu: true,
          },
        ]}
      />
    }
  </Box>;
}
