import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import UsuarioService from '../services/usuario.service';
import BotaPanicoService from '../services/botao-panico.service';
import CloseIcon from '@material-ui/icons/Close';
import Person from '@material-ui/icons/Person';
import userPerfilDefault from '../assets/images/user-perfil-default.png';
import {Alert} from '@material-ui/lab';
import TabelaPaginada from '../components/partials/DataTablePaginadapadrao';
import VisualizarUsuario from './VisualizarUsuario';
import ModalPadrao from '../components/partials/ModalPadrao';

const API_URL = process.env.REACT_APP_API_URL;

const styles = (theme) => ({
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formCadastroTitulo: theme.pagina.formCadastroTitulo,
  buttonCadastro: theme.pagina.buttonCadastro,
  mainBox: {
    overflowY: 'auto',
    width: '100%',
    position: 'fixed',
    margin: 0,
    padding: 0,
    top: 0,
    left: 0,
    height: '100%',
  },
  modalExclusao:{
    boxShadow: theme.shadows[5],
  },
  titleModal: {
    borderBottom: '1px solid #ccc'
  },
  actionsModal: {
    borderTop: '1px solid #ccc'
  },
  headerPanel: {
    backgroundColor: '#185b8d',
    width: '100%',
    height: 176,
  },
  logo: {
    width: 350,
    lineHeight: 94,
  },
  logoDiv: {
    width: '100%',
    textAlign: 'center',
  },
  button: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    background: theme.palette.background.button,
    padding: '10px 10px',
    lineHeight: 1.4,
    borderRadius: 30,
    width: '100%',
  },
  content: {
    backgroundColor: '#FFF',
    marginTop: '20px',
  },
  buttonDiv: {
    textAlign: '-webkit-center',
  },
  buttonContainer: {
    marginTop: 20,
    width: 300,
    marginBottom: 20,
  },
  link: {
    color: '#979797',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  storeButton: {
    width: 150,
    lineHeight: 94,
  },
  contentFooterDiv: {
    marginTop: '1%',
    marginBottom: '1%'
  },
  sMediaDiv: {
    width: '10%'
  },
  sMediaButton: {
    width: 50,
    marginRight: 10
  },
  footerDiv: {
    textAlign: 'center',
    marginTop: '1%',
  },
  buttonGreen: {
    color: '#fff',
    backgroundColor: '#28a745',
    borderColor: '#28a745',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#28A745',
      border: '0px',
    },
  },

  formCadastro: {
    margin: '15px 0 15px 0',
  },

  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '85%'
  },
  iconButton: {
    padding: 10,
    right: '-4%'
  },
  message: {
    marginBottom: '10px',
  }
});

class ListarUsuarios extends Component {
  constructor(props) {
    super(props);
    this.getAllUsers = this.getAllUsers.bind(this);
    this.onClickExcluir = this.onClickExcluir.bind(this);
    this.onClickVisualizar = this.onClickVisualizar.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.excluirUsuario = this.excluirUsuario.bind(this);
    this.handleConfirmaExclusao = this.handleConfirmaExclusao.bind(this);
    this.handleCloseUsuarioComChamado = this.handleCloseUsuarioComChamado.bind(this);

    this.state = {
      open: null,
      codUsuario: null,
      codUsuarioModal: null,
      redirect: false,
      arrDadosUsuario: [],
      arrUsuariosList: [],
      arrUsuariosListOriginais: false,
      isLoadingVisualizarUsuario: false,
      isLoading: true,
      image: null,
      str_nome_pessoa: '',
      str_sobrenome: '',
      dat_nascimento: null,
      flg_genero: '',
      str_email: '',
      error_email: '',
      str_email_adicional: '',
      num_cpf: '',
      error_cpf: '',
      str_telefone: '',
      num_cep: '',
      error_cep: '',
      str_logradouro: '',
      cod_unidade_federacao: '',
      str_municipio: '',
      str_bairro: '',
      str_complemento_endereco: '',
      num_residencial: '',
      message: '',
      loading: false,
      successful: '',
      showForm: 'login',
      arrUsuariosComChamadosAbertos: [],
      openModalUsuarioComChamado: false,
    };
    this.getAllUsers();
  }

  async getAllUsers(valor) {

    // Não pode excluir usuarios com chamados abertos
    let arrUsuarios = [];
    arrUsuarios = await BotaPanicoService.getUsuariosComChamadosAbertos();
    this.setState({
      arrUsuariosComChamadosAbertos: arrUsuarios,
    });

    fetch(API_URL + 'usuario/listar', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((responseJson) =>
      {
        this.setState({
          arrUsuariosList: responseJson.data,
          arrUsuariosListOriginais: responseJson.data,
          isLoading: false
        });

      });
  }

  onClickExcluir(codUsuario) {
    this.setState({
      message: '',
      open: true,
      codUsuarioModal: codUsuario,
    });
  }

  onClickVisualizar = (codUsuario) => {
    this.setState({ isLoadingVisualizarUsuario: true });
    this.setState({ codUsuario: codUsuario });
    this.setState({ message: '' });
  }

  handleConfirmaExclusao(e) {
    this.setState({
      open: false,
      successful: false,
    });
    if (this.state.open) {
      this.excluirUsuario(this.state.codUsuarioModal);
    }
  };

  exibirModalUsuarioComChamado(e) {
    this.setState({
      openModalUsuarioComChamado: true
    });
  };

  handleClose(e) {
    this.setState({
      open: false,
    });
  }

  handleCloseUsuarioComChamado(e) {
    this.setState({
      openModalUsuarioComChamado: false,
    });
  }

  removerUsuario = async (cod_usuario) => {
    console.log(cod_usuario);
  }

  excluirUsuario = async (cod_usuario) => {
    const retorno = await UsuarioService.removerUsuario(cod_usuario);
    console.log(retorno);
    if (retorno === 'ok') {
      this.setState({
        message: 'Usuário excluido com sucesso.',
        successful: true,
      });
      this.getAllUsers();
    } else {
      this.state({
        message: 'Não foi possível remover o usuário.',
      });
    }
  }

  handleChange(e) {
    e.preventDefault();
    const valor = e.target.value;
    this.props.funcao(valor);
  }

  // filtrarUsuariosCarregados = (event) => {
  //   if (event.target.value.length === 0) {
  //     return this.setState({ arrUsuariosList: this.state.arrUsuariosListOriginais });
  //   }
  //   let value = event.target.value.toString().toLowerCase();
  //   let result = [];
  //   if (this.state.arrUsuariosListOriginais === false) {
  //     this.setState({ arrUsuariosListOriginais: this.state.arrUsuariosList });
  //   }
  //   if (this.state.arrUsuariosList.length == 0) {
  //     result = this.state.arrUsuariosListOriginais.filter((data) => {
  //       console.log(data)
  //       return (
  //         (data && data.str_nome_pessoa && data.str_nome_pessoa.toLowerCase().search(value.toLowerCase()) != -1)
  //         || (data && data.str_sobrenome && data.str_sobrenome.toLowerCase().search(value.toLowerCase()) != -1)
  //         || (data && data.str_email && data.str_email.toLowerCase().search(value.toLowerCase()) != -1)
  //         || (data && data.str_nome_pessoa && data.str_sobrenome && (data.str_nome_pessoa.toString() + ' ' + data.str_sobrenome.toString()).toLowerCase().search(value.toLowerCase()) != -1)
  //       )
  //     });
  //   } else {
  //     result = this.state.arrUsuariosList.filter((data) => {
  //       return (
  //         (data && data.str_nome_pessoa && data.str_nome_pessoa.toLowerCase().search(value.toLowerCase()) != -1)
  //         || (data && data.str_sobrenome && data.str_sobrenome.toLowerCase().search(value.toLowerCase()) != -1)
  //         || (data && data.str_email && data.str_email.toLowerCase().search(value.toLowerCase()) != -1)
  //         || (data && data.str_nome_pessoa && data.str_sobrenome && (data.str_nome_pessoa.toString() + ' ' + data.str_sobrenome.toString()).toLowerCase().search(value.toLowerCase()) != -1)
  //       )
  //     });
  //   }
  //   this.setState({ arrUsuariosList: result });
  // }

  filtrarUsuariosCarregados = (event) => {
    if (event.target.value.length === 0) {
      return this.setState({ arrUsuariosList: this.state.arrUsuariosListOriginais });
    }
    let value = event.target.value.toString().toLowerCase();
    let result = [];
    if (this.state.arrUsuariosListOriginais === false) {
      this.setState({ arrUsuariosListOriginais: this.state.arrUsuariosList });
    }

    if(this.state.arrUsuariosListOriginais.length > 0){
      result = this.state.arrUsuariosListOriginais.filter((data) => {
        return (
          (data && data.str_nome_pessoa && data.str_nome_pessoa.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_sobrenome && data.str_sobrenome.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_email && data.str_email.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_nome_pessoa && data.str_sobrenome && (data.str_nome_pessoa.toString() + ' ' + data.str_sobrenome.toString()).toLowerCase().search(value.toLowerCase()) != -1)
        )
      })
    }else{
      result = this.state.arrUsuariosList.filter((data) => {
        return (
          (data && data.str_nome_pessoa && data.str_nome_pessoa.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_sobrenome && data.str_sobrenome.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_email && data.str_email.toLowerCase().search(value.toLowerCase()) != -1)
          || (data && data.str_nome_pessoa && data.str_sobrenome && (data.str_nome_pessoa.toString() + ' ' + data.str_sobrenome.toString()).toLowerCase().search(value.toLowerCase()) != -1)
        )
      })
    }

    this.setState({ arrUsuariosList: result });
  }

  exibeFotoPerfil = (str_imagem_perfil) => {
    return (
      <img src={str_imagem_perfil ? str_imagem_perfil : userPerfilDefault} className="img-perfil" style={{objectFit: 'cover'}} />
    )
  }

  escondeExcluir = (cod_usuario) => {
    if (JSON.parse(localStorage.getItem('user')) !== null) {
      if (cod_usuario === JSON.parse(localStorage.getItem('user')).cod_usuario) {
        return (
          <SearchIcon style={{cursor: 'pointer'}}
                      onClick={() => this.onClickVisualizar(cod_usuario)}/>
        );
      } else if (this.state.arrUsuariosComChamadosAbertos.includes(cod_usuario)) {
        return ([
          <SearchIcon style={{cursor: 'pointer'}}
                      onClick={() => this.onClickVisualizar(cod_usuario)}/>,
          <CloseIcon style={{cursor: 'pointer'}}
                     onClick={() => this.exibirModalUsuarioComChamado()}/>,
        ]);
      } else {
        return ([
          <SearchIcon style={{cursor: 'pointer'}}
                      onClick={() => this.onClickVisualizar(cod_usuario)}/>,
          <CloseIcon style={{cursor: 'pointer'}}
                     onClick={() => this.onClickExcluir(cod_usuario)}/>,
        ]);
      }
    }
  };

  onVoltar = () => {
    this.setState({ isLoadingVisualizarUsuario: false });
    this.setState({ codUsuario: null });
  }

  render() {
    const {message, classes} = this.props;

    // if (this.state.redirect) {
    //   return <VisualizarUsuario path={'/usuarios/visualizar/' + this.state.codUsuario} to={'/usuarios/visualizar/' + this.state.codUsuario} codUsuario={this.state.codUsuario} onVoltar={this.onVoltar} />
    // }

    return (
      <div className={classes.root}>
        <Grid container className={classes.conteudoFundoBranco} style={{minWidth: 974}}>
          <Grid item xs={12} className={classes.formCadastroTitulo}>
            <Person className={classes.iconeTitulo} /> <span className={classes.tituloPadrao} >{this.state.codUsuario !== null ? 'Visualizar Usuário' : 'Gerenciar Usuários'}</span>
          </Grid>
          {this.state.isLoadingVisualizarUsuario === true && this.state.codUsuario !== null &&
          <Grid container >
            <Grid item xs={12} >
              <VisualizarUsuario codUsuario={this.state.codUsuario} onVoltar={this.onVoltar}  />
            </Grid>
          </Grid>
          }
          {this.state.message && (
            <Grid container className={classes.message}>
              <Grid item xs={12}>
                <Alert severity={this.state.successful ? 'success' : ''}>{this.state.message}</Alert>
              </Grid>
            </Grid>
          )}
          {this.state.codUsuario == null &&
          <>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Paper style={{ margin: '15px 0' }}>
                  <InputBase
                    name="Busca"
                    id="Busca"
                    className={classes.input}
                    placeholder="Pesquisar Usuários"
                    inputProps={{ 'aria-label': 'search google maps' }}
                    onChange={this.filtrarUsuariosCarregados}
                    autoComplete="off"
                  />
                  <IconButton className={classes.iconButton} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ textAlign: 'right', width: '100%' }} >
                  <Button component={Link} to="/usuarios/cadastrar"
                          variant="outlined"
                          className={classes.buttonCadastro}>Cadastrar Usuário
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid container spacing={3}>
                <Grid item xs={12} id='tabela-listar-usuario' name="tabela-sem-borda">
                  <TabelaPaginada
                    arrCabecalho={['Foto', 'Nome', 'Email', 'Ações']}
                    isLoading={this.state.isLoading}
                    arrDados={this.state.arrUsuariosList}
                    mensagemSemRegistros="Nenhum usuário encontrado"
                    arrFuncoes={
                      {
                        str_imagem_perfil: {
                          chave: 'str_imagem_perfil',
                          funcao: this.exibeFotoPerfil,
                        },
                        cod_usuario: {
                          chave: 'cod_usuario',
                          funcao: this.escondeExcluir,
                        },
                      }
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
          }
          <ModalPadrao open={this.state.open} handleCloseModal={this.handleClose} >
              <h5>Aviso!</h5>
              <hr></hr>
              <p>Deseja Excluir este usuário?</p>
              <hr></hr>
              <Button onClick={this.handleConfirmaExclusao} variant="outlined">
                Sim
              </Button>&nbsp;
              <Button onClick={this.handleClose}>
                Não
              </Button>
          </ModalPadrao>
          <ModalPadrao open={this.state.openModalUsuarioComChamado} handleCloseModal={this.handleCloseUsuarioComChamado} >
              <h5>Aviso!</h5>
              <hr></hr>
              <p>Este usuário não pode ser excluído pois possui um chamado em aberto. Finalize o chamado antes de realizar a exclusão.</p>
              <hr></hr>
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Button onClick={this.handleCloseUsuarioComChamado}>
                  Fechar
                </Button>
              </div>
          </ModalPadrao>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}
export default connect(mapStateToProps)(withStyles(styles)(ListarUsuarios));
