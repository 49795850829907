import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from './TypesAction';


import BotaoPanicoService from '../services/botao-panico.service';

const API_URL = process.env.REACT_APP_API_URL;


export const instrucao = (
  cod_instrucao_coleta,
  str_desc_instrucao,
  str_desc_instrucao_fmt,
  is_null,
) => (dispatch) => {
  return BotaoPanicoService.instrucao(
    cod_instrucao_coleta,
    str_desc_instrucao,
    str_desc_instrucao_fmt,
    is_null,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};

export const salvarObservacao = (
  cod_chamado,
  str_observacao,
) => (dispatch) => {
  return BotaoPanicoService.salvarObservacao(
    cod_chamado,
    str_observacao,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};

export const capturarChamado = (
  cod_chamado,
  flg_situacao,
) => (dispatch) => {
  return BotaoPanicoService.capturarChamado(
    cod_chamado,
    flg_situacao,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};
