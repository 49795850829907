import 'date-fns';
import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import {FaCamera} from 'react-icons/fa';
import {Button, FormControl, Grid, Select, TextField} from '@material-ui/core';
import {
  cepMaks,
  cpfMask,
  telefoneMask,
  validateEmail,
} from '../components/util';
import styled from 'styled-components';
import {Alert} from '@material-ui/lab';
import * as Icon from '@material-ui/icons';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from 'react-router-dom';
import Form from 'react-validation/build/form';
import {connect} from 'react-redux';
import {register} from '../actions/UserRegisterAction';
import './style/style.css';
import userPerfilDefault from '../assets/images/user-perfil-default.png';
import {cpf} from 'cpf-cnpj-validator';
import {Redirect} from 'react-router';
import DateFnsUtils from '@date-io/date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import {sendEmail} from '../actions/AuthAction';
import PhotoIcon from '@material-ui/icons/Photo';

const API_URL = process.env.REACT_APP_API_URL;

const SubTitulo = styled.h2`
    width: 100%;
    color: #3e3e3e;
    font-size: 15px;
    border-bottom: 1px solid #ccc;
    padding: 0 10px 10px 10px;
    font-weight: 600;
`;

const styles = (theme) => ({
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formButton : theme.pagina.formButton,
  mainBox: {
    overflowY: 'auto',
    width: '100%',
    position: 'fixed',
    margin: 0,
    padding: 0,
    top: 0,
    left: 0,
    height: '100%',
  },
  inputPhoto: {
    cursor: 'pointer',
    fontSize: 24,
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '5px 5px 5px',
  },
  headerPanel: {
    backgroundColor: '#185b8d',
    width: '100%',
    height: 176,
  },
  logo: {
    width: 350,
    lineHeight: 94,
  },
  logoDiv: {
    width: '100%',
    textAlign: 'center',
  },
  formCadastro: {
    margin: '15px',
  },
  userCicle: {
    width: '150px'
  },
  button: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    padding: '10px 10px',
    lineHeight: 1.4,
    borderRadius: 30,
    width: '100%',
  },
  content: {
    backgroundColor: "#FFF",
    padding: '5px 5px 5px',
    borderRadius: '5px'
  },
  buttonDiv: {
    textAlign: '-webkit-center',
  },
  buttonContainer: {
    marginTop: 20,
    width: 300,
    marginBottom: 20,
  },
  link: {
    color: '#979797',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  storeButton: {
    width: 150,
    lineHeight: 94,
  },
  contentFooterDiv: {
    marginTop: '1%',
    marginBottom: '1%',
  },
  sMediaDiv: {
    width: '10%',
  },
  sMediaButton: {
    width: 50,
    marginRight: 10,
  },
  footerDiv: {
    textAlign: 'center',
    marginTop: '1%',
  },
  invalidFeedback: {
    display: 'none'
  },
  formContainer: {
    position: 'relative',
    padding: '5% 10% 5% 10%',
    opacity: 1,
    borderRadius: '20px',
    backgroundColor: '#fff'
  },
  dataPicker: {
    fontcolor: '#4AE2D2'
  },
  inputText: {
    color: 'red',
  },
  formCadastroTitulo: {
    margin: '15px 0 15px 0',
  },
});

class CadastrarUsuarios extends Component {
  constructor(props) {
    super(props);
    this.onImageChange = this.onImageChange.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeLastname = this.onChangeLastname.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeEmailAdicional = this.onChangeEmailAdicional.bind(this);
    this.onChangeBirthDate = this.onChangeBirthDate.bind(this);
    this.onChangeGender = this.onChangeGender.bind(this);
    this.onChangeCpf = this.onChangeCpf.bind(this);
    this.onChangeTelefone = this.onChangeTelefone.bind(this);
    this.onChangeCep = this.onChangeCep.bind(this);
    this.onChangeLogradouro = this.onChangeLogradouro.bind(this);
    this.onChangeUnidadeFederacao = this.onChangeUnidadeFederacao.bind(this);
    this.onChangeMunicipio = this.onChangeMunicipio.bind(this);
    this.onChangeBairro = this.onChangeBairro.bind(this);
    this.onChangeComplementoEndereco = this.onChangeComplementoEndereco.bind(this);
    this.onChangeNumeroResidencial = this.onChangeNumeroResidencial.bind(this);
    this.validarConfirmacaoEmail = this.validarConfirmacaoEmail.bind(this);
    this.validarConfirmacaoEmailAdicional = this.validarConfirmacaoEmailAdicional.bind(this);

    this.state = {
      user: localStorage.getItem('user'),
      image: null,
      str_nome_pessoa: '',
      str_sobrenome: '',
      dat_nascimento: null,
      flg_genero: '',
      str_email: '',
      error_email: '',
      str_email_adicional: '',
      num_cpf: '',
      error_cpf: '',
      str_telefone: '',
      error_telefone: '',
      num_cep: '',
      error_cep: '',
      erroLink: '',
      str_logradouro: '',
      cod_unidade_federacao: '',
      str_municipio: '',
      str_bairro: '',
      str_complemento_endereco: '',
      num_residencial: '',
      dt_max: null,
      message: '',
      messagePhoto: '',
      loading: false,
      successful: '',
      warningImage: '',
      redirect: false,
      arrDadosUnidadeFederacao: [],
      minDate: null,
      error_date: null,
      maxDate: null,
      error_logradouro: null,
    };
    this.getAllUfs();
  }

  componentDidMount() {
    // on mount component

    let today = new Date();
    let dtAtual =  today.getFullYear() -1 + '-' +'12-31';


    this.setState({maxDate: dtAtual});
    this.setState({minDate: today.getFullYear() - 100 + '-' +'12-31'});
  }

  componentDidUpdate(prevProps, prevState) {
    console.log(prevState);
  }

  onImageChange(e) {
    const files = e.target.files;

    if(e.target.files.length <= 0){
      this.setState({
        warningImage: false,
        messagePhoto: ''
      });
      return;
    }

    let arFile = files[0];
    let typeImage = arFile.type.split('/').pop();
    let size = arFile.size;
    let maxSize = 5242880;

    let isValidSize = true;
    let arrType = ["jpeg", 'JPEG', 'png', 'PNG'];
    let isValide = arrType.indexOf(typeImage);

    if(maxSize <= size){
      isValidSize = false
    }

    if(isValide < 0 || !isValidSize){
      this.setState({
        messagePhoto: 'O arquivo de foto deverá ser em formato .JPEG ou .PNG e tamanho máximo de 5MB.',
        warningImage: true
      });
    }else{
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);

      reader.onload = (e) => {
        this.setState({
          image: e.target.result,
          warningImage: false,
          messagePhoto: ''
        });
      }
    }
  }

  onChangeName(e) {
    this.setState({
      str_nome_pessoa: e.target.value,
    });
  }

  onChangeLastname(e) {
    this.setState({
      str_sobrenome: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      str_email: e.target.value,
      message: '',
      error_email: ''
    });
    this.setState({
      successful: false,
    });
    const {value} = e.target;

    const email = value;

    fetch(API_URL + 'usuario/interno/verifica-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({str_email: email}),
    }).then((res) => res.json())
      .then((res) => {
        if(res.status == true){
          this.setState({
            error_email: res.message,
          });
        }
      });
  }

  validarConfirmacaoEmail() {
    if (!validateEmail(this.state.str_email) && this.state.str_email != '') {
      this.setState({
        error_email: 'Formato de email inválido',
      });
    } else {
      this.setState({
        error_email: false,
      });
    }
  }

  validarConfirmacaoEmailAdicional() {
    if (!validateEmail(this.state.str_email_adicional) && this.state.str_email_adicional != '') {
      this.setState({
        error_email_adicional: 'Formato de email inválido',
      });
    } else {
      this.setState({
        error_email_adicional: false,
      });
    }
  }

  onChangeEmailAdicional(e) {
    this.setState({
      str_email_adicional: e.target.value,
    });
  }

  onChangeBirthDate(data) {
       let today = new Date();
       let dtAtual =  today.getFullYear() -1 + '-' + (today.getMonth() + 1) + '-' + (today.getDate());
       let dtInf = data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + (data.getDate());

    if(data == 'Invalid Date' || today.getFullYear() <=data.getFullYear()){
      this.setState({
        error_date: 'Data de nascimento inválida',
      });
    }else{
      if(data != null){
        let today = new Date();
        let dtAtual =  today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + (today.getDate());
        let dtInf = data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + (data.getDate());

        if((parseInt(dtAtual.split('-')[0]) - parseInt(dtInf.split('-')[0])) <= 100){
          this.setState({
            error_date: null
          });
        }
      }else{
        this.setState({
          error_date: null
        });
      }
    }

    this.setState({
      dat_nascimento: data,
    });
  }

  onChangeGender(e) {
    this.setState({
      flg_genero: e.target.value,
    });
  }

  onChangeCpf(e) {
    this.setState({
      num_cpf: cpfMask(e.target.value),
      error_cpf: '',
    });
    const {value} = e.target;

    const cpfClear = value?.replace(/[^0-9]/g, '');

    if (cpfClear?.length !== 11) {
      return;
    }

    if (!cpf.isValid(e.target.value)) {
      this.setState({
        error_cpf: 'CPF inválido.',
        successful: '',
      });
    }else{
      this.setState({
        error_cpf: '',
        successful: '',
      });
    }

    fetch(API_URL + 'usuario/interno/verifica-cpf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({num_cpf: cpfClear}),
    }).then((res) => res.json())
      .then((res) => {
        if(res.message != 'ok.')
        {
          this.setState({
            error_cpf: res.message,
          });
        }
      });
  }

  onChangeTelefone(e) {
    this.setState({
      str_telefone: telefoneMask(e.target.value),
      error_telefone: '',
    });
  }

  onChangeCep(e) {
    this.setState({
      num_cep: e.target.value.replace(/[^\d\s-/]/g, "") ? cepMaks(e.target.value) : '',
      error_cep: '',
      error_logradouro: null,
    });

    const {value} = e.target;

    const cep = value?.replace(/[^0-9]/g, '');

    if (cep?.length !== 8) {
      this.setState({
        cod_unidade_federacao: '',
        str_municipio: '',
        str_bairro: '',
        str_logradouro: '',
        num_residencial: '',
      });
      return;
    }

    const url = `https://viacep.com.br/ws/${cep}/json/`;

    this.onChangeCheckLinkOnline(url, this.setState({ erroLink: true }), this.setState({ erroLink: false }));

    if(!this.state.erroLink) {
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            this.setState({
              cod_unidade_federacao: '',
              str_municipio: '',
              str_bairro: '',
              str_logradouro: '',
              error_cep: 'CEP não encontrado.',
            });
          } else {
            this.setState({
              cod_unidade_federacao: data.uf,
              str_municipio: data.localidade,
              str_bairro: data.bairro,
            });
          }
        });
    }else{
      this.setState({
        error_cep: 'Serviço temporariamente indisponível, por favor, tente novamente em alguns instantes.'
      });
    }
  }

  onChangeCheckLinkOnline(url, error, ok) {
    try {
      var scriptElem = document.createElement('script');
      scriptElem.type = 'text/javascript';
      scriptElem.onerror = error;
      scriptElem.onload = ok;
      scriptElem.src = url;
      document.getElementsByTagName("body")[0].appendChild(scriptElem);
    } catch(err) {
      error(err);
    }
  }

  onChangeLogradouro(e) {
    this.setState({
      str_logradouro: e.target.value,
      error_logradouro: null,
    });
  }

  getAllUfs(valor) {
    fetch(API_URL + 'usuario/lista-unidade-federacao', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((responseJson) =>
      {
        this.setState({
          arrDadosUnidadeFederacao: responseJson
        });
      });
  }

  onChangeUnidadeFederacao(e) {
    this.setState({
      cod_unidade_federacao: e.target.value,
    });
  }

  onChangeMunicipio(e) {
    this.setState({
      str_municipio: e.target.value,
    });
  }

  onChangeBairro(e) {
    this.setState({
      str_bairro: e.target.value,
    });
  }

  onChangeComplementoEndereco(e) {
    this.setState({
      str_complemento_endereco: e.target.value,
    });
  }

  onChangeNumeroResidencial(e) {
    this.setState({
      num_residencial: e.target.value,
    });
  }

  handleRegister(e) {
    e.preventDefault();

    if(this.state.error_date || this.state.error_cpf ||
      this.state.error_email || this.state.error_email_adicional || this.state.error_cep){

      return false;
    }

    if(this.state.num_cpf.length === 0){
      this.setState({
        error_cpf: '',
      });
    }

    if(this.state.num_cpf.length !== 0  && (this.state.num_cpf.replace(/[^0-9]/g, '').length !== 11 || this.state.error_cpf.length > 0)){
      this.setState({
        error_cpf: 'CPF inválido.',
      });
      return false;
    }

    if(this.state.str_telefone !== null && this.state.str_telefone.length !== 0  && (this.state.str_telefone.replace(/[^0-9]/g, '').length !== 11 && this.state.str_telefone.replace(/[^0-9]/g, '').length !== 10)){
      this.setState({
        error_telefone: 'Telefone inválido.',
      });
      return false;
    }else{
      this.setState({
        error_telefone: '',
      });
    }

    this.setState({
      successful: '',
      message: ''
    });

    this.form.validateAll();
    this.props
      .dispatch(
        register(
          this.state.image,
          this.state.str_nome_pessoa,
          this.state.str_sobrenome,
          this.state.dat_nascimento.toLocaleDateString(),
          this.state.flg_genero,
          this.state.num_cpf?.replace(/[^0-9]/g, ''),
          this.state.str_email,
          this.state.str_email_adicional,
          this.state.str_telefone?.replace(/[^0-9]/g, ''),
          this.state.num_cep?.replace(/[^0-9]/g, ''),
          this.state.cod_unidade_federacao,
          this.state.str_municipio,
          this.state.str_bairro,
          this.state.str_logradouro,
          this.state.str_complemento_endereco,
          this.state.num_residencial,
        ),
      )
      .then(() => {
        this.sendEmailRegister();
        this.setState({
          successful: true,
          message: 'Usuário cadastrado com sucesso.'
        });
        this.timeout = setTimeout(() => {
          this.setState({
            redirect: true,
          });
        }, 3000);
      })
      .catch(() => {
        this.setState({successful: false});
      });
  }

  sendEmailRegister() {
    this.props
      .dispatch(
        sendEmail(this.state.str_email, null, 'novo-cadastro')
      )
  }

  render() {
    const {successful, isLoggedIn, message, classes} = this.props;

    if (this.state.redirect) {
      return <Redirect to="/usuarios/listar" />;
    }

    return (
      <div className={classes.root}>
        <Grid container className={classes.conteudoFundoBranco} style={{ minWidth: 598 }}>
          <Grid item xs={12} className={classes.formCadastroTitulo}>
            <Icon.PersonAdd className={classes.iconeTitulo} /><span className={classes.tituloPadrao}> Cadastrar Usuário</span>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.content}>
              <Form onSubmit={this.handleRegister} ref={(c) => { this.form = c; }} className={classes.formCadastro}>
                {this.state.successful && (
                  <div className="row">
                    <div className="col mb-2">
                      <Alert severity={this.state.successful ? 'success' : 'error'}>{this.state.message}</Alert>
                    </div>
                  </div>
                )}
                {this.state.messagePhoto && (
                  <div className="row">
                    <div className="col mb-2">
                      <Alert severity={this.state.warningImage ? 'warning' : 'error'}>{this.state.messagePhoto}</Alert>
                    </div>
                  </div>
                )}
                <SubTitulo>Dados Pessoais</SubTitulo>
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <label htmlFor="myImagem" className="col-sm-5 col-form-label camera" style={{marginLeft: 0}}>
                      <PhotoIcon className={classes.inputPhoto}/>
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <input type="file" accept="image/*" name="image" id="myImagem" onChange={this.onImageChange} />
                        <img src={this.state.image ? this.state.image : userPerfilDefault} className="img-cadastro" />
                      </div>
                    </div>
                  </div>
                </div>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="str_nome_pessoa"
                      name="str_nome_pessoa"
                      label="Nome"
                      inputProps={{maxLength: 125,}}
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                      value={this.state.str_nome_pessoa}
                      onChange={this.onChangeName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      id="str_sobrenome"
                      name="str_sobrenome"
                      label="Sobrenome"
                      inputProps={{maxLength: 125,}}
                      fullWidth
                      autoComplete="family-name"
                      variant="outlined"
                      value={this.state.str_sobrenome}
                      onChange={this.onChangeLastname}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale} >
                      <KeyboardDatePicker
                        error={this.state.error_date}
                        helperText={this.state.error_date !== '' ? this.state.error_date : ''}
                        required
                        autoOk={true}
                        name="dat_nascimento"
                        fullWidth
                        variant="inline"
                        disableFuture="true"
                        invalidDateMessage="Data inválida"
                        inputVariant="outlined"
                        label="Data de Nascimento"
                        format="dd/MM/yyyy"
                        openTo="year" //abre direto na escolha do ano
                        views={["year", "month", "date"]} // formato de sequência do calendário
                        maxDate={this.state.maxDate}
                        minDate={this.state.minDate}
                        maxDateMessage={'Data não pode ser superior a data atual'}
                        minDateMessage={'Data inválida'}
                        InputAdornmentProps={{ position: "end" }}
                        value={this.state.dat_nascimento}
                        onChange={this.onChangeBirthDate}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <FormControl required variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel id="demo-simple-select-outlined-label">Gênero</InputLabel>
                      <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Gênero"
                        fullWidth
                        name="flg_genero"
                        value={this.state.flg_genero}
                        onChange={this.onChangeGender}
                      >
                        <MenuItem value="">
                          <em>Selecione</em>
                        </MenuItem>
                        <MenuItem value={'M'}>Masculino</MenuItem>
                        <MenuItem value={'F'}>Feminino</MenuItem>
                        <MenuItem value={'N'}>Não desejo informar</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={this.state.error_cpf.length > 0}
                      helperText={this.state.error_cpf !== '' ? this.state.error_cpf : ' '}
                      className={classes.inputText}
                      id="num_cpf"
                      name="num_cpf"
                      label="CPF"
                      fullWidth
                      autoComplete="given-name"
                      variant="outlined"
                      value={this.state.num_cpf}
                      onChange={this.onChangeCpf}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <SubTitulo>Dados de Contato</SubTitulo>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type='email'
                      error={this.state.error_email}
                      helperText={this.state.error_email !== '' ? this.state.error_email : ''}
                      required
                      id="str_email"
                      name="str_email"
                      label="E-mail"
                      inputProps={{maxLength: 150,}}
                      fullWidth
                      autoComplete="shipping address-line1"
                      variant="outlined"
                      value={this.state.str_email}
                      onChange={this.onChangeEmail}
                      onBlur={this.validarConfirmacaoEmail}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      type='email'
                      error={this.state.error_email_adicional}
                      helperText={this.state.error_email_adicional !== '' ? this.state.error_email_adicional : ''}
                      id="str_email_adicional"
                      name="str_email_adicional"
                      label="E-mail Adicional"
                      inputProps={{maxLength: 150,}}
                      fullWidth
                      autoComplete="shipping address-line1"
                      variant="outlined"
                      value={this.state.str_email_adicional}
                      onChange={this.onChangeEmailAdicional}
                      onBlur={this.validarConfirmacaoEmailAdicional}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={this.state.error_telefone.length > 0}
                      helperText={this.state.error_telefone !== '' ? this.state.error_telefone : ' '}
                      id="str_telefone"
                      name="str_telefone"
                      label="Telefone"
                      fullWidth
                      autoComplete="shipping address-line2"
                      variant="outlined"
                      value={this.state.str_telefone}
                      onChange={this.onChangeTelefone}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <SubTitulo>Endereço</SubTitulo>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={this.state.error_cep.length > 0}
                      helperText={this.state.error_cep !== '' ? this.state.error_cep : ' '}
                      id="num_cep"
                      name="num_cep"
                      label="CEP"
                      fullWidth
                      autoComplete="shipping address-level2"
                      variant="outlined"
                      value={this.state.num_cep}
                      onChange={this.onChangeCep}
                      inputProps={{ maxLength: 10 }}
                      keyboardType = 'numeric'
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel id="demo-simple-select-outlined-label">Estado</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Estado"
                        fullWidth
                        name="cod_unidade_federacao"
                        value={this.state.cod_unidade_federacao}
                        onChange={this.onChangeUnidadeFederacao}
                      >
                        <MenuItem value="">
                          <em>Selecione o Estado</em>
                        </MenuItem>
                        {
                          Object.values(this.state.arrDadosUnidadeFederacao).map(
                            (row, index) =>
                              <MenuItem value={row.str_sigla_uf} key={row.uf}>{row.str_nome_uf}</MenuItem>
                          )
                        }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="str_municipio"
                      name="str_municipio"
                      label="Município"
                      inputProps={{maxLength: 50,}}
                      fullWidth
                      autoComplete="shipping address-level2"
                      variant="outlined"
                      value={this.state.str_municipio}
                      onChange={this.onChangeMunicipio}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="str_bairro"
                      name="str_bairro"
                      label="Bairro"
                      inputProps={{maxLength: 250,}}
                      fullWidth
                      autoComplete="shipping address-level2"
                      variant="outlined"
                      value={this.state.str_bairro}
                      onChange={this.onChangeBairro}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={this.state.error_logradouro != null }
                      helperText={this.state.error_logradouro !== null ? this.state.error_logradouro : ' '}
                      id="str_logradouro"
                      name="str_logradouro"
                      label="Logradouro"
                      inputProps={{maxLength: 150,}}
                      fullWidth
                      autoComplete="shipping address-level2"
                      variant="outlined"
                      value={this.state.str_logradouro}
                      onChange={this.onChangeLogradouro}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="str_complemento_endereco"
                      name="str_complemento_endereco"
                      label="Complemento"
                      inputProps={{maxLength: 250,}}
                      fullWidth
                      autoComplete="shipping address-level2"
                      variant="outlined"
                      value={this.state.str_complemento_endereco}
                      onChange={this.onChangeComplementoEndereco}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="num_residencial"
                      name="num_residencial"
                      type='number'
                      label="Número"
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                      }}
                      inputProps={{
                        max: 9999999999,
                        min: 0
                      }}
                      fullWidth
                      autoComplete="shipping address-level2"
                      variant="outlined"
                      value={this.state.num_residencial}
                      onChange={this.onChangeNumeroResidencial}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div style={{textAlign: 'center'}}>
                      <Button
                        type="submit"
                        className={classes.formButton}
                        variant="outlined">Cadastrar</Button> &nbsp;
                      <Button
                        className={classes.formButton}
                        component={Link} to="/usuarios/listar">Cancelar</Button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(CadastrarUsuarios));
