import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Button, Grid} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DescriptionIcon from '@material-ui/icons/Description';
import {Titulo, SubTitulo} from '../../views/style/styles';
import TextField from '@material-ui/core/TextField';
import ModalPadrao from '../partials/ModalPadrao';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import BotaoPanicoService from '../../services/botao-panico.service';
import {withRouter} from 'react-router-dom';
import userPerfilDefault from '../../assets/images/user-perfil-default.png';
import ArquivoService from '../../services/arquivo.service';
import GetAppIcon from '@material-ui/icons/GetApp';
import ChatIcon from '@material-ui/icons/Chat';
import GoogleMapReact from 'google-map-react';
import PersonIcon from '@material-ui/icons/Person';
import {TextArea} from 'semantic-ui-react';
import MapIcon from '@material-ui/icons/Map';
import RoomIcon from '@material-ui/icons/Room';
import {dateFormatter, telefoneMask} from '../util';


const REACT_APP_KEY_GOOGLE_MAPS_API = process.env.REACT_APP_KEY_GOOGLE_MAPS_API;

const styles = theme => ({
  formCadastroTitulo: {
    margin: '15px 0 15px 0',
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
    maxWidth: 400,
  },
  button: {
    color: '#000',
    backgroundColor: '#FFF',
    border: 'solid 1px #005b96',
    // height: '42px',
    width: 150,
    textAlign: 'center',
    '&:hover': {
      color: '#005b96',
      backgroundColor: '#FFF',
      border: 'solid 1px #005b96',
    },
  },
  buttonSalvar: {
    color: '#FFF',
    // backgroundColor: '#FFF',
    // border: 'solid 1px #005b96',
    // height: '42px',
    width: 150,
    textAlign: 'center',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#005b96',
      // border: 'solid 1px #005b96',
    },
  },
  fotoPerfil: {
    borderRadius: 30,
    width: 60,
    height: 60,
    objectFit: 'cover',
    right: 130,
    position: 'static',
    //float: 'right',
    //marginRight: '10%',
  },
  buttonDesfazerCaptura: {
    color: '#FFF',
    backgroundColor: '#D80909',
    border: 'solid 1px #FFF',
    borderRadius: '5px',
    height: '42px',
    textAlign: 'center',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#D80909',
      border: 'solid 1px #333333',
    },
  },
  buttonCapturar: {
    color: '#FFF',
    backgroundColor: '#668CFF',
    border: 'solid 1px #FFF',
    borderRadius: '5px',
    height: '42px',
    textAlign: 'center',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#668CFF',
      border: 'solid 1px #333333',
    },
  },
  miniImagem: {
    width: 60,
    height: 60,
    margin: 5,
    objectFit: 'cover',
    cursor: 'pointer',
  },
  divImagens: {
    width: 70,
    height: 90,
    flex: 1,
  },
  divAudios: {
    flex: 1,
    textAlign: 'center',
  },
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );
const loadingPadraoBotao =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/>
        </span>
  );

class VisualizarChamado extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: localStorage.getItem('user'),
      mensagemAlert: {mensagem: null, severity: 'success', closeButton: true},
      isLoading: true,
      isSaving: false,
      arrDadosChamado: null,
      imagemUsuario: null,
      isLoadingButton: false,
      usuarioCapturouChamado: null,
      codUsuarioCaptura: null,
      semImagens: true,
      semAudios: true,
      modalMensagens: false,
      erroCampoRespostaFinal: null,
      defaultProps: {
        center: {
          lat: -25.5003678,
          lng: -54.5684169,
        },
        zoom: 15,
      },
      exibirRotaNoMapa: false,
      map: null,
      maps: null,
      directionsRenderer: null,
      arrMarker: null,
      exibirMarkersNoMapa: false,
      modalFinalizarChamado: false,
      isSavingFinalizarChamado: false,
      flgSituacao: '',
      flgSituacaoFmt: '',
    };

  }

  componentDidMount() {
    this.carregarDadosChamado();
    setInterval(() => {
      this.setChamados();
    }, 3000);
  }

  setChamados = async () => {


    if (this.state.arrDadosChamado.cod_chamado) {

      let arrChamadoAberto = await BotaoPanicoService.getChamados({
        codChamado: this.state.arrDadosChamado.cod_chamado,
      });

      this.setState({flgSituacao: arrChamadoAberto[0].flgSituacao});
      this.trataSituacaoChamado(arrChamadoAberto[0].flgSituacao);

    }

  };

  carregarDadosChamado = async () => {
    const url = window.location.href;
    let strin = url.split('/');
    let codChamado = strin.pop();
    const arrDadosChamado = await BotaoPanicoService.carregarDadosChamado(codChamado);
    if (!arrDadosChamado || arrDadosChamado.cod_chamado == null || arrDadosChamado.cod_chamado == undefined) {
      this.setState({isLoading: false});
      this.setState({
        mensagemAlert: {
          mensagem: 'Chamado não encontrado.',
          severity: 'error',
          closeButton: true,
        },
      });
      return false;
    }
    this.setState({arrDadosChamado: arrDadosChamado});
    this.trataSituacaoChamado(arrDadosChamado.flg_situacao);

    if (arrDadosChamado.num_latitude_chamado && arrDadosChamado.num_longitude_chamado) {
      const defaultProps = {
        center: {
          lat: parseFloat(arrDadosChamado.num_latitude_chamado),
          lng: parseFloat(arrDadosChamado.num_longitude_chamado),
        },
        zoom: 17,
      };
      this.setState({defaultProps: defaultProps});
    }
    await this.carregaFotoUsuario();

    if (arrDadosChamado.cod_usuario_responsavel != undefined && arrDadosChamado.cod_usuario_responsavel.cod_usuario != undefined) {
      this.setState({codUsuarioCaptura: arrDadosChamado.cod_usuario_responsavel.cod_usuario});
    }

    this.setState({isLoading: false});
  };

  carregaFotoUsuario = async () => {
    let arquivo = null;
    if (this.state.arrDadosChamado.cod_usuario_chamado.cod_pessoa.cod_arquivo != undefined) {
      const dadosArquivo = this.state.arrDadosChamado.cod_usuario_chamado.cod_pessoa.cod_arquivo;
      const arrData = dadosArquivo.tms_criacao.split('-');
      const path = arrData[0] + '/' + arrData[1] + '/' + arrData[2].substring(0, 2) + '/' + dadosArquivo.cod_arquivo.toString();
      arquivo = await ArquivoService.getImage(path);
    }
    this.setState({imagemUsuario: arquivo});
  };

  onVoltar = () => {
    this.props.history.push('/botao-panico/dashboard');
  };

  capturarChamado = async () => {
    this.setState({isLoadingButton: true});
    this.setState({
      mensagemAlert: {
        mensagem: null,
        severity: 'success',
        closeButton: true,
      },
    });
    const retorno = await BotaoPanicoService.capturarChamado(this.state.arrDadosChamado.cod_chamado, 'E');
    this.setState({
      mensagemAlert: {
        mensagem: retorno.data.message,
        severity: retorno.data.status ? 'success' : 'error',
        closeButton: true,
      },
    });
    let arrDadosChamado = this.state.arrDadosChamado;
    if (retorno.data.status) {
      arrDadosChamado.flg_situacao = 'E';
    }
    this.setState({
      isLoadingButton: false,
      usuarioCapturouChamado: JSON.parse(localStorage.getItem('user')).nome,
      codUsuarioCaptura: JSON.parse(localStorage.getItem('user')).cod_usuario,
      arrDadosChamado: arrDadosChamado,
    });
  };

  desfazerCaptura = async () => {
    this.setState({isLoadingButton: true});
    this.setState({
      mensagemAlert: {
        mensagem: null,
        severity: 'success',
        closeButton: true,
      },
    });
    const retorno = await BotaoPanicoService.capturarChamado(this.state.arrDadosChamado.cod_chamado, 'A');
    this.setState({
      mensagemAlert: {
        mensagem: retorno.data.message,
        severity: retorno.data.status ? 'success' : 'error',
        closeButton: true,
      },
    });
    let arrDadosChamado = this.state.arrDadosChamado;
    if (retorno.data.status) {
      arrDadosChamado.flg_situacao = 'A';
    }
    this.setState({
      isLoadingButton: false,
      usuarioCapturouChamado: '-',
      arrDadosChamado: arrDadosChamado,
      codUsuarioCaptura: null,
    });
  };

  trataData = (data) => {
    let data_hora = '';
    const arrData = data.search(' ') >= 0 ? data.split(' ')[0].split('-') : data.split('T')[0].split('-');
    data_hora = arrData[2] + '/' + arrData[1] + '/' + arrData[0] + ' ' + (data.search(' ') >= 0 ? data.split(' ')[1].substring(0, 5) : data.split('T')[1].substring(0, 5));
    return <span>{data_hora}</span>;
  };

  downloadArquivo = (arquivo) => {
    const linkSource = arquivo.base64;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = arquivo.str_nome_arq + '.' + arquivo.str_extensao;
    downloadLink.click();
  };

  trataSituacaoChamado = (letra) => {
    let situacao = '';
    switch (letra) {
    case 'A':
      situacao = 'Aberto';
      break;
    case 'F':
      situacao = 'Finalizado';
      break;
    case 'E':
      situacao = 'Em andamento';
      break;
    case 'C':
      situacao = 'Cancelado';
      break;
    default:
      situacao = '-';
    }

    this.setState({flgSituacaoFmt: situacao});
  };

  handleCloseModal = () => {
    this.setState({modalMensagens: false});
  };

  handleCloseModalFinalizarChamado = () => {
    this.setState({modalFinalizarChamado: false});
  };

  exibirRotaNoMapa = () => {
    this.setState({exibirRotaNoMapa: !this.state.exibirRotaNoMapa});
    if (!this.state.exibirRotaNoMapa) {
      this.state.directionsRenderer.setMap(this.state.map);
    } else {
      this.state.directionsRenderer.setMap(null);
    }
  };

  retornaMaximoWayPointsPorArray = (arrWayPoints) => {

    const num_maximo = 25;
    const tamanho_array = arrWayPoints.length;

    if (tamanho_array <= num_maximo) {
      return arrWayPoints;
    }

    const num_elementos = Math.trunc(tamanho_array / num_maximo);

    let arrPontos = [];
    for (let i = 0; i <= tamanho_array; i += num_elementos) {
      let chave = i === tamanho_array ? tamanho_array - 1 : i;
      arrPontos.push(arrWayPoints[chave]);
    }
    return arrPontos;
  };

  exibirMarkerNoMapa = () => {
    this.setState({exibirMarkersNoMapa: !this.state.exibirMarkersNoMapa});
    if (this.state.exibirMarkersNoMapa) {
      this.state.arrMarker.map(marker => {
        marker.setMap(null);
      });
    } else {
      let map = this.state.map;
      this.state.arrMarker.map(marker => {
        marker.setMap(map);
      });
    }
  };

  calculateAndDisplayRoute(maps, directionsService, directionsRenderer, map) {
    const waypts = [];
    if (this.state.arrDadosChamado.geo_traje_percorrido) {

      // const arrPontosMarker = this.state.arrDadosChamado.geo_traje_percorrido;
      const arrPontosMarker = this.retornaMaximoWayPointsPorArray(this.state.arrDadosChamado.geo_traje_percorrido);

      // Create an info window to share between markers.
      const infoWindow = new maps.InfoWindow();

      if (arrPontosMarker.length > 0) {
        let num_marker = 1;
        let arrMarker = [];
        arrPontosMarker.map(dado => {
          var marker = new maps.Marker({
            position: new maps.LatLng(dado[0], dado[1]),
            icon: 'https://chart.googleapis.com/chart?chst=d_map_spin&chld=0.8|0|FF0000|12|b|' + num_marker,
            title: 'Data: ' + dado[2] + ', Latitude: ' + dado[0] + ', Longitude: ' + dado[1],
          });

          marker.addListener('click', () => {
            infoWindow.close();
            infoWindow.setContent('<br>Data: ' + dado[2] + '<br>Latitude: ' + dado[0] + '<br>Longitude: ' + dado[1]);
            infoWindow.open(marker.getMap(), marker);
          });

          arrMarker.push(marker);
          num_marker++;
          this.setState({arrMarker: arrMarker});
        });

      }

      const arrPontos = this.retornaMaximoWayPointsPorArray(this.state.arrDadosChamado.geo_traje_percorrido);

      if (arrPontos.length > 0) {
        let i = 0;
        arrPontos.map(dado => {
          if (i !== 0 && i !== (arrPontos.length - 1)) {
            waypts.push({
              location: new maps.LatLng(dado[0], dado[1]),
              stopover: true,
            });
          }
          i++;
        });
      }
      directionsService
        .route({
          origin: new maps.LatLng(arrPontos[0][0], arrPontos[0][1]),
          destination: new maps.LatLng(arrPontos[arrPontos.length - 1][0], arrPontos[arrPontos.length - 1][1]),
          waypoints: waypts,
          optimizeWaypoints: false,
          travelMode: maps.TravelMode.BICYCLING,
        })
        .then((response) => {
          directionsRenderer.setDirections(response);
          const route = response.routes[0];
        })
        .catch((e) => console.log('Directions request failed due to', e));
    }
  }

  handleApiLoaded = (map, maps) => {
    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();
    this.calculateAndDisplayRoute(maps, directionsService, directionsRenderer, map);
    this.setState({
      map: map,
      maps: maps,
      directionsRenderer: directionsRenderer,
    });
    // directionsRenderer.setMap(map);
  };

  onChangeRespostaChamado = (event) => {
    let arrDadosChamado = this.state.arrDadosChamado;
    arrDadosChamado.str_resposta_chamado = event.target.value;
    this.setState({arrDadosChamado: arrDadosChamado});
  };

  finalizarChamado = () => {
    if (this.state.arrDadosChamado.str_resposta_chamado == undefined || this.state.arrDadosChamado.str_resposta_chamado.length < 1) {
      this.setState({erroCampoRespostaFinal: 'Campo obrigatório.'});
      return false;
    } else {
      this.setState({erroCampoRespostaFinal: null});
    }
    this.setState({modalFinalizarChamado: true});
  };

  confirmarFinalizarChamado = async () => {
    if (this.state.arrDadosChamado.str_resposta_chamado == undefined || this.state.arrDadosChamado.str_resposta_chamado.length < 1) {
      this.setState({erroCampoRespostaFinal: 'Campo obrigatório.'});
      return false;
    }
    this.setState({
      erroCampoRespostaFinal: null,
      isSavingFinalizarChamado: true,
    });
    this.setState({isLoadingButton: true});
    this.setState({
      mensagemAlert: {
        mensagem: null,
        severity: 'success',
        closeButton: true,
      },
    });
    const retorno = await BotaoPanicoService.finalizarChamado(this.state.arrDadosChamado.cod_chamado, this.state.arrDadosChamado.str_resposta_chamado);
    this.setState({
      mensagemAlert: {
        mensagem: retorno.message,
        severity: retorno.status ? 'success' : 'error',
        closeButton: true,
      },
    });
    if (retorno.status) {
      let arrDadosChamado = this.state.arrDadosChamado;
      arrDadosChamado.flg_situacao = 'F';
      arrDadosChamado.tms_fim_chamado = retorno.tms_fim_chamado;
      this.setState({arrDadosChamado: arrDadosChamado});
    }
    this.setState({
      isLoadingButton: false,
      modalFinalizarChamado: false,
      isSavingFinalizarChamado: false,
    });
  };

  salvarChamado = async () => {
    if (this.state.arrDadosChamado.str_resposta_chamado == undefined || this.state.arrDadosChamado.str_resposta_chamado.length < 1) {
      this.setState({erroCampoRespostaFinal: 'Campo obrigatório.'});
      return false;
    }
    this.setState({erroCampoRespostaFinal: null});
    this.setState({isLoadingButton: true});
    this.setState({
      mensagemAlert: {
        mensagem: null,
        severity: 'success',
        closeButton: true,
      },
    });
    const retorno = await BotaoPanicoService.salvarChamadoInterno(this.state.arrDadosChamado.cod_chamado, this.state.arrDadosChamado.str_resposta_chamado);
    this.setState({
      mensagemAlert: {
        mensagem: retorno.message,
        severity: retorno.status ? 'success' : 'error',
        closeButton: true,
      },
    });
    this.setState({isLoadingButton: false});
  };

  generoFormatter = (value) => {

    switch (value) {
    case 'M':
      return 'Masculino';
    case 'F':
      return 'Feminino';
    default:
      return 'Não deseja informar';

    }

  };

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <Grid container className={classes.conteudoFundoBranco}
              style={{minWidth: 623}}>
          {this.state.isLoading === true &&
            <Grid item xs={12} sm={12}
                  style={{marginTop: 25, textAlign: 'center'}}>
              {mensagemPadraoCarregando}
            </Grid>
          }
          {this.state.isLoading === false &&
            <>
              <Grid item xs={12} className={classes.formCadastroTitulo}>
                <DescriptionIcon className={classes.iconeTitulo}/>
                <span className={classes.tituloPadrao}>Visualizar Chamado</span>
              </Grid>
              {this.state.mensagemAlert.mensagem !== null &&
                <Grid item xs={12} style={{marginBottom: 15, marginTop: 15}}>
                  <Alert
                    severity={this.state.mensagemAlert.severity}
                    action={
                      this.state.mensagemAlert.closeButton === true &&
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          this.setState({
                            mensagemAlert: {
                              mensagem: null,
                              severity: 'success',
                              closeButton: true,
                            },
                          });
                        }}
                      >
                        <CloseIcon fontSize="inherit"/>
                      </IconButton>
                    }
                  >
                    {this.state.mensagemAlert.mensagem}
                  </Alert>
                </Grid>
              }
              {this.state.arrDadosChamado &&
                <>
                  <Grid item xs={12}
                        style={{marginTop: 25, textAlign: 'right'}}>
                    <strong>Responsável pelo
                      Chamado:</strong> {this.state.arrDadosChamado.cod_usuario_responsavel && this.state.usuarioCapturouChamado === null ? this.state.arrDadosChamado.cod_usuario_responsavel.cod_pessoa.str_nome_pessoa + ' ' + this.state.arrDadosChamado.cod_usuario_responsavel.cod_pessoa.str_sobrenome : (this.state.usuarioCapturouChamado ?? '-')}
                    {this.state.flgSituacao === 'A' &&
                      <Button className={classes.buttonCapturar}
                              style={{width: 200, marginLeft: '25px'}}
                              disabled={this.state.isLoadingButton}
                              variant="contained"
                              onClick={this.capturarChamado}>
                        {this.state.isLoadingButton ? loadingPadraoBotao : 'Capturar Chamado'}
                      </Button>
                    }
                    {this.state.flgSituacao === 'E' && this.state.codUsuarioCaptura === JSON.parse(localStorage.getItem('user')).cod_usuario &&
                      <Button className={classes.buttonDesfazerCaptura}
                              style={{width: 200, marginLeft: '25px'}}
                              disabled={this.state.isLoadingButton}
                              variant="text" onClick={this.desfazerCaptura}>
                        {this.state.isLoadingButton ? loadingPadraoBotao : 'Desfazer Captura'}
                      </Button>
                    }
                  </Grid>
                  <Grid item xs={12} style={{marginBottom: 25, minWidth: 249}}>
                    <SubTitulo>Dados do Chamado</SubTitulo>
                  </Grid>

                  <Grid container spacing={2}>

                    <Grid item xs={12} sm={11}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <strong>Nome do
                            Usuário</strong>: {this.state.arrDadosChamado.cod_usuario_chamado ? this.state.arrDadosChamado.cod_usuario_chamado.cod_pessoa.str_nome_pessoa + ' ' + this.state.arrDadosChamado.cod_usuario_chamado.cod_pessoa.str_sobrenome : '-'}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <strong>E-mail</strong>: {this.state.arrDadosChamado.cod_usuario_chamado.str_email ?? '-'}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <strong>Data de
                            Nacimento</strong>: {this.state.arrDadosChamado.cod_usuario_chamado.cod_pessoa.dat_nascimento ? dateFormatter(this.state.arrDadosChamado.cod_usuario_chamado.cod_pessoa.dat_nascimento) : '-'}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <strong>Telefone</strong>: {this.state.arrDadosChamado.cod_usuario_chamado.cod_pessoa.str_telefone ? telefoneMask(this.state.arrDadosChamado.cod_usuario_chamado.cod_pessoa.str_telefone) : '-'}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <strong>Gênero</strong>: {this.state.arrDadosChamado.cod_usuario_chamado.cod_pessoa.flg_genero ? this.generoFormatter(this.state.arrDadosChamado.cod_usuario_chamado.cod_pessoa.flg_genero) : '-' }
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <strong>Número do
                            chamado</strong>: {this.state.arrDadosChamado.str_identificacao_chamado ?? '-'}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <strong>Situação do
                            Chamado</strong>: {this.state.arrDadosChamado.flg_situacao ? this.state.flgSituacaoFmt : '-'}
                        </Grid>


                        <Grid item xs={12} sm={6}>
                          <strong>Abertura do
                            Chamado</strong>: {this.state.arrDadosChamado.tms_abertura_chamado ? this.trataData(this.state.arrDadosChamado.tms_abertura_chamado) : '-'}
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <strong>Término do
                            chamado</strong>: {this.state.arrDadosChamado.tms_fim_chamado ? this.trataData(this.state.arrDadosChamado.tms_fim_chamado) : '-'}
                        </Grid>


                        <Grid item xs={12} sm={6}>
                          <strong>Localização</strong>: {this.state.arrDadosChamado.num_latitude_chamado ?? '-'}, {this.state.arrDadosChamado.num_longitude_chamado ?? '-'}
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <strong>Observação</strong>: {!this.state.arrDadosChamado.str_observacao || this.state.arrDadosChamado.str_observacao == ' ' ? '-' : this.state.arrDadosChamado.str_observacao}
                        </Grid>

                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={1}>
                      <Grid container>
                        <img src={this.state.imagemUsuario ?? userPerfilDefault}
                             className={classes.fotoPerfil}/>
                      </Grid>
                    </Grid>

                  </Grid>


                  <Grid item xs={12} style={{marginBottom: 25, minWidth: 249}}>
                    <SubTitulo style={{marginTop: 25}}>Dados coletados do
                      usuário</SubTitulo>
                  </Grid>
                  <Grid item xs={3} style={{
                    marginBottom: 25,
                    textAlign: 'center',
                    minWidth: 230,
                  }}>
                    <strong>Imagens da Câmera</strong><br/>
                    <div style={{
                      flexWrap: 'nowrap',
                      display: 'flex',
                      flexFlow: 'wrap',
                    }}>
                      {this.state.arrDadosChamado.anexos.length > 0 && this.state.arrDadosChamado.anexos.map((dado, index) => {
                        return (
                          <>
                            {dado.base64 && dado.str_tipo_anexo === 'Imagem' &&
                              <div key={dado.cod_arquivo + '_div'}
                                   className={classes.divImagens}>
                                {this.state.semImagens === true && this.setState({semImagens: false})}
                                <img key={dado.cod_arquivo + '_img'}
                                     src={dado.base64}
                                     className={classes.miniImagem}
                                     onClick={() => this.downloadArquivo(dado)}/>
                                <br/>
                                <GetAppIcon key={dado.cod_arquivo + '_icon'}
                                            style={{cursor: 'pointer'}}
                                            onClick={() => this.downloadArquivo(dado)}/>
                              </div>
                            }
                          </>
                        );
                      })
                      }
                      {this.state.semImagens &&
                        <div style={{flex: 1, textAlign: 'center'}}>Não existem
                          imagens.</div>
                      }
                    </div>
                  </Grid>
                  <Grid item xs={3} style={{
                    marginBottom: 25,
                    textAlign: 'center',
                    minWidth: 230,
                  }}>
                    <strong>Áudios capturados</strong><br/>
                    <div style={{
                      flexWrap: 'nowrap',
                      display: 'flex',
                      flexFlow: 'wrap',
                    }}>
                      {this.state.arrDadosChamado.anexos.length > 0 && this.state.arrDadosChamado.anexos.map((dado, index) => {
                        return (
                          <>
                            {dado.base64 && dado.str_tipo_anexo === 'Áudio' &&
                              <div key={dado.cod_arquivo + '_div_audio'}
                                   className={classes.divAudios}>
                                {this.state.semAudios === true && this.setState({semAudios: false})}
                                <audio style={{maxWidth: 220}}
                                       key={dado.cod_arquivo + '_audio_tag'}
                                       controls="controls">
                                  <source
                                    key={dado.cod_arquivo + '_audio_sorce'}
                                    src={dado.base64}/>
                                </audio>
                              </div>
                            }
                          </>
                        );
                      })
                      }
                      {this.state.semAudios &&
                        <div style={{flex: 1, textAlign: 'center'}}>Não existem
                          áudios.</div>
                      }
                    </div>
                  </Grid>
                  <Grid item xs={3} style={{
                    marginBottom: 25,
                    textAlign: 'center',
                    minWidth: 230,
                  }}>
                    <strong>Mensagens enviadas</strong><br/>
                    {this.state.arrDadosChamado.mensagens.length > 0 &&
                      <span style={{cursor: 'pointer'}}
                            onClick={() => this.setState({modalMensagens: true})}>
                                                <ChatIcon style={{
                                                  width: 30,
                                                  height: 30,
                                                }}/>
                        {this.state.arrDadosChamado.mensagens.length} Mensagens
                                            </span>
                    }
                    {this.state.arrDadosChamado.mensagens.length === 0 &&
                      <div style={{flex: 1, textAlign: 'center'}}>Não existem
                        mensagens.</div>
                    }
                    <ModalPadrao open={this.state.modalMensagens}
                                 handleCloseModal={() => this.handleCloseModal()}>
                      <h5>Mensagens</h5>
                      <hr></hr>
                      <div style={{textAlign: 'justify'}}>
                        {this.state.arrDadosChamado.mensagens.length > 0 && this.state.arrDadosChamado.mensagens.map((dado, index) => {
                          return (
                            <>
                              <div>
                                <p
                                  style={{overflowWrap: 'anywhere'}}>{dado.str_mensagem}</p>
                                <div
                                  style={{width: '100%', textAlign: 'right'}}>
                                  {this.trataData(dado.tms_envio_mensagem)}
                                </div>
                                <hr/>
                              </div>
                            </>
                          );
                        })
                        }
                      </div>
                      <div style={{textAlign: 'right'}}>
                        <Button variant="text"
                                onClick={() => this.setState({modalMensagens: false})}>Fechar</Button>
                      </div>
                    </ModalPadrao>
                    <ModalPadrao open={this.state.modalFinalizarChamado}
                                 handleCloseModal={this.handleCloseModalFinalizarChamado}>
                      <h5>Aviso!</h5>
                      <hr></hr>
                      <p>Deseja mesmo finalizar o chamado?</p>
                      <hr></hr>
                      {this.state.isSavingFinalizarChamado === false &&
                        <>
                          <Button variant="outlined"
                                  onClick={this.confirmarFinalizarChamado}
                                  style={{width: 80}}>Sim</Button> &nbsp;
                          <Button variant="text"
                                  onClick={() => this.setState({modalFinalizarChamado: false})}>Não</Button>
                        </>
                      }
                      {this.state.isSavingFinalizarChamado !== false &&
                        <>
                          <Button variant="outlined" disabled
                                  style={{width: 80}}><CircularProgress style={{
                            color: '#FFF',
                            width: 20,
                            height: 20,
                          }}/></Button> &nbsp;
                          <Button variant="text" disabled>Não</Button>
                        </>
                      }
                    </ModalPadrao>
                  </Grid>
                  <Grid item xs={3} style={{
                    marginBottom: 25,
                    textAlign: 'center',
                    minWidth: 230,
                  }}>
                    <strong>Trajeto Percorrido desde o início do
                      chamado</strong><br/>
                    {this.state.arrDadosChamado.geo_traje_percorrido &&
                      <>
                        <span onClick={this.exibirRotaNoMapa}
                              style={{cursor: 'pointer'}}><MapIcon style={{
                          width: 30,
                          height: 30,
                        }}/> {!this.state.exibirRotaNoMapa ? 'Exibir Rota' : 'Remover Rota'}</span><br/>
                        <span onClick={this.exibirMarkerNoMapa}
                              style={{cursor: 'pointer'}}><RoomIcon style={{
                          width: 30,
                          height: 30,
                        }}/> {!this.state.exibirMarkersNoMapa ? 'Exibir Pontos' : 'Remover Pontos'}</span>
                      </>
                    }
                    {!this.state.arrDadosChamado.geo_traje_percorrido &&
                      <div style={{flex: 1, textAlign: 'center'}}>Não existe
                        trajeto.</div>
                    }
                  </Grid>
                  <Grid item xs={12} style={{marginBottom: 25}}>
                    <SubTitulo style={{marginTop: 25}}>Localização no
                      Mapa</SubTitulo>
                    <div style={{height: '400px', width: '100%'}}>
                      <GoogleMapReact
                        bootstrapURLKeys={{key: REACT_APP_KEY_GOOGLE_MAPS_API}}
                        defaultCenter={this.state.defaultProps.center}
                        defaultZoom={this.state.defaultProps.zoom}
                        // options={this.createMapOptions}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({
                          map,
                          maps,
                        }) => this.handleApiLoaded(map, maps)}
                      >
                        <PersonIcon key="icone_mapa" style={{
                          width: 40,
                          height: 40,
                          color: '#000',
                        }}
                                    lat={this.state.arrDadosChamado.num_latitude_chamado}
                                    lng={this.state.arrDadosChamado.num_longitude_chamado}
                        />
                      </GoogleMapReact>
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{marginBottom: 25}}>
                    <SubTitulo style={{marginTop: 25}}>Finalizar
                      Chamado</SubTitulo>
                  </Grid>
                  {this.state.mensagemAlert.mensagem !== null &&
                    <Grid item xs={12}
                          style={{marginBottom: 15, marginTop: 15}}>
                      <Alert
                        severity={this.state.mensagemAlert.severity}
                        action={
                          this.state.mensagemAlert.closeButton === true &&
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              this.setState({
                                mensagemAlert: {
                                  mensagem: null,
                                  severity: 'success',
                                  closeButton: true,
                                },
                              });
                            }}
                          >
                            <CloseIcon fontSize="inherit"/>
                          </IconButton>
                        }
                      >
                        {this.state.mensagemAlert.mensagem}
                      </Alert>
                    </Grid>
                  }
                  <Grid item xs={2} style={{marginTop: 40}}><strong>Resposta do
                    Chamado*</strong></Grid>
                  <Grid item xs={10}>
                    <TextArea
                      name="str_resposta_chamado"
                      rows={7}
                      placeholder="Resposta do Chamado"
                      disabled={this.state.flgSituacao !== 'E' ? true : false}
                      onChange={this.onChangeRespostaChamado}
                      value={this.state.arrDadosChamado.str_resposta_chamado}
                      maxLength="1000"
                      style={{display: 'block', width: '100%', padding: 5}}
                    />
                    <span
                      style={{color: 'red'}}>{this.state.erroCampoRespostaFinal}</span>
                  </Grid>
                  <Grid item xs={12} style={{marginBottom: 25}}>
                    <div style={{
                      textAlign: 'center',
                      padding: 15,
                      margin: '0 auto',
                    }}>
                      {this.state.flgSituacao === 'A' &&
                        <>
                          <Button style={{width: 150}} variant="outlined"
                                  disabled={this.state.flgSituacao !== 'E' || this.state.isLoadingButton ? true : false}
                                  onClick={this.finalizarChamado}>
                            {this.state.isLoadingButton ? loadingPadraoBotao : 'Finalizar Chamado'}
                          </Button> &nbsp;
                          <Button variant="contained"
                                  className={classes.buttonSalvar}
                                  color="primary"
                                  disabled={this.state.flgSituacao !== 'E' || this.state.isLoadingButton ? true : false}
                                  onClick={this.salvarChamado}>
                            {this.state.isLoadingButton ? loadingPadraoBotao : 'Salvar'}
                          </Button> &nbsp;
                        </>
                      }
                      {this.state.flgSituacao === 'E' &&
                        this.state.codUsuarioCaptura === JSON.parse(localStorage.getItem('user')).cod_usuario &&
                        <>
                          <Button style={{width: 150}} variant="outlined"
                                  disabled={this.state.flgSituacao !== 'E' || this.state.isLoadingButton ? true : false}
                                  onClick={this.finalizarChamado}>
                            {this.state.isLoadingButton ? loadingPadraoBotao : 'Finalizar Chamado'}
                          </Button> &nbsp;
                          <Button variant="contained"
                                  className={classes.buttonSalvar}
                                  color="primary"
                                  disabled={this.state.flgSituacao !== 'E' || this.state.isLoadingButton ? true : false}
                                  onClick={this.salvarChamado}>
                            {this.state.isLoadingButton ? loadingPadraoBotao : 'Salvar'}
                          </Button> &nbsp;
                        </>
                      }

                      <Button variant="contained" className={classes.button}
                              onClick={this.onVoltar}>Voltar</Button>
                    </div>
                  </Grid>
                </>
              }
            </>
          }
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  return {
    user,
  };
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(VisualizarChamado)));
