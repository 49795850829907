import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { Button, Grid } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ReportProblem from '@material-ui/icons/ReportProblem';
import { Titulo, SubTitulo } from '../../views/style/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import BotaoPanicoService from '../../services/botao-panico.service';
import ArquivoService from '../../services/arquivo.service';
import GoogleMapReact from 'google-map-react';
import IconeMapaChamado from '../partials/mapa-chamado/IconeMapa';
import ReactDOM from 'react-dom';
import PersonIcon from '@material-ui/icons/Person';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Paper } from '@material-ui/core';

const REACT_APP_KEY_GOOGLE_MAPS_API = process.env.REACT_APP_KEY_GOOGLE_MAPS_API

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
    },
    conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
    tituloPadrao: theme.pagina.tituloPadrao,
    iconeTitulo: theme.pagina.iconeTitulo,
    formCadastroTitulo: {
        margin: '15px 0 15px 0',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 350,
        maxWidth: 400,
    },
    button: {
        color: '#000',
        backgroundColor: '#FFF',
        border: 'solid 1px #005b96',
        maxWidth: 100,
        height: '42px',
        marginTop: '25px',
        '&:hover': {
            color: '#005b96',
            backgroundColor: '#FFF',
            border: 'solid 1px #005b96',
        },
    },
});

const mensagemPadraoCarregando =
    (
        <span>
            <CircularProgress style={{ width: 20, height: 20, marginRight: 15 }} /> Carregando...
        </span>
    )

class MapaChamado extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: localStorage.getItem("user"),
            mensagemAlert: { mensagem: null, severity: 'success', closeButton: true },
            isLoading: true,
            isSaving: false,
            arrChamadosEmAndamento: [],
            arrChamadosAtivos: [],
            arrChamadosEmAndamentoOriginal: [],
            arrChamadosAtivosOriginal: [],
            arrChamados: [],
            codChamado: 0,
            imagemUsuario: null,
            isLoadingInfo: false,
            htmlLegenda: 'legend',
            defaultChecked: true,
            numRequisicao: 0,
        };

    }

    static defaultProps = {
        center: {
            lat: -25.5003678,
            lng: -54.5684169
        },
        zoom: 15
    };

    componentDidMount() {
        this.carregarChamados()
        this.onLoadChamados();
    }

    onLoadChamados = () => {
        let count = this.state.numRequisicao + 1;

        setInterval(() => {
            this.carregarChamados()
        }, 3000);

        this.setState({numRequisicao: count});

        if(this.state.numRequisicao == 300){
            document.location.reload(true);
        }
    }

    carregarChamados = async () => {
        const arrChamados = await BotaoPanicoService.carregarChamados();
        const arrChamadosAtivos = arrChamados.filter((data) => {
            return (
                (data.flg_situacao === 'A')
            );
        });
        const arrEmAndamento = arrChamados.filter((data) => {
            return (
                (data.flg_situacao === 'E')
            );
        });

        this.setState({ arrChamadosAtivos: arrChamadosAtivos ?? [] });
        this.setState({ arrChamadosEmAndamento: arrEmAndamento ?? [] });
        this.setState({ arrChamadosAtivosOriginal: arrChamadosAtivos ?? [] });
        this.setState({ arrChamadosEmAndamentoOriginal: arrEmAndamento ?? [] });
        this.setState({ isLoading: false });
    }

    exibeLegenda = () => {
        document.getElementById('div_botao_legendas').style.display = 'none';
        document.getElementById('div_botao_filtros').style.display = 'none';
        document.getElementById('paper_filtros').style.display = 'none';
        document.getElementById('paper_legendas').style.display = '';
        document.getElementById('div_legendas').style.display = '';
        document.getElementById('div_filtros').style.display = 'none';
    }

    escondeLegenda = () => {
        document.getElementById('div_botao_legendas').style.display = '';
        document.getElementById('div_botao_filtros').style.display = '';
        document.getElementById('paper_filtros').style.display = '';
        document.getElementById('paper_legendas').style.display = '';
        document.getElementById('div_legendas').style.display = 'none';
        document.getElementById('div_filtros').style.display = 'none';
    }

    exibeFiltros = () => {
        document.getElementById('div_botao_legendas').style.display = 'none';
        document.getElementById('div_botao_filtros').style.display = 'none';
        document.getElementById('paper_filtros').style.display = '';
        document.getElementById('paper_legendas').style.display = 'none';
        document.getElementById('div_legendas').style.display = 'none';
        document.getElementById('div_filtros').style.display = '';
    }

    escondeFiltros = () => {
        document.getElementById('div_botao_legendas').style.display = '';
        document.getElementById('div_botao_filtros').style.display = '';
        document.getElementById('paper_filtros').style.display = '';
        document.getElementById('paper_legendas').style.display = '';
        document.getElementById('div_legendas').style.display = 'none';
        document.getElementById('div_filtros').style.display = 'none';
    }

    filtraChamado = (event) => {
        const filtro = event.target.value;
        if (filtro === 'todos') {
            if (event.target.checked) {
                this.setState({ arrChamadosAtivos: this.state.arrChamadosAtivosOriginal });
                this.setState({ arrChamadosEmAndamento: this.state.arrChamadosEmAndamentoOriginal });
                document.getElementById('checkbox_chamados_abertos').checked = true;
                document.getElementById('checkbox_chamados_em_andamento').checked = true;
                document.getElementById('checkbox_chamados_todos').checked = true;
            } else {
                this.setState({ arrChamadosAtivos: [] });
                this.setState({ arrChamadosEmAndamento: [] });
                document.getElementById('checkbox_chamados_abertos').checked = false;
                document.getElementById('checkbox_chamados_em_andamento').checked = false;
                document.getElementById('checkbox_chamados_todos').checked = false;
            }
        }
        if (filtro === 'abertos') {
            if (event.target.checked) {
                this.setState({ arrChamadosAtivos: this.state.arrChamadosAtivosOriginal });
            } else {
                this.setState({ arrChamadosAtivos: [] });
                document.getElementById('checkbox_chamados_todos').checked = false;

            }
        }
        if (filtro === 'em_andamento') {
            if (event.target.checked) {
                this.setState({ arrChamadosEmAndamento: this.state.arrChamadosEmAndamentoOriginal });
            } else {
                this.setState({ arrChamadosEmAndamento: [] });
                document.getElementById('checkbox_chamados_todos').checked = false;

            }
        }
    }

    handleApiLoaded = (map, maps) => {

        const botaoLegenda = (
            <>
                <Paper id="paper_legendas" elevation={3} style={{ margin: 10, minWidth: 150, textAlign: 'center', padding: 5, minWidth: 30 }} >
                    <span id="div_botao_legendas" style={{ cursor: 'pointer', fontSize: "16px" }} onClick={this.exibeLegenda}>Legendas</span>
                    <div id="div_legendas" style={{ display: 'none', textAlign: 'initial' }}  >
                        <div><ArrowBackIcon onClick={this.escondeLegenda} style={{ cursor: 'pointer' }} /></div>
                        <div><PersonIcon style={{ color: '#000' }} /> Chamados Abertos</div>
                        <div><PersonIcon style={{ color: '#CCC' }} />Chamados em Andamento</div>
                    </div>
                </Paper>
                <Paper id="paper_filtros" elevation={3} style={{ margin: 10, minWidth: 150, textAlign: 'center', padding: 5, minWidth: 30 }} >
                    <span id="div_botao_filtros" style={{ cursor: 'pointer', fontSize: "16px" }} onClick={this.exibeFiltros}>Exibição</span>
                    <div id="div_filtros" style={{ display: 'none', textAlign: 'initial' }}  >
                        <div><ArrowBackIcon onClick={this.escondeFiltros} style={{ cursor: 'pointer' }} /></div>
                        <div><input defaultChecked={true} id="checkbox_chamados_todos" onChange={this.filtraChamado} value="todos" type="checkbox" /> Todos os Chamados</div>
                        <div><input id="checkbox_chamados_abertos" onChange={this.filtraChamado} value="abertos" type="checkbox" /> Chamados Abertos</div>
                        <div><input id="checkbox_chamados_em_andamento" onChange={this.filtraChamado} value="em_andamento" type="checkbox" /> Chamados em Andamento</div>
                    </div>
                </Paper>
            </>
        )
        const controlButtonDiv = document.createElement('div');
        ReactDOM.render(botaoLegenda, controlButtonDiv);
        map.controls[maps.ControlPosition.RIGHT_TOP].push(controlButtonDiv);

        /*const LatLngVilaA = [
            { lat: -25.495412690084866, lng: -54.583651847204585 },
            { lat: -25.507730182009606, lng: -54.58476764615478 },
            { lat: -25.50984288744987, lng: -54.57833034451904 },
            { lat: -25.510596335424726, lng: -54.55863220151367 },
            { lat: -25.503392089957735, lng: -54.556186026892085 },
            { lat: -25.493940704051578, lng: -54.55682975705566 },
            { lat: -25.494173124097827, lng: -54.570905989965816 },
        ];

        // Construct the polygon.
        const regiaoVilaA = new maps.Polygon({
            paths: LatLngVilaA,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
        });

        //regiaoVilaA.setMap(map);

        // Add a listener for the click event
        map.addListener("click", (event) => {
            console.log('latLng -> ', event.latLng.lat() + ' , ' + event.latLng.lng());

            const ifDentroVila = maps.geometry.poly.containsLocation(
                event.latLng,
                regiaoVilaA
            )
                ? true
                : false;

            if (ifDentroVila) {
                var marker = new maps.Marker({
                    position: event.latLng,
                    map,
                }).addListener("click", (event) => {
                    console.log('latLng do marker -> ', event.latLng.lat() + ' , ' + event.latLng.lng());
                });;
            } else {
                console.log('fora do Vila A');
            }

        });*/


    }

    createMapOptions = (maps) => {
        return {
            panControl: true,
            mapTypeControl: true,
            scrollwheel: true,
            streetViewControl: true,
            rotateControl: true,
            fullscreenControl: true,
        }
    }

    exibeInfoChamado = async (chamado) => {
        if (this.state.codChamado === chamado.cod_chamado) {
            this.setState({ codChamado: 0, imagemUsuario: null });
        } else {
            this.setState({ codChamado: chamado.cod_chamado, imagemUsuario: null, isLoadingInfo: true });
            let arquivo = null;
            if (chamado.cod_arquivo != undefined) {
                const arrData = chamado.tms_criacao_arquivo.split('-');
                const path = arrData[0] + '/' + arrData[1] + '/' + arrData[2].substring(0, 2) + '/' + chamado.cod_arquivo.toString();
                arquivo = await ArquivoService.getImage(path);
            }
            this.setState({ codChamado: chamado.cod_chamado, imagemUsuario: arquivo, isLoadingInfo: false });

        }
    }

    visualizarChamado = (codChamado) => {
        console.log('visualizar cod_chamado', codChamado)
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container className={classes.conteudoFundoBranco} style={{minWidth: '471px'}}>
                    {(this.state.isLoading === true || this.state.isLoadingVisualizarUsuariosBloqueados === true) &&
                        <Grid item xs={12} sm={12} style={{ marginTop: 25, textAlign: 'center' }} >
                            {mensagemPadraoCarregando}
                        </Grid>
                    }
                    {this.state.isLoading === false &&
                        <>
                            <Grid item xs={12} className={classes.formCadastroTitulo}>
                                <ReportProblem className={classes.iconeTitulo} />
                                <span className={classes.tituloPadrao} >Botão de Pânico</span>
                            </Grid>
                            {this.state.mensagemAlert.mensagem !== null &&
                                <Grid item xs={12} style={{ marginBottom: 15, marginTop: 15 }} >
                                    <Alert
                                        severity={this.state.mensagemAlert.severity}
                                        action={
                                            this.state.mensagemAlert.closeButton === true &&
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                    >
                                        {this.state.mensagemAlert.mensagem}
                                    </Alert>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <SubTitulo style={{ marginTop: 25 }} >Mapa em tempo real</SubTitulo>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
                                <div style={{ height: '70vh', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: REACT_APP_KEY_GOOGLE_MAPS_API }}
                                        defaultCenter={this.props.center}
                                        defaultZoom={this.props.zoom}
                                        options={this.createMapOptions}
                                        yesIWantToUseGoogleMapApiInternals
                                        onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                                    >
                                        {this.state.arrChamadosAtivos.length > 0 && this.state.arrChamadosAtivos.map(chamado => {
                                            return (
                                                <IconeMapaChamado key={chamado.cod_chamado} exibeInfoChamado={this.exibeInfoChamado} exibeCodChamado={this.state.codChamado} imagemUsuario={this.state.imagemUsuario} isLoading={this.state.isLoadingInfo}
                                                    lat={chamado.num_latitude_chamado}
                                                    lng={chamado.num_longitude_chamado}
                                                    chamado={chamado}
                                                />
                                            )
                                        })
                                        }
                                        {this.state.arrChamadosEmAndamento.length > 0 && this.state.arrChamadosEmAndamento.map(chamado => {
                                            return (
                                                <IconeMapaChamado key={chamado.cod_chamado} exibeInfoChamado={this.exibeInfoChamado} exibeCodChamado={this.state.codChamado} imagemUsuario={this.state.imagemUsuario} isLoading={this.state.isLoadingInfo}
                                                    lat={chamado.num_latitude_chamado}
                                                    lng={chamado.num_longitude_chamado}
                                                    chamado={chamado}
                                                />
                                            )
                                        })
                                        }
                                    </GoogleMapReact>
                                </div>
                            </Grid>
                        </>
                    }
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(MapaChamado));
