import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import {connect} from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Alert} from '@material-ui/lab';
import TabelaPaginada from '../partials/DataTablePaginadapadrao';
import {FaBook} from 'react-icons/fa';
import EditIcon from '@material-ui/icons/Edit';
import Visualizar from '../partials/instrucao-botao-panico/Visualizar';
import Cadastrar from '../partials/instrucao-botao-panico/Cadastrar';
import BotaoPanicoService from '../../services/botao-panico.service';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
  tituloPadrao: theme.pagina.tituloPadrao,
  iconeTitulo: theme.pagina.iconeTitulo,
  buttonCadastro: theme.pagina.buttonCadastro,
  mainBox: {
    overflowY: 'auto',
    width: '100%',
    position: 'fixed',
    margin: 0,
    padding: 0,
    top: 0,
    left: 0,
    height: '100%',
  },
  modalExclusao: {
    boxShadow: theme.shadows[5],
  },
  titleModal: {
    borderBottom: '1px solid #ccc',
  },
  actionsModal: {
    borderTop: '1px solid #ccc',
  },
  headerPanel: {
    backgroundColor: '#185b8d',
    width: '100%',
    height: 176,
  },
  logo: {
    width: 350,
    lineHeight: 94,
  },
  logoDiv: {
    width: '100%',
    textAlign: 'center',
  },
  button: {
    color: '#FFF',
    textTransform: 'none',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    background: theme.palette.background.button,
    padding: '10px 10px',
    lineHeight: 1.4,
    borderRadius: 30,
    width: '100%',
  },
  content: {
    backgroundColor: '#FFF',
    marginTop: '20px',
  },
  buttonDiv: {
    textAlign: '-webkit-center',
  },
  buttonContainer: {
    marginTop: 20,
    width: 300,
    marginBottom: 20,
  },
  link: {
    color: '#979797',
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  storeButton: {
    width: 150,
    lineHeight: 94,
  },
  contentFooterDiv: {
    marginTop: '1%',
    marginBottom: '1%',
  },
  sMediaDiv: {
    width: '10%',
  },
  sMediaButton: {
    width: 50,
    marginRight: 10,
  },
  footerDiv: {
    textAlign: 'center',
    marginTop: '1%',
  },
  buttonGreen: {
    color: '#fff',
    backgroundColor: '#28a745',
    borderColor: '#28a745',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#28A745',
      border: '0px',
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '90%',
  },
  iconButton: {
    padding: 10,
  },
  message: {
    marginBottom: '10px',
  },
  formCadastroTitulo: {
    margin: '15px 0 15px 0',
  },
});

class ListarIntrucao extends Component {
  constructor(props) {
    super(props);
    this.getListPaginated = this.getListPaginated.bind(this);
    this.onClickExcluir = this.onClickExcluir.bind(this);
    this.onClickVisualizar = this.onClickVisualizar.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.excluirIntrucao = this.excluirIntrucao.bind(this);
    this.handleConfirmaExclusao = this.handleConfirmaExclusao.bind(this);

    this.state = {
      open: null,
      codInstrucaoBotaoPanico: null,
      codInstrucaoBotaoPanicoModal: null,
      redirect: false,
      arruIntrucaolist: [],
      isLoadingVisualizarUsuario: true,
      isLoading: true,
      message: '',
      loading: false,
      successful: '',
      showForm: 'listar',
    };
  }

  componentDidMount() {
    this.getListPaginated();
  }

  getListPaginated = () => {
    const data = Promise.resolve(BotaoPanicoService.getAllInstrucao());
    data.then((v) => {
      this.populate(v);
    });
  };

  populate = (data) => {
    this.setState({
      arruIntrucaolist: data, isLoading: false,
    });
  }

  onClickExcluir(id) {
    this.setState({
      message: '',
      open: true,
      codInstrucaoBotaoPanicoModal: id,
    });
  }

  onClickEditar = (codInstrucaoBotaoPanico) => {
    this.setState({showForm: 'editar', codInstrucaoBotaoPanico: codInstrucaoBotaoPanico});
  };

  onClickVisualizar = (codInstrucaoBotaoPanico) => {
    this.setState({showForm: 'visualzar', codInstrucaoBotaoPanico: codInstrucaoBotaoPanico});
  };

  onClickCadastrar = (codInstrucaoBotaoPanico) => {
    this.setState({showForm: 'cadastrar', codInstrucaoBotaoPanico: codInstrucaoBotaoPanico});
  };

  handleConfirmaExclusao(e) {
    this.setState({
      open: false,
      successful: false,
    });
    if (this.state.open) {
      this.excluirIntrucao(this.state.codInstrucaoBotaoPanicoModal);
    }
  };

  handleClose(e) {
    this.setState({
      open: false,
    });
  }

  excluirIntrucao = async (id) => {
    const retorno = await BotaoPanicoService.excluirIntrucao(id);
    if (retorno.status) {
      this.setState({
        message: 'Instrução do Botão de Pânico excluída com sucesso.',
        successful: true,
      });
      this.getListPaginated();
    } else {
      this.state({
        message: 'Não foi possível excluir a instrução.',
      });
    }
  };

  exibeFormtIntrucao = (str_desc_instrucao) => {
    let string = str_desc_instrucao.length > 150 ? ' ...' : '';
    let title = str_desc_instrucao.length > 150 ? str_desc_instrucao : '';

    return (
      <span title={title}
            style={{cursor: 'pointer'}}>{str_desc_instrucao.substring(0, 150)} {string}</span>
    );
  };

  onVoltar = () => {
    this.setState({
      showForm: 'listar',
      codInstrucaoBotaoPanico: null,
      isLoading: true,
    }, this.getListPaginated());
  };

  render() {
    const {message, classes} = this.props;

    return (
      <>
        {
          this.state.showForm === 'cadastrar' &&
            <Cadastrar  codInstrucaoBotaoPanico={this.state.codInstrucaoBotaoPanico} onVoltar={this.onVoltar}/>
        }
        {
          this.state.showForm === 'editar' &&
          <Cadastrar  codInstrucaoBotaoPanico={this.state.codInstrucaoBotaoPanico} onVoltar={this.onVoltar}/>
        }
        {this.state.showForm === 'visualzar' &&
        <Visualizar codInstrucaoBotaoPanico={this.state.codInstrucaoBotaoPanico} onVoltar={this.onVoltar}/>
        }
        {
          this.state.showForm === 'listar' &&
          <div className={classes.root}>
            <Grid container className={classes.conteudoFundoBranco} style={{minWidth: '558px'}}>
              <Grid item xs={12} className={classes.formCadastroTitulo}>
                <FaBook className={classes.iconeTitulo}/> <span
                className={classes.tituloPadrao}>{'Gerenciar Instruções do Botão do Pânico'}</span>
              </Grid>
              {this.state.message && (
                <Grid container className={classes.message}>
                  <Grid item xs={12}>
                    <Alert
                      severity={this.state.successful ? 'success' : ''}>{this.state.message}</Alert>
                  </Grid>
                </Grid>
              )}
              {this.state.codInstrucaoBotaoPanico == null &&
              <>
                <Grid container>
                  <Grid item xs={12} sm={6}></Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      onClick={() => {this.onClickCadastrar(null)}}
                      variant="outlined"
                      style={{minWidth: '170px'}}
                      className={classes.buttonCadastro}> Cadastrar Instrução Botão do Pânico</Button>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid container spacing={3}>
                    <Grid item xs={12} id="table" name="tabela-sem-borda">
                      <TabelaPaginada
                        arrCabecalho={['Instruções', 'Ações']}
                        isLoading={this.state.isLoading}
                        style={{boxShadow: 'none!important'}}
                        mensagemSemRegistros="Nenhuma instrução do botão de pânico cadastrada"
                        arrDados={
                          this.state.arruIntrucaolist
                        }
                        arrFuncoes={
                          {
                            str_desc_instrucao_fmt: {
                              chave: 'str_desc_instrucao_fmt',
                              funcao: this.exibeFormtIntrucao,
                            },
                          }
                        }
                        arrAcoes={
                          {
                            cod_instrucao_botao_panico: [
                              {
                                chave: 'cod_instrucao_botao_panico',
                                funcao: this.onClickVisualizar,
                                icone: SearchIcon,
                              },
                              {
                                chave: 'cod_instrucao_botao_panico',
                                funcao: this.onClickEditar,
                                icone: EditIcon,
                              },
                              {
                                chave: 'cod_instrucao_botao_panico',
                                funcao: this.onClickExcluir,
                                icone: CloseIcon,
                              },
                            ],
                          }
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
              }
              <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.modalExclusao}
              >
                <DialogTitle id="alert-dialog-title"
                             className={classes.titleModal}>{'Aviso!'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Deseja Excluir a Instrução do Botão de Pânico?
                  </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.actionsModal}>
                  <Button onClick={this.handleConfirmaExclusao}
                          variant="outlined">
                    Sim
                  </Button>
                  <Button onClick={this.handleClose}>
                    Não
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </div>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(withStyles(styles)(ListarIntrucao));
