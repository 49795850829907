import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { clearMessage } from "../../actions/MessageAction";
import { history } from "../../helpers/history";
import { Button, Grid } from '@material-ui/core';
import StorageIcon from '@material-ui/icons/Storage';
import InputPesquisarPadrao from '../partials/InputPesquisarPadrao';
import TabelaPaginada from '../partials/DataTablePaginadapadrao';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import NotificacoesService from "../../services/notificacoes.service";
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CadastrarNotificacao from '../partials/notificacao/Cadastrar';
import VisualizarNotificacao from '../partials/notificacao/Visualizar';
import ModalPadrao from '../partials/ModalPadrao';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
    },
    buttonCadastro: theme.pagina.buttonCadastro,
    conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
    tituloPadrao: theme.pagina.tituloPadrao,
    iconeTitulo: theme.pagina.iconeTitulo,
    formCadastroTitulo: theme.pagina.formCadastroTitulo,
    buttonPesquisar: theme.pagina.buttonPesquisar,
    formControl: {
        margin: theme.spacing(1),
        minWidth: 350,
        maxWidth: 400,
    },
    button: {
        color: '#000',
        backgroundColor: '#FFF',
        border: 'solid 1px #005b96',
        maxWidth: 100,
        height: '42px',
        marginTop: '25px',
        '&:hover': {
            color: '#005b96',
            backgroundColor: '#FFF',
            border: 'solid 1px #005b96',
        },
    },
});

class NotificarUsuarios extends Component {

    constructor(props) {
        super(props);
        history.listen((location) => {
            props.dispatch(clearMessage());
        });

        this.state = {
            user: localStorage.getItem("user"),
            campoPesquisar: null,
            mensagemAlert: { mensagem: null, severity: 'success', closeButton: true },
            isLoading: true,
            arrNotificacoes: null,
            isLoadingVisualizarNotificacao: false,
            isLoadingNotificarUsuarios: false,
            operacaoEmtela: 'listar',
            codNotificacao: null,
            modalExclusao: false,
            index: null,
            isSavingExclusao: false,
        };

    }

    componentDidMount() {
        // on mount component
        this.carregarNotificacoes()
    }

    componentDidUpdate() {
        // on update component
    }

    recarregarLista = () => {
        this.setState({ arrNotificacoes: [], isLoading: true });
        this.carregarNotificacoes()
    }

    carregarNotificacoes = async () => {
        const arrNotificacoes = await NotificacoesService.getAll();
        this.setState({ arrNotificacoes: arrNotificacoes, isLoading: false });
    }

    exibeMensagemAlert = (mensagem) => {
        this.setState({ mensagemAlert: { mensagem: mensagem.mensagem, severity: mensagem.severity ?? 'success', closeButton: true } });
    }

    handleChangeCampoPesquisar = (valor) => {
        this.setState({ campoPesquisar: valor });
        this.pesquisarNotificacoes(valor);
    }

    pesquisarNotificacoes = async (busca) => {
        this.setState({ isLoading: true, arrNotificacoes: [] });
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
        const arrFiltros =
        {
            busca: busca,
        }
        const arrNotificacoes = await NotificacoesService.getAll(arrFiltros);
        this.setState({ arrNotificacoes: arrNotificacoes, isLoading: false });
    }

    onClickVisualizarNotificacao = (codNotificacao) => {
        this.setState({ isLoadingVisualizarNotificacao: true, operacaoEmtela: 'visualizar', codNotificacao: codNotificacao });
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
    }

    handleClickNotificarUsuarios = () => {
        this.setState({ operacaoEmtela: 'cadastrar', isLoadingNotificarUsuarios: true, codNotificacao: null });
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
    }

    onVoltar = () => {
        this.setState({ isLoadingVisualizarNotificacao: false, isLoadingNotificarUsuarios: false, operacaoEmtela: 'listar', codNotificacao: null });
    }

    onClickVisualizar = (codNotificacao) => {
        this.setState({ operacaoEmtela: 'visualizar', isLoadingNotificarUsuarios: false, codNotificacao: codNotificacao });
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
    }

    onClickEditar = (codNotificacao) => {
        this.setState({ operacaoEmtela: 'editar', isLoadingNotificarUsuarios: true, codNotificacao: codNotificacao });
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
    }

    onClickExcluir = (codNotificacao, index) => {
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
        this.setState({ codNotificacao: codNotificacao, index: index, modalExclusao: true });
    }

    confirmarExcluirNotificacao = async () => {

        this.setState({isSavingExclusao: true});
        const retorno = await NotificacoesService.excluirNotificacao(this.state.codNotificacao);
        if (retorno === 'ok') {
            let arrNotificacoes = [...this.state.arrNotificacoes];
            arrNotificacoes.splice(this.state.index, 1);
            this.setState({ arrNotificacoes: arrNotificacoes });
            this.setState({ mensagemAlert: { mensagem: 'Notificação excluída com sucesso.', severity: 'success', closeButton: true } });
        } else {
            this.setState({ mensagemAlert: { mensagem: 'Não foi possível excluir a notificação.', severity: 'error', closeButton: true } });
        }
        this.setState({ codNotificacao: null, index: null, modalExclusao: false , isSavingExclusao: false});

    }

    handleCloseModal = () => {
        this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
        this.setState({ codNotificacao: null, index: null, modalExclusao: false });
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container className={classes.conteudoFundoBranco} style={{minWidth: '471px'}}>
                    <Grid item xs={12} className={classes.formCadastroTitulo}>
                        <NotificationsIcon className={classes.iconeTitulo} />
                        <span className={classes.tituloPadrao} >
                            {this.state.operacaoEmtela === 'listar' ? 'Notificar Usuários' : ''}
                            {this.state.operacaoEmtela === 'visualizar' ? 'Visualizar Notificação de Usuários' : ''}
                            {this.state.operacaoEmtela === 'cadastrar' || this.state.operacaoEmtela === 'editar' ? 'Notificar Usuários do Aplicativo' : ''}
                        </span>
                    </Grid>
                    {(this.state.operacaoEmtela === 'cadastrar' || this.state.operacaoEmtela === 'editar') &&
                        <CadastrarNotificacao codNotificacao={this.state.codNotificacao} onVoltar={this.onVoltar} exibeMensagemAlert={this.exibeMensagemAlert} recarregarLista={this.recarregarLista} />
                    }
                    {this.state.operacaoEmtela === 'visualizar' &&
                        <VisualizarNotificacao codNotificacao={this.state.codNotificacao} onVoltar={this.onVoltar} exibeMensagemAlert={this.exibeMensagemAlert} recarregarLista={this.recarregarLista} />
                    }
                    {this.state.operacaoEmtela === 'listar' &&
                        <>
                        <Grid container>
                            <Grid item xs={12} sm={6} style={{padding: '15px 0'}}>
                                <InputPesquisarPadrao
                                  exibirBotao={false}
                                  placeholder="Pesquisar Notificações"
                                  onChange={this.handleChangeCampoPesquisar}
                                  value={this.state.campoPesquisar || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                    <Button variant="outlined"
                                            onClick={this.handleClickNotificarUsuarios}
                                            className={classes.buttonCadastro}><span>Notificar Usuários</span>
                                    </Button>
                            </Grid>
                        </Grid>
                            {this.state.mensagemAlert.mensagem !== null &&
                                <Grid item xs={12} style={{ marginBottom: 15, marginTop: 15 }} >
                                    <Alert
                                        severity={this.state.mensagemAlert.severity}
                                        action={
                                            this.state.mensagemAlert.closeButton === true &&
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    this.setState({ mensagemAlert: { mensagem: null, severity: 'success', closeButton: true } });
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                    >
                                        {this.state.mensagemAlert.mensagem}
                                    </Alert>
                                </Grid>
                            }
                            <TabelaPaginada
                                arrCabecalho={['Título', 'Data de Envio', 'Descrição', 'Situação', 'Ações']}
                                isLoading={this.state.isLoading}
                                style={{boxShadow: 'none!important'}}
                                arrDados={this.state.arrNotificacoes}
                                arrAcoes={
                                    {
                                        acao: [
                                            {
                                                chave: 'cod_notificacao',
                                                funcao: this.onClickVisualizar,
                                                icone: SearchIcon
                                            },
                                            {
                                                chave: 'cod_notificacao',
                                                funcao: this.onClickEditar,
                                                icone: EditIcon
                                            },
                                            {
                                                chave: 'cod_notificacao',
                                                funcao: this.onClickExcluir,
                                                icone: CloseIcon
                                            }
                                        ]

                                    }
                                }
                            />
                        </>
                    }
                </Grid>
                <ModalPadrao open={this.state.modalExclusao} handleCloseModal={this.handleCloseModal} >
                    <h5>Aviso!</h5>
                    <hr></hr>
                    <p>Deseja Excluir esta notificação?</p>
                    <hr></hr>
                    {this.state.isSavingExclusao === false &&
                        <>
                            <Button variant="outlined" onClick={() => this.confirmarExcluirNotificacao()} style={{ width: 80 }} >Sim</Button> &nbsp;
                            <Button variant="text" onClick={() => this.setState({ modalExclusao: false })}>Não</Button>
                        </>
                    }
                    {this.state.isSavingExclusao !== false &&
                        <>
                            <Button variant="outlined" disabled style={{ width: 80 }} ><CircularProgress style={{ color: '#FFF', width: 20, height: 20 }} /></Button> &nbsp;
                            <Button variant="text" disabled >Não</Button>
                        </>
                    }
                </ModalPadrao>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user } = state.auth;
    return {
        user,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(NotificarUsuarios));
