import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from './TypesAction';

import DpoService from '../services/dpo.service';
const API_URL = process.env.REACT_APP_API_URL;

export const salvar = (
  cod_solic_anonimizacao,
  cod_usuario_anonimizacao,
  str_observacao,
  dat_requerimento,
) => (dispatch) => {
  return DpoService.salvar(
    cod_solic_anonimizacao,
    cod_usuario_anonimizacao,
    str_observacao,
    dat_requerimento,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};

export const salvarResposta = (
  cod_solic_anonimizacao,
  str_observacao_resposta,
  dat_devolutiva_resposta,
  dat_limite_exclusao,
  flg_situacao,
  file_base64,
  str_nome_arquivo,
  str_nome_solicitante,
) => (dispatch) => {
  return DpoService.salvarResposta(
    cod_solic_anonimizacao,
    str_observacao_resposta,
    dat_devolutiva_resposta,
    dat_limite_exclusao,
    flg_situacao,
    file_base64,
    str_nome_arquivo,
    str_nome_solicitante,
  ).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};

export const anonimizarDados = (params) => (dispatch) => {
  return DpoService.anonimizarDados(params).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    },
  );
};
