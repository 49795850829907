import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import AuditoriaService from '../../../services/auditoria.service';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyle = makeStyles((theme) => ({
  button: {
    color: '#000',
    backgroundColor: '#FFF',
    border: 'solid 1px #005b96',
    maxWidth: 100,
    height: '42px',
    marginTop: '25px',
    '&:hover': {
      color: '#005b96',
      backgroundColor: '#FFF',
      border: 'solid 1px #005b96',
    },
  },
  gridLabel: {
    fontWeight: 'bold',
  },
  dadoDiferente: {
    backgroundColor: 'rgb(250, 179, 174)',
    borderRadius: '4px',
  },
  dadoIgual: {},
}));

const mensagemPadraoCarregando =
  (
    <div style={{textAlign: 'center', padding: 15, margin: '0 auto'}}>
      <CircularProgress
        style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
    </div>
  );

const VisualizarLog = (props) => {

  const classes = useStyle();
  const [arrDadosLogAuditoria, setArrDadosLogAuditoria] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [anonimizado, setAnonimizado] = React.useState(false);

  useEffect(() => {
    carregarDadosLogAuditoria();
  }, [props.codAuditoria]);

  const carregarDadosLogAuditoria = async () => {
    setIsLoading(true);
    const arrDadosLogAuditoria = await AuditoriaService.carregarDadosLogAuditoria(props.codAuditoria);
    arrDadosLogAuditoria.str_antes = arrDadosLogAuditoria.str_antes ? JSON.parse(arrDadosLogAuditoria.str_antes) : null;
    arrDadosLogAuditoria.str_depois = arrDadosLogAuditoria.str_depois ? JSON.parse(arrDadosLogAuditoria.str_depois) : null;
    const data = arrDadosLogAuditoria.tms_audit.split('T')[0] + ' ' + arrDadosLogAuditoria.tms_audit.split('T')[1].split('-')[0];
    const dataJS = new Date(data);
    /*console.log(dataJS.toLocaleDateString() + ' ' + dataJS.toLocaleTimeString());
    console.log('data', data);
    console.log(arrDadosLogAuditoria);*/

    if (props.codAuditoria != null && props.codAuditoria != undefined) {
      if (arrDadosLogAuditoria.str_antes == null && arrDadosLogAuditoria.str_depois == null) {
        setAnonimizado(true);
      }
    }

    setArrDadosLogAuditoria(arrDadosLogAuditoria);
    setIsLoading(false);
  };

  const onVoltar = () => {
    props.onVoltar();
  };

  const formataDataHora = (data) => {
    const dataBR = data.split('T')[0] + ' ' + data.split('T')[1].split('-')[0];
    const dataJS = new Date(dataBR);
    return dataJS.toLocaleDateString() + (dataJS.toLocaleTimeString() !== '00:00:00' ? ' ' + dataJS.toLocaleTimeString() : '');
  };

  const exibeImagem = (stringBase64) => {
    return <img src={stringBase64} className="img-perfil"/>;
  };

  const trataLabel = (label) => {

    let arrLabel = label.split('_');
    let labelTratada = '';
    const arrStringParaRemover = ['cod', 'str', 'num', 'flg'];

    if (arrLabel.length > 0) {
      arrLabel.map(partLabel => {
        if (!arrStringParaRemover.includes(partLabel)) {
          labelTratada += ' ' + (partLabel == 'dat' ? 'data' : partLabel);
        }
      });
    } else {
      labelTratada = label;
    }

    return labelTratada;
  };

  const switchCampoLabel = (valor) => {
    let labelCampo = null;
    let valorCampo = null;
    switch (valor) {
    case 'S':
      valorCampo = 'Sim';
      labelCampo = 'Excluído';
      break;
    case 'N':
      valorCampo = 'Não';
      labelCampo = 'Excluído';
      break;
    default:
      valorCampo = valor;
    }

    if (valorCampo.toString().search('T') == 10 && valorCampo.toString().search(':') == 13 && valorCampo.toString().split('-').length == 4) {
      valorCampo = formataDataHora(valorCampo);
    }

    if (typeof valorCampo == 'string' && valorCampo.search('data:image') == 0) {
      valorCampo = exibeImagem(valorCampo);
    }

    return [valorCampo, labelCampo];
  };

  const exibeDadosAntesDepois = (chaveArray = null, arrDadosAntes = [], arrDadosDepois = []) => {

    if (arrDadosAntes == null && arrDadosDepois == null) {
      return;
    }

    const arrCampos = ['password', 'cod_usuario_equipe_perfil', 'tms_criacao', 'cod_equipe_perfil',
      'tms_atualizacao', 'tms_ultimo_acesso', 'cod_usuario', 'cod_pessoa', 'cod_unidade_federacao',
      'str_codigo_ibge', 'flg_cadastro_confirmado', 'flg_superusuario', 'tms_ultimo_login', 'str_oauth_segredo',
      'cod_sistema', 'cod_equipe', 'cod_perfil'];

    return Object.keys(arrDadosDepois).map((campo, indexCampo) => {

      let labelCampo = campo;

      if (arrDadosDepois && arrDadosDepois[campo] && typeof arrDadosDepois[campo] !== 'object' && typeof arrDadosDepois[campo] !== 'symbol') {
        let arrCampoLabel = switchCampoLabel(arrDadosDepois[campo]);
        arrDadosDepois[campo] = arrCampoLabel[0];
        labelCampo = arrCampoLabel[1] ?? labelCampo;
      }

      if (arrDadosAntes && arrDadosAntes[campo] && typeof arrDadosAntes[campo] !== 'object' && typeof arrDadosAntes[campo] !== 'symbol') {
        let arrCampoLabelAntes = switchCampoLabel(arrDadosAntes[campo]);
        arrDadosAntes[campo] = arrCampoLabelAntes[0];
      }

      labelCampo = trataLabel(labelCampo);
      chaveArray = chaveArray ? trataLabel(chaveArray) : null;
      labelCampo = (chaveArray ? chaveArray + ' - ' : '') + labelCampo;

      return (
        <>
          {(typeof arrDadosDepois[campo] !== 'object' || (typeof arrDadosDepois[campo] === 'object' && arrDadosDepois[campo].type == 'img')) && !arrCampos.includes(campo) &&
            <>
              <Grid item xs={4}
                    style={{textTransform: 'capitalize'}}>{labelCampo}</Grid>
              <Grid style={{wordBreak: 'break-word'}} item
                    xs={4}>{arrDadosAntes && arrDadosAntes[campo] && typeof arrDadosAntes[campo] !== 'object' ? arrDadosAntes[campo] : '-'}</Grid>
              <Grid style={{wordBreak: 'break-word'}} item xs={4}
                    className={arrDadosAntes && arrDadosAntes[campo] && arrDadosAntes[campo] !== arrDadosDepois[campo] ? classes.dadoDiferente : classes.dadoIgual}>
                {arrDadosDepois[campo]}
              </Grid>
            </>
          }
          {!['cod_usuario', 'cod_usuario_criacao', 'cod_usuario_atualizacao', 'cod_sistema'].includes(campo) && typeof arrDadosDepois[campo] === 'object' && arrDadosDepois[campo].type != 'img' && exibeDadosAntesDepois(campo, arrDadosAntes && arrDadosAntes[campo] ? arrDadosAntes[campo] : [], arrDadosDepois[campo])}
        </>
      );
    });
  };

  return (
    <>
      {isLoading == false &&
        <>
          <h5 style={{marginTop: 15}}>Dados do Registro</h5>
          <hr/>
          <Grid container spacing={2}>
            <Grid item xs={6} style={{display: 'flex', flexWrap: 'wrap'}}>
              <Grid className={classes.gridLabel} item xs={3}>Usuário</Grid>
              <Grid item
                    xs={9}>{arrDadosLogAuditoria.cod_usuario.cod_pessoa.str_nome_pessoa} {arrDadosLogAuditoria.cod_usuario.cod_pessoa.str_sobrenome}</Grid>
              <Grid className={classes.gridLabel} item
                    xs={3}>Funcionalidade</Grid>
              <Grid item
                    xs={9}>{arrDadosLogAuditoria.cod_funcionalidade.str_nome}</Grid>
            </Grid>
            <Grid item xs={6} style={{display: 'flex', flexWrap: 'wrap'}}>
              <Grid className={classes.gridLabel} item xs={3}>Data</Grid>
              <Grid item
                    xs={9}>{formataDataHora(arrDadosLogAuditoria.tms_audit)}</Grid>
              <Grid className={classes.gridLabel} item xs={3}>ID</Grid>
              <Grid item xs={9}>{props.codAuditoria}</Grid>
            </Grid>
            <Grid item xs={12} style={{marginTop: '-15px'}}>
              <span className={classes.gridLabel}>Descrição:</span><br/>
              <div
                dangerouslySetInnerHTML={{__html: arrDadosLogAuditoria.str_descricao}}></div>
            </Grid>
          </Grid>
          <hr/>
          <h5 style={{marginTop: 15}}>Alterações</h5>
          <hr/>

          {anonimizado &&
            <strong>Os dados deste usuário foram anonimizados.</strong>
          }

          {!anonimizado &&
            <Grid container spacing={2}>
              <Grid key={1} className={classes.gridLabel} item xs={4}>Nome do
                Campo</Grid>
              <Grid key={2} className={classes.gridLabel} item xs={4}>Valor
                Anterior</Grid>
              <Grid key={3} className={classes.gridLabel} item xs={4}>Valor
                Atual</Grid>
              {exibeDadosAntesDepois(null, arrDadosLogAuditoria.str_antes, arrDadosLogAuditoria.str_depois)}
            </Grid>
          }
        </>
      }
      {isLoading == true && mensagemPadraoCarregando}
      <div style={{textAlign: 'center', padding: 15, margin: '0 auto'}}>
        <Button variant="contained" className={classes.button}
                onClick={onVoltar} color="primary">Voltar</Button>
      </div>
    </>
  );
};


export default VisualizarLog;
