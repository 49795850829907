import axios from "axios";
import authHeader from './auth-header';
import trataErroApi from "./trata-erro-api";

const API_URL = process.env.REACT_APP_API_URL

class TermoPoliticaService {

    termo(
        cod_termo_uso,
        str_termo_uso,
        str_termo_uso_tag,
        is_null_termo,
        showForm,
    ) {
        return axios.post(API_URL + 'termopolitica/formulario', {
                cod_termo_uso,
                str_termo_uso,
                str_termo_uso_tag,
                is_null_termo,
                showForm,
            },
            {headers: authHeader()}
        );
    }

    politica(
        cod_politica_privacidade,
        str_politica_privacidade,
        str_politica_privacidade_tag,
        is_null_politica,
        showForm,
    ) {
        return axios.post(API_URL + 'termopolitica/formulario', {
                cod_politica_privacidade,
                str_politica_privacidade,
                str_politica_privacidade_tag,
                is_null_politica,
                showForm,
            },
            {headers: authHeader()}
        );
    }

    async getDadosTermo() {
        return axios
            .post(API_URL + 'termopolitica/populate', {}, {headers: authHeader()})
            .then((response) => {
                return response.data.data;
            });
    }

    async saveAcceptTerms(params = "") {
        return axios
            .post(API_URL + "termopolitica/saveTermoPoliticaAceite", params, {headers: authHeader()})
            .then((response) => {
                return response.data;
            }).catch(error => {
                if (error.response.data.code === 401) {
                    localStorage.clear();
                    window.location.href = '/login';
                } else if (error.response.data.code === 403) {
                    trataErroApi(error.response.data);
                } else {
                    return error.response.data
                }
            });
    }
}

export default new TermoPoliticaService();
