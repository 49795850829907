export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_CONFIRM_SUCCESS = "REGISTER_CONFIRM_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_CONFIRM_FAIL = "REGISTER_CONFIRM_FAIL";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS"
export const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
