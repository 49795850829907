import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Form from 'react-validation/build/form';
import 'semantic-ui-css/semantic.min.css';
import {TextArea} from 'semantic-ui-react';
import {salvarObservacao} from '../../../actions/BotaoPanicoAction';
import {Alert} from '@material-ui/lab';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  formButton : theme.pagina.formButton,
  formCadastro: {
    margin: '15px',
  },
});

const mensagemPadraoCarregando =
  (
    <div style={{textAlign: 'center', padding: 15, margin: '0 auto'}}>
      <CircularProgress
        style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
    </div>
  );

class Detalhar extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeCodChamado = this.onChangeCodChamado.bind(this);
    this.setObservacao = this.setObservacao.bind(this);
    this.setCountObservacao = this.setCountObservacao.bind(this);
    this.onClickVoltar = props.onVoltar;

    this.state = {
      cod_chamado: props.arrObservacoes.codChamado,
      str_observacao: this.setObservacao(props.arrObservacoes.strObservacao),
      count: this.setCountObservacao(props.arrObservacoes.strObservacao),
      isLoadingEdit: true,
      isLoading: false,
      alert: '',
      loading: false,
      successful: '',
      redirect: false,
    };
  }

  componentDidMount() {
    this.setState({isLoadingEdit: false});
    // on mount component
  }

  componentDidUpdate() {
    // on update component
  }

  setCountObservacao = (strObservacao) => {
    if(strObservacao == ' '){
      return 0;
    }else{
      return strObservacao.length;
    }
  }

  setObservacao = (strObservacao) => {
    if(strObservacao == ' '){
      return null;
    }else{
      return strObservacao;
    }
  }

  onChangeInput = (e) => {

    this.setState({
      count: e.target.value.length,
      str_observacao: e.target.value,
    });

    if (e.target.value.length == 0) {
      e.target.setCustomValidity('Campo obrigatório');
    }

    if (e.target.value.length > 0) {

      e.target.setCustomValidity('');

    }

  };

  onChangeCodChamado(e) {
    this.setState({
      cod_chamado: e.target.value,
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      successful: '',
      isLoading: true,
    });

    this.form.validateAll();
    this.props
      .dispatch(
        salvarObservacao(
          this.state.cod_chamado,
          this.state.str_observacao,
        ),
      )
      .then(() => {
        this.setState({
          successful: true,
          isLoading:false,
          redirect: false,
        });
        this.timeout = setTimeout(() => {
          this.setState({
            successful: '',
          });
          this.onClickVoltar();
        }, 3000);
      })
      .catch(() => {
        this.setState({
          successful: false,
          redirect: false,
          isLoading: false,
        });
      });
  }

  render() {
    const {successful, isLoggedIn, message, classes} = this.props;

    // if (this.state.redirect) {
    //   window.location = '/chamados';
    // }

    return (
      <>
        {this.state.isLoadingEdit === true &&
        <Grid container spacing={2} style={{marginTop: 25}}>
          <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
            {mensagemPadraoCarregando}
          </Grid>
        </Grid>
        }
        {
          this.state.isLoadingEdit === false &&
          <Form onSubmit={this.handleRegister} ref={(c) => {
            this.form = c;
          }} className={classes.formCadastro}>
            {this.state.successful == false && this.state.successful !== '' && (
              <div className="row">
                <div className="col mb-2">
                  <Alert severity={'error'}>{message}</Alert>
                </div>
              </div>
            )}
            {this.state.successful == true && this.state.successful !== '' && (
              <div className="row">
                <div className="col mb-2">
                  <Alert severity={'success'}>{message}</Alert>
                </div>
              </div>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextArea
                  name="str_observacao"
                  rows={7}
                  placeholder="Observações"
                  onChange={this.onChangeInput}
                  required
                  onInvalid={this.onChangeInput}
                  value={this.state.str_observacao}
                  maxLength={250}
                  style={{display: 'block', width: '100%', padding: 5}}
                />
                <span style={{
                  display: 'block',
                  float: 'right',
                }}> {this.state.count}/250</span>
                <input
                  type="hidden"
                  id="cod_chamado"
                  name="cod_chamado"
                  value={this.state.cod_chamado}
                  onChange={this.onChangeCodChamado}
                />
              </Grid>
            </Grid>
            <hr></hr>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div style={{textAlign: 'right'}}>
                  {this.state.isLoading === false &&
                  <>
                    <Button
                      type="submit"
                      className={classes.formButton}
                      variant="outlined">Salvar
                    </Button> &nbsp;
                    <Button
                      className={classes.formButton}
                      variant="text"
                      onClick={this.onClickVoltar}>Cancelar
                    </Button>
                  </>
                  }
                  {this.state.isLoading === true &&
                  <>
                    <Button variant="outlined" disabled
                            style={{width: 100}}>
                      <CircularProgress style={{
                        width: 20,
                        height: 20,
                        marginRight: 15,
                      }}/>
                    </Button> &nbsp;
                    <Button type="button">Cancelar </Button>
                  </>
                  }
                </div>
              </Grid>
            </Grid>
          </Form>
        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const {user} = state.auth;
  const {message} = state.message;

  return {user, message};
}

export default connect(mapStateToProps)(withStyles(styles)(Detalhar));
