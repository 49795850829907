import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button, Grid} from '@material-ui/core';
import Form from 'react-validation/build/form';
import {Titulo, SubTitulo} from '../../../views/style/styles';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {FaBook} from 'react-icons/fa';
import ColetaSeletivaService from '../../../services/coleta-seletiva.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import {cepMaks, telefoneMask} from '../../util';

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    conteudoFundoBranco: theme.pagina.conteudoFundoBranco,
    tituloPadrao: theme.pagina.tituloPadrao,
    iconeTitulo: theme.pagina.iconeTitulo,
    formCadastroTitulo: theme.pagina.formCadastroTitulo,
    mainBox: {
        overflowY: 'auto',
        width: '100%',
        position: 'fixed',
        margin: 0,
        padding: 0,
        top: 0,
        left: 0,
        height: '100%',
    },
    headerPanel: {
        backgroundColor: '#185b8d',
        width: '100%',
        height: 176,
    },
    logo: {
        width: 350,
        lineHeight: 94,
    },
    logoDiv: {
        width: '100%',
        textAlign: 'center',
    },
    formCadastro: {
        margin: '15px',
    },
    userCicle: {
        width: '150px',
    },
    button: {
        color: '#FFF',
        textTransform: 'none',
        fontSize: '1.1rem',
        fontWeight: 'bold',
        padding: '10px 10px',
        lineHeight: 1.4,
        borderRadius: 30,
        width: '100%',
    },
    content: {
        backgroundColor: '#FFF',
        padding: '5px 5px 5px',
        borderRadius: '5px',
    },
    buttonDiv: {
        textAlign: '-webkit-center',
    },
    buttonContainer: {
        marginTop: 20,
        width: 300,
        marginBottom: 20,
    },
    link: {
        color: '#979797',
        cursor: 'pointer',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    storeButton: {
        width: 150,
        lineHeight: 94,
    },
    contentFooterDiv: {
        marginTop: '1%',
        marginBottom: '1%',
    },
    sMediaDiv: {
        width: '10%',
    },
    sMediaButton: {
        width: 50,
        marginRight: 10,
    },
    footerDiv: {
        textAlign: 'center',
        marginTop: '1%',
    },
    invalidFeedback: {
        display: 'none',
    },
    formContainer: {
        position: 'relative',
        padding: '5% 10% 5% 10%',
        opacity: 1,
        borderRadius: '20px',
        backgroundColor: '#fff',
    },
    dataPicker: {
        fontcolor: '#4AE2D2',
    },
    inputText: {
        color: 'red',
    },

    seletor: {
        border: '4px solid #f9f9f9;',
        borderRadius: '5px',
    },

    wrapperClass: {
        padding: '1rem;',
        bordered: '1px solid #ccc;',
    },

    editorClass: {
        // backgroundColor: 'lightgray;',
        padding: '1rem;',
        // border: '1px solid #ccc;',
    },

    toolbarClass: {
        border: '1px solid #ccc;',
    },

    gridLabel: {
        fontWeight: 'bold',
        textAlign: 'right'
    },

    gridText: {
        paddingLeft: '50px'
    },
});

const mensagemPadraoCarregando =
  (
    <span>
            <CircularProgress style={{width: 20, height: 20, marginRight: 15}}/> Carregando...
        </span>
  );

class ColetaSeletiva extends Component {
    constructor(props) {
        super(props);

        this.onClickVoltar = props.onVoltar;

        this.state = {
            cod_ponto_coleta: props.codPontoColeta,
            arrPostoColeta: [],
            arrValues: [],
            message: '',
            isLoading: true,
            successful: false,
            redirect: false,
        };
    }

    componentDidMount() {
        if (this.state.cod_ponto_coleta) {
            const data = Promise.resolve(ColetaSeletivaService.getDadosPontoColeta(this.state.cod_ponto_coleta));
            data.then((v) => {
                this.populateForm(v[0]);
            });
        }
    }

    populateForm(dados) {
        this.setState({arrPostoColeta: dados, isLoading: false});

        if(dados.arr_dia_funcionamento !== null){
            dados.arr_dia_funcionamento.map(function(v, i) {
                let elem = document.createElement('div');
                elem.innerHTML = v.str;
                let spanHolder = document.getElementById('tags');
                spanHolder.appendChild(elem);
            });
         }
    }

    openLink(){
        window.open = this.state.arrPostoColeta.str_link;
    }

    render() {
        const {successful, isLoggedIn, message, classes} = this.props;

        const {editorState} = this.state;
        const titulo = 'Visualizar Pontos de Coleta';

        return (

          <>
              {this.state.isLoading === true &&
              <Grid container spacing={2} style={{marginTop: 25}}>
                  <Grid item xs={10} sm={10} style={{textAlign: 'center'}}>
                      {mensagemPadraoCarregando}
                  </Grid>
              </Grid>
              }
              {this.state.isLoading === false && <div className={classes.root}>
                  <Grid container className={classes.conteudoFundoBranco} style={{ minWidth: 692 }} >
                      <Grid item xs={12} className={classes.formCadastroTitulo}>
                          <FaBook className={classes.iconeTitulo}/>
                          <span className={classes.tituloPadrao}> {titulo}</span>
                      </Grid>
                      <Grid container>
                          <Grid item xs={12} className={classes.content}>
                              <Form onSubmit={this.handleRegister} ref={(c) => {
                                  this.form = c;
                              }} className={classes.formCadastro}>
                                  <Grid container spacing={2} style={{marginTop: 25}}>
                                      <SubTitulo>Dados do Ponto de Coleta</SubTitulo>
                                  </Grid>

                                  <Grid container spacing={3}>

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={2}className={classes.gridLabel}>Nome:</Grid>
                                      <Grid item xs={12} sm={8} className={classes.gridText}>{this.state.arrPostoColeta.str_nome_ponto_coleta}</Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={2}className={classes.gridLabel}>CEP:</Grid>
                                      <Grid item xs={12} sm={3} className={classes.gridText}>{cepMaks(this.state.arrPostoColeta.num_cep)}</Grid>

                                      <Grid item xs={12} sm={2}className={classes.gridLabel}>Logradouro:</Grid>
                                      <Grid item xs={12} sm={3} className={classes.gridText}>{this.state.arrPostoColeta.str_logradouro}</Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={2}className={classes.gridLabel}>Bairro:</Grid>
                                      <Grid item xs={12} sm={3} className={classes.gridText}>{this.state.arrPostoColeta.str_bairro}</Grid>

                                      <Grid item xs={12} sm={2}className={classes.gridLabel}>Estado:</Grid>
                                      <Grid item xs={12} sm={3} className={classes.gridText}>{this.state.arrPostoColeta.str_nome_uf}</Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={2}className={classes.gridLabel}>Contato:</Grid>
                                      <Grid item xs={12} sm={8} className={classes.gridText}>{telefoneMask(this.state.arrPostoColeta.str_contato)}</Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={2}className={classes.gridLabel}>Link:</Grid>
                                      <Grid item xs={12} sm={8} className={classes.gridText}>

                                          {this.state.arrPostoColeta.str_link ?
                                            <a href="#"
                                               onClick={() => {openLink(this.state.arrPostoColeta.str_link)}}
                                            >
                                                {this.state.arrPostoColeta.str_link}</a> : ' - '
                                          }
                                      </Grid>
                                      <Grid item xs={12} sm={1}></Grid>

                                      <SubTitulo>
                                          <div style={{textAlign: 'center'}}>
                                              Funcionamento
                                          </div>
                                      </SubTitulo>
                                      <Grid item xs={12} sm={1}></Grid>
                                      <Grid item xs={12} sm={10}>
                                          <div id="funcionamento">
                                              <span id="tags"></span>
                                          </div>
                                      </Grid>
                                  </Grid>
                                  <Grid container spacing={3}>
                                      <Grid item xs={12}>
                                          <div style={{textAlign: 'center'}}>
                                              <Button onClick={this.onClickVoltar}>Voltar </Button>
                                          </div>
                                      </Grid>
                                  </Grid>
                              </Form>
                          </Grid>
                      </Grid>
                  </Grid>
              </div>
              }
          </>
        );
    }
}

function openLink(link){
    window.open(link, "_blank");
}

function mapStateToProps(state) {
    const {
        isLoggedIn,
    }

      = state.auth;
    const {message} = state.message;

    return {isLoggedIn, message};
}

export default connect(mapStateToProps)(withStyles(styles)(ColetaSeletiva));
