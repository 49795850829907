import axios from "axios";
import authHeader from './auth-header';
import trataErroApi from './trata-erro-api';

const API_URL =  process.env.REACT_APP_API_URL

class EquipeService {

    async getAll() {
        return axios
          .get(API_URL + "equipe/getall?flgExcluido=N", {headers: authHeader()})
          .then((response) => {
            return response.data;
          }).catch(error => {
            trataErroApi(error.response.data);
         });
      }

      async getAllPost() {
        const postParams = { flgExcluido: 'N' };
        return axios
          .post(API_URL + "equipe/getall", postParams, {headers: authHeader()})
          .then((response) => {
            return response.data;
          }).catch(error => {
            trataErroApi(error.response.data);
         });
      }

      async getById(id) {
        const postParams = { id: id };
        return axios
          .post(API_URL + "equipe/getById", postParams, {headers: authHeader()})
          .then((response) => {
            return response.data;
          }).catch(error => {
            trataErroApi(error.response.data);
         });
      }

      async getPerfilPorEquipe(cod_equipe) {
        const postParams = { codEquipe: cod_equipe };
        return axios
          .post(API_URL + "equipe/getPerfilPorEquipe", postParams, {headers: authHeader()})
          .then((response) => {
            return response.data;
          }).catch(error => {
            trataErroApi(error.response.data);
         });
      }

      async getUsuariosPorPerfil(cod_perfil,cod_equipe) {
        const postParams = { cod_perfil: cod_perfil, cod_equipe: cod_equipe };
        return axios
          .post(API_URL + "equipe/getUsuariosPorPerfil", postParams, {headers: authHeader()})
          .then((response) => {
            return response.data;
          }).catch(error => {
            trataErroApi(error.response.data);
         });
      }

      async getUsuariosPorBuscaDeTexto(texto) {
        const postParams = { texto: texto };
        return axios
          .post(API_URL + "equipe/getUsuariosPorBuscaDeTexto", postParams, {headers: authHeader()})
          .then((response) => {
            return response.data;
          }).catch(error => {
            trataErroApi(error.response.data);
         });
      }

      async getUsuariosParaIncluirNaEquipe(arrCodUsuariosExistentes = []) {
        const postParams = { arrCodUsuariosExistentes: arrCodUsuariosExistentes };
        return axios
          .post(API_URL + "equipe/getUsuariosParaIncluirNaEquipe", postParams, {headers: authHeader()})
          .then((response) => {
            return response.data;
          }).catch(error => {
            trataErroApi(error.response.data);
         });
      }

      async getEquipesUsuario(codUsuario) {
        const postParams = { codUsuario: codUsuario };
        return axios
          .post(API_URL + "equipe/getEquipesUsuario", postParams, {headers: authHeader()})
          .then((response) => {
            return response.data;
          }).catch(error => {
            trataErroApi(error.response.data);
         });
      }

      async removerUsuarioDaEquipe(codUsuarioEquipePerfil) {
        const postParams = { codUsuarioEquipePerfil: codUsuarioEquipePerfil };
        return axios
          .post(API_URL + "equipe/removerUsuarioDaEquipe", postParams, {headers: authHeader()})
          .then((response) => {
            return response.data;
          }).catch(error => {
            trataErroApi(error.response.data);
         });
      }

      async removerUsuarioDaEquipePorCodigoEquipeCodPerfil(codUsuario, codEquipe, codPerfil) {
        const postParams = { codUsuario: codUsuario, codEquipe : codEquipe, codPerfil: codPerfil };
        return axios
          .post(API_URL + "equipe/removerUsuarioDaEquipePorCodigoEquipeCodPerfil", postParams, {headers: authHeader()})
          .then((response) => {
            return response.data;
          }).catch(error => {
            trataErroApi(error.response.data);
         });
      }

      async salvarUsuariosNaEquipe(codEquipe, codPerfil, arrUsuarios) {
        const postParams = { codEquipe: codEquipe, codPerfil: codPerfil, arrUsuarios : arrUsuarios };
        return axios
          .post(API_URL + "equipe/salvarUsuariosNaEquipe", postParams, {headers: authHeader()})
          .then((response) => {
            return 'ok';
          }).catch(error => {
            trataErroApi(error.response.data);
         });
      }

  async montaPaginacao(postParams) {
   // const postParams = { codEquipe: codEquipe, codPerfil: codPerfil, arrUsuarios : arrUsuarios };
    return axios
      .post(API_URL + "equipe/montaPaginacao", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        trataErroApi(error.response.data);
      });
  }

  // async getMenu() {
  //   const postParams = {};
  //   return axios
  //     .post(API_URL + "equipe/get-menu", postParams, {headers: authHeader()})
  //     .then((response) => {
  //       return response.data;
  //     }).catch(error => {
  //       trataErroApi(error.response.data);
  //     });
  // }

  async getMenu() {
    const postParams = {};
    return axios
      .post(API_URL + 'equipe/get-menu', postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async verificaPermicao(rota) {
    const postParams = {rota: rota};
    return axios
      .post(API_URL + "equipe/check-permissao", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        trataErroApi(error.response.data);
      });
  }

  async getMenuHome(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "equipe/get-menu-home", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        if (error.response.data.code === 401) {
          localStorage.clear();
          window.location.href = '/login';
        } else if (error.response.data.code === 403) {
          trataErroApi(error.response.data);
        } else {
          return error.response.data
        }
      });
  }

  async getPerfiluserPublic(paranms = {}) {
    const postParams = paranms;
    return axios
      .post(API_URL + "equipe/getPerfiluserPublic", postParams, {headers: authHeader()})
      .then((response) => {
        return response.data;
      }).catch(error => {
        trataErroApi(error.response.data);
      });
  }

}export default new EquipeService();
