import axios from 'axios';
import authHeader from './auth-header';
import {history} from '../helpers/history';

const API_URL = process.env.REACT_APP_API_URL;
const authenticateTimeout = '';
const sesion = localStorage.getItem('user');

class AlterarSenhaService {

  alterarSenha(
    str_new_senha,
    str_conf_senha,
    str_login,
    url_token,
  ) {
    return axios.post(API_URL + 'usuario/alterar-senha', {
        str_new_senha,
        str_conf_senha,
        str_login,
        url_token,
      },
      {headers: authHeader()},
    );
  }
}

export default new AlterarSenhaService();
